import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { RequestService } from 'src/app/services/request/request.service';
//import { MyWalletComponent } from 'src/app/components/go-ecosystem/wallet-app/sections/my-wallet/my-wallet.component';
//import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ModalController,MenuController } from '@ionic/angular';
import { IonSlides } from '@ionic/angular';
import Swal2 from 'sweetalert2';
import { InAppPurchase2,IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import Swal from 'sweetalert2';
import { PagoVerificacionComponent } from 'src/app/components/pago-verificacion/pago-verificacion.component';
import { SliderInformationComponent } from 'src/app/components/slider-information/slider-information.component';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
//import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
import { threadId } from 'worker_threads';
import { Capacitor } from '@capacitor/core';
import { SeguridadComponent } from 'src/app/components/general-design/seguridad/seguridad.component';
import { PopoverController } from '@ionic/angular';
import { Bienes6Component } from 'src/app/components/publication/create/bienes/bienes6/bienes6.component';
//import { AppComponent } from 'src/app/app.component';
@Component({
  selector: 'app-Pagos-por-publicacion',
  templateUrl: './Pagos-por-publicacion.component.html',
  styleUrls: ['./Pagos-por-publicacion.component.scss']
})
export class PagosPorPublicacionComponent implements OnInit, OnDestroy {
  radioValue = true;
profile_id;
address;
  slideHubblePayOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 'auto',
    spaceBetween: 8,
    centeredSlides: false,
    freeMode: true,
    freeModeSticky: true,
    direction: 'horizontal',
    autoplay: {
      delay: 3000, // time in ms between slides, adjust as needed
      disableOnInteraction: false, // keeps autoplay enabled after user interaction
    },
  };
  currentUTCTime: string;
  public showCalendar: any;
 public calendarPrice: any;
  @ViewChild(IonSlides) slides: IonSlides;
  slideOpts = {
    direction: 'vertical',
    initialSlide: 0,
    speed: 400,
  };
selectedCurrencyIndex: number = -1; // Initialize the selected index
selectedCurrencyName: string = ''; 
public msg:string="Leyendo..";
public HubbleExpired: boolean = false;
	pr: any = '';
  @Input() public;
  //public balance: number;
  public balances : any [];
  public balanceRateMXN: any [];
  //public LaniakeaBalance = this.LaniakeWallet.LaniakeaBalance;
  hideBalance: boolean = false;
	hideBalanceMXN: boolean = false;
	hideBalanceBTC: boolean = false;
	hideBalanceUSDT: boolean = false;
	hideBalanceETH: boolean = false;
	hideBalanceLTC: boolean = false;
	hideBalanceXRP: boolean = false;
	hideBalanceDOGE: boolean = false;
	hideBalanceSHIB: boolean = false;
  productId2: string = '1618';
  listaDivisas=[

    // {
    //   divisa:"Btc",
    //   valor: 0.003,
    //   conversion: 2400.45
    // },
    // {
    //   divisa:"USDT",
    //   valor: 220,
    //   conversion:4700.45
    // },
    // {
    //   divisa:"ETH",
    //   valor: 6011.19,
    //   conversion:2400.45
    // },
    {
      divisa:"MXN",
      valor: this.walletService.balance,
      conversion:this.walletService.balance
    },
    // {
    //   divisa:"USD",
    //   valor: 10,
    //   conversion:210.45
    // },



    ]
    delivery_window_start;
    delivery_window_end;
    total_charge_value;
    total_charge_unit;
    delivery_payload;
    pick_up_window_start;
    pick_up_window_end;
    selected_balance;
  constructor(private walletService:WalletServiceService,
    private request: RequestService,
    private menu: MenuController,
    //public app :  AppComponent,
    private router: Router,
    //private wallet: MyWalletComponent,
    //private LaniakeWallet: WalletAppComponent,
    private profile:ProfileService, private modalController: ModalController, 	private store: InAppPurchase2, private faio:FingerprintAIO,   public formservice: FormServiceService,//, public app :  AppComponent,
    private popoverController: PopoverController
    ) { }

      openCarteraHubble(shortcut?) {
        try {
        this.router.navigate(['/home/ecosystem-page']);  
        Swal2.fire({
          title: 'Procesando...',
          text: 'Hubble está procesando.',
          icon: 'success',
          timer: 9900, // Show the alert for 3.9 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
          });
          //if (this.app.SubscriptionExpired !== true){
        if (!this.HubbleExpired){
          this.HubbleExpired = true;

          let TotalPayment = 0;
          if (this.showCalendar){
            TotalPayment = this.calendarPrice * 1.1;
          }else{
            TotalPayment = this.public.price * 1.1;
          }
         // this.request.createRequestGet("Hubble", null, { id: this.profile_id })
         // .then((result) => {
         //   if (result && result.length > 0) {
           // console.log("Successfully fetched Hubble info.");
           // console.log("Hubble info:", result);
        
           // const wallets = JSON.parse(result);
           // const transactions = wallets[0].transactions;
        
           // const balancesArray = wallets.map((wallet) => wallet.balance);
           let objmedia;
           if (this.public.publication_type === 'land' || this.public.publication_type === 'earth'){
            objmedia = {
            publication_id: this.public.id, 
            buyer_id: this.pr.id, // Replace with the actual buyer's ID
          //  currency: this.selectedCurrencyName, // Replace with the actual currency
            Availability: this.formservice.earthpublication.ReservaCalendario,
            publication_type: this.public.publication_type
          };
        }else {
          objmedia = {
          publication_id: this.public.id, 
          buyer_id: this.pr.id, // Replace with the actual buyer's ID
       //   currency: this.selectedCurrencyName, // Replace with the actual currency
          publication_type: this.public.publication_type
        };
      }

          if (this.public){
            this.modalController.create({
              component: WalletAppComponent,
              cssClass: 'detailsInModal',
              swipeToClose: true,
              componentProps: {
                shortcut:'pagoDePublicacion',
                publication_id:this.public.id,
                objmedia:objmedia,
              profile_id: this.profile_id,
              TotalPayment: TotalPayment,
           //   transactions: transactions,
          //    balances: balancesArray,
              },
            }).then((modal) => {
              
              modal.present();
              this.HubbleExpired = false;
            });
                        
          }
          /*     } else {
            console.log("No results found.");
            }
       })
          .catch((error) => {
            Swal2.fire({
              title: 'Hubo un error al abrir la cartera...',
              text: 'Intenta de nuevo más tarde.',
              icon: 'error',
              timer: 3600, // Show the alert for 2 seconds
              timerProgressBar: true,
              toast: true,
              position: 'top-end',
              showConfirmButton: false
            });
            console.error("Error fetching Hubble info:", error);
          });*/
        }
      /* }else if (this.app.SubscriptionExpired === true){
        Swal2.fire({
          title: 'Hubo un error al abrir la cartera...',
          text: 'Tu suscripción ha caducado, reneuévala para continuar en la cartera Hubble.',
          icon: 'error',
          timer: 3600, // Show the alert for 2 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
        });
        }*/
      }catch{
        Swal2.fire({
          title: 'Hubo un error al abrir la cartera...',
          text: 'Tu suscripción ha caducado, reneuévala para continuar en la cartera Hubble.',
          icon: 'error',
          timer: 3600, // Show the alert for 2 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
        });
      }
      }
    
ngOnDestroy(): void {
  this.formservice.dias = 0;
  this.formservice.horas = 0;
  this.formservice.cupos = 0;
  this.formservice.payCalendar =false;
  this.formservice.EarthCalendar =false;
  this.formservice.Availability=0;
}
  ngOnInit() {
    if(!this.balances){
      this.changeHiddenBalance();
    }
    this.updateCurrentUTCTime(); // Initialize the time immediately
    setInterval(() => {
      this.updateCurrentUTCTime(); // Update the time every second
    }, 1000);
    if (this.public.publication_type === 'land' ) {
      this.formservice.payCalendar =true;
      this.showCalendar = true;
      this.solicitarCupoLand(null, null);
      this.formservice.time_zone=this.public?.time_zone;
 
    }else if (this.public.publication_type === 'earth'){
      this.formservice.payCalendar =true;
      this.showCalendar = true;
     this.formservice.EarthCalendar =true;
     this.formservice.time_zone=this.public?.time_zone;
    }else if(this.public.publication_type === 'feex'){
      console.log('Partida',this.public?.location )
      this.request.createRequestPost('ShippingAddressesController', {get:true })
      .then((data) => {
        if (data && typeof data === 'string') {
          try {
            data = JSON.parse(data);
            console.log('getRecordByToken ios:',data);
          } catch (error) {
            console.error("Error parsing data:", error);
          }
          }
        console.log('ShippingAddressesController',data);
       this.address=data.title;
       let getRates;
       let getUberRates;
       if(this.public.envio === 'Amazon Shipping'){
        getRates=true;
        getUberRates=false;
       }else if(this.public.envio == 'Uber Eats'){
        getRates=false;
        getUberRates=true;
       }
       this.request.createRequestPost('handleShipping', {getRates:getRates,getUberRates:getUberRates, publication_id:this.public.id })
       .then((data) => {
        if (data && typeof data === 'string') {
          try {
            data = JSON.parse(data);
            console.log('getRecordByToken ios:',data);
          } catch (error) {
            console.error("Error parsing data:", error);
          }
          }
         console.log('data',data);
         if(this.public.envio === 'Amazon Shipping'){
         const rate = data.payload.rates[0]; // Access the first rate object (index 0)
          this.delivery_payload=rate;
         this.delivery_window_start = rate.promise.deliveryWindow.start;  
         this.delivery_window_end = rate.promise.deliveryWindow.end;
         this.total_charge_value = rate.totalCharge.value;  
this.total_charge_unit = rate.totalCharge.unit;

this.pick_up_window_start = this.delivery_payload?.promise?.pickupWindow?.start ;
this.pick_up_window_end =this.delivery_payload?.promise?.pickupWindow?.end;
         }else if(this.public.envio === 'Uber Eats'){
           
          const rate = data; // Access the first rate object (index 0)
          this.delivery_payload=rate;
         this.delivery_window_start = rate.dropoff_eta;  
         this.delivery_window_end = rate.dropoff_deadline;
         this.pick_up_window_start = rate.pickup_duration;
         this.pick_up_window_end =rate.duration;
         this.total_charge_value = rate.fee/100;  
this.total_charge_unit = rate.currency_type;
         }
      
       });
      }).catch((error) => {
        console.error('Failed to send notification:', error);
     
      });
    }
    //console.log('balances para pagar', this.balances);
    this.profile.getProfileInfo().then((result)=>{
      this.pr = JSON.parse(result);
      console.log("profile", this.pr);
      this.profile_id = result.id;
      });
      
  }

  async openShippingAddressPopover(ev: Event) {
    const popover = await this.popoverController.create({
      component: Bienes6Component,
      componentProps: {
        show_save_address_button:true,
      },
    //  event: ev,            // Positions the popover near the clicked element
      translucent: true,     // Optional: makes the popover semi-transparent
      cssClass: 'my-custom-popover' // Optional: custom styling class
    });
    await popover.present();
  }
  get_divisas_name(i) {
    return 'assets/divisas/logos-png/currency-'+(i+1)+'.png';
  }
  changeHiddenBalance() {
    this.getCarteraHubble();
		this.hideBalance = (this.hideBalance) ? false : true;
	this.changeHiddenBalanceMXN();
	this.changeHiddenBalanceBTC();
	this.changeHiddenBalanceUSDT();
	this.changeHiddenBalanceETH();
	this.changeHiddenBalanceLTC();
	this.changeHiddenBalanceXRP();
	this.changeHiddenBalanceDOGE();
	this.changeHiddenBalanceSHIB();
	}
	changeHiddenBalanceMXN() {
		this.hideBalanceMXN = (this.hideBalanceMXN) ? false : true;
	}
	changeHiddenBalanceBTC() {
		this.hideBalanceBTC = (this.hideBalanceBTC) ? false : true;
	}
	changeHiddenBalanceUSDT() {
		this.hideBalanceUSDT = (this.hideBalanceUSDT) ? false : true;
	}
	changeHiddenBalanceETH() {
		this.hideBalanceETH = (this.hideBalanceETH) ? false : true;
	}
	changeHiddenBalanceLTC() {
		this.hideBalanceLTC = (this.hideBalanceLTC) ? false : true;
	}
	changeHiddenBalanceXRP() {
		this.hideBalanceXRP = (this.hideBalanceXRP) ? false : true;
	}
	changeHiddenBalanceDOGE() {
		this.hideBalanceDOGE = (this.hideBalanceDOGE) ? false : true;
	}
	changeHiddenBalanceSHIB() {
		this.hideBalanceSHIB = (this.hideBalanceSHIB) ? false : true;
	}

  
  getCarteraHubble() {
    console.log('cartera pr', this.pr.id);
    this.request.createRequestGet("Hubble", null, { id: this.pr.id })
      .then((result) => {
      if (result && result.length > 0) {
        console.log("Successfully fetched Hubble info.");
        console.log("Hubble info:", result);
    
        const wallets = JSON.parse(result);
        const transactions = wallets[0].transactions;
    
        const balancesArray = wallets.map((wallet) => wallet.balance);
    
  this.balances = balancesArray;
  this.retrieveBalanceRateMXN();
      } else {
        console.log("No results found.");
      }
      })
      .catch((error) => {
      console.error("Error fetching Hubble info:", error);
      });
      
    }

    
  	public calculateSumatory(): number {
      if (this.balanceRateMXN && this.balanceRateMXN.length > 0) {
        const balance1 = parseFloat(this.balances?.[0]?.currency_1 ?? '0');
        const sum = this.balanceRateMXN.reduce((acc, rate) => acc + rate, balance1);
  
        return sum;
      }
      return 0;
      }
      public retrieveBalanceRateMXN() {
        const currencyMap = {
          currency_2: 'BTC',
          currency_3: 'USDT',
          currency_4: 'ETH',
          currency_5: 'LTC',
          currency_6: 'XRP',
          currency_7: 'DOGE',
          currency_8: 'SHIB',
        };
        
        const balanceOrder = ['currency_2', 'currency_3', 'currency_4', 'currency_5', 'currency_6', 'currency_7', 'currency_8'];
        
        const balances = this.balances ?? [];
        const balanceRateArray = [];
        
        const requests = balances.map((balance) => {
          const currencyKey = Object.keys(balance)[0];
          const currencyName = currencyMap[currencyKey];
          const params: { [key: string]: string | string[] } = {
          currency: currencyName,
          amount: balance[currencyKey].toString(),
          };
        
          return this.request.createRequestGet('Conversiones', params)
          .then((response) => {
            console.log('Conversion response:', response);
            const value = parseFloat(response.match(/float\((.*)\)/)?.[1] || '0');
            console.log(`balance rate for ${currencyKey}:`, value);
        
            const index = balanceOrder.indexOf(currencyKey);
            balanceRateArray[index] = value;
        
            return value;
          })
          .catch((error) => {
            console.error('Conversion error:', error);
            balanceRateArray.push(0); // Provide a default value (0) for the balance rate
            return 0; // Return 0 as the value for the error case
          });
        });
        
        Promise.all(requests)
          .then(() => {
          console.log('Balance rates array:', balanceRateArray);
          this.balanceRateMXN = balanceRateArray;
          // Rounding the decimals into integers
this.balanceRateMXN = this.balanceRateMXN.map(rate => Math.round(rate));
          })
          .catch((error) => {
          console.error('Error retrieving balance rates:', error);
          });
        }  
        dismiss() {
          this.modalController.dismiss();
        }
        PAY() {
          if (this.public.publication_type === 'land' || this.public.publication_type === 'earth'){
          this.CapturaReservaEarth();
          this.CapturaReservaLand();
          }
          if (this.selectedCurrencyName){
          if (this.walletService.SubscriptionExpired !== true){
          if (this.public.publication_type === 'lkfilm' || this.public.publication_type === 'normal' ||  this.public.publication_type === 'eclipse' || this.public.publication_type === 'epoch' ){
          
        //  if (!this.HubbleExpired){
          console.log('public', this.public.id, );

          const objmedia = {
            publication_id: this.public.id, 
            buyer_id: this.pr.id, // Replace with the actual buyer's ID
            currency: this.selectedCurrencyName // Replace with the actual currency
          };
          
          console.log('objmedia', objmedia);
        
          this.request.createRequestPost('PagoDePublicaciones', objmedia)
            .then((response) => {
              if (response && typeof response === 'string') {
                try {
                  response = JSON.parse(response);
                  console.log('getRecordByToken ios:',response);
                } catch (error) {
                  console.error("Error parsing data:", error);
                }
                }
              this.sendNotificationCompra(this.public);
              console.log('Transaction completed successfully', response);
              Swal2.fire({
                title: 'Pago completado',
                text: `Pago completado por ${this.public.price * 1.1} MXN. La publicación se ha guardado exitosamanete en tu colección.`,
                icon: 'success',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
                position: 'top-end',
                timerProgressBar: true,
                toast: true,
                }).then(() => {
                  this.modalController.dismiss();
                console.log("Alert displayed successfully.");
                }).catch((error) => {
                console.error("Error displaying alert:", error);
                });
              // Handle the successful transaction response
            })
            .catch((error) => {
              console.error('Transaction failed', error);

let errorMessage = 'An error occurred';
if (error.message) {
  errorMessage = error.message; // Extract the error message from the response object
}

Swal2.fire({
  title: 'La transacción no pudo ser procesada',
  text: errorMessage,
  icon: 'error',
  confirmButtonText: 'Entendido',
  color: '#ffffff',
  background: '#010f4e',
  position: 'top-end',
  timerProgressBar: true,
  toast: true,
}).then(() => {
  this.modalController.dismiss();
  console.log("Alert displayed successfully.");
}).catch((error) => {
  console.error("Error displaying alert:", error);
});

              // Handle the failed transaction response
            });
         // }
      
      }else if (this.public.publication_type === 'land' ){
if (this.formservice.landpublication.IsReservaCalendario === true){

        console.log('public', this.public.id, );

        const objmedia = {
          publication_id: this.public.id, 
          buyer_id: this.pr.id, // Replace with the actual buyer's ID
          currency: this.selectedCurrencyName, // Replace with the actual currency
          Availability: this.formservice.landpublication.ReservaCalendario,
          publication_type: this.public.publication_type
        };
        
        console.log('objmedia', objmedia);
      
        this.request.createRequestPost('PagoDePublicacionesCalendario', objmedia)
          .then((response) => {
            if (response && typeof response === 'string') {
              try {
                response = JSON.parse(response);
                console.log('getRecordByToken ios:',response);
              } catch (error) {
                console.error("Error parsing data:", error);
              }
              }
            this.sendNotificationCompra(this.public);
            console.log('Transaction completed successfully', response);
            Swal2.fire({
              title: 'Pago completado',
              text: `Pago completado por ${this.calendarPrice * 1.1} MXN. La publicación se ha guardado exitosamanete en tu colección.
               ¡Ya eres amigo del vendedor, ponte en contacto para más detalles de la reservación!`,
              icon: 'success',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
              }).then(() => {
                this.modalController.dismiss();
              console.log("Alert displayed successfully.");
              }).catch((error) => {
              console.error("Error displaying alert:", error);
              });
            // Handle the successful transaction response
          })
          .catch((error) => {
            console.error('Transaction failed', error);

            let errorMessage = 'An error occurred';
            if (error.error) {
              errorMessage ='Ocurrió un error inseperado, si persiste comunícate con el soporte de Laniakea'; // Extract the error message from the response object
             
            }
            
            Swal2.fire({
              title: 'La transacción no pudo ser procesada',
              text: errorMessage,
              icon: 'error',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
            }).then(() => {
              this.modalController.dismiss();
              console.log("Alert displayed successfully.");
            }).catch((error) => {
              console.error("Error displaying alert:", error);
            });
            
            // Handle the failed transaction response
          });
        }else{
          Swal2.fire({
            title: 'Debes primero agendar',
            text: `El pago no pudo ser procesado, primero debes agendar una fecha en el horario de disponibilidad`,
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
            }).then(() => {
              this.modalController.dismiss();
            console.log("Alert displayed successfully.");
            }).catch((error) => {
            console.error("Error displaying alert:", error);
            });
        }
      } else if (this.public.publication_type === 'earth'){
        if (this.formservice.earthpublication.IsReservaCalendario === true){
          console.log('public', this.public.id, );
 
          const objmedia = {
            publication_id: this.public.id, 
            buyer_id: this.pr.id, // Replace with the actual buyer's ID
            currency: this.selectedCurrencyName, // Replace with the actual currency
            Availability: this.formservice.earthpublication.ReservaCalendario,
            publication_type: this.public.publication_type,
            Step1_PSP_Paying:true
          };
          
          console.log('objmedia', objmedia);
        
          this.request.createRequestPost('PagoDePublicacionesCalendario', objmedia)
            .then((response) => {
              if (response && typeof response === 'string') {
                try {
                  response = JSON.parse(response);
                  console.log('getRecordByToken ios:',response);
                } catch (error) {
                  console.error("Error parsing data:", error);
                }
                }
              this.sendNotificationCompra(this.public);
              console.log('Transaction completed successfully', response);
              Swal2.fire({
                title: 'Pago completado',
                text: `Pago completado por ${this.calendarPrice * 1.1} MXN. La publicación se ha guardado exitosamanete en tu colección.
                ¡Ya eres amigo del vendedor, ponte en contacto para más detalles de la reservación!`,
                icon: 'success',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
                position: 'top-end',
                timerProgressBar: true,
                toast: true,
                }).then(() => {
                  this.modalController.dismiss();
                console.log("Alert displayed successfully.");
                }).catch((error) => {
                console.error("Error displaying alert:", error);
                });
              // Handle the successful transaction response
            })
            .catch((error) => {
              console.error('Transaction failed', error);

              let errorMessage = 'An error occurred';
              if (error.error) {
                errorMessage ='Ocurrió un error inseperado, si persiste comunícate con el soprte de Laniakea'; // Extract the error message from the response object
              
              }
              
              Swal2.fire({
                title: 'La transacción no pudo ser procesada',
                text: errorMessage,
                icon: 'error',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
              }).then(() => {
                this.modalController.dismiss();
                console.log("Alert displayed successfully.");
              }).catch((error) => {
                console.error("Error displaying alert:", error);
              });
              
              // Handle the failed transaction response
            });
          }else{
            Swal2.fire({
              title: 'Debes primero agendar',
              text: `La transacción no pudo ser procesada, primero debes agendar una fecha en el horario de disponibilidad`,
              icon: 'error',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
              }).then(() => {
                this.modalController.dismiss();
              console.log("Alert displayed successfully.");
              }).catch((error) => {
              console.error("Error displaying alert:", error);
              });
          }
      }else if(this.public.publication_type === 'feex'){
        const objmedia = {
          publication_id: this.public.id, 
          buyer_id: this.pr.id, // Replace with the actual buyer's ID
          payment_currency: this.selectedCurrencyName ,// Replace with the actual currency
          currency:this.selectedCurrencyName ,
          sendRequestToSeller:true,
          shipping_cost:this.total_charge_value,
          shipping_cost_currency:this.total_charge_unit,
          pick_up_window_start:this.pick_up_window_start ,
          pick_up_window_end:this.pick_up_window_end ,
          delivery_window_start:this.delivery_window_start,
          delivery_window_end:this.delivery_window_end,
          delivery_type:this.public.envio,
          
        };
        
        console.log('objmedia', objmedia);
      
        this.request.createRequestPost('PagoDePublicaciones', objmedia)
          .then((response) => {
            if (response && typeof response === 'string') {
              try {
                response = JSON.parse(response);
                console.log('getRecordByToken ios:',response);
              } catch (error) {
                console.error("Error parsing data:", error);
              }
              }
            this.sendNotificationCompra(this.public);
            console.log('Transaction completed successfully', response);
            Swal2.fire({
              title: 'Transacción procesada',
              text: `La transacción se ha procesado exitosamente, el vendedor aceptará o rechazará tu compra.`,
              icon: 'success',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
              }).then(() => {
                this.modalController.dismiss();
              console.log("Alert displayed successfully.");
              }).catch((error) => {
              console.error("Error displaying alert:", error);
              });
            // Handle the successful transaction response
          })
          .catch((error) => {
            console.error('Transaction failed', error);

let errorMessage = 'An error occurred';
if (error.message) {
errorMessage = error.message; // Extract the error message from the response object
}

Swal2.fire({
title: 'La transacción no pudo ser procesada',
text: errorMessage,
icon: 'error',
confirmButtonText: 'Entendido',
color: '#ffffff',
background: '#010f4e',
position: 'top-end',
timerProgressBar: true,
toast: true,
}).then(() => {
this.modalController.dismiss();
console.log("Alert displayed successfully.");
}).catch((error) => {
console.error("Error displaying alert:", error);
});

            // Handle the failed transaction response
          });
      }
    }else if (this.walletService.SubscriptionExpired === true){
      this.RenewSuscription();
    }
  }else{
    Swal2.fire({
      title: 'Debes elegir una moneda',
      text: `La transacción no pudo ser procesada, primero debes elegir una moneda para pagar`,
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      }).then(() => {
        this.modalController.dismiss();
      console.log("Alert displayed successfully.");
      }).catch((error) => {
      console.error("Error displaying alert:", error);
      });
  }
        }
        checkboxChanged(index: number, currencyName: string, balance_selected) {
          this.selected_balance=balance_selected;
          if(!this.balances){
           return;
          }
          const checkboxes = document.getElementsByClassName('Select_moneda')[0].getElementsByTagName('ion-item');

// Iterate through checkboxes from index 0 to 8
for (let i = 0; i <= 8; i++) {
  const checkbox = checkboxes[i].querySelector('ion-checkbox');
  checkbox.removeAttribute('checked');
}
          if (this.selectedCurrencyIndex === index) {
            // Uncheck the currently selected checkbox
            this.selectedCurrencyIndex = -1;
            this.selectedCurrencyName = '';
          } else {
            // Uncheck the previously selected checkbox
            if (this.selectedCurrencyIndex !== -1) {
              const previousCheckbox = document.getElementsByClassName('Select_moneda')[0].getElementsByTagName('ion-item')[this.selectedCurrencyIndex];
              previousCheckbox.querySelector('ion-checkbox').setAttribute('checked', 'false');
            }
            
            // Check the newly selected checkbox
            this.selectedCurrencyIndex = index;
            this.selectedCurrencyName = currencyName;
           // console.log( 'balances:', this.selected_balance);
          }
        }
        
        sendNotificationCompra(pub) {
   
          return new Promise((resolve, reject) => {
            const notification = {
            title: 'Nueva compra recibida',
            body: `¡Has recibido una compra de @${this.pr.username} en tu publicación!`,
            user_id: pub.userProfile.user_id,
        
            };
          
            this.request.createRequestPost('GreatAtractorNotifications', notification)
            .then((data) => {
            
              resolve(data);
            }).catch((error) => {
              console.error('Failed to send notification:', error);
              reject(error);
            });
          });
          }
          async RenewSuscription(): Promise<void> {
            Swal.fire({
              title: 'Sucripción de usuario...',
              text: 'Tu suscripción de usuario verificado ha expirado. Espera...',
              icon: 'success',
              timer: 4500, // Show the alert for 4.5 seconds
              timerProgressBar: true,
              toast: true,
              position: 'top-end',
              showConfirmButton: false
              });
            return new Promise<void>(async (resolve, reject) => {
              this.store.verbosity = this.store.DEBUG;
            
              // Register the product with the given ID
              this.store.register({
              id: this.productId2,
              alias: this.productId2,
              type: this.store.PAID_SUBSCRIPTION, // Change the type to PAID_SUBSCRIPTION
              });
            
              // Fetch the product information
              const product = await this.store.get(this.productId2);
            
              // Updated
              this.store.when(this.productId2).updated((product: IAPProduct) => {
	
                console.log('Updated' + JSON.stringify(product));
                });
            
              // User closed the native purchase dialog
              this.store.when(this.productId2).cancelled((product) => {
              console.error('Purchase was Cancelled');
              });
            
              // Run some code only when the store is ready to be used
              this.store.ready(() => {
              console.log('Store is ready');
              console.log('Products: ' + JSON.stringify(this.store.products));
              console.log(JSON.stringify(this.store.get(this.productId2)));
              });
            
              if (product) {
              // Check if the product is already owned
              if (product.state === this.store.OWNED) {
                //this.PAY();
                console.log('Product already owned');
                resolve();
                return;
              }
            
              // Check if the product is invalid
              if (product.state === this.store.INVALID) {
                reject(new Error('Product is invalid'));
                return;
              }
             
            
            
            
              // To make a purchase
              this.store.order(this.productId2);
            
              // Proceed with the purchase flow
              this.store.when(this.productId2).approved((product) => {
                product.finish();
            
                console.log('Subscription ordered!');
            
                this.store.when(this.productId2).owned(async (product) => {
                  this.PAY();
                console.log('Product is owned');
                this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Ya tienes acceso a la cartera Hubble.');
                
                });
              });
            
              // Handle error events
              this.store.when(this.productId2).error((err) => {
                console.log('Error occurred during purchase:', err);
                reject(err);
              });
            
              // Refresh the product information
              this.store.refresh();
              } else {
              reject(new Error('Failed to fetch product information'));
              }
            });
            }
            
            sendNotification(title, body) {
             
            return new Promise((resolve, reject) => {
              const notification = {
              title: title,
              body: body,
              user_id:this.pr.id,
          
              };
            
              this.request.createRequestPost('GreatAtractorNotifications', notification)
              .then((data) => {
               
                resolve(data);
              }).catch((error) => {
                console.error('Failed to send notification:', error);
                reject(error);
              });
            });
            }
            onClick() {
              if(this.public.price * 1.1 >     this.selected_balance )
              if(this.public.publication_type === 'feex' && !this.address){
                Swal.fire({
                  title: 'ERROR...',
                  text: 'Hubble identifica que no tienes una dirección.',
                  icon: 'error',
                  timer: 9900, // Show the alert for 9.9 seconds
                  timerProgressBar: true,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  confirmButtonText: 'Añadir'
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.openShippingAddressPopover(null);
                  }
                });
return;
              }
              if(Capacitor.getPlatform()!=='web'){
              this.faio.show({
                title: "Leyendo huella Digital",
                subtitle: "Use su huella para permitir el acceso a retiros",
              })
                .then((res) => {
      
                      // Call envioRegalo() here
                      this.PayKYCApplicationModal();
             
                  console.log(res);
                  this.msg = 'Se valido el acceso con tu huella';
              
                })
                .catch((err) => {
                  console.log(err);
                  this.msg = 'Acceso incorrecto';
                  alert(err);
                });
              }else{
                if(this.formservice.face_id_active){
                this.modalController.create({
                  component: SeguridadComponent,
                  cssClass: 'detailsInModal',
                  swipeToClose: true,
                  componentProps: {
                    face_id: true,
                  }
                  }).then((modal) => {
                  modal.onDidDismiss().then((data) => {
                    console.log('Modal dismissed:', data);
                    if (data.data.role == 'success' ) {
                    console.log('Output data:', data.role);
                    // Handle the output data from the modal here
                     // Call envioRegalo() here
                     this.PayKYCApplicationModal();
                    }
                  });
                  modal.present();
                   // Listen to the output when the modal is dismissed
                
                  }).catch((error) => {
                  console.error(error);
                  });
                                    
                }else{
                  this.enterPIN_to_enter_Hubble();
                }
              }
            }
            async enterPIN_to_enter_Hubble(){
              try {
                const { value: pin } = await Swal.fire({
                  title: 'Facial ID desactivado. Ingresa PIN',
                  text: `Por favor ingresa tu PIN alfanumรฉrico. Este PIN fue enviado a tu correo, si no tienes ninguno, puedes solicitarlo en Mรกs/Configuraciรณn/Seguridad/PIN de Hubble.`,
                  icon: 'info',
                  confirmButtonText: 'Validar',
                  color: '#ffffff',
                  background: '#010f4e',
                  position: 'top-end',
                  timerProgressBar: true,
                  toast: true,
                  input: 'password',  // Change input type to 'password' to hide input content
                  inputPlaceholder: 'Ingresa tu PIN',
                  inputAttributes: {
                  'aria-label': 'PIN'
                  },
                  showCancelButton: true,
                  inputValidator: (value) => {
                  if (!value) {
                    return 'Debes ingresar un PIN!';
                  }
                  }
                });
              
                if (pin) {
                  Swal.fire({
                    title: 'Procesando...',
                    text: 'Hubble está procesando.',
                    icon: 'success',
                    timer: 3900, // Show the alert for 3.9 seconds
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false
                    });
                  const PIN = pin;  // Store the input value in a constant called PIN
                  console.log("Entered PIN:", PIN);
                  this.request.createRequestPost('enterToWalletWithPIN', { token: PIN }).then((response) => {
                    if (response && typeof response === 'string') {
                      try {
                        response = JSON.parse(response);
                        console.log('getRecordByToken ios:',response);
                      } catch (error) {
                        console.error("Error parsing data:", error);
                      }
                      }
                    console.log("response:", response);
                  if (response.title =='El email ha sido confirmado!'){
                    Swal.fire({
                      title: 'Procesando...',
                      text: 'Hubble está procesando.',
                      icon: 'success',
                      timer: 3900, // Show the alert for 3.9 seconds
                      timerProgressBar: true,
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false
                      });
                    this.PayKYCApplicationModal();
                  }else{
                    Swal.fire({
                      title: 'Procesando...',
                      text: response.title,
                      icon: 'error',
                      timer: 3900, // Show the alert for 3.9 seconds
                      timerProgressBar: true,
                      toast: true,
                      position: 'top-end',
                      showConfirmButton: false
                      });
                  }
                }).catch((error) => {
                  console.error('Failed to send request:', error);
                });
                }
              } catch (error) {
                console.error("Error displaying alert:", error);
                }
            }
            private PayKYCApplicationModal() {
              Swal.fire({
                title: 'Procesando...',
                text: 'Hubble está procesando.',
                icon: 'success',
                timer: 3000, // Show the alert for 3.0 seconds
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false
                });
                console.log('openKYCApplicationModal called');
                this.walletService.KYC_validation().then((result_kyc) => {
                  console.log('KYC_validation result:', result_kyc);
                  if (result_kyc['permit']) {
                  this.PAY();
                  } else if (this.pr.kyc === "Sin Validar") {
                  this.showSliderInformationModal();
                  } else {
                  this.showPagoVerificacionModal();
                  }
                }).catch((error) => {
                  console.error('KYC_validation error:', error);
                  console.error(error);
                });
                }
                private showSliderInformationModal() {
                  console.log('showSliderInformationModal called');
                  this.modalController.create({
                    component: SliderInformationComponent,
                    cssClass: 'detailsInModal',
                    swipeToClose: true,
                    componentProps: {
                    pr: this.pr,
                    },
                  }).then((modal) => {
                    modal.present();
                  }).catch((error) => {
                    console.error(error);
                  });
                  }
                  private showPagoVerificacionModal() {
                    console.log('showPagoVerificacionModal called');
                    console.log("entro al else if ");
                    
                    this.modalController.create({
                      component: PagoVerificacionComponent,
                      cssClass: 'detailsInModal',
                      swipeToClose: true,
                      componentProps: {
                      pr: this.pr
                      }
                    }).then((modal) => {
                      modal.present();
                    });
                    }

                    getLandCalendarPrice(){ 
                      if (this.public.publication_type === 'land'){
                      if(this.public.price){
                      console.log('1');
                      this.calendarPrice = this.public.price * this.formservice.cupos * this.formservice.horas * this.formservice.dias;
                      console.log('cp', this.calendarPrice);
                      }
                    }
                    }

                    getEarthCalendarPrice(){

                      if (this.public.publication_type === 'earth'){
                      if(this.public.price){
                        console.log('1');
                        this.calendarPrice = this.public.price * this.formservice.dias;
                        console.log('cp', this.calendarPrice);
                        }
                      }
                    }
                    updateCurrentUTCTime() {
                      const now = new Date();
                      const currentTime = now.toISOString().split('T')[1].substring(0, 8);
                      this.currentUTCTime = currentTime; // Get the current UTC time (HH:mm:ss)
                    }

                    solicitarCupoLand(dias, horas) {
                      console.log('dias', dias);
                      console.log('horas', horas);
                      console.log('id', this.public.id);
                    
                      const params: { [key: string]: string | string[] } = {
                        publicacion: this.public.id.toString(),
                        dias: [dias] ,   // ? [dias] : 'null' Convert the single value to an array
                        horas: horas,   // ? horas : 'null'  No need to modify horas, as it's already an array
                      };
                      
                      this.request.createRequestGet('CupoDisponible', params)
                        .then((result) => {
                          console.log('Conversion response:', result);
                          JSON.parse(result);
                          console.log('Conversion response::', result);
                          this.formservice.Availability = result;
                          
                        });
                    }
                    

                    solicitarCupodeMesLand(dias, horas) {
                      console.log('dias', dias);
                      console.log('horas', horas);
                      console.log('id', this.public.id);
                    
                      const params: { [key: string]: string | string[] } = {
                        publicacion: this.public.id.toString(),
                        dias: dias ? [dias] : 'null',   // Convert the single value to an array
                        horas: horas ? horas : 'null',   // No need to modify horas, as it's already an array
                      };
                      
                      this.request.createRequestGet('CupoDisponible', params)
                        .then((result) => {
                          console.log('Conversion response:', result);
                          JSON.parse(result);
                          console.log('Conversion response::', result);
                          this.formservice.Availability = result;
                          
                        });
                    }
                    solicitarCupoEarth(dias, horas) {
                      console.log('dias', dias);
                      console.log('horas', horas);
                      console.log('id', this.public.id);
                    
                      const params: { [key: string]: string | string[] } = {
                        publicacion: this.public.id.toString(),
                        dias: [dias] ,   // ? [dias] : 'null'  Convert the single value to an array
                        horas: horas ? horas : 'null',   // No need to modify horas, as it's already an array
                      };
                      
                      this.request.createRequestGet('CupoDisponibleEarth', params)
                        .then((result) => {
                          console.log('Conversion response:', result);
                          JSON.parse(result);
                          console.log('Conversion response::', result);
                          this.formservice.Availability = result;
                          
                        });
                    }
                    
                    CapturaReservaLand() {


                      let Reservas = this.formservice.landpublication.ReservaCalendario;
                  
                      // console.log('Reserva en descripcion:', Reservas);
                  
                      const Agenda: Array<object> = [];
                  
                      Agenda.push(Reservas);
                  
                      // console.log('Agenda:', Agenda);
                  
                      Agenda.forEach((element) => {
                        let key = Object.keys(element);
                        let valor = Object.values(element);
                  
                        valor = valor.map((obj, idx) => ({
                          fecha: key[idx],
                          cupo: obj.cupo,
                          horas: obj.hora,
                        })
                  
                  
                        );
                  
                        this.formservice.landpublication.ReservaCalendario = valor
                        // console.log("nuevo valor ReservaC:", this.formservice.landpublication.ReservaCalendario);
                  
                  
                        // console.log("Latitud:", this.formservice.landpublication.latitud, "Longitud", this.formservice.landpublication.longitud);
                  
                  
                      });
                  
                  
                    }
                    CapturaReservaEarth() {
                  
                  
                      let Reservas = this.formservice.earthpublication.ReservaCalendario;
                  
                      // console.log('Reserva en descripcion:', Reservas);
                  
                      const Agenda: Array<object> = [];
                  
                      Agenda.push(Reservas);
                  
                      // console.log('Agenda:', Agenda);
                  
                      Agenda.forEach((element) => {
                        let key = Object.keys(element);
                        let valor = Object.values(element);
                  
                        valor = valor.map((obj, idx) => ({
                          fecha: key[idx],
                          cupo: obj.cupo,
                          horas: obj.hora,
                        })
                  
                  
                        );
                  
                        this.formservice.earthpublication.ReservaCalendario = valor
                        // console.log("nuevo valor ReservaC:", this.formservice.landpublication.ReservaCalendario);
                  
                  
                        // console.log("Latitud:", this.formservice.landpublication.latitud, "Longitud", this.formservice.landpublication.longitud);
                  
                  
                      });
                  
                  
                    }
                  
}
