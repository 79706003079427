import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { PublicacionesGuardadasComponent } from '../../publicaciones-guardadas/publicaciones-guardadas/publicaciones-guardadas.component';
import { PublicacionesResumenComponent } from '../../publicaciones-resumen/publicaciones-resumen/publicaciones-resumen.component';
import { PublicationFeedPage } from 'src/app/pages/publication-feed/publication-feed.page';
import { MainPublicationsSwipecontainerComponent } from './../../../../../pages/publication-feed/main-publications-swipecontainer/main-publications-swipecontainer.component';
import Swal2 from 'sweetalert2';
import {ModulesLockComponent} from "../../../../go-ecosystem/modules-lock/modules-lock.component";
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import {ColeccionPremium} from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/ColeccionLaniakea/Coleccion-premium/Coleccion-premium';
import {ColeccionRegalos} from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/ColeccionLaniakea/Coleccion-regalos/Coleccion-regalos';
import { RequestService } from 'src/app/services/request/request.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-publicaciones-coleccion',
  templateUrl: './publicaciones-coleccion.component.html',
  styleUrls: ['./publicaciones-coleccion.component.scss'],
})
export class PublicacionesColeccionComponent implements OnInit {
  @Input() publications;
  selectVal:number=104
  @Input() profile_id: any;
  divisaSelect:string=""
  currency: string;
  listaDivisas=[

    {
      id:104,
      name:"MXN",
      valor: 1,
      conversion:1
    },
    {
      id:105,
      name:"BTC",
      valor: 1,
      conversion:1
    },

    {
     id:106,
     name:"ETH",
       valor: 1,
       conversion:1
     },
     {
       id:107,
       name:"DOGE",
       valor: 1,
       conversion: 1
     },

     {
       id:108,
       name:"USDT",
       valor: 1,
       conversion: 1
     },
     {
       id:109,
       name:"XRP",
       valor: 1,
       conversion:1
     },





    ]


  constructor(public modalController:ModalController, 
    private formservice:FormServiceService,
    private router: Router,
    private requestService: RequestService,
    public navCtrl: NavController) 
    { }

  ngOnInit() {
this.GetCurrency();
    // this.publications.forEach(element => {
    //   console.log("publicaciones",element);


    // });


    console.log("estas en la seccion",this.formservice.sectionVer);
    console.log("estas en la seccion",this.formservice.divisa=this.divisaSelect);

  }
UpdateCurrency(){
  const selectedDivisa = this.listaDivisas.find(divisa => divisa.id === this.selectVal);
  const requestData = {
    profile_id: this.profile_id,
    currency: selectedDivisa.name
  };

  this.requestService.createRequestPost('MiMonedaDeRegalos', requestData)
      .then((result) => {
 // Process the API response
 console.log(result);
 Swal.fire('Success', 'Moneda para envio de regalos actualizada.', 'success');
})
.catch((error) => {
 console.error(error);
 Swal.fire('Error', 'Un error ocurrió al actualizar la moneda', 'error');
});
}
GetCurrency() {


  this.requestService.createRequestGet('VerMiMonedaDeRegalos', null, { id: this.profile_id})
    .then((result) => {
      // Process the API response
      console.log(result);
      if (result) {
        const parsedResult = JSON.parse(result);
        if (parsedResult.currency) {
          // Update the currency value in the UI
          this.currency = parsedResult.currency;
        }
      }
    })
    .catch((error) => {
      console.error(error);
    });
}


  OnChange(event) {
    this.listaDivisas.forEach(element => {
      if(this.selectVal === element.id){
        this.divisaSelect=element.name

      }



    });

    console.log(`Divisa id = ${this.selectVal} con currency ${this.divisaSelect}`);
    this.formservice.divisa=this.divisaSelect
    console.log("divisa formservice",this.formservice.divisa);



    console.log(`Divisa id Select ${this.selectVal} currency ${this.divisaSelect}`);
  }

  // OnChange(event) {
  //   console.log(`Divisa id Select ${this.selectVal}`);

  //   this.listaDivisas.forEach(element => {
  //     if(this.selectVal === element.id){
  //       this.divisaSelect=element.name

  //     }



  //   });

  //   console.log(`Divisa id = ${this.selectVal} con Divisa ${this.divisaSelect}`);
  //   this.formservice.divisa=this.divisaSelect
  //   console.log("divisa formservice",this.formservice.divisa);





  // }
  sectionPublication(section:string){

    switch (section) {
      case "de-megusta":
        this.formservice.sectionVer=section
        this.modalController.create({
          component: PublicationFeedPage,
          cssClass: 'detailsInModal',
          swipeToClose: true,
        }).then((modal)=>{
          modal.present();
        });


        break;
      case "Regalos":
        //this.formservice.sectionVer=section
        this.modalController.create({
          component: ColeccionRegalos,
          cssClass: 'detailsInModal',
          swipeToClose: true,
        }).then((modal)=>{
          modal.present();
        });


        break;
      case "pago-publicacion":
      //this.formservice.sectionVer=section
      this.modalController.create({
        component: ColeccionPremium,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      }).then((modal)=>{
        modal.present();
      });


      break;

      //case "cumulos":
        //this.formservice.sectionVer=section
        //this.modalController.create({
          //component: PublicationFeedPage
        //}).then((modal)=>{
          //modal.present();
        //});


        //break;

      default:
        Swal2.fire({
          title: 'No se encontro la sección',
          text: "Error ",
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
          });
        break;
    }

  }

  publicacionMegusta(){
    this.formservice.sectionVer="de-megusta"
    this.modalController.create({
      component: PublicationFeedPage,
      cssClass: 'detailsInModal',
			swipeToClose: true,
    }).then((modal)=>{
      modal.present();
    });
  }
  home(){
    this.closeAllModals();
  }
  async closeAllModals() {
    await this.modalController.dismiss();
  }

  resumen() {
    this.modalController
      .create({
        component: PublicacionesResumenComponent
      })
      .then((modal) => modal.present())
  }

  guardadas(){
    this.modalController
      .create({
        component:PublicacionesGuardadasComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      })
      .then((modal) => modal.present() )
  }

  dismiss(){
    this.modalController.dismiss({
      'dismissed' : true
    })
  }

  disponiblePronto(){this.modalController.create({
    // component: EventosEclipseViewPage
component: ModulesLockComponent,
cssClass: 'detailsInModal',
swipeToClose: true,
}).then((modal)=>{
    modal.present();
});
  }

}
