import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/profile/profile.service';
import Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
import { Plugins, Capacitor } from '@capacitor/core';
import Swal from 'sweetalert2';
const { LocalNotifications } = Plugins;
@Component({
  selector: 'app-informacion-del-usuario',
  templateUrl: './informacion-del-usuario.component.html',
  styleUrls: ['./informacion-del-usuario.component.scss'],
})
export class InformacionDelUsuarioComponent implements OnInit {
  public emailSent:boolean=false;
  public twoFactortoChangeEmail:boolean=false;
  public twoFactortoChangePhoneNumber:boolean=false;
  nombreDestinatario;
  Destinatario;
  NewTelephone;
  public SMSSent:boolean=false;
  public tokenEdit: boolean=false;
  remainingTime: number;
	public token: string;
  private countdownInterval: any;
  public newUsername: any;
  public newEmail: any;
  notificationText: string = '';
  notificationTitle: string = '';
  notificationUserID: number = 0 ;
  complete_name:string="";
  name:string="";
  last_name:string="";
  username:string="";
  email:string="";
  birth:string="";
  telefono:string="";
corp: boolean=false;
profile_id: any;
  constructor(public modalController:ModalController, private profile_service:ProfileService, private request: RequestService) { }

  ngOnInit() {
    this.getProfile();
    // Register a background handler

  }

  VerificarCorreo(){

    Swal2.fire({
      title: 'Correo de Verificacion',
      text: 'Por favor asegurate de revisar tu correo Electronico y aceptar la verificacion de tu cuenta',
      icon: 'warning',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });

  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  getProfile() {
		this.profile_service.getProfileInfo().then((data) => {
			data = JSON.parse(data);
			// this.profile_photo = data.profile_photo.route;
      this.profile_id = data.id;
			this.complete_name = data.name +" "+ data.last_name;
      this.name= data.name;
      this.last_name=data.last_name;
			this.username = data.username;
      this.email =data.email;
      this.birth =data.birth;
      this.telefono=data.telefono;
      console.log(data.role_id);
      console.log('dat',data);
        this.corp=data.role_id;
      
		}).catch((error) => {
			console.error(error);
		}).finally(() => {

		});
	}
  sendEmail() {
   
    return new Promise((resolve, reject) => {
      const notification = {
        subject: this.notificationTitle,
        Cuerpo: this.notificationText,
        name: this.nombreDestinatario,
        recipient_email: this.Destinatario,

      };
  
      this.request.createRequestPost('sendPragmaticCorporateMail', notification)
        .then((data) => {
          Swal2.fire({
            title: 'Correo enviado',
            text: 'El correo ha sido enviado exitosamente',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
    });
  }
  sendwhatsapp() {
   
    return new Promise((resolve, reject) => {
      const notification = {
        send: true,
        responseMessage: this.notificationText,
   
        telefono: this.Destinatario,

      };
  
      this.request.createRequestPost('WhatsappMesagesHandler', notification)
        .then((data) => {
          Swal2.fire({
            title: 'Correo enviado',
            text: 'El correo ha sido enviado exitosamente',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
    });
  }
  sendsms() {
   
    return new Promise((resolve, reject) => {
      const notification = {
        send: true,
        responseMessage: this.notificationText,
   
        telefono: this.Destinatario,

      };
  
      this.request.createRequestPost('smsMesagesHandler', notification)
        .then((data) => {
          Swal2.fire({
            title: 'Correo enviado',
            text: 'El correo ha sido enviado exitosamente',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
    });
  }
  editarCorreoyNombreDeUsuario() {

    Swal2.fire({
      title: 'Edita tu información del perfil.',
      html: `
      <!--   <label for="descriptionInput">Usuario:</label>
      <input type="text" id="descriptionInput" class="swal2-input" value="${this.username}" placeholder="Nombre de usuario" [(ngModel)]="username" readonly>
      -->
      <label for="webPageInput">Correo:</label>
      <input type="text" id="webPageInput" class="swal2-input" value="${this.email}" placeholder="correo electrónico" [(ngModel)]="email">
      `,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        const username = (document.getElementById('descriptionInput') as HTMLInputElement).value;
        const email = (document.getElementById('webPageInput') as HTMLInputElement).value;
        return {
          username,
          email
        };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { username, email } = result.value;
        if(email){
        //this.tokenEdit=true;
        //this.startCountdown();
        //this.ConfirmaciondeCorreo( );
        if(!this.twoFactortoChangeEmail){ //step 1 to change email
          this.twoFactortoChangeEmail=true;
          this.newEmail=email;
        
          this.tokenEdit=true;
          this.startCountdown();
  
          this.EnviarCodigoDeVerificacion();
        }
   
      
       
        }else{
          Swal2.fire({
            title: 'Error',
            text: 'Ingresa un correo',
            icon: 'error',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
          });
        }
       // this.newUsername=username;
          Swal2.fire({
            title: 'Nombre de usuario y/o correo electrónico en proceso de actualización.',
            timer: 1500
          });
        
      }
    });
    
  }
  editarTelefono() {

    Swal2.fire({
      title: 'Editar el teléfono. ',
      html: `
      <label for="descriptionInput">Telefono:</label>
      <label style="font-size:36px">
						${this.getNumberFlag(this.telefono)}
					</label>
      <input type="text" id="descriptionInput" class="swal2-input" value="${this.telefono}" placeholder="Telefono" [(ngModel)]="telefono">

      `,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        const telefono = (document.getElementById('descriptionInput') as HTMLInputElement).value;
        //const email = (document.getElementById('webPageInput') as HTMLInputElement).value;
        return {
          telefono,
         
        };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { telefono } = result.value;

        if(this.NewTelephone){
          if(!this.twoFactortoChangePhoneNumber){ //step 1 to change email
            this.twoFactortoChangePhoneNumber=true;
            this.NewTelephone=telefono;
            this.tokenEdit=true;
            this.startCountdown();
            this.ConfirmaciondeCorreo( );
          }
      

        }else{
          Swal2.fire({
            title: 'Error',
            text: 'Ingresa un número de celular',
            icon: 'error',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
          });
        }
          Swal2.fire({
            title: 'TELEFONO EN PROCESO DE ACTUALIZACIÓN.',
            timer: 1500
          });
        
      }
    });
    
  }
  EnviarCodigoDeVerificacion(){
    this.SMSSent=true;
      this.startCountdown();
    this.request.createRequestPost('SMSCodeVerification', { phone_number: this.NewTelephone, SendSMS:true })
    .then((final_data) => {
      let text;
     if(this.twoFactortoChangePhoneNumber){
text=`El token ha sido enviado exitosamente: ${this.NewTelephone}. Verifica que el código telefónico del país sea correcto.`;
     }else if (this.twoFactortoChangeEmail){
text=`El token ha sido enviado exitosamente: ${this.telefono}. `;
     }
      Swal2.fire({
        title: 'Token enviado exitosamente',
        text: text,
        icon: 'success',
        confirmButtonText: 'Aceptar',
        background: '#010f4e',
      });
      
    })
    .catch((error) => {
      console.error('An error occurred:', error);
      Swal2.fire({
        title: 'Error',
        text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. Verifica que la extension del país sea correcta.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        background: '#010f4e',
      });
    });
         

  }
  saveDescription() {
    // Perform the necessary actions to save the edited description and webpage
    const object = {
      email: this.newEmail,
   //   username: this.newUsername,
      id: this.profile_id
    };
  
     this.request.createRequestPost('modifyUsernameAndEmail', object)
      .then((data) => {
        console.log('Web page and description saved:', data);
        this.dismissTokenConfirmation();
        Swal2.fire({
          title: 'Información de perfil actualizada',
          text: `Información de perfil actualizada exitosamente`,
          icon: 'success',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
          }).then(() => {
          console.log("Alert displayed successfully.");
          }).catch((error) => {
          console.error("Error displaying alert:", error);
          });
this.dismissTokenConfirmation();
      })
      .catch((error) => {
        console.log('API error:', error);
        throw error;
      });
  }

  ngOnDestroy() {
    this.stopCountdown();
    }
  
    startCountdown() {
    this.remainingTime = 300; // 299 = 4 minutes and 59 seconds
  
    this.countdownInterval = setInterval(() => {
      this.remainingTime--;
  
      if (this.remainingTime <= 0) {
      // Stop the countdown when the remaining time reaches zero or below
      this.stopCountdown();
      // Perform any necessary actions when the countdown completes
      // For example, show a message to the user indicating the timeout
      }
    }, 1000);
    }
  
    stopCountdown() {
    clearInterval(this.countdownInterval);
    }
    formatTime(time: number): string {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
    }
    updateToken(event: any) {
    this.token = event.target.value;
    }
    confirmEmail() {
      console.log('tok', this.token);
      console.log('profile_id', this.profile_id);
      if (this.token) {
        
        this.request.createRequestPost(`verified-confirmation`, {token: this.token, profile_id: this.profile_id})// profile_id: this.id
        .then(response => {
          console.log('response', response);
          const responseData = JSON.parse(response);
          console.log('response code', responseData.code);
  
          if (responseData.code === 200) {
         
  
            Swal.fire({
              title: 'El token es valido',
              text: 'Token validado exitosamente',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger'
              },
              buttonsStyling: false,
              background: '#010f4e',
              iconColor: '#ffffff',
            });
            if (this.twoFactortoChangeEmail){
         
              this.saveDescription( );
            }else if(this.twoFactortoChangePhoneNumber){
              this.stopCountdown();
              this.tokenEdit=true;
              this.startCountdown();
      
              this.EnviarCodigoDeVerificacion();
            }
  
   

  
            } else {
              // Token is incorrect
              Swal.fire({
                title: 'El token no es válido',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-danger'
                },
                buttonsStyling: false,
                background: '#010f4e',
                iconColor: '#ffffff',
                text: response.title
              });
            }
          })
          .catch(error => {
            Swal.fire({
              title: 'Email Confirmation Failed',
              icon: 'error',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger'
              },
              buttonsStyling: false,
              background: '#010f4e',
              iconColor: '#ffffff',
              text: error.response.title
            });
          });
      }
    }
    
    
  
    
      
    dismissTokenConfirmation(){
      this.tokenEdit=false;
      this.stopCountdown();
      this.dismiss();
    }
    async ConfirmaciondeCorreo() {
      this.emailSent=true;
      let data = {
        email: this.email,
      };
      
      // Step 1: Request email confirmation
      try {
        Swal.fire({
          title: 'Procesando...',
          text: 'El correo se está procesando.',
          icon: 'success',
          timer: 3600, // Show the alert for 3 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
        });
        const response = await this.request.createRequestPost('send-email', data);
        const responseData = JSON.parse(response);
        // Check if email confirmation was successful
        if (responseData.code === 200) {
       
          Swal2.fire({
            title: 'Confirmacion de Correo Electronico',
            text: `Confirmacion de correo Enviada a ${this.email}`,
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            }).then(() => {
            console.log("Alert displayed successfully.");
            }).catch((error) => {
            console.error("Error displaying alert:", error);
            });

        } else {
        Swal2.fire({
          title: 'Confirmación de correo no procesada',
          text: `Confirmación no enviada a la cuenta de tu correo ${this.email}`,
          icon: 'error',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        }).then(() => {
          console.log("Alert displayed successfully.");
        }).catch((error) => {
          console.error("Error displaying alert:", error);
        });
        // Email confirmation failed
        // Handle failure here (e.g., show an error message to the user)
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle network errors or other unexpected errors here
      }
      }
      VerificarCodigo(){
        this.request.createRequestPost('SMSCodeVerification', { phone_number: this.NewTelephone, ValidateCode:true, token: this.token })
        .then((final_data) => {
         
          Swal2.fire({
            title: 'Token verificado exitosamente.',
            text: `El token ha sido validado exitosamente: ${this.NewTelephone}. `,
            icon: 'success',
            confirmButtonText: 'Aceptar',
            background: '#010f4e',
          });
          if(this.twoFactortoChangePhoneNumber){
            this.modifyPhone();
          }else if (this.twoFactortoChangeEmail){
            this.stopCountdown();
            this.tokenEdit=true;
            this.startCountdown();
            this.ConfirmaciondeCorreo( );
          }

       
        })
        .catch((error) => {
          console.error('An error occurred:', error);
          Swal2.fire({
            title: 'Error',
            text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
            icon: 'error',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
          });
        });
      }
      getNumberFlag(number: string): string {
        // Define a mapping of country codes to flag emojis
        const flagEmojis: { [key: string]: string } = {
          '+93': '🇦🇫', //Afghanistan
          '+335':'🇦🇱',//Albania
          '+213':'🇩🇿', //Algeria
          '+376':'🇦🇩',//Andorra
          '+244':'🇦🇴',//Angola
          '+1268':'🇦🇬',//Antigua and Barbuda
          '+54':'🇦🇷',//Argentina
          '+374':'🇦🇲',//Armenia
          '+297':'🇦🇼',//Aruba
          '+61':'🇦🇺',//Australia
          '+43':'🇦🇹',//Austria
          '+994':'🇦🇿',//Azerbaijan
          '+1242':'🇧🇸',//Bahamas
          '+973':'🇧🇭',//Bahrain
          '+880':'🇧🇩',//Bangladesh
          '+1246':'🇧🇧',//Barbados
          '+375':'🇧🇾',//Belarus
          '+32':'🇧🇪',//Belgium
          '+501':'🇧🇿',//Belize
          '+229':'🇧🇯',//Benin
          '+975':'🇧🇹',//Bhutan
          '+591':'🇧🇴',//Bolivia
          '+387':'🇧🇦',//Bosnia and Herzegovina
          '+267':'🇧🇼',//Botswana
          '+55':'🇧🇷',//Brazil
          '+246':'🇮🇴',//British Indian Ocean Territory
          '+673':'🇧🇳', //Brunei
          '+359':'🇧🇬',//Bulgari
          '+226':'🇧🇫',//Burkina Faso
          '+257':'🇧🇮',//Burundi
          '+855':'🇰🇭', //Cambodia
          '+237':'🇨🇲',//Cameroon
          '+238':'🇨🇻',//Cape Verde
          '+599':'🇧🇶',//Caribbean Netherlands
          '+236':'🇨🇫',//Central African Republic
          '+234':'🇹🇩🇳🇬',//Chad AND NIGERIA
          '+56':'🇨🇱',//Chile
          '+86':'🇨🇳',//China
          '+57':'🇨🇴',//Colombia
          '+269':'🇰🇲',//COMOROS
          '+243':'🇨🇩',//CONGO
          '+242':'🇨🇬',//CONGO
          '+506':'🇨🇷',//COSTA RICA
          '+225':'🇨🇮',//cote d'ivoire
          '+385':'🇭🇷',//CROATIA
          '+53':'🇨🇺',//CUBA
          '+559':'🇨🇼',//Curaçao
          '+357':'🇨🇾',//CYPRUS
          '+420':'🇨🇿',//CZECH REPUBLIC
          '+45':'🇩🇰',//DENMARK
          '+253':'🇩🇯',//DJIBOUTI
          '+1767':'🇩🇲',//DOMINICANA
          '+593':'🇪🇨',//ECUADOR
          '+20':'🇪🇬', //EGIPTO
          '+503':'🇸🇻',//EL SALVADOR
          '+240':'🇬🇶',//EQUATORIAL GUINEA
          '+291':'🇪🇷',//ERITREA
          '+372':'🇪🇪',//ESTONIA
          '+251':'🇪🇹',//ETHIOPIA
          '+679':'🇫🇯',//FIJI
          '+358':'🇫🇮',//FINLAND
          '+33': '🇫🇷', // France
          '+594':'🇬🇫',//FRENCH GUIANA
          '+689':'🇵🇫',//FRENCH POLYNESIA
          '+241':'🇬🇦',//GABON
          '+220':'🇬🇲',//GAMBIA
          '+995':'🇬🇪',//GEORGIA
          '+49': '🇩🇪', // Germany
          '+233':'🇬🇭',//GHANA
          '+30':'🇬🇷',//GREECE
          '+1473':'🇬🇩',//GRENADA
          '+590':'🇬🇵',//GUADALOUPE
          '+1671':'🇬🇺',//GUAM
          '+502':'🇬🇹',//GUATEMALA
          '+224':'🇬🇳',//GUINEA
          '+245':'🇬🇼',//GUINEA BISSAU
          '+592':'🇬🇾',//GUYANA
          '+509':'🇭🇹',//HAITI
          '+504':'🇭🇳',//HONDURAS
          '+852':'🇭🇰',//HONG KONG
          '+36':'🇭🇺',//HUNGARY
          '+354':'🇮🇸',//ICELAND
          '+91':'🇮🇳',//INDIA
          '+62':'🇮🇩',//INDONESIA
          '+98':'🇮🇷',//IRAN
          '+964':'🇮🇶',//IRAQ
          '+353':'🇮🇪',//ireland
          '+972':'🇮🇱',//ISRAEL
          '+39':'🇮🇹🇻🇦',//ITALY AND VATICAN CITY
          '+1876':'🇯🇲',//JAMAICA
          '+81': '🇯🇵', // Japan
          '+962':'🇯🇴',//JORDAN
          '+254':'🇰🇪',//KENYA
          '+686':'🇰🇮',//KIRIBATI
          '+383':'🇽🇰',//KOSOVO
          '+965':'🇰🇼',//KUWAIT
          '+996':'🇰🇬',//KYRGYZSTAN
          '+856':'🇱🇦',//LAOS
          '+371':'🇱🇻',//LATVIA
          '+961':'🇱🇧',//LEBANON
          '+266':'🇱🇸',//LESOTHO
          '+231':'🇱🇷',//LIBERIA
          '+281':'🇱🇾', //LIBYA
          '+423':'🇱🇮',//LIECHTENSTEIN
          '+370':'🇱🇹',//LITHUANIA
          '+352':'🇱🇺', //LUXEMBOURG
          '+853':'🇲🇴',//MACAU
          '+389':'🇲🇰',//MACEDONIA
          '+261':'🇲🇬',//MADAGASCAR
          '+265':'🇲🇼',//MALAWI
          '+60':'🇲🇾',//MALAYSIA
          '+960':'🇲🇻',//MALDIVES
          '+223':'🇲🇱',//MALI
          '+356':'🇲🇹',//MALTA
          '+692':'🇲🇭',//MARSHALL ISLANDS
          '+596':'🇲🇶',//MARTINIQUE
          '+222':'🇲🇷',//MAURITANIA
          '+230':'🇲🇺',//MAURITIUS
          '+262':'🇾🇹🇷🇪',//Mayotte and Réunion
          '+52': '🇲🇽', // Mexico
          '+691':'🇫🇲',//MICRONESIA
          '+373':'🇲🇩',//MOLDOVA
          '+377':'🇲🇨',//MONACO
          '+976':'🇲🇳',//MONGOLIA
          '+382':'🇲🇪',//MONTENEGRO
          '+1664': '🇲🇸',  // Montserrat
          '+212':'🇪🇭🇲🇦',//Western Sahara and Morocco
          '+258':'🇲🇿',//MOZAMBIQUE
          '+95':'🇲🇲',//MYANMAR
          '+264':'🇳🇦', //NAMIBIA
          '+674':'🇳🇷',//NAURU
          '+977':'🇳🇵',//NEPAL
          '+31':'🇳🇱', //NETHERLANDS
          '+687':'🇳🇨',//NEW CALEDONIA
          '+64':'🇳🇿',//NEW ZEALAND
          '+505':'🇳🇮', //NICARAGUA
          '+227':'🇳🇪',//NIGER
          '+850':'🇰🇵',//NORTH KOREA
          '+47':'🇳🇴',//NORWAY
          '+968':'🇴🇲',//OMAN
          '+92':'🇵🇰',//PAKISTAN
          '+680':'🇵🇼',//PALAU
          '+970':'🇵🇸',//PALESTINE
          '+507':'🇵🇦',//PANAMA
          '+675':'🇵🇬',//PAPUA NEW GUINEA
          '+595':'🇵🇾',//PARAGUAY
          '+51':'🇵🇪',//PERU
          '+63':'🇵🇭',//PHILIPPINES
          '+48':'🇵🇱',//POLAND
          '+351':'🇵🇹',//PORTUGAL
          '+1787': '🇵🇷',  // Puerto Rico
          '+974':'🇶🇦',//QATAR
          '+40':'🇷🇴',//ROMANIA
          '+7':'🇷🇺',//RUSSIA AND KAZAKHSTAN FLAG
          '+250':'🇷🇼',//RWANDA
          '+1869':'🇰🇳',//SAINT KITTS AND NEVIS
          '+1758':'🇱🇨',//SAINT LUCIA
          '+1784':'🇻🇨',//SAINT VINCENT AND THE GRENADINES
          '+685':'🇼🇸',//SAMOA
          '+378':'🇸🇲',//SAN MARINO
          '+239':'🇸🇹',//São Tomé and Príncipe
          '+966':'🇸🇦',//SAUDI ARABIA
          '+221':'🇸🇳',//SENEGAL
          '+381':'🇷🇸',//SERBIA
          '+248':'🇸🇨',//SEYCHELLES
          '+232':'🇸🇱',//SIERRA LEONE
          '+65':'🇸🇬',//SINGAPORE
          '+421':'🇸🇰',//SLOVAKIA
          '+386':'🇸🇮',//SLOVENIA
          '+677':'🇸🇧',// SOLOMON ISLANDS
          '+252':'🇸🇴',//SOMALIA
          '+27':'🇿🇦', //SOUTH AFRICA
          '+82':'🇰🇷',//SOUTH KOREA
          '+211':'🇸🇸',//SOUTH SUDAN
          '+34':'🇪🇸',//SPAIN
          '+94':'🇱🇰',//SRI LANKA
          '+249':'🇸🇩',//SUDAN
          '+597':'🇸🇷',//SURINAME
          '+268':'🇸🇿',//SWAZILAND
          '+46':'🇸🇪',//SWEDEN
          '+41':'🇨🇭',//SWITZERLAND
          '+963':'🇸🇾',//SYRIA
          '+886':'🇹🇼',//TAIWAN
          '+992':'🇹🇯',//TAJIKISTAN
          '+255':'🇹🇿',//TANZANIA
          '+66':'🇹🇭',//THAILAND
          '+670':'🇹🇱',//TIMOR-LESTE
          '+228':'🇹🇬',//TOGO
          '+690': '🇹🇰',  // Tokelau
          '+676':'🇹🇴',//TONGA
          '+1868':'🇹🇹',//TRINIDAD AND TOBAGO
          '+216':'🇹🇳',//TUNISIA
          '+90':'🇹🇷',//TURKEY
          '+993':'🇹🇲',//TURKMENISTAN
          '+1649': '🇹🇨',  // Turks and Caicos Islands
          '+688':'🇹🇻',//TUVALU
          '+256':'🇺🇬',//UGANDA
          '+380':'🇺🇦',//UKRAINE
          '+971':'🇦🇪',//UNITED ARAB EMIRATES
          '+44': '🇬🇧🇮🇲🇯🇪🇬🇸🇸🇭', // // United Kingdom, Isle of Man, Jersey, South Georgia and the South Sandwich Islands, and Saint Helena, Ascension and Tristan da Cunha
          '+598':'🇺🇾',//URUGUAY
          '+998':'🇺🇿',//UZBEKISTAN
          '+678':'🇻🇺',//VANUATU
          '+58':' 🇻🇪',//VENEZUELA
          '+84':'🇻🇳',//VIETNAM
          '+967':'🇾🇪',//YEMEN
          '+260':'🇿🇲',//ZAMBIA
          '+263':'🇿🇼',//ZIMBABWE
          '+1': '🇺🇸🇨🇦🇩🇴🇵🇷', // United States
        };
      if(number){
     // Loop through the keys of flagEmojis to find a matching country code
     for (const countryCode in flagEmojis) {
      if (number.startsWith(countryCode)) {
          return flagEmojis[countryCode];
      }
    }
  }else{
    return '🌍';
  }
}

modifyPhone(){
  this.request.createRequestPost('ModifyPhoneNumber', { id: this.NewTelephone })
  .then((final_data) => {
   
    Swal2.fire({
      title: 'Telefono actualizado exitosamente.',
      text: `El telefono ha sido actualizado exitosamente: ${this.NewTelephone}. `,
      icon: 'success',
      confirmButtonText: 'Aceptar',
      background: '#010f4e',
    });
    this.dismissTokenConfirmation();
 
  })
  .catch((error) => {
    console.error('An error occurred:', error);
    Swal2.fire({
      title: 'Error',
      text: 'Ocurrió un error inesperado. Si el problema persiste, comunícate con el soporte de Laniakea. ',
      icon: 'error',
      confirmButtonText: 'Entendido',
      background: '#010f4e',
    });
  });
}
}