import { RequestService } from './../../../../services/request/request.service';
import { FormServiceService } from 'src/app/services/publications/form-service.service';

import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { SellerProfile } from "../../User/Store/seller-profile";
import { CommentClass } from "../Comments/comment-class";
import { PublicationClass } from "../publication-class";

export class Land extends PublicationClass {
    time_zone;
    //Multimedia
    doesItHaveMultimedia: boolean;
    multimediaCounter: number;
    multimediaContainer: Array<MediaItem>;
    public multimedia: Array<MediaItem> = new Array<MediaItem>()
    //Land Publication Data
    title: string;
    description: string;
    price: number;
    cantidad: number;
    quienpuedever:Array<string>=["Para todos"];
    publication_type: string;
    is_feed: boolean;
    // price: number;
    //land1----------------------------------------------------------
    views: number;
    regalos: number;
    Verified: boolean;
    SaveCounter: any;
    ShareCounter: any;
    category: string;
    service: string;
    type_of_service: string;
    //land2----------------------------------------------------------
    agenda: string;
    ubicacion: string;
    //land5---------------------------------------------------------
    //land3-----------------------------------
    status:string="disponible"
    dias
    horas:number;
    cupos:number;
    //disponibilidad:string

    //land6--------------------------------
    capacidad: string;
    //landpago--------------------------------
    title2: string;
    description2: string;
    price2: number;
    Imagen2:string;
    ServiciosAdicionales:Array<any>=[]
    
    //land calendario
    zonaHoraria: string;
    fecha_inicial: string;
    fecha_final: string;
    hora_inicial=[];
    hora_final: string;
    latitud:number;
    longitud:number;
    hr1:boolean;
    hr2:boolean;
    disponibilidad_dia:Array<any>=[];
    ReservaCalendario:Array<any>=[];
    IsReservaCalendario: boolean = false;
    

    constructor( publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any  ) {
        super(publication, userProfile, reactionContainer, ownReaction);
        if (publication && publication.container) {
            //Multimedia
            this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
            this.multimediaCounter = (publication.container.multimediaCounter) ? parseInt(publication.container.multimediaCounter) : 0;
            this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
                this.multimediaContainer = multimedia;
            });
            //Land Publication Data
            this.title = (publication.container.title) ? publication.container.title : null;
            this.description = (publication.container.description) ? publication.container.description : null;
            this.price = (publication.container.price) ? publication.container.price : null;
            this.cantidad = (publication.container.cantidad) ? publication.container.cantidad : null;
            this.quienpuedever = (publication.container.quienpuedever) ? publication.container.quienpuedever : null;
            this.is_feed = (publication.container.is_feed) ? publication.container.is_feed : null;
            //land1----------------------------------------------------------
            this.category = (publication.container.category) ? publication.container.category : null;
            this.service = (publication.container.service) ? publication.container.service : null;
            this.type_of_service = (publication.container.type_of_service) ? publication.container.type_of_service : null;
            //land2----------------------------------------------------------
            this.agenda = (publication.container.agenda) ? publication.container.agenda : null;
            this.ubicacion = (publication.container.ubicacion) ? publication.container.ubicacion : null;
            //land3----------------------------------------------------------
            this.zonaHoraria = (publication.container.zonaHoraria) ? publication.container.zonaHoraria : null;
            //land6--------------------------------
            this.capacidad = (publication.container.capacidad) ? publication.container.capacidad : null;
            //landpago--------------------------------
            this.title2 = (publication.container.title2) ? publication.container.title2 : null;
            this.description2 = (publication.container.description2) ? publication.container.description2 : null;
            this.price2 = (publication.container.price2) ? publication.container.price2 : null;
            //land calendario
            this.fecha_inicial=(publication.container.fecha_inicial) ? publication.container.fecha_inicial : null;
            this.fecha_final=(publication.container.fecha_final) ? publication.container.fecha_final : null;
            this.hora_inicial=(publication.container.hora_inicial) ? publication.container.hora_inicial : null;
            this.hora_final=(publication.container.hora_final) ? publication.container.hora_final : null;
            this.latitud=(publication.container.latitud) ? publication.container.latitud : null;
            this.longitud=(publication.container.longitud) ? publication.container.longitud : null;
            this.horas=(publication.container.horas) ? publication.container.horas : null;
            this.status=(publication.container.status) ? publication.container.status : null;
            this.dias=(publication.container.dias) ? publication.container.dias : null;
            this.cupos=(publication.container.cupos) ? publication.container.cupos : null;
            this.disponibilidad_dia=(publication.container.disponibilidad_dia) ? publication.container.disponibilidad_dia : null;
            this.hr1=(publication.container.hr1) ? publication.container.hr1 : null;
            this.views = (publication.container.views) ? publication.container.views : null;
            this.regalos = (publication.container.regalos) ? publication.container.regalos : null;
            this.Verified = (publication.container.Verified) ? publication.container.Verified : null;
            this.SaveCounter = (publication.container.SaveCounter) ? publication.container.SaveCounter : null;
            this.ShareCounter = (publication.container.ShareCounter) ? publication.container.ShareCounter : null;
            this.time_zone=(publication.container.time_zone) ? publication.container.time_zone : null;
        } else {
            //Multimedia
            this.doesItHaveMultimedia = false;
            this.multimediaCounter = 0;
            this.multimediaContainer = new Array<MediaItem>();
            //LandPublication Data
            this.title = undefined;
            this.description = undefined;
            this.views = undefined;
            this.Verified = undefined;
            this.ShareCounter = undefined;
            this.SaveCounter = undefined;
            this.regalos = undefined;
            this.price = undefined;
            this.cantidad = undefined;
            this.quienpuedever = undefined;
            this.is_feed = undefined;
            //land1----------------------------------------------------------
            this.category = undefined;
            this.service = undefined;
            this.type_of_service = undefined;
            //land2----------------------------------------------------------
            this.agenda = undefined;
            this.ubicacion = undefined;
            //land3----------------------------------------------------------
            this.zonaHoraria = undefined;
            //land5----------------------------------------------------------

            //land6--------------------------------
            this.capacidad = undefined;
            //landpago--------------------------------
            this.title2 = undefined;
            this.description2 = undefined;
            this.price2 = undefined;
            //land calendario
           this.fecha_inicial=undefined;
           this.fecha_final=undefined;
           this.hora_inicial=undefined;
           this.hora_final=undefined;
           this.longitud=0;
           this.latitud=0;
           this.status="disponible";
           this.dias=undefined;
           this.horas=undefined;
           this.cupos=undefined;
           this.disponibilidad_dia=undefined;
           this.hr1=false;

        }
    }

    public updatePublication(publication: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: any) {
        //Multimedia
        this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
        this.multimediaCounter = (publication.multimediaCounter) ? parseInt(publication.multimediaCounter) : this.multimediaCounter;
        this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
            this.multimediaContainer = multimedia;
        });
        //Publication
        this.id = (publication.id) ? parseInt(publication.id) : this.id;
        this.publication_type = (publication.publication_type_string) ? publication.publication_type_string : this.publication_type;
        this.status_string = (publication.status_string) ? publication.status_string : this.status_string;
        //Interaction
        this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : this.reactionCounter;
        this.reactionContainer = reactionContainer;
        this.ownReaction = ownReaction;
        this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : this.commentCounter;
        //User
        this.userProfile = userProfile;
        this.corp = (publication.corp) ? publication.corp : this.corp;
        this.is_saved = (publication.is_saved) ? publication.is_saved : this.is_saved;
        this.owner = (publication.owner) ? publication.owner : this.owner;
    }


    setMultimedia(mediaItem) {
        if (this.publication_type == "event") {
            if (this.doesItHaveMultimedia) {
                this.deleteMultimedia(0);
            }
        }
        this.multimediaContainer.push(mediaItem);
        this.doesItHaveMultimedia = true;
    }

    deleteMultimedia(index: number) {
        this.multimediaContainer.splice(index, 1);
        if (this.multimediaContainer.length == 0) {
            this.doesItHaveMultimedia = false;
        }
    }

    getPublicationsDataToSend() {
        
        let data = {
            doesItHaveMultimedia: this.doesItHaveMultimedia,
            publication_type: this.publication_type,
            data: {
                title: this.title,
                description: this.description,
                quienpuedever:this.quienpuedever.join(","),
                is_feed: this.is_feed,
                category: this.category,
                service: this.service,
                type_of_service: this.type_of_service,
                price: Number(this.cantidad),
                // agenda: this.agenda,
                ubicacion: this.ubicacion,
                zonaHoraria:this.zonaHoraria,
                // fecha_inicial:this.fecha_inicial,
                // fecha_final:this.fecha_final,
                // hora_inicial:this.hora_inicial,
                // hora_final:this.hora_final,
                // capacidad:this.capacidad,
                
                // price:this.price,
                // price2:this.price2,
                // title2:this.title2,
                // description2:this.description2,
                longitud:this.longitud.toString(),
                latitud:this.latitud.toString(),
                status:this.status="disponible",
                // dias:this.dias,
                // horas:this.horas,
                // cupos: this.cupos,
                servicios:this.ServiciosAdicionales,
                disponibilidad_dia: this.ReservaCalendario,
                time_zone:this.time_zone,
                // hr1:this.hr1

            }
        }
        console.log("Data",data)
        return data;
    }

    transformMediaToMediaItem(objectMedia) {
        return new Promise<Array<MediaItem>>((resolve) => {
            let MediaContainer = new Array<MediaItem>();
            if (objectMedia) {
                objectMedia.forEach(media => {
                    MediaContainer.push(new MediaItem(media));
                });
                resolve(MediaContainer);
            }
            else {
                resolve(MediaContainer);
            }

        });
    }
}
