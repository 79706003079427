import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PublicationMediaTemplateComponent } from '../publication-media-template/publication-media-template.component';
import { PublicationElementsModuleModule } from '../single-elements/publication-elements-module/publication-elements-module.module';
import { CommentsModuleModule } from '../comments/comments-module/comments-module.module';
import { ShowPublicationModuleModule } from '../show/show-publication-module/show-publication-module.module';
import { AmigoslistComponent } from '../publication-design/amigoslist/amigoslist.component';
import { SliderInformationModule } from '../../slider-information/slider-information.module';
import { SliderInformationComponent } from '../../slider-information/slider-information.component';
import { VerDetallesComponent } from './../create/Ver-detalles/Ver-detalles.component';
import { PagosPorPublicacionComponent } from '../create/Pagos/Pagos-por-publicacion/Pagos-por-publicacion.component';
import { PublicationSaveTemplateComponent } from './../publication-save-template/publication-save-template.component';
import { CumuloSubscriptionComponent } from './../create/cumulos-suscription/cumulos/cumulo-subscription/cumulo-subscription.component';
import { FormsModule } from '@angular/forms';
import { CalendariomodulModule } from 'src/app/components/calendar/calendariomodul/calendariomodul.module';
//import {HeaderComponent} from 'src/app/components/publication/single-elements/header/header.component';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';
import {FeexOrdersComponent} from 'src/app/feex-orders/feex-orders.component';
@NgModule({
	declarations: [
		FeexOrdersComponent,
		PublicationMediaTemplateComponent,
    PublicationSaveTemplateComponent,
		AmigoslistComponent,
		SliderInformationComponent,
    VerDetallesComponent,
    PagosPorPublicacionComponent,
	GooglemapsComponent
	//HeaderComponent,

		 ],
	exports: [
		FeexOrdersComponent,
		PublicationMediaTemplateComponent,
		AmigoslistComponent,
		SliderInformationComponent,
    VerDetallesComponent,
    PagosPorPublicacionComponent,
	GooglemapsComponent

	],
	entryComponents: [
		FeexOrdersComponent,
		PublicationMediaTemplateComponent,
    PublicationSaveTemplateComponent,
		AmigoslistComponent,
		SliderInformationComponent,
		GooglemapsComponent


	],
	imports: [


	CommonModule,
		IonicModule,
		PublicationElementsModuleModule,
		CommentsModuleModule,
		ShowPublicationModuleModule,
    FormsModule,
	CalendariomodulModule
	],
})
export class PublicationModuleModule { }
