import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
@Component({
  selector: 'app-bienes6',
  templateUrl: './bienes6.component.html',
  styleUrls: ['./bienes6.component.scss'],
})
export class Bienes6Component implements OnInit {

  constructor(
    private formservice : FormServiceService
  ) { }

  ngOnInit() {}
}
