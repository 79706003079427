import { Component, OnInit } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import EventoJson from "src/assets/plantillas/data-evento.json";
import Swal from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
import {LoadingController} from "@ionic/angular";
@Component({
  selector: "app-eclipse-eventos",
  templateUrl: "./eclipse-eventos.component.html",
  styleUrls: ["./eclipse-eventos.component.scss"],
})
export class EclipseEventosComponent implements OnInit {
  displayContainer:boolean=true;
  public loading: any;
  categoriasHistory: Array<any> = [];
  selectedCategoria: string;
  public categories: Array<string> = Object.keys(EventoJson.eventos)
  DataPublications = [
    {
      Name: 'Normal',
      selected: true
    },
    {
      Name: 'Eventos Eclipse',
      Categorias: [

        {
          categoria: 'Eventos sociales',
          selected: false,
          id: 1,
          creator_id: 0,
        },
        {
          categoria: 'Eventos de empresa y eventos corporativos',
          selected: false,
          id: 12,
          creator_id: 0

        },
        {
          categoria: 'Eventos con causa y de recaudación de fondos',
          selected: false,
          id: 36,
          creator_id: 0
        },
        {
          categoria: 'Espectáculos y eventos de ocio',
          selected: false,
          id: 44,
          creator_id: 0,
        },
        {
          categoria: 'Eventos deportivos',
          selected: false,
          id: 49,
          creator_id: 0,
        },
        {
          categoria: 'Reuniones o convenciones',
          selected: false,
          id: 53,
          creator_id: 0,
        },
      ],
      selected: true
    },
    {
      Name: '---------',
      selected: false
    },
    {
      Name: '---------',
      selected: false
    },
    {
      Name: '---------',
      selected: false
    },
    {
      Name: '---------',
      selected: false
    },
  ];
  selectCheck(check) {
    console.log(check);
  
  
  }
  constructor(
    public formservice: FormServiceService, private requestService: RequestService,
    private loadingController:LoadingController,) { }

  ngOnInit() {
    console.log(Object.keys(EventoJson.eventos))
  }
  
  selectCategory(event) {
  
    this.formservice.eventospublication.category=event.target.value
  }

  updateSelectedCategoria() {
    const categorias = this.DataPublications[1].Categorias;
    const selectedCategoria = categorias.find(categoria => categoria.selected);
    this.selectedCategoria = selectedCategoria ? selectedCategoria.categoria : '';
    this.formservice.eventospublication.category = this.selectedCategoria;
  }

  async showLoading() {
    this.loading = await this.loadingController.create({
      message: '⚡️⚡️⚡️',
      spinner: 'circles'

    });
    this.loading.present();
  }
  async onClick(checklist) {
      // Update the selected attribute of the clicked checklist
  checklist.selected = !checklist.selected;
  // Log the updated checklist
  console.log(checklist);
    this.updateSelectedCategoria();
    console.log('onClick() function called'); // Debugging message
  
    // Check if there are no categories selected
    const hasNoSelectedCategories = this.DataPublications.every(publication => {
      if (publication.Categorias) {
        return publication.Categorias.every(category => !category.selected);
      }
      return true;
    });
  
    if (hasNoSelectedCategories) {
      Swal.fire('Error', 'Porfavor selecciona al menos una categoría.', 'error');
      return;
    }
  
    await this.showLoading();
  
    const getCategorias = async () => {
      console.log('getCategorias() function called'); // Debugging message
  
      // Step 1: Get the current state of the 'Categorias' array
      const categorias = this.DataPublications[1].Categorias;
  
      // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
      const selectedCategorias = categorias.filter(categoria => categoria.selected);
  
      console.log('selectedCategorias:', selectedCategorias); // Debugging message
  
      // Step 3: Extract the 'id' values from the filtered objects
      const selectedIds = selectedCategorias.map(cat => cat.id);

      const endpoint = 'categoriesEclipse';
      const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
      try {
        const data = await this.requestService.createRequestGet(endpoint, queryParams);
        console.log('data:', data); // Debugging message
  
        // Add JSON parsing and logging
        console.log("Raw JSON data:", data);
        let parsedData;
        try {
          parsedData = JSON.parse(data);
          console.log("Parsed JSON data:", parsedData);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
        }  
//step 4: map th data
        const newPublication = {
          Categorias: parsedData.map(categoria => ({
            ...categoria,
            selected: false,
          })),
        };
  
        // Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
        if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
          const newCategorias = parsedData.map(categoria => ({
            categoria: categoria.categoria,
            id: categoria.id,
            creator_id: categoria.creator_id,
            selected: false,
          }));
        
          console.log('newCategorias:', newCategorias); // Debugging message
          if(newCategorias.length ===0){
            this.displayContainer=false;
            }
          // Replace the 'Categorias' array with the new one
          const categoriasCopy = JSON.parse(JSON.stringify(this.DataPublications[1].Categorias));
  categoriasCopy.forEach(categoria => categoria.selected = false);
  this.categoriasHistory.push(categoriasCopy);
          this.DataPublications[1].Categorias = newCategorias;
           // Select or unselect checkboxes based on "categorias" data
         
        } else {
          console.error('Unexpected data format:', data);
        }
  
        // Hide the loading spinner when the operation is complete
        this.loading.dismiss();
      } catch (error) {
        console.error('Error fetching categories:', {
          message: error.message,
          name: error.name,
          stack: error.stack,
          errorObject: error,
        });
      }
    };
  
    await getCategorias(); // Invoke the function
  }
  onClickBack() {
    if (this.categoriasHistory.length > 0) {
      this.DataPublications[1].Categorias = this.categoriasHistory.pop();
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'No hay historial',
        text: 'No se puede retroceder más.',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
    }
  }
}