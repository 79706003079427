import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
import { AlertController, ModalController, NavController, ActionSheetController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { UploadMultimediaService } from './../../../../../services/upload/upload-multimedia.service';
import { isVideo, isImage } from 'src/app/utils';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import Swal2 from 'sweetalert2';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import {PagarCComponent} from "../../../../pagar-c/pagar-c.component";
import {CreatePublicationPage} from "../../../../../pages/create-publication/create-publication.page";
import { CreatePublicationComponent } from '../../create-publication/create-publication.component';
import { CumuloSubscriptionComponent } from './../../cumulos-suscription/cumulos/cumulo-subscription/cumulo-subscription.component';
import { Camera, CameraResultType, CameraSource, CameraOptions } from '@capacitor/camera';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-principal-publication',
  templateUrl: './principal-publication.component.html',
  styleUrls: ['./principal-publication.component.scss'],
})
export class PrincipalPublicationComponent implements OnInit {
  rutMedia: any;
  mediaType: string;
  rutaPortada : any;
  public step = 1;
  public buttonSee='ver';
  rutImage;
  public blobsMedia = [];
  @Input() siguienteStep

  constructor(public formservice: FormServiceService,
    public modalController: ModalController,
    public alertController: AlertController,
    public navCtrl: NavController,
    private cameraService: CameraServiceService,
    public uploadMedia: UploadMultimediaService,
    public pagarc: PagarCComponent,
    public createPubPage: CreatePublicationPage,
    private actionSheetController: ActionSheetController,
    private ref: ChangeDetectorRef,
    ) {
    this.formservice.normalpublication.title=this.formservice.title;
    this.formservice.normalpublication.description=this.formservice.description;
    this.formservice.normalpublication.publication_type=this.formservice.publication_type;
    this.formservice.normalpublication.quienpuedever=this.formservice.quienpuedever;
    this.formservice.normalpublication.price=this.formservice.price;
    this.formservice.normalpublication.ubicacion=this.formservice.ubicacion;
    this.formservice.normalpublication.is_feed=this.formservice.is_feed;
    this.formservice.normalpublication.multimedia=this.formservice.multimedia;
  }

  ngOnInit() {
    this.uploadMedia.image='';
    this.formservice.multimedia2=[];
    console.log("blobsmedia",this.blobsMedia);
    console.log("input siguiente",this.siguienteStep);

  }


  //Funcion para el boton de siguiente
   next() {
    if (this.step < 2) {
      this.step++;
    }
    if(this.step===2){
      this.buttonSee='oculto';
    }

  }

  //cerrar el modal
  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  retorno(){
    if (this.createPubPage.siguiente == true && this.step > 1) {
      this.step--;
    }else {
      this.createPubPage.siguiente = false;
    }
    if (this.step != 4){
      this.buttonSee = 'ver';
    }
  }

  public isVideo(url: string) {
    Capacitor.convertFileSrc(url);
    return isVideo(url);
  }

  public isImage(url: string) {
    Capacitor.convertFileSrc(url);
    return isImage(url);
  }

  public setMultimediaCamera2(mediaItem: MediaItem) {
    this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    this.uploadMedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile).
    then((result)=>{
        this.rutImage={image: this.uploadMedia.image};
    });
  }

  setMultimediaVideo2(mediaItem: MediaItem) {
    this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    this.uploadMedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile).
    then((result)=>{
        this.rutImage={image: this.uploadMedia.image};
    });
  }

  public takePhoto() {
    this.cameraService
      .askForMediCapture()
      .then((data: [MediaItem, String])=>{
        if(data[1].toString() === 'camara'){
          this.setMultimediaCamera(data[0]);
        }
        else if(data[1].toString()==='camvideo'){
          this.setMultimediaVideo(data[0]);
        }

      }).catch((error)=>{
      Swal2.fire({
        title: 'Error take photo',
        text: error,
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });

    });
  }


  public pickMedia() {
    this.uploadMedia.pickmultimedia().
      then((result)=>{
        this.rutImage={image: this.uploadMedia.image};
        console.log(this.formservice.multimedia2);
    });
  }

  guardarMedia(){
    this.formservice.multimedia2.push(this.rutImage);
    this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob, filename:this.uploadMedia.filename});
    console.log('blobsmedia',this.blobsMedia);
    console.log("blobs en formservice:",this.formservice.normalpublication.blobsMultimedia);
    console.log("formservice:",this.formservice.normalpublication);

  }

  agregarCumulo(){
    this.modalController.create({
      component:CumuloSubscriptionComponent
    }).then((modal=>{
      modal.present()
    }))
  }
  askForLibraryCapture(take_photo: boolean = true, take_video: boolean = true, pick_image: boolean = true,pick_video: boolean = true) {
    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {

			let botonesDisponibles = [
				(pick_image) ?
					{
						text: 'Seleccionar una foto',
						icon: 'camera',
						handler: () => {
							console.log("oprimiste camara");

							this
                             .pickImage()
                             .then((mediaItem) => {
                               resolve([mediaItem,'camara']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                                 position: 'top-end',
                                 timerProgressBar: true,
                                 toast: true,
                               });
                             });
						}
					} : null,
				(pick_video) ?
					{
						text: 'Seleccionar un vídeo',
						icon: 'videocam',
						handler: () => {
							console.log("oprimiste video");
							this
                            .pickVideo()
                            .then((mediaItem) => {
                              resolve([mediaItem,'camvideo']);
                            })
                            .catch((error) => {
                              Swal2.fire({
                                title: 'Error al capturar video',
                                text: error,
                                icon: 'warning',
                                confirmButtonText: 'Entendido',
                                color: '#ffffff',
                                background: '#010f4e',
                                position: 'top-end',
                                timerProgressBar: true,
                                toast: true,
                              });
                            });
						}
					} : null,
				{
					text: 'Cancelar',
					icon: 'close',
					role: 'cancel',
					handler: () => {
					}
				}
			];

			botonesDisponibles = botonesDisponibles.filter((boton) => {
				if (boton != null) {
					return true;
				}
				return false;
			});


			this.actionSheetController.create({
				header: '¿Desde donde deseas añadir multimedia?',
				cssClass: 'options-sheet',
				buttons: botonesDisponibles
			}).then((actionSheet) => {
				actionSheet.present();
			});
		});
	}
  async pickImage(){
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {
        console.log('Media picked successfully:', media);
        if (Capacitor.getPlatform() === 'web') {
          // For web, create a Blob from the media data and convert it to a data URL
          const response = await fetch(media.webPath);
          const blobfile = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            const imageUrl = reader.result as string;
            imagePreviewElement.src = imageUrl;
          };
          reader.readAsDataURL(blobfile);
        } else {
          // For iOS and Android, use Capacitor.convertFileSrc to get the public URL
          const imageUrl = Capacitor.convertFileSrc(media.path);
          imagePreviewElement.src = imageUrl;
        }
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
        const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        if (Capacitor.getPlatform() !== 'web') {
          this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
    }else{
      const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
      this.uploadMedia.filename = currentTime + '.png';
    }

  this.uploadMedia.blob = blobfile;
        //this.uploadMedia.filename = filename;
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename});
        } 
        
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  
  
  
  async pickVideo() {
    if (Capacitor.getPlatform() === 'ios') {
      Swal2.fire({
        title: 'Añade videos en tu dispositivo apple',
        text: 'Para añadir videos optimizados añade el video de tu librería de Fotos a tus Archivos. A continuación la opción optimizada es Choose File',
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
    }
    
    const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
  
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.multiple = false;
  
    fileInput.addEventListener("change", () => {
      const files = fileInput.files;
  
      if (files && files.length) {
        const file = files[0];

        const mimeType = file.type;


        const blobfile = file;
        const filename = file.name;
       // Create a MediaItem object from the File
      const mediaItem: MediaItem = {
        mediaPath: file.name,
        typeData: file.type,
        nameFile: file.name,
        id: 1, // Assign a unique ID here if needed
        size: file.size,
        index: 0, // Set the desired index value
        route: "", // Set the route value if needed
        status: "", // Set the initial status
        url: "", // Set the URL property
        localUrl: "", // Set the local URL property
        type: "", // Set the type property
        is_premium: 0, // Set the is_premium property
        // Add any other required properties
      };

      this.uploadMedia.blob = blobfile;
      this.uploadMedia.filename = filename;
        if (this.formservice.multimedia.length > 0) {
          this.formservice.multimedia[0] =  mediaItem;
        } else {
          this.formservice.multimedia.push(mediaItem);
        }
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename});
        }
  
        const videoURL = URL.createObjectURL(file);
  
        // Update the video source and display it
        videoPreviewElement.src = videoURL;
      }
    });
  
    // Trigger the file input dialog
    fileInput.click();
  }
  public setMultimediaCamera(mediaItem: MediaItem) {
    
    console.log("setMultimediaCamera function called");
    const imagePath = mediaItem.mediaPath + mediaItem.nameFile;
    console.log("Captured image path:", imagePath);
    this.uploadMedia.image = Capacitor.convertFileSrc(imagePath);
    this.rutaPortada = { image: this.uploadMedia.image };
    this.uploadMedia.blob = mediaItem.mediaPath;
    this.uploadMedia.filename =  mediaItem.nameFile;
    if (this.formservice.multimedia.length > 0) {
      console.log("Inside  ecosystem condition");
      this.formservice.multimedia[0] = this.rutaPortada;
    } else {
      this.formservice.multimedia.push(this.rutaPortada);
    }
    if(this.formservice.normalpublication.blobsMultimedia.length < 1){
      this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename};
    }else {
      this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename});
    }
  
    console.log("Updated rutaPortada:", this.rutaPortada);
  
    // Set the image source in the HTML
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    imagePreviewElement.src = this.uploadMedia.image;
  }
  setMultimediaVideo(mediaItem: MediaItem) {
    
    this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    const videoSourceElement = document.getElementById("videoSource") as HTMLSourceElement;
  
  videoSourceElement.src = this.uploadMedia.image;
  const VideoPath = mediaItem.mediaPath + mediaItem.nameFile;
  this.uploadMedia.image = Capacitor.convertFileSrc(VideoPath);
  this.rutMedia = { image: this.uploadMedia.image };
  this.uploadMedia.blob = mediaItem.mediaPath;
    this.uploadMedia.filename =  mediaItem.nameFile;
      if (this.formservice.multimedia.length >0){
        this.rutMedia={image: this.uploadMedia.image};
        this.formservice.multimedia[0]= (this.rutMedia);
      }else {
        this.rutMedia={image: this.uploadMedia.image};
        this.formservice.multimedia.push(this.rutMedia);
      }
      if(this.formservice.normalpublication.blobsMultimedia.length < 1 ){ //&& this.selectedEcosystem === 'normal'
        this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename};
      }else{
        this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:true, filename: this.uploadMedia.filename});
      }

  }


	
}

