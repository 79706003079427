import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { ModalController,
  IonSlides, PopoverController } from '@ionic/angular';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { PagoVerificacionComponent } from './../pago-verificacion.component';
import { create } from 'domain';
import { Message } from './../../../Models/Classes/Chat/message';
import {isNumeric} from "rxjs/internal-compatibility";
import { HttpClient } from '@angular/common/http';
import { transaction,createTransactionDTO } from './../../../Models/Interfaces/transaction_stripe.model';
import {input} from "aws-amplify";
import { Wallet } from 'src/app/Models/Classes/Wallet/wallet';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
// import { environment } from './../../../../environments/environment.prod';
//import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';

import { environment } from 'src/environments/environment';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { Key } from 'protractor';
import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
declare function validateCreditCard(): any;

// src/openpay.d.ts
declare var OpenPay: any;

const KEY = environment.apikeystripe;
@Component({
  selector: 'app-Pago-Tarjeta',
  templateUrl: './Pago-Tarjeta.component.html',
  styleUrls: ['./Pago-Tarjeta.component.scss'],
})
export class PagoTarjetaComponent implements OnInit, OnChanges {
  setDrive_media;
  @Input() pago_tarjeta_stripe:boolean=true;
  cardNum: string='4111111111111111';
  titular: string='juan perez';
  month: number;
  year: number;
  cvc: string;
  image_brand: string = '';
  amount=99;
  currency:string="mxn"
  @Input() profile: any;
  @Input() openpay_subscription_payment: any;
  deviceDataId;
  object;
  openpay_token;
  formData = {
    city: 'Querétaro',
    line3: 'Queretaro',
    postal_code: '',
    line1: 'Av 5 de Febrero',
    line2: 'Roble 207',
    state: 'Queretaro',
    country_code: 'MX',
  };
  slideOpts = {
    initialSlide: 0,
    speed: 1000, // Speed of transition (1 second)
    autoplay: {
      delay: 3000, // 3 seconds delay
    },
    loop: true
  };
  @ViewChild('slides', { static: false }) slides: IonSlides;
  deviceSessionId;
  constructor(
    private NgZone: NgZone,
    private router: Router,
    private request: RequestService,
    private modalcontroller: ModalController,
    private profileService: ProfileService,
    private walletService:WalletServiceService,
    private stripe:Stripe,
    private popOverController: PopoverController,
    //private iap2: InAppPurchase2,
  ) {
    this.stripe.setPublishableKey(KEY)

  }
  onSuccess(response){
    localStorage.removeItem('openpay_token');
    var token_id = response.data.id;
    console.log('onSuccess',response);
console.log('onSuccess',token_id);
localStorage.setItem('openpay_token', token_id);

Swal2.fire({
  title: 'Procesando',
  text: 'El pago se está procesando...',
  icon: 'info',
  timer: 9999, // Show the alert for 45 seconds
  timerProgressBar: true,
  color: '#ffffff',
  background: '#010f4e',
  }).then((result) => {
		if (result.isConfirmed) {

//this.openpay_token=token_id;
//this.pagar_suscripcion(token_id);
    }
  })
  }
  onError(){
    localStorage.removeItem('openpay_token');
    this.setDrive_media=null;
    clearInterval(this.setDrive_media);
    console.log('onError');
    Swal2.fire({
      title: 'Pago Rechazado',
      text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
      icon: 'error',
      timer: 10000, // Show the alert for 45 seconds
			timerProgressBar: true,
      confirmButtonText: 'Ok',
      color: '#ffffff',
      background: '#010f4e',
    })
    setTimeout(() => {
      window.location.reload();
		  }, 9000);
  }
  loadScripts() {
    if(!this.setDrive_media){ // CONDICION AGREGADA PARA NO ESTRESAR A SERVIDORES OP
      return;
          }
    // Load jQuery
    this.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js')
      .then(() => {
        // Load OpenPay scripts after jQuery is loaded
        return this.loadScript('https://js.openpay.mx/openpay.v1.min.js');
      })
      .then(() => {
        return this.loadScript('https://js.openpay.mx/openpay-data.v1.min.js');
      })
      .then(() => {
        // Initialize OpenPay after all scripts are loaded
        this.initializeOpenPay();
      })
      .catch((error) => {
        console.error('Error loading scripts', error);
      });
  }

  loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      script.onload = () => resolve();
      script.onerror = (error) => reject(error);
      document.head.appendChild(script);
    });
  }

  initializeOpenPay(): void {

      OpenPay.setId('mnoneybxt8wqggyaxol2');
      OpenPay.setApiKey('pk_0ef12ae0ed5b48bfa19abeae693e046e');
       this.deviceSessionId = OpenPay.deviceData.setup();
      console.log('Device Session ID:', this.deviceSessionId);
      this.pagar_suscripcion();
  }
  openCarteraHubble(){
   
    let objmedia = {
      publication_id:0, 
      buyer_id: this.profile.id, // Replace with the actual buyer's ID
   //   currency: this.selectedCurrencyName, // Replace with the actual currency
      publication_type: 'inscription'
    };
    console.log('profile', this.profile.id)
    this.modalcontroller.create({
      component: WalletAppComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps: {
        shortcut:'pagoDePublicacion',
        publication_id:1,
        objmedia:objmedia,
      profile_id: this.profile.id,
      TotalPayment: 99,
   //   transactions: transactions,
  //    balances: balancesArray,
      },
    }).then((modal) => {
      
      modal.present();
      this.popOverController.dismiss();
   
    });
  
  }
  async open_pago_tarjeta(){
    
    const modal = await this.modalcontroller.create({
      component: PagoTarjetaComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps: {
        openpay_subscription_payment:false,
        profile: this.profile,
        pago_tarjeta_stripe:true
      },
    });
    modal.onDidDismiss().then((data) => {
     
    });
    this.popOverController.dismiss();
    return await modal.present();


  }
  ngOnInit() {
    if(this.openpay_subscription_payment){
      this.amount=20;
    }
    console.log('pago_tarjeta_stripe',this.pago_tarjeta_stripe)
    console.log('openpay_subscription_payment',this.openpay_subscription_payment)
if(!this.pago_tarjeta_stripe){
  this.router.navigate(['/home/go-ecosystem-page']);
}


OpenPay.setId('mnoneybxt8wqggyaxol2');
OpenPay.setApiKey('pk_0ef12ae0ed5b48bfa19abeae693e046e');
OpenPay.setSandboxMode(false);
//this.pagar_verificacion();
console.log('getSandboxMode',OpenPay.getSandboxMode());
console.log('OpenPay',OpenPay);
/*
OpenPay.token.create({
  card_number:"4111111111111111",
  holder_name:"Juan Perez Ramirez",
  expiration_year:27,
  expiration_month:12,
  cvv2:"110",
  address:{
     city:"Querétaro",
     line3:"Queretaro",
     postal_code:"76900",
     line1:"Av 5 de Febrero",
     line2:"Roble 207",
     state:"Queretaro",
     country_code:"MX"
  }
}, this.onSuccess, this.onError);
*/


  }

  regresar(){
    this.modalcontroller.dismiss()
  }
  ngAfterViewInit(){
    if(!this.pago_tarjeta_stripe){
      this.slides.startAutoplay();
    }
  }
  get_card_type()
  {
    console.log('refactoring card');
    this.image_brand = "";

    //Remove all spaces and dashes from the passed string
    const CardNo = this.cardNum.replace("-", "").replace(" ", "");

    //Check that the minimum length of the string isn't less
    //than fourteen characters and -is- numeric
    if (CardNo.length < 14 || !isNumeric(CardNo)) {
      this.image_brand = ''
    }

    //Check the first two digits first
    if(parseInt(this.cardNum.substring(0,2)) === 34 || parseInt(this.cardNum.substring(0,2)) === 37) {
      this.image_brand = 'assets/icon/pagos/amex.png';

    }else if(parseInt(this.cardNum.substring(0,2)) >= 51 && parseInt(this.cardNum.substring(0,2)) <= 55){
      this.image_brand = 'assets/icon/pagos/master_card.png';
    }else if(parseInt(this.cardNum.substring(0,1)) === 4) {
      this.image_brand = 'assets/icon/pagos/visa.png';
    }

    console.log(this.image_brand);

    this.cardNum = this.cardNum.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();

  }

  pagar_suscripcion(){
    if(!this.setDrive_media){
return;
    }
 
    this.setDrive_media=null;
    clearInterval(this.setDrive_media);
  this.request.createRequestPost('VerificationWithOpenPay', {token:localStorage.getItem('openpay_token'), device_session_id:this.deviceSessionId, holder_name:this.titular})
  .then((data) => {
    console.log('data',data);
    if(data.status){
      Swal2.fire({
        title: 'Pago de inscripción procesado con éxito!',
        text: 'Continua con los siguientes pasos para regocijarte como usuario Verificado.',
        icon: 'success',
        confirmButtonText: 'Ok',
        color: '#ffffff',
        background: '#010f4e',
      }).then((r) =>{

        this.profileService.getProfileInfo().then((profile) => {
          console.log("promesa del perfile en verificación", profile);
          this.modalcontroller.dismiss(JSON.parse(profile));
        })

}).catch((error) => {
console.log("Error de stripe",error);
});

    }else{
      Swal2.fire({
        title: 'Pago Rechazado',
        text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
        icon: 'error',
        confirmButtonText: 'Ok',
        color: '#ffffff',
        background: '#010f4e',
      })
    }

}).catch((error) => {
  console.log("Error de openpay",error);
  Swal2.fire({
    title: 'Pago Rechazado',
    text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
    icon: 'error',
    confirmButtonText: 'Ok',
    color: '#ffffff',
    background: '#010f4e',
  })
});
}

  pagar(){
    if(this.openpay_subscription_payment){
      if(!this.formData.postal_code){
        Swal2.fire({
          title: 'Pago Rechazado',
          text: 'Por favor, añade un código postal.',
          icon: 'error',
          confirmButtonText: 'Ok',
          color: '#ffffff',
          background: '#010f4e',
        })
        return;
      }
      if(!this.formData.line1 || !this.formData.city  || !this.formData.line3 || !this.formData.line2 || !this.formData.state || !this.formData.country_code){
        Swal2.fire({
          title: 'Pago Rechazado',
          text: 'Por favor, añade todos los datos de la dirección de facturación.',
          icon: 'error',
          confirmButtonText: 'Ok',
          color: '#ffffff',
          background: '#010f4e',
        })
        return;
      }


      let month=Number(this.month);
      console.log('month',month)
      let yearNumber = Number(this.year.toString().slice(-2));
      console.log('yearNumber',yearNumber);
      let cardNum = this.cardNum.toString().replace(/\s+/g, '');
      console.log('cardNum', cardNum);
      
      let titular=this.titular.toString();
      console.log('titular',titular);
      let cvv2=this.cvc.toString();
      console.log('cvv2',cvv2);
      this.setDrive_media=  setInterval(() => {
if( localStorage.getItem('openpay_token')){

  this.loadScripts();
}
      }, 2100); 
      OpenPay.token.create({
        card_number:cardNum,
        holder_name:titular,
        expiration_year: yearNumber,  // Only the last two digits
        expiration_month:month,
        cvv2: cvv2,  // Converted to a number
        address:{
           city:this.formData.city,
           line3:this.formData.line3,
           postal_code:this.formData.postal_code,
           line1:this.formData.line1,
           line2:this.formData.line2,
           state:this.formData.state,
           country_code:this.formData.country_code
        }
      }, this.onSuccess, this.onError);



    }else{


    
    let card = {
      number: this.cardNum.replace(' ', ''),
      expMonth: this.month,
      expYear: this.year,
      cvc: this.cvc
     }
     
      
     this.stripe.createCardToken(card)
     .then((token) =>{
      const token_id=token.id
      console.log("Token obtenido",token_id);

      let object={
          amount:this.amount,
          currency:this.currency,
          description:"Validacion",
          tokenid:token_id,
          profile_id: this.profile.id  // Pass the profile id
        }
        this.request.createRequestPost('stripe-pay',object).then((result)=>{
          const parsedResult = JSON.parse(result);  // Parse the result string into a JSON object
          console.log("resultado de respuesta back stripe",result);
          console.log(JSON.stringify(result));

          if (parsedResult.code == 200) {

              Swal2.fire({
                title: 'Pago de inscripción procesado con éxito!',
                text: 'Continua con los siguientes pasos para regocijarte como usuario Verificado.',
                icon: 'success',
                confirmButtonText: 'Ok',
                color: '#ffffff',
                background: '#010f4e',
              }).then((r) =>{

                this.profileService.getProfileInfo().then((profile) => {
                  console.log("promesa del perfile en verificación", profile);
                  this.modalcontroller.dismiss(JSON.parse(profile));
                })
    

                //return this.subscribeToProduct('1618');
  //}).then(() => {
    //return this.profileService.getProfileInfo();
  //}).then((profile) => {
    //console.log("promesa del profile en verificacion",profile);
   // this.modalcontroller.dismiss(JSON.parse(profile));
  }).catch((error) => {
    console.log("Error de stripe",error);
  });

         
            
            
          } else {
            Swal2.fire({
              title: 'Pago Rechazado',
              text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
              icon: 'error',
              confirmButtonText: 'Ok',
              color: '#ffffff',
              background: '#010f4e',
            })

            
          }

          
         
          
        })
          
        .catch(error => console.error(error));
     })

    
  
  
     
        // const tokenid=token.id

        // let object={
        //   amount:this.amount,
        //   currency:this.currency,
        //   description:"Validacion",


        //   tokenid:tokenid
        // }

      //   this.request.createRequestPost('stripe-pay',object).then((result) =>{
      //     console.log("Respuesta back", result);
          
        //   Swal2.fire({
        //     title: 'Pago Exitoso',
        //     text: 'Su pago fue procesado con exito',
        //     icon: 'success',
        //     confirmButtonText: 'Ok',
        //     color: '#ffffff',
        //     background: '#010f4e',
        //   }).then((r) =>{
        //     this.profileService.getProfileInfo().then((profile) => {
        //       this.modalcontroller.dismiss(JSON.parse(profile));
        //     });
          
        // }).catch((error)=>{
        //   console.log("Error de stripe",error);
        // });

      // })

    
      // amount: this.walletService.amount,
      // type: "validacion"
      

          
    }
  }


 
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
  
  
  



}
