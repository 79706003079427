import { Component, OnInit, Input } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import Swal2 from 'sweetalert2';
@Component({
  selector: 'app-my-ds-purchases',
  templateUrl: './my-ds-purchases.component.html',
  styleUrls: ['./my-ds-purchases.component.scss'],
})
export class MyDsPurchasesComponent implements OnInit {
  trackingData: any;
  Ordered_Items: any;
  Tracking_Timeline: any;
  @Input() orders;
  constructor(
    private request: RequestService,
    private modalcontroller:ModalController,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute  // Inject ActivatedRoute
  ) { }
  dismiss() {
    try {
      if (!this.trackingData) {
        this.modalcontroller.dismiss().then(() => {
          console.log("Modal dismissed successfully");
        }).catch((dismissError) => {
          // If there's an error dismissing the modal, catch and log it
          console.error("Error dismissing modal:", dismissError);
          // Navigate to 'feex-comerce' if there's an issue
          this.navCtrl.navigateForward('/feex-comerce');
        });
      } else {
        // Otherwise, clear the trackingData
        this.trackingData = null;
      }

     
    } catch (error) {
      console.error('Error occurred while dismissing the modal:', error);
      // In case of an error, navigate to 'feex-comerce'
      this.navCtrl.navigateForward('/feex-comerce');
    }
  }
  ngOnInit() { 
 

this.activatedRoute.paramMap.subscribe(params => {
  let query = params.get('query');  // Get the 'query' parameter
  console.log('getRecordByToken :',query);
  if(query  ){
   

}else{
  if(!this.orders){
    this.request.createRequestPost('ControladorDeCarritoDeCompras', {get_purchases:true})
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      this.orders=data.success;

     //
     console.log(this.orders);
    
    
   
    });
  }
}



    

  });
    /*
   
*/
  }
  ngAfterViewInit(){
    // Access the 'query' parameter from the route
const url = window.location.pathname; // Get the full URL path
const query = url.substring(url.lastIndexOf('/') + 1); // Get the slug after the last "/"
Swal2.fire({
  title: 'Espera un momento',
  text: 'Buscando información de tu compra ',
  icon: 'info',
  timer: 9999, // Show the alert for 45 seconds
  timerProgressBar: true,
  toast: true,
  position: 'top-start',
  showConfirmButton: false
  });
console.log('getRecordByToken :', query);
if(query){
  this.request.createRequestPost('AlibabaProductHandler', {get_purchases:true, order_id:query})
  .then((data) => {

    if (data && typeof data === 'string') {
      try {
        data = JSON.parse(data);
     
      } catch (error) {
        console.error("Error parsing data:", error);
      }
      }
      let output: string = JSON.stringify(data);
/*
      Swal2.fire({
        title: 'Espera un momento',
        text: 'Buscando información de tu compra: ' + output ,
        icon: 'info',
        timer: 9999, // Show the alert for 45 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false
        });
     */
         //console.log(data)
         let orders = Array.isArray(data.success) ? data.success : [data.success];


this.orders=orders;
console.log('getRecordByToken :',this.orders);

  
 
  
});
}else{
    this.activatedRoute.paramMap.subscribe(params => {
      let query = params.get('query');  // Get the 'query' parameter
      console.log('getRecordByToken :',query);
      if(query  ){
       
      this.request.createRequestPost('ControladorDeCarritoDeCompras', {get_purchases:true, order_id:query})
      .then((data) => {
    
        if (data && typeof data === 'string') {
          try {
            data = JSON.parse(data);
         
          } catch (error) {
            console.error("Error parsing data:", error);
          }
          }
         
             //console.log(data)
             let orders = Array.isArray(data.success) ? data.success : [data.success];
    
    
    this.orders=orders;
    console.log('getRecordByToken :',this.orders);
    
      
     
      
    });
    }
  });
}
  }
  image
  open_tracking_details(order_id, order, image){
    this.image=image;
    console.log('order', order);
    this.request.createRequestPost('AlibabaProductHandler', {track_order: true,ae_order_id:order_id})
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      this.trackingData=data;
      console.log(this.trackingData);
      this.Ordered_Items=this.trackingData?.success?.aliexpress_ds_order_tracking_get_response?.result?.data?.tracking_detail_line_list?.tracking_detail[0]?.package_item_list?.package_item;
      console.log('Ordered_Items', this.Ordered_Items);
      this.Tracking_Timeline=this.trackingData?.success?.aliexpress_ds_order_tracking_get_response?.result?.data?.tracking_detail_line_list?.tracking_detail[0]?.detail_node_list?.detail_node;
      console.log('Tracking_Timeline', this.Tracking_Timeline);
    });
  }

  private requestedProductIds = new Set<string>(); // Store requested product IDs
  async handleImageError(event: Event, product_id: string, order) {
    console.log('Product ID:', product_id);
    // Check if the product ID has already been requested
    if (this.requestedProductIds.has(product_id)) {
      console.log(`Skipping duplicate request for product ID: ${product_id}`);
      return;
    }

    const imgElement = event.target as HTMLImageElement;
  
    try {
      let data = await this.request.createRequestPost('AlibabaProductHandler', { product_id: product_id });
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      console.log('Product Data:', data);

      order.title=data.success.aliexpress_ds_product_get_response.result.ae_item_base_info_dto.subject;
      // Extract the mobileDetail string
      let detail = data.success.aliexpress_ds_product_get_response.result.ae_item_base_info_dto.mobile_detail;

      // Parse JSON safely
      let parsedDetail;
      if(!detail){
        imgElement.src='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
        return;
      }
      try {
        parsedDetail = JSON.parse(detail);
      } catch (error) {
        console.error('Invalid JSON format:', error);
        return;
      }
  
      if (!parsedDetail?.moduleList) {
        console.error('No moduleList found in the response');
        return;
      }
  
      // Function to validate image URLs
      const validateImage = async (url: string): Promise<boolean> => {
        try {
          const response = await fetch(url, { method: 'HEAD' });
          return response.ok;
        } catch (error) {
          return false;
        }
      };
  
      // Find and set the first valid image
      for (const module of parsedDetail.moduleList) {
        if (module.type === 'image' && module.images) {
          for (const image of module.images) {
              if (image.imgUrl) {
                  const isValid = await validateImage(image.imgUrl);
                  if (isValid) {
                      imgElement.src = image.imgUrl;
                      console.log('Valid image set:', image.imgUrl);
                      return;
                  }
              }
          }
        }
        if (module.type === 'image' && module.data?.url) {
          const isValid = await validateImage(module.data.url);
          if (isValid) {
            imgElement.src = module.data.url;
            console.log('Valid image set:', module.data.url);
            return;
          }
        }
      
      }
      imgElement.src='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
      console.warn('No valid image found for the product');

  
    this.requestedProductIds.add(product_id); // Mark product ID as requested
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  }
}
