import { Component, OnInit, Input } from '@angular/core';
import {AlibabaCheckoutCartComponent}  from 'src/app/alibaba-checkout-cart/alibaba-checkout-cart.component';
import {
  NavController,
  AlertController,
  ModalController, IonicModule, ActionSheetController, IonSelect, IonSlides
} from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
@Component({
  selector: 'app-stock-keeping-unit-selection',
  templateUrl: './stock-keeping-unit-selection.component.html',
  styleUrls: ['./stock-keeping-unit-selection.component.scss'],
})
export class StockKeepingUnitSelectionComponent implements OnInit {
  complete_data;
  @Input() data;
  @Input() product_id;
  @Input() product_name;
  @Input() product_details;
  cart: any[] = [];
  constructor(
    public modalController: ModalController,
    private formservice:FormServiceService,
  ) { }

  ngOnInit() {
    console.log('data', this.data)
    this.complete_data=this.data;
   // if(!this.product_details){
      this.data=this.data.success.aliexpress_ds_product_get_response.result.ae_item_sku_info_dtos
    
   // }
 
    // Ensure that the 'ae_item_sku_info_d_t_o' property exists in this.data
    if (this.data && this.data.ae_item_sku_info_d_t_o) {
      for (let item of this.data.ae_item_sku_info_d_t_o) {
       
        if (item.sku_attr && item.offer_sale_price) {
          // Apply the update
          let price = parseFloat(item.offer_sale_price.replace(/[^0-9.-]+/g, ''));
          if (!isNaN(price)) {
            let new_price= (price * this.formservice.ds_margin).toFixed(2);
            item.offer_sale_price =new_price;
          }
        }

      }
      
      
      console.log('data', this.data)
    } else {
      console.error("Invalid data structure: 'ae_item_sku_info_d_t_o' not found in this.data.");
    }
    

    

  }
  updateCart(skuGroup: any, sku_image): void {
    // Ensure the `cart` array exists and is initialized
    if (!this.cart) {
      this.cart = [];
    }

    // Ensure the `sku_id` exists and `selectedQuantity` is valid
    if (!skuGroup.sku_id || skuGroup.selectedQuantity == null) {
      return;
    }

    // Find the existing item in the cart
    const existingItem = this.cart.find((item) => item.sku_id === skuGroup.sku_id);

    if (skuGroup.selectedQuantity > 0) {
      if (existingItem) {
        // Update the quantity if the item is already in the cart
        existingItem.selectedQuantity = skuGroup.selectedQuantity;
      } else {
        // Add the item to the cart if it doesn't exist
        this.cart.push({
          sku_id: skuGroup.sku_id,
          price: skuGroup.offer_sale_price,
          selectedQuantity: skuGroup.selectedQuantity,
          product_id:this.product_id,
          item_image:sku_image,
          product_name:this.product_name,
          sku_attr:skuGroup.sku_attr

        });
      }
    } else {
      // Remove the item from the cart if quantity is 0
      this.cart = this.cart.filter((item) => item.sku_id !== skuGroup.sku_id);
    }

    console.log(this.cart); // Debugging: Log the updated cart
  }

  // Method to handle increment and decrement buttons
  updateQuantity(skuGroup: any, change: number, sku_image): void {
    // Ensure `selectedQuantity` is initialized
    if (skuGroup.selectedQuantity == null) {
      skuGroup.selectedQuantity = 0;
    }

    const newQuantity = skuGroup.selectedQuantity + change;

    // Validate the new quantity against stock limits
    if (newQuantity >= 0 && newQuantity <= skuGroup.ipm_sku_stock) {
      skuGroup.selectedQuantity = newQuantity;
      this.updateCart(skuGroup, sku_image);
    }
  }
    // Calcular el total de la compra
    calculateTotal(){
      return this.cart?.reduce(
        (total, item) => total + item.price * item.selectedQuantity,
        0
      );
    }
  
    // Método para manejar el checkout
    agregar_al_carrito(): void {
      this.cart.forEach((newItem) => {
        // Find the index of the existing item in AlibabaCheckoutCart
        const existingIndex = this.formservice.AlibabaCheckoutCart.findIndex(
          (item) => item.sku_id === newItem.sku_id && item.product_id === newItem.product_id
        );
      
        if (existingIndex !== -1) {
          // If the item exists, update its quantity
          const existingItem = this.formservice.AlibabaCheckoutCart[existingIndex];
          this.formservice.AlibabaCheckoutCart[existingIndex] = {
            ...existingItem,
            selectedQuantity: existingItem.selectedQuantity + newItem.selectedQuantity,
          };
        } else {
          // If the item doesn't exist, add it as a new item
          this.formservice.AlibabaCheckoutCart.push(newItem);
        }
      });
      


      console.log('Productos seleccionados:', this.cart);
      this.modalController.create({
        component: AlibabaCheckoutCartComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
       

        }
    }).then((modal)=>{
        modal.present();
    });
      // Aquí puedes redirigir al usuario o realizar la lógica necesaria para procesar el pedido.
    }
    comprar_ahora(){
      console.log('Productos seleccionados:', this.cart);
      this.modalController.create({
        component: AlibabaCheckoutCartComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          cart: this.cart,

        }
    }).then((modal)=>{
        modal.present();
    });
    }
    private requestedProductIds = new Set<string>(); // Store requested product IDs
    async handleImageError(event: Event, product_id: string) {
      console.log('Product ID:', product_id);
      // Check if the product ID has already been requested
      if (this.requestedProductIds.has(product_id)) {
        console.log(`Skipping duplicate request for product ID: ${product_id}`);
        return;
      }
    
      const imgElement = event.target as HTMLImageElement;
    
      try {
     
     
  
     
        // Extract the mobileDetail string
        let detail = this.complete_data.success.aliexpress_ds_product_get_response.result.ae_item_base_info_dto.mobile_detail;
  
        // Parse JSON safely
        let parsedDetail;
        if(!detail){
          this.requestedProductIds.add(product_id); // Mark product ID as requested
          imgElement.src='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
          return;
        }
        try {
          parsedDetail = JSON.parse(detail);
        } catch (error) {
          console.error('Invalid JSON format:', error);
          return;
        }
    
        if (!parsedDetail?.moduleList) {
          imgElement.src='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
          console.error('No moduleList found in the response');
          return;
        }
    
        // Function to validate image URLs
        const validateImage = async (url: string): Promise<boolean> => {
          try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.ok;
          } catch (error) {
            return false;
          }
        };
    
        // Find and set the first valid image
        for (const module of parsedDetail.moduleList) {
          if (module.type === 'image' && module.images) {
            for (const image of module.images) {
                if (image.imgUrl) {
                    const isValid = await validateImage(image.imgUrl);
                    if (isValid) {
                        imgElement.src = image.imgUrl;
                        console.log('Valid image set:', image.imgUrl);
                        this.requestedProductIds.add(product_id); // Mark product ID as requested
                        return;
                    }
                }
            }
          }
          if (module.type === 'image' && module.data?.url) {
            const isValid = await validateImage(module.data.url);
            if (isValid) {
              imgElement.src = module.data.url;
              console.log('Valid image set:', module.data.url);
              this.requestedProductIds.add(product_id); // Mark product ID as requested
              return;
            }
          }
        
        }
        imgElement.src='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
        console.warn('No valid image found for the product');
  
        this.requestedProductIds.add(product_id); // Mark product ID as requested
   
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    }
    dismiss(){
      this.modalController.dismiss();
    }
    getStars(rating: number): { icon: string, filled: boolean }[] {
      const stars: { icon: string, filled: boolean }[] = [];
      const fullStars = Math.floor(rating);
      const decimal = rating % 1;
    
      // Add filled stars
      for (let i = 0; i < fullStars; i++) {
        stars.push({ icon: 'star', filled: true });
      }
    
      // Add half star if decimal >= 0.5 and not at 5 stars
      if (decimal >= 0.5 && stars.length < 5) {
        stars.push({ icon: 'star-half', filled: true });
      }
    
      // Fill remaining with empty stars
      while (stars.length < 5) {
        stars.push({ icon: 'star-outline', filled: false });
      }
    
      return stars;
    }
    view_stock(){
      this.product_details=false;
    }
}
