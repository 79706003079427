import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-my-ds-purchases',
  templateUrl: './my-ds-purchases.component.html',
  styleUrls: ['./my-ds-purchases.component.scss'],
})
export class MyDsPurchasesComponent implements OnInit {
  trackingData: any;
  Ordered_Items: any;
  Tracking_Timeline: any;
  orders;
  constructor(
    private request: RequestService,
    private modalcontroller:ModalController
  ) { }
  dismiss(){
    if(!this.trackingData){
      this.modalcontroller.dismiss();
    }else{
      this.trackingData=null;
    }

  }
  ngOnInit() { 

    this.request.createRequestPost('AlibabaProductHandler', {get_purchases:true})
    .then((data) => {
    
      this.orders=data.success;

     //
     console.log(this.orders);
    
    
   
    });
    /*
   
*/
  }
  open_tracking_details(order_id, order){
    console.log('order', order);
    this.request.createRequestPost('AlibabaProductHandler', {track_order: true,ae_order_id:order_id})
    .then((data) => {
      this.trackingData=data;
      console.log(this.trackingData);
      this.Ordered_Items=this.trackingData?.success?.aliexpress_ds_order_tracking_get_response?.result?.data?.tracking_detail_line_list?.tracking_detail[0]?.package_item_list?.package_item;
      console.log('Ordered_Items', this.Ordered_Items);
      this.Tracking_Timeline=this.trackingData?.success?.aliexpress_ds_order_tracking_get_response?.result?.data?.tracking_detail_line_list?.tracking_detail[0]?.detail_node_list?.detail_node;
      console.log('Tracking_Timeline', this.Tracking_Timeline);
    });
  }

  private requestedProductIds = new Set<string>(); // Store requested product IDs
  async handleImageError(event: Event, product_id: string, order) {
    console.log('Product ID:', product_id);
    // Check if the product ID has already been requested
    if (this.requestedProductIds.has(product_id)) {
      console.log(`Skipping duplicate request for product ID: ${product_id}`);
      return;
    }

    const imgElement = event.target as HTMLImageElement;
  
    try {
      const data = await this.request.createRequestPost('AlibabaProductHandler', { product_id: product_id });
      console.log('Product Data:', data);

      order.title=data.success.aliexpress_ds_product_get_response.result.ae_item_base_info_dto.subject;
      // Extract the mobileDetail string
      let detail = data.success.aliexpress_ds_product_get_response.result.ae_item_base_info_dto.mobile_detail;

      // Parse JSON safely
      let parsedDetail;
      if(!detail){
        imgElement.src='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
        return;
      }
      try {
        parsedDetail = JSON.parse(detail);
      } catch (error) {
        console.error('Invalid JSON format:', error);
        return;
      }
  
      if (!parsedDetail?.moduleList) {
        console.error('No moduleList found in the response');
        return;
      }
  
      // Function to validate image URLs
      const validateImage = async (url: string): Promise<boolean> => {
        try {
          const response = await fetch(url, { method: 'HEAD' });
          return response.ok;
        } catch (error) {
          return false;
        }
      };
  
      // Find and set the first valid image
      for (const module of parsedDetail.moduleList) {
        if (module.type === 'image' && module.images) {
          for (const image of module.images) {
              if (image.imgUrl) {
                  const isValid = await validateImage(image.imgUrl);
                  if (isValid) {
                      imgElement.src = image.imgUrl;
                      console.log('Valid image set:', image.imgUrl);
                      return;
                  }
              }
          }
        }
        if (module.type === 'image' && module.data?.url) {
          const isValid = await validateImage(module.data.url);
          if (isValid) {
            imgElement.src = module.data.url;
            console.log('Valid image set:', module.data.url);
            return;
          }
        }
      
      }
      imgElement.src='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
      console.warn('No valid image found for the product');

  
    this.requestedProductIds.add(product_id); // Mark product ID as requested
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  }
}
