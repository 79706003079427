import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { IonSlides, ModalController } from '@ionic/angular';
import Swal2 from 'sweetalert2';
import {InlineBowsingComponent} from 'src/app/inline-bowsing/inline-bowsing.component';
import { Browser } from '@capacitor/browser';
import {UploadMultimediaService} from "src/app/services/upload/upload-multimedia.service";
import { Utils } from 'src/app/Models/Classes/utils';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
@Component({
  selector: 'app-feex-orders',
  templateUrl: './feex-orders.component.html',
  styleUrls: ['./feex-orders.component.scss'],
})
export class FeexOrdersComponent implements OnInit {
  courier_name;
  courier_photo;
  courier_phone_and_pin;
  driverlat;
  driverlng
  current_order;
  orders;
  slideOpts = {
    loop: true,
    direction: 'vertical',
    initialSlide: 0,
    speed: 420,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true, // doesnt keeps autoplay enabled after user interaction
    }
  };
  @ViewChild(IonSlides) slides: IonSlides;
  constructor(  private request: RequestService,private Mctrl: ModalController, private uploadMultimedia: UploadMultimediaService, 	private utils: Utils,
    public formservice: FormServiceService,) { }
  async openPublication(id){
    let url =  `https://supercumulo.laniakea.tv/home/go-ecosystem-page?p=${id}`;
    const modal = await this.Mctrl.create({
			cssClass: 'detailsInModal',
			swipeToClose: true,
			component: InlineBowsingComponent,
			componentProps: {
				url: url,
			
			},
		  });
		  modal.onDidDismiss().then((data)=>{
			
		  });
		  return await modal.present();
  }
  dismiss(){
    this.Mctrl.dismiss();
  }
  ngOnInit() {
    this.request.createRequestPost('handleShipping', {getProductOrders:true})
    .then((data) => {
      console.log('dataa:',data);
      this.current_order=data[0];
      if(this.current_order.delivery_type=='Uber Eats'){
        this.request.createRequestPost('handleShipping', {getUberDelivery:true,  deliveryID: this.current_order.ship_id})
        .then((data) => {
          console.log('dataa2:',data);
          if(data?.courier?.location){
            this.handleCourier(data?.courier?.location.lat, data?.courier?.location.lng);
          }
          if(data?.courier?.name){
            this.courier_name=data?.courier?.name;
            this.courier_photo=data?.courier?.img_href;
            this.courier_phone_and_pin=data?.courier?.public_phone_info.formatted_phone_number;
          }
        
        }).catch((error) => {
          console.error('Failed to send notification:', error);
       
        });
      }
      this.orders=data;
    }).catch((error) => {
      console.error('Failed to get handleShipping:', error);
   
    });
  }
  async onSlideChange() {
    const activeIndex = await this.slides.getActiveIndex();
    console.log('Current slide index:', activeIndex);
    this.current_order=this.orders[activeIndex];
    if(this.current_order.delivery_type=='Uber Eats'){
    this.request.createRequestPost('handleShipping', {getUberDelivery:true,  deliveryID: this.current_order.ship_id})
    .then((data) => {
      console.log('dataa2:',data);
      if(data?.courier?.location){
        this.handleCourier(data?.courier?.location.lat, data?.courier?.location.lng);
      }
    }).catch((error) => {
      console.error('Failed to send notification:', error);
   
    });
  }
    // Perform any additional actions based on slide change
  }
  onClick(type, order){
    Swal2.fire({
			title: 'Procesando',
			text: 'Se está procesando la solicitud.',
			icon: 'info',
			timer: 4500, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    let Uber_Eats=false;
    let Amazon=false;
    if(order.delivery_type==='Uber Eats'){
      Uber_Eats=true;
    }else if(order.delivery_type==='Amazon Shipping'){
      Amazon=true;
    }
    this.request.createRequestPost('handleShipping', {buyUberDelivery:Uber_Eats,type:type, order:order,  buyAmazonShipping: Amazon})
    .then((data) => {

      if(data[0]['contents']){
        console.log('contents',data[0]['contents']);
        const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
				const filename = currentTime + '.png';

        this.formservice.uploadLabel(filename, data[0]['contents'], order.id);
      
      }

    
    // Assign the modified data to this.orders
    this.orders = data;

      console.log('dataa:',data);
      this.current_order=data[0];
      if(this.current_order.delivery_type=='Uber Eats'){
        this.request.createRequestPost('handleShipping', {getUberDelivery:true,  deliveryID: this.current_order.ship_id})
        .then((data) => {
          if(data?.courier?.location){
            this.handleCourier(data?.courier?.location.lat, data?.courier?.location.lng);
          }
          console.log('dataa2:',data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
       
        });
      }
     
    	Swal2.fire({
        title: 'Pedido aceptado',
        text: 'El pedido ha sido aceptado exitosamente.',
        icon: 'success',
        timer: 9999, // Show the alert for 45 seconds
        timerProgressBar: true,
       // toast: true,
     //   position: 'top-start',
        showConfirmButton: false
        });
    }).catch((error) => {
      //this.dismiss();
      console.error('Failed to send notification:', error);
      Swal2.fire({
        title: 'Pedido no aceptado',
        text: 'El pedido no ha sido aceptado exitosamente. Si el problema persiste, favor de contactar al soporte.',
        icon: 'error',
        timer: 9999, // Show the alert for 45 seconds
        timerProgressBar: true,
       // toast: true,
     //   position: 'top-start',
        showConfirmButton: false
        });
    });
  }
	  
  async openBrowser(url: string) {
		await Browser.open({ url });
	  }
    handleCourier(lat, lng){
      this.driverlat=lat;
      this.driverlng=lng;

    }
}
