import { Component, OnInit } from '@angular/core';
import * as QRCode from 'qrcode'; // Import the qrcode library
import { RequestService } from 'src/app/services/request/request.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgZone } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-qr-code-collection',
  templateUrl: './qr-code-collection.component.html',
  styleUrls: ['./qr-code-collection.component.scss'],
})
export class QrCodeCollectionComponent implements OnInit {
  public available_sections: Array<string> = ["Boletos", "Estrellas Fugaces"];
	public icons_for_sections: Array<string> = [ "bag-handle-sharp", "airplane-sharp"];
	public actual_section: string = "Boletos";
  public reservations:Array<any>=[];
  public vehicles:Array<any>=[];
  public qrData: string = '784y2839y9482';
  public qrCodeImage: string; // To store the generated QR code image data URL
  public QR : boolean=false;
  public fetchedTickets : boolean=false;
  displayedReservations: any[] = []; // New array to store displayed reservations
numItemsToDisplay = 0; // Number of items to display

  public currentReservationIndex: number = 0; // Initial index
  
  constructor(
    private request:RequestService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private modalController: ModalController,
  ) { }

  async emitChange(event){
		//this.optionChange.emit(event.detail.value);
		this.actual_section = event.detail.value;

    if (this.actual_section==='Boletos'){
      this.fetchTickets();
    }else if(this.actual_section==='Estrellas Fugaces'){
this.fetchShootinStars();
    }
	  
    const imagePreviewElement = document.getElementById("ReservationQR") as HTMLImageElement;
    imagePreviewElement.src = null; // Set src to null
	  }

  ngOnInit() {
   this.fetchTickets();
  }

  generateQRCode(qrData, ngFor?) {
    const url = `https://${qrData}`;
    this.QR=true;
    let imagePreviewElement
    if(!ngFor){
       imagePreviewElement = document.getElementById("ReservationQR") as HTMLImageElement;
    }else{
      imagePreviewElement = document.getElementById("ReservationQR"+qrData) as HTMLImageElement;
    }
   

    QRCode.toDataURL(url, (err, url) => {
      if (err) {
        console.error('Error generating QR code:', err);
      } else {
        console.log('Generated QR code URL:', url);

        fetch(url)
          .then((res) => {
            if (!res.ok) {
              throw new Error('Network response was not ok');
            }
            return res.blob();
          })
          .then((blob) => {
            const blobURL = URL.createObjectURL(blob);
            console.log('Generated QR code URL:', blobURL);

            imagePreviewElement.src = blobURL;
          })
          .catch((error) => {
            console.error('Error fetching QR code:', error);
          });
      }
    });
  }
  
  fetchTickets() {
    this.request.createRequestGet('collectionOfReservations').then((final_data) => {
      console.log('Received data:', final_data);
  
      const parsedData = JSON.parse(final_data).rsvData;
      console.log('Parsed data:', parsedData);
  
      this.reservations = parsedData;
      this.numItemsToDisplay = parsedData.length; // Set the number of items to display
  
      this.fetchedTickets = true;
      console.log('Tickets fetched:', this.fetchedTickets);
  
     
      console.log('Change detected',  this.numItemsToDisplay);
    });
  }

  fetchShootinStars() {
    var params = {
      "GetVehicles": 'true'
    }
    this.request.createRequestGet('collectionOfReservations', params, ).then((final_data) => {
      console.log('Received data:', final_data);
  
      //const parsedData = JSON.parse(final_data);
    //console.log('Parsed data:', parsedData);
     // console.log('Parsed data:', parsedData[0].id);
     // this.reservations = parsedData;

      //this.vehicles= JSON.parse(final_data).vehicle;
      if (typeof final_data === 'string') {
        let response = JSON.parse(final_data);
        console.log('Parsed data:', response);
        this.reservations=response;
        } else {

          this.reservations=final_data;
        }
    });
  }
  
  
  
  async verPublicacion(reservationId: number) {
    let url
    if (Capacitor.getPlatform() !== 'web') {
       url = `laniakea://supercumulo.laniakea.tv/home/ecosystem-page?p=${reservationId}`;
    }else{
       url = `https://supercumulo.laniakea.tv/home/ecosystem-page?p=${reservationId}`;
    }
   

    try {
      this.dismiss();
    	window.open(url, '_blank'); 
    } catch (error) {
      console.error('Error opening URL:', error);
    }
  }
  dismiss() {
    this.modalController.dismiss();

  }

  nextReservation() {
    if (this.currentReservationIndex < this.reservations.length - 1) {
      this.currentReservationIndex++;
      const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
      imagePreviewElement.src = null; // Set src to null
    }
  }

  previousReservation() {
    if (this.currentReservationIndex > 0) {
      this.currentReservationIndex--;
      const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
      imagePreviewElement.src = null; // Set src to null
    }
  }
  calculateCost(reservation) {
    if (reservation?.reservation?.created_at && reservation.vehicle?.Tarifa) {
      const createdAt = new Date(reservation.reservation.created_at);
      const now = new Date();
      
      // Get the current UTC time (HH:mm:ss)
      const currentTime = now.toISOString().split('T')[1].substring(0, 8);

      // Calculate the difference in hours
      const timeDifference = now.getTime() - createdAt.getTime();
      const hoursPassed = timeDifference / (1000 * 60 * 60);

      // Calculate the total cost
      const totalCost = hoursPassed * reservation.vehicle.Tarifa;
      // Format the total cost with commas as thousand separators
    const formattedTotalCost = new Intl.NumberFormat('en-US').format(totalCost);
      return formattedTotalCost;
    } 
  }
}  
