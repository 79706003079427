import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { FormServiceService } from "src/app/services/publications/form-service.service";
import Swal2 from "sweetalert2";

@Component({
  selector: "app-principal-eventos",
  templateUrl: "./principal-eventos.component.html",
  styleUrls: ["./principal-eventos.component.scss"],
})
export class PrincipalEventosComponent implements OnInit {
  public step: number = 1;
  public buttonSee :string="ver";
  constructor(public formservice:FormServiceService,
    public modalController:ModalController,
    public navCtrl: NavController,
    public alertController: AlertController) {
    this.formservice.eventospublication.title=this.formservice.title
    this.formservice.eventospublication.description=this.formservice.description
    this.formservice.eventospublication.publication_type=this.formservice.publication_type
    this.formservice.eventospublication.quienpuedever=this.formservice.quienpuedever
    this.formservice.eventospublication.is_feed=this.formservice.is_feed
    this.formservice.eventospublication.multimedia=this.formservice.multimedia
  }

  ngOnInit() {}

  //Funcion para el boton de siguiente
  next() {
    switch (this.step < 6) {
      case this.step === 1:
        if(this.formservice.eventospublication.category){
          this.step++;
        }
        else{
          Swal2.fire({
            title:'¡Advertencia!',
            text:'Recuerda seleccionar una categoría',
            icon:'warning',
            confirmButtonText: 'Entendido',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
            color:'#FFFFFF'
          })
          
        }
        break
      case this.step === 2:
        if(this.formservice.eventospublication.fecha_inicial && this.formservice.eventospublication.fecha_final && this.formservice.eventospublication.hora_inicial && this.formservice.eventospublication.hora_final ){
          this.step++
        }else{
          Swal2.fire({
            title:'¡Advertencia!',
            text: 'Recuerda llenar todos los campos ',
            icon: 'warning',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
            color: '#FFFFFF'
          })
        }  
        
        break;
        case this.step===3:
          if(this.formservice.eventospublication.price && this.formservice.eventospublication.limited_availability){
            this.step++

          }else{
            Swal2.fire({
              title:'¡Advertencia!',
              text:'verifique la información porfavor. El cupo es necesario.',
              icon: 'warning',
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
              color: '#FFFFFF'

            })
          }
          break;
        case this.step===4:
        //  if(this.formservice.eventospublication.street && (this.formservice.eventospublication.interior_number || this.formservice.eventospublication.exterior_number)&&this.formservice.eventospublication.postal_code && this.formservice.eventospublication.col && this.formservice.eventospublication.state && this.formservice.eventospublication.city){
          if(this.formservice.eventospublication.latitud && this.formservice.eventospublication.longitud){
        this.step++
          }  
          else{
            Swal2.fire({
              title:'¡Advertencia!',
              text: 'Porfavor asegurate de poner todos los datos de ubicacion de tu evento',
              icon: 'warning',
              background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
              color: '#FFFFFF'
            })
          }
          break;
       // case this.step === 5:
         // if(this.formservice.eventospublication.latitud && this.formservice.eventospublication.longitud){
           // this.step++
         // }  
          
        //  break;

    
      default:
        break;
    }
   
    // if (this.step < 6) {
    //   this.step++;
    // }
    // if(this.step===6){
    //   this.buttonSee="oculto"
    // }
  
  }

  //cerrar el modal
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  //abrir un mensaje de salir de la publicacion
  async back() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Salir de la publicación',
      message: 'Si desea salir se eliminará todo su avance',
      buttons: [
        {
          text: 'Salir',
          handler: () => {
            this.formservice.title="";
            this.formservice.description="";
            this.formservice.is_feed=false;
            this.formservice.quienpuedever="Para todos";
            this.formservice.eventospublication.category=undefined;
            this.formservice.eventospublication.link=undefined;
            this.formservice.eventospublication.limited_availability=undefined;
            this.formservice.eventospublication.ubicacion=undefined;
            this.formservice.eventospublication.street=undefined;
            this.formservice.eventospublication.interior_number=undefined;
            this.formservice.eventospublication.exterior_number=undefined;
            this.formservice.eventospublication.postal_code=undefined;
            this.formservice.eventospublication.col=undefined;
            this.formservice.eventospublication.city=undefined;
            this.formservice.eventospublication.state=undefined;
            this.formservice.eventospublication.fecha_inicial=undefined;
            this.formservice.eventospublication.fecha_final=undefined;
            this.formservice.eventospublication.hora_inicial=undefined;
            this.formservice.eventospublication.hora_final=undefined;
            this.dismiss();
          }
        },
        {
          text: 'Continuar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });
  
    await alert.present();
  }

}
