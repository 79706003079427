import { Component, OnInit, ViewChild } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import { ModalController, IonSlides } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import Swal2 from 'sweetalert2';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-shooting-star4',
  templateUrl: './shooting-star4.component.html',
  styleUrls: ['./shooting-star4.component.scss'],
})
export class ShootingStar4Component implements OnInit {
	public ConductorEnCamino:boolean=false;
  private currentIndex: number = 0; // Keep track of the current slide index
  @ViewChild('slides', { static: true }) slides: IonSlides; // ViewChild to access IonSlides
  public StartlocationsCalled:boolean=false;
  public DestinationlocationsCalled:boolean=false;
  public destination;
  public start;
public trips: any;
slideOpts = {
  direction: 'horizontal',
  initialSlide: 0, // Set the initial slide
  speed: 400, // Slide transition speed
  //slidesPerView: 1, // Number of slides visible at once
  spaceBetween: 10, // Space between slides
  // Add other options as needed
};
  constructor( private request: RequestService,
    private modalctrl:ModalController,
    private alertController: AlertController) { 
     
    }

  ngOnInit() {this.getOrders();}

  getOrders() {
    return new Promise((resolve, reject) => {
        this.request.createRequestPost('getShootingStarsOrders', {}) .then((data) => {
			if (data && typeof data === 'string') {
				try {
				  data = JSON.parse(data);
				  console.log('getRecordByToken ios:',data);
				} catch (error) {
				  console.error("Error parsing data:", error);
				}
				}
          console.log(data);
          console.log(data.final_data);
          if (typeof data === 'string') {
            let response = JSON.parse(data);
            console.log('json result publication service.ts', response);
            this.trips=response.data;
     
            } else {
              this.trips=data.data;
  
            }
              // Initial update when entering the view
  this.updateStartAndDestination();
         // this.trips=data.trip;
            resolve(data); 
           }).catch((error) => { 
             console.error('Failed to send notification:', error);
              reject(error); }); }); } 
              dismiss(){
                this.modalctrl.dismiss();
              }
              ionViewDidEnter() {
                setTimeout(() => {
                  if(this.trips){
  // Initial update when entering the view
  this.updateStartAndDestination();
                  }
              
              }, 900);
            
                // Subscribe to slide changes after view is fully initialized
                this.slides.ionSlideDidChange.subscribe(() => {
                  this.updateStartAndDestination();
                });
              }
             // Update start and destination based on the current slide index
  private updateStartAndDestination() {
    this.slides.getActiveIndex().then((index) => {
      this.currentIndex = index;
      const currentTrip = this.trips[this.currentIndex];
      if (currentTrip) {
        this.start = currentTrip.trip.startPoint;
        this.destination = currentTrip.trip.destination;
		if(currentTrip.trip.status.includes('viaje aceptado') && !currentTrip.trip.status.includes('El viaje ha comenzado exitosamente')){
			this.ConductorEnCamino = true;
		}
      }
    });
  }

   // Function to generate an array of icon names based on value
	 getStarIcons(value: number): string[] {
		const icons: string[] = [];
		const fullStars = Math.floor(value);
		const halfStar = value - fullStars >= 0.5;
	
		for (let i = 0; i < fullStars; i++) {
		  icons.push('star');
		}
	
		if (halfStar) {
		  icons.push('star-half');
		}
	
		while (icons.length < 9) {
		  icons.push('star-outline');
		}
	
		return icons;
	  }
	  async presentStarsSelectionAlert(trip_id) {
      console.log(trip_id);
		const alert = await this.alertController.create({
		  header: 'Selecciona tu valoración',
		  inputs: [
			{
			  type: 'radio',
			  label: '1 estrella',
			  value: 1,
			},
			{
			  type: 'radio',
			  label: '2 estrellas',
			  value: 2,
			},
			{
			  type: 'radio',
			  label: '3 estrellas',
			  value: 3,
			},
			{
			  type: 'radio',
			  label: '4 estrellas',
			  value: 4,
			},
			{
			  type: 'radio',
			  label: '5 estrellas',
			  value: 5,
			},
			{
				type: 'radio',
				label: '6 estrellas',
				value: 6,
			  },
			  {
				type: 'radio',
				label: '7 estrellas',
				value: 7,
			  },
			  {
				type: 'radio',
				label: '8 estrellas',
				value: 8,
			  },
			  {
				type: 'radio',
				label: '9 estrellas',
				value: 9,
			  },
			
		  ],
		  buttons: [
			{
			  text: 'Cancelar',
			  role: 'cancel',
			  cssClass: 'secondary',
			  handler: () => {
				console.log('Cancelar clicked');
			  },
			},
			{
			  text: 'Aceptar',
			  handler: (selectedValue) => {
				console.log('Selected value:', selectedValue);
				this.enviarValoracion(selectedValue, trip_id);
				// You can update the selected value to your component property here
				// For example: this.valoracion = selectedValue;
			  },
			},
		  ],
		});
	  
		await alert.present();
	  }

	  async enviarResena(trip_id) {
		console.log(trip_id);
		const alert = await this.alertController.create({
		  header: 'Ingresa una reseña.',
		  inputs: [
			{
			  name: 'review',
			  type: 'text',
			  placeholder: 'Reseña', // User can type their review here
			},
		  ],
		  buttons: [
			{
			  text: 'Cancelar',
			  role: 'cancel',
			  cssClass: 'secondary',
			  handler: () => {
				console.log('Cancelar clicked');
			  },
			},
			{
			  text: 'Aceptar',
			  handler: (data) => {
				console.log('Typed review:', data.review);
				// Here, you'd typically handle the typed review, like sending it to your backend
				// For example, you might call a function like this:
				// this.submitReview(trip_id, data.review);
				this.actualizarReseña(data.review, trip_id);
			  },
			},
		  ],
		});
	  
		await alert.present();
	  }

	  enviarValoracion(value, trip_id){
	
   
			return new Promise((resolve, reject) => {
			  const notification = {
			rate: value,
			trip_id:trip_id,
     
			  };
		  
			  this.request.createRequestPost('reviewAndRateShootingStarsTrip', notification)
				.then((data) => {
					if (data && typeof data === 'string') {
						try {
						  data = JSON.parse(data);
						  console.log('getRecordByToken ios:',data);
						} catch (error) {
						  console.error("Error parsing data:", error);
						}
						}
					Swal2.fire({
						title: 'Valoración enviada exitosamente',
				
						icon: 'success',
						confirmButtonText: 'OK',
						color: '#ffffff',
						background: '#010f4e',
						position: 'top-end',
						timerProgressBar: true,
						toast: true,
					  });
				  resolve(data);
				}).catch((error) => {
					Swal2.fire({
						title: 'No se pudo procesar tu valoración',
				
						icon: 'error',
						confirmButtonText: 'OK',
						color: '#ffffff',
						background: '#010f4e',
						position: 'top-end',
						timerProgressBar: true,
						toast: true,
					  });
				  console.error('Failed to send notification:', error);
				  reject(error);
				});
			});
		  
	  }
	  actualizarReseña(review, trip_id){
	
   
		return new Promise((resolve, reject) => {
		  const notification = {
		review: review,
		trip_id:trip_id,
 
		  };
	  
		  this.request.createRequestPost('reviewAndRateShootingStarsTrip', notification)
			.then((data) => {
				if (data && typeof data === 'string') {
					try {
					  data = JSON.parse(data);
					  console.log('getRecordByToken ios:',data);
					} catch (error) {
					  console.error("Error parsing data:", error);
					}
					}
				Swal2.fire({
					title: 'Valoración enviada exitosamente',
			
					icon: 'success',
					confirmButtonText: 'OK',
					color: '#ffffff',
					background: '#010f4e',
					position: 'top-end',
					timerProgressBar: true,
					toast: true,
				  });
			  resolve(data);
			}).catch((error) => {
				Swal2.fire({
					title: 'No se pudo procesar tu valoración',
			
					icon: 'error',
					confirmButtonText: 'OK',
					color: '#ffffff',
					background: '#010f4e',
					position: 'top-end',
					timerProgressBar: true,
					toast: true,
				  });
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  
  }
}
