import { Capacitor, Plugins } from '@capacitor/core';
import { Component, OnInit, Input,ViewChild } from '@angular/core';
import {
  NavController,
  AlertController,
  ModalController, IonicModule, ActionSheetController, IonSelect
} from '@ionic/angular';
import ecosystemJson from 'src/assets/plantillas/ecosistemdata.json';
import socialJson from 'src/assets/plantillas/ver/data-social.json';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { isVideo, isImage } from 'src/app/utils';
import Swal2 from 'sweetalert2';
import { UploadMultimediaService } from './../../services/upload/upload-multimedia.service';
import { CameraService } from 'src/app/services/multimedia/capacitor-camera/camera.service';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Media, MediaFetchOptions } from '@capacitor-community/media';
import { CameraPreview, CameraPreviewOptions } from '@capacitor-community/camera-preview';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router'; // Import the Router
import { RequestService } from 'src/app/services/request/request.service';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { SliderInformationComponent } from 'src/app/components/slider-information/slider-information.component';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { PagoVerificacionComponent } from 'src/app/components/pago-verificacion/pago-verificacion.component';
defineCustomElements(window);

declare var google;
declare var gapi;
declare var Google;
@Component({
  selector: 'app-create-publication',
  templateUrl: './create-publication.page.html',
  styleUrls: ['./create-publication.page.scss'],
})
export class CreatePublicationPage implements OnInit {
  isToggleChecked;
  listaDivisas=[

   
    {
      id:104,
      name:"MXN",
      valor: 1000,
      conversion:100
    },
  ];

  audio_preview_set:boolean=true;
  audio_blob_set:boolean=false;
  setDrive_media_called:boolean=false;
  setDrive_media;
  private apiKey = 'AIzaSyDKzCRYNscPNGWIGhsVZdFk-q-QuQmDjEs'; // Replace with your actual API Key
  private scope = 'https://www.googleapis.com/auth/drive.readonly';

  private oauthToken: string='sbjcbjdcs';
  user_id;
  profile_id;

  audio_start_value: number = 0;
  endValue: number = 30; // or any default value you want
  audio_duration=30;
  rangeValues: any = { lower: this.audio_start_value, upper: this.endValue };
  timeoutId;
  @ViewChild('selectTrigger', { static: false }) selectTrigger: IonSelect;
  @Input() entrelazamiento: boolean = false;
  mediaType: string;
  public selectedEcosystem: string = '';
  public ecosystemdata: Array<string> = Object.keys(ecosystemJson.ecosistema);
  public socialdata: Array<string> = Object.keys(socialJson.Social);
  public multimedia: Array<MediaItem> = new Array<MediaItem>();
  mediaPath: string;
  nameFile: string;
  
  ruta: Array<object> = [];
  rutImage: any;
  rutaImagen: Array<string>=[];
  public siguiente: boolean = false;
  validar: boolean = false;
  blobTrailer;
  rutaPortada : any;

  isPickedImage: boolean = false;
  isPickedVideo: boolean = false;
  public isCameraCalled = false;
public isVideoCalled = false;
  selectedMediaSrc: string;
  constructor(
    private walletService: WalletServiceService,
    private cameraService: CameraServiceService,
    public formservice: FormServiceService,
    public modalController: ModalController,
    public navCtrl: NavController,
    public alertController: AlertController,
    public uploadMedia: UploadMultimediaService,
    private cameraCapacitor:CameraService,
    private uploadMultimediaService: UploadMultimediaService,
    private actionSheetController: ActionSheetController,
    private sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private router: Router,
    private request: RequestService,
    private inputAlertController: InputAlertControllerService,
    public https: HttpClient,
    private faio:FingerprintAIO,

  ) {  this.rangeValues = { lower: this.audio_start_value, upper: this.endValue };}
  openIonSelect(event){
    console.log(event);

    this.selectTrigger.open();
    }
    private showSliderInformationModal() {
      this.inputAlertController.OkInfoMessage(
        'Usuario no verificado',
        'Esta característica solo está disponible para usuarios verificados, sigue los pasos en la sección de configuración de la cuenta y completa la verificación de la cuenta para acceder a esta funcionalidad',
        'Ir a completar mi perfil'
      );
      this.modalController.create({
        component: SliderInformationComponent,
        componentProps: {
        pr: this.formservice.profile,
        },
      }).then((modal) => {
        modal.present();
      }).catch((error) => {
        console.error(error);
      });
      }
      cambio($event){
        //this.openKYCApplicationModal();
        this.onClick();
          }
          private openKYCApplicationModal() {
            console.log('openKYCApplicationModal called');
            this.walletService.KYC_validation().then((result_kyc) => {
              console.log('KYC_validation result:', result_kyc);
              if (result_kyc['permit']) {
              this.showWalletAppModal();
              } else if (this.formservice.profile.kyc === "Sin Validar") {
                this.isToggleChecked = false;
              this.showSliderInformationModal();
              } else {
                this.isToggleChecked = false;
              this.showPagoVerificacionModal();
        
              }
            }).catch((error) => {
              console.error('KYC_validation error:', error);
              console.error(error);
            });
            }
            onClick() {
              if (Capacitor.getPlatform() !== 'web') {
              this.faio.show({
                title: "Leyendo huella Digital",
                subtitle: "Use su huella para permitir el acceso a retiros",
              })
                .then((res) => {
      
                      
                      this.openKYCApplicationModal();
             
                  console.log(res);
              
              
                })
                .catch((err) => {
                  console.log(err);

                  alert(err);
                });
              }else{
                this.openKYCApplicationModal();
              }
            }
            private showWalletAppModal() {
              if (this.walletService.SubscriptionExpired !== true){
             // if (!this.HubbleExpired){
               // this.HubbleExpired = true;
          // Set the authorization response status
          // Set the authorization response status
          this.validar=!this.validar;
          this.formservice.normalpublication.current_multimedia_status = this.validar;
          console.log(this.validar);
            console.log('showWalletAppModal called');
        
          // Set HubbleExpired to false after 6 seconds
          //setTimeout(() => {
            //this.HubbleExpired = false;
            //console.log('HubbleExpired set to false');
          //}, 6000);
        
             // }
            } else if (this.walletService.SubscriptionExpired === true){
              this.isToggleChecked = false;
             // this.RenewSuscription();
             this.showPagoVerificacionModal();
              Swal2.fire({
                title: 'Debes renovar tu suscripción',
                text: "Tu suscripción ha caducado, renuevala para acceder a esta función.",
                icon: 'info',
              //  showCancelButton: true,
                confirmButtonColor: '#3085d6',
                background: '#010f4e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancelar'
              });
            }
          
          
            }
            private showPagoVerificacionModal() {
              console.log('showPagoVerificacionModal called');
              console.log("entro al else if ");
              
              this.modalController.create({
                component: PagoVerificacionComponent,
                cssClass: 'detailsInModal',
                swipeToClose: true,
                componentProps: {
                pr: this.formservice.profile
                }
              }).then((modal) => {
                modal.present();
              });
              }
modify_publicaction_type(event: CustomEvent){
  this.walletService.KYC_validation().then((result_kyc) => {
    if (result_kyc['permit']) {
      Swal2.fire({
        title: 'Tipo de publicación modificada',
        text: "Se ha modificado exitosamente el tipo de publicación.",
        icon: 'info',
      //  showCancelButton: true,
        confirmButtonColor: '#3085d6',
        background: '#010f4e',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar'
      });
      this.formservice.publication_type= event.detail.value;
      this.selectedEcosystem = this.formservice.publication_type;
    }  else  {
      this.showSliderInformationModal();
  
    }
  }).catch((error) => {
    console.error(error);
  });
 
}
ngOnDestroy(){
    this.formservice.Capacitor_convertFileSrc=null;
    this.formservice.Capacitor_convertFileSrc_video=null;
    clearInterval(this.setDrive_media);
}
  ngOnInit() {




    localStorage.removeItem('fileId');
   this.profile_id=this.formservice.profile.id;
   localStorage.setItem('profile_id', this.profile_id);

    console.log('Entrando a oninit create publication', this.profile_id);
    this.rutaPortada = '';
    this.formservice.multimedia = [];
    this.selectedEcosystem = this.formservice.publication_type;
    console.log(this.selectedEcosystem);
    this.uploadMedia.image = '';
    console.log("siguiente create",this.siguiente);

  }
  private handlePlaceChanged(
    autocomplete: google.maps.places.Autocomplete,
   
    inputValue: string
  ) {

  
    const place = autocomplete.getPlace();
          // Calculate and display the route
          const startPointInput = document.getElementById('pac-input') as HTMLInputElement;
    console.log('place',place);
    console.log('startPointInput',startPointInput);
    this.formservice.ubicacion = startPointInput.value;
    this.formservice.normalpublication.ubicacion=this.formservice.ubicacion;
    this.formservice.lkpublication.ubicacion = this.formservice.ubicacion;
    this.formservice.landpublication.ubicacion = this.formservice.ubicacion;
    this.formservice.epochpublication.ubicacion = this.formservice.ubicacion;
    this.formservice.eventospublication.ubicacion = this.formservice.ubicacion;
    this.formservice.earthpublication.ubicacion = this.formservice.ubicacion;


  }
  ngAfterViewInit(){
    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
    };
    const input = document.getElementById('pac-input') as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener('place_changed', () => {
      this.handlePlaceChanged(autocomplete, input.value);
    });
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    if (this.formservice.Capacitor_convertFileSrc && imagePreviewElement){
    setTimeout(() => {
      if (this.formservice.Capacitor_convertFileSrc){
        imagePreviewElement.src =this.formservice.Capacitor_convertFileSrc;
      }

  }, 999);
}else {
  imagePreviewElement.src ='https://cdn-icons-png.flaticon.com/512/1329/1329925.png';
}
const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
if (this.formservice.Capacitor_convertFileSrc_video && videoPreviewElement){
setTimeout(() => {
  if (this.formservice.Capacitor_convertFileSrc_video){
    videoPreviewElement.src =this.formservice.Capacitor_convertFileSrc_video;
  }

}, 999);
}
  }

  public isVideo(url: string) {
    Capacitor.convertFileSrc(url);
    return isVideo(url);
  }
  
  public isImage(url: string){
    Capacitor.convertFileSrc(url);
    return isImage(url);
  }


  is_feed() {
    this.validar = !this.validar;

  }

  public setMultimediaCamera2(mediaItem: MediaItem) {
    console.log("setMultimediaCamera function called");
    const imagePath = mediaItem.mediaPath + mediaItem.nameFile;
    console.log("Captured image path:", imagePath);
    this.uploadMedia.image = Capacitor.convertFileSrc(imagePath);
    this.uploadMedia
      .readBlobFile(imagePath)
      .then(() => {
        console.log("Inside readBlobFile then block");
        if (this.selectedEcosystem === 'normal' && this.multimedia.length > 0) {
          console.log("Inside normal ecosystem condition");
          this.rutaPortada = { image: this.uploadMedia.image };
          this.multimedia[0] = this.rutaPortada;
        } else {
          this.rutaPortada = { image: this.uploadMedia.image };
          this.multimedia.push(this.rutaPortada);
        }
        /*
        if (
          this.formservice.normalpublication.blobsMultimedia.length > 0 &&
          this.selectedEcosystem === 'video'
        ) {
          console.log("Inside video ecosystem condition");
          this.formservice.normalpublication.blobsMultimedia[0] = {
            blob: this.uploadMedia.blob,
            isPremium: false,
            filename: this.uploadMedia.filename,
          };
        } else {*/
          this.formservice.normalpublication.blobsMultimedia.push({
            blob: this.uploadMedia.blob,
            isPremium: false,
            filename: this.uploadMedia.filename,
          });
     //   }
        console.log("Updated rutaPortada:", this.rutaPortada);
        return Promise.resolve();
      })
      .catch((error) => {
        console.log("Inside readBlobFile catch block:", error);
        return Promise.reject(error);
      });
  }
  
  public setMultimediaCamera(mediaItem: MediaItem) {
    
    console.log("setMultimediaCamera function called");
    const imagePath = mediaItem.mediaPath + mediaItem.nameFile;
    console.log("Captured image path:", imagePath);
    this.uploadMedia.image = Capacitor.convertFileSrc(imagePath);
    this.rutaPortada = { image: this.uploadMedia.image };
    this.uploadMedia.blob = mediaItem.mediaPath;
    this.uploadMedia.filename =  mediaItem.nameFile;
    if (this.formservice.multimedia.length > 0) {
      console.log("Inside  ecosystem condition");
      this.formservice.multimedia[0] = this.rutaPortada;
    } else {
      this.formservice.multimedia.push(this.rutaPortada);
    }
    if(this.formservice.normalpublication.blobsMultimedia.length < 1){
      this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
    }else {
      this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
    }
  
    console.log("Updated rutaPortada:", this.rutaPortada);
  
    // Set the image source in the HTML
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    imagePreviewElement.src = this.uploadMedia.image;
  }
  
  
  
  
  
  

  setMultimediaVideo2(mediaItem: MediaItem) {
    
    this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    const videoSourceElement = document.getElementById("videoSource") as HTMLSourceElement;
  
  videoSourceElement.src = this.uploadMedia.image;
    this.uploadMedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile).
    then((result)=>{
      if (this.formservice.multimedia.length >0){
        this.rutImage={image: this.uploadMedia.image};
        this.formservice.multimedia[0]= (this.rutImage);
      }else {
        this.rutImage={image: this.uploadMedia.image};
        this.formservice.multimedia.push(this.rutImage);
      }
      if(this.formservice.normalpublication.blobsMultimedia.length > 0 ){ //&& this.selectedEcosystem === 'normal'
        this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
      }else{
        this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
      }
    });
  }

  setMultimediaVideo(mediaItem: MediaItem) {
    
    this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    const videoSourceElement = document.getElementById("videoSource") as HTMLSourceElement;
  
  videoSourceElement.src = this.uploadMedia.image;
  this.uploadMedia.blob = mediaItem.mediaPath;
  this.uploadMedia.filename =  mediaItem.nameFile;
      if (this.formservice.multimedia.length >0){
        this.rutImage={image: this.uploadMedia.image};
        this.formservice.multimedia[0]= (this.rutImage);
      }else {
        this.rutImage={image: this.uploadMedia.image};
        this.formservice.multimedia.push(this.rutImage);
      }
      if(this.formservice.normalpublication.blobsMultimedia.length < 1 ){ //&& this.selectedEcosystem === 'normal'
        this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
      }else{
        this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
      }
 
  }

  public takePhoto(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.cameraService
        .askForMediCapture()
        .then((data: [MediaItem, string]) => {
          if (data[1].toString() === 'camara') {
           
            this.setMultimediaCamera(data[0]);
            resolve(data[0].mediaPath + data[0].nameFile);
          } else if (data[1].toString() === 'camvideo') {
           
            console.log("Estoy en video");
            this.setMultimediaVideo(data[0]);
            resolve(data[0].mediaPath + data[0].nameFile);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  

  async pickImage2() {
    const { Camera } = Plugins;
  
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    const videoSourceElement = document.getElementById("videoSource") as HTMLSourceElement;
  
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: 'dataUrl',
        source: 'library',
        saveToGallery: false,
        correctOrientation: true,
      });
  
      if (image) {
        const dataUrl = image.dataUrl;
  
        // Update the UI with the selected image
        imagePreviewElement.src = dataUrl;
        videoSourceElement.src = '';
   // Read the file as a Blob
   const response = await fetch(image.mediaPath);
   const blobfile = await response.blob();
 

        // Call the setMultimediaCamera function with the selected image data
        if ( this.formservice.multimedia.length > 0) {
          console.log("Inside normal ecosystem condition");
          this.formservice.multimedia[0] = dataUrl;
        } else {
          this.formservice.multimedia.push(dataUrl);
        }
  
        // Access the media path and name directly
        const mediaPath = image.mediaPath;
        const nameFile = 'PortadaImage';
  
        this.uploadMedia.blob = mediaPath;
        this.uploadMedia.filename = nameFile;
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
        }
        // Use the mediaPath and nameFile for further processing
        // ...
      }
    } catch (error) {
      console.log('Error picking image:', error);
    }
  }
  async pickImage(){
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {

        console.log('Media picked successfully:', media);

      if (Capacitor.getPlatform() === 'web') {
        // For web, create a Blob from the media data and convert it to a data URL
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result as string;
          imagePreviewElement.src = imageUrl;
          this.formservice.Capacitor_convertFileSrc=imageUrl;
        };
        reader.readAsDataURL(blobfile);
      } else {
        // For iOS and Android, use Capacitor.convertFileSrc to get the public URL
        const imageUrl = Capacitor.convertFileSrc(media.path);
        imagePreviewElement.src = imageUrl;
        this.formservice.Capacitor_convertFileSrc=imageUrl;
      }
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
        const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        
  
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        //const filename = media.path.substring(media.path.lastIndexOf('/') + 1);

  this.uploadMedia.blob = blobfile;
  if (Capacitor.getPlatform() !== 'web') {
        this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
  }else{
    const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
    this.uploadMedia.filename = currentTime + '.jpeg';
  }
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
        } 
        
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  
  
  async pickVideo() {
    if (Capacitor.getPlatform() === 'ios') {
      Swal2.fire({
        title: 'Añade videos en tu dispositivo apple',
        text: 'Para añadir videos optimizados añade el video de tu librería de Fotos a tus Archivos. A continuación la opción optimizada es: Choose File',
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }
    
    const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
  
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "video/*";
    fileInput.multiple = false;
  
    fileInput.addEventListener("change", () => {
      setTimeout(() => {
      const files = fileInput.files;
  
      if (files && files.length) {
        const file = files[0];

        const mimeType = file.type;


        const blobfile = file;
        const filename = file.name;
       // Create a MediaItem object from the File
      const mediaItem: MediaItem = {
        mediaPath: file.name,
        typeData: file.type,
        nameFile: file.name,
        id: 1, // Assign a unique ID here if needed
        size: file.size,
        index: 0, // Set the desired index value
        route: "", // Set the route value if needed
        status: "", // Set the initial status
        url: "", // Set the URL property
        localUrl: "", // Set the local URL property
        type: "", // Set the type property
        is_premium: 0, // Set the is_premium property
        // Add any other required properties
      };

      this.uploadMedia.blob = blobfile;
      this.uploadMedia.filename = filename;
        if (this.formservice.multimedia.length > 0) {
          this.formservice.multimedia[0] =  mediaItem;
        } else {
          this.formservice.multimedia.push(mediaItem);
        }
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
        }else {
          this.formservice.normalpublication.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
        }
  
        const videoURL = URL.createObjectURL(file);
  
        // Update the video source and display it
        videoPreviewElement.src = videoURL;
        this.formservice.Capacitor_convertFileSrc_video=videoURL;
      }
    }, 999);
    });
  
    // Trigger the file input dialog
    fileInput.click();
  }
  addtime_range(event: any) {
    console.log(event)
    let startValue = event.detail.value;

    this.audio_start_value=startValue;
    console.log("Start Time:", startValue);


   
    // Fetch the audio element dynamically
    const audioElement = document.getElementById('audioSource') as HTMLAudioElement;

    if (!audioElement) {
      console.error('Audio element not found');
      return;
    }

    // Clear any existing timeout
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Set the audio start time
    audioElement.currentTime = startValue;

    // Play the audio
    audioElement.play();

   let endValue = startValue+30;
   if(endValue>this.audio_duration){
    Swal2.fire({
      title: 'Error',
      text: 'La canción debe ser mayor a 30 segundos.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
    this.audio_preview_set=false;
    return;
   }else{
    Swal2.fire({
      title: 'éxito',
      text: 'La muestra de la canción se ha configurado exitosamente',
      icon: 'success',
      timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
this.audio_preview_set=true;
   }
    // Set a timeout to pause the audio at the end time
    const duration = (endValue - startValue) * 1000; // duration in milliseconds
    this.timeoutId = setTimeout(() => {
      audioElement.pause();
    }, duration);
  }
  async pickAudio() {
    Swal2.fire({
      title: 'Escoge un archivo para subir desde tu dispositivo.',
      text: "Al subirlo, confirmas que la multimedia cumple con nuestros Términos y Condiciones, y que no infringe en los derechos de alguien más.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
    if (Capacitor.getPlatform() === 'ios') {
      Swal2.fire({
        title: 'Añade audio en tu dispositivo apple',
        text: 'Para añadir audio optimizado añade el audio de tu librería de Música a tus Archivos. A continuación la opción optimizada es: Choose File',
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }
  
    const audioPreviewElement = document.getElementById("audioSource") as HTMLAudioElement;
  
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "audio/mp3";
    fileInput.multiple = false;
  
    fileInput.addEventListener("change", () => {
      const files = fileInput.files;
  
      if (files && files.length) {
        const file = files[0];
  
        const mimeType = file.type;
        const blobfile = file;
        const filename = file.name;
  
        // Create a MediaItem object from the File
        const mediaItem: MediaItem = {
          mediaPath: file.name,
          typeData: file.type,
          nameFile: file.name,
          id: 1, // Assign a unique ID here if needed
          size: file.size,
          index: 0, // Set the desired index value
          route: "", // Set the route value if needed
          status: "", // Set the initial status
          url: "", // Set the URL property
          localUrl: "", // Set the local URL property
          type: "", // Set the type property
          is_premium: 0, // Set the is_premium property
          // Add any other required properties
        };
  
        this.uploadMedia.blob = blobfile;
        this.uploadMedia.filename = filename;
    
        const audioURL = URL.createObjectURL(file);
        console.log('URLLL',audioURL);
  if(this.audio_blob_set){
return;
  }
        // Update the audio source and display it
        audioPreviewElement.src = audioURL;
    
           
       if (this.formservice.multimedia.length > 0) {
        this.formservice.multimedia[0] = mediaItem;
      } else {
        this.formservice.multimedia.push(mediaItem);
      }

      if(this.formservice.normalpublication.blobsMultimedia.length < 1){
        this.formservice.normalpublication.blobsMultimedia[0] = {blob: this.uploadMedia.blob, isPremium: false, filename: this.uploadMedia.filename};
      } else {
        this.formservice.normalpublication.blobsMultimedia.push({blob: this.uploadMedia.blob, isPremium: false, filename: this.uploadMedia.filename});
      }
      
      }
      this.audio_blob_set=true;
    });
 
    // Trigger the file input dialog
    fileInput.click();
  }
});
  }

  updateDuration(event: Event){
    const audio = event.target as HTMLAudioElement;
    this.audio_duration=audio.duration;
    console.log('audio_duration',this.audio_duration)
   // this.formservice.Capacitor_convertFileSrc_audio = audioURL;
   if(this.audio_duration < 30){
    Swal2.fire({
      title: 'Error',
      text: 'La canción debe ser mayor a 30 segundos.',
      icon: 'error',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
    this.modalController.dismiss();
    this.formservice.normalpublication.blobsMultimedia=null;
    this.audio_preview_set=false;
    return;
   }
  }

  async oprimeSiguiente() {

 
    if(this.selectedEcosystem !== 'LK MUSIC'){
    if (this.formservice.normalpublication.blobsMultimedia.length > 0 && this.formservice.title && this.formservice.description && this.formservice.ubicacion) {
      this.siguiente = true;
    } else {
      Swal2.fire({
        title: 'Error en contenido',
        text: 'Porfavor llena todos los campos',
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }
  }else {
    if (this.audio_blob_set&&this.audio_preview_set&&this.audio_duration!==30 && this.audio_start_value && this.formservice.normalpublication.blobsMultimedia.length > 1 && this.formservice.title && this.formservice.description && this.formservice.ubicacion) {
      this.siguiente = true;
    } else {
      Swal2.fire({
        title: 'Error en contenido',
        text: 'Porfavor llena todos los campos',
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    }

  }
  }

  back() {
    if (this.siguiente == false || this.selectedEcosystem === 'LK MUSIC') {
      this.finishModal();
    }
  }
  get_drive_image(){
    Swal2.fire({
			title: 'Usuario de google',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false,
      background: '#010f4e',
		  });

    const fileId = localStorage.getItem('fileId');
    if (fileId) {
      if(this.setDrive_media_called){
        return;
       }else{
         this.setDrive_media_called=true;
       }
      const url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.oauthToken}`
    });
    console.log('Fetched Blob:');

    this.https.get(url, { headers, responseType: 'blob' }).toPromise()
    .then(result => {
      console.log('Fetched Blob:', result);
      let file_type=localStorage.getItem('file_type');
      let file_url= localStorage.getItem('file_url');
        // Create a MediaItem object from the File
           // Generate a timestamp for the filename
           if(file_type=='photo' || file_type =='video'){
          
         
      const timestamp = new Date().toISOString();
      const filename = `file_${timestamp}`;
        const mediaItem: MediaItem = {
          mediaPath: file_url,
          typeData: file_type,
          nameFile: filename,
          id: 1, // Assign a unique ID here if needed
          size: 500,
          index: 0, // Set the desired index value
          route: "", // Set the route value if needed
          status: "", // Set the initial status
          url: "", // Set the URL property
          localUrl: "", // Set the local URL property
          type: "", // Set the type property
          is_premium: 0, // Set the is_premium property
          // Add any other required properties
        };
    
        this.uploadMedia.blob = result;
        this.uploadMedia.filename = filename;
        if(file_type=='photo' || file_type==='video' ){
          Swal2.fire({
            title: '',
            text: '',
            icon: 'success',
            timer: 9999, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-start',
            showConfirmButton: false
            });
        }else{
          Swal2.fire({
            title: 'Solo puedes subir fotos o videos',
            text: 'Aseguráte de seleccionar una foto o un video, si el error persiste comunícate con el soporte de Laniakea.',
            icon: 'error',
            timer: 9999, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-start',
            showConfirmButton: false
            });
            return;
        }
        if (this.formservice.multimedia.length > 0) {
          this.formservice.multimedia[0] = mediaItem;
        } else {
          this.formservice.multimedia.push(mediaItem);
        }
  
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0] = {blob: this.uploadMedia.blob, isPremium: false, filename: this.uploadMedia.filename};
        } else {
          this.formservice.normalpublication.blobsMultimedia.push({blob: this.uploadMedia.blob, isPremium: false, filename: this.uploadMedia.filename});
        }
  
        const media_URL = URL.createObjectURL(result);

        console.log(file_type);

        console.log(file_url);
        if(file_type=='photo'){
          
          const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
if(imagePreviewElement){

          imagePreviewElement.src=media_URL;
          this.formservice.Capacitor_convertFileSrc=media_URL;
          clearInterval(this.setDrive_media);
            
}
        }else if(file_type=='video'){

          const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
          if(videoPreviewElement){
          videoPreviewElement.src=media_URL;
          this.formservice.Capacitor_convertFileSrc_video=media_URL;
          clearInterval(this.setDrive_media);
        }
        }
      }else{
        Swal2.fire({
          title: 'Error en contenido',
          text: 'Solo puedes subir fotos o videos',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
       }
    })
    .catch(error => {
      console.error('Error fetching blob:', error);
    });
        
    

      
    }
   
  
  }
  finishModal() {
    Swal2.fire({
      title: 'Estas seguro de regresar?',
      text: "Tenga en cuenta perdera todo el proceso de este apartado",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.modalController.dismiss();
      }
    });
  }
  askForLibraryCapture(take_photo: boolean = true, pick_AI: boolean = true, pick_image: boolean = true,pick_video: boolean = true,  pick_GOOGLE: boolean = true,) {
    Swal2.fire({
      title: 'Escoge un archivo para subir desde tu dispositivo.',
      text: "Al subirlo, confirmas que la multimedia cumple con nuestros Términos y Condiciones, y que no infringe en los derechos de alguien más.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
     

    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {

			let botonesDisponibles = [
				(pick_image) ?
					{
						text: 'Seleccionar una foto',
						icon: 'camera',
						handler: () => {
							console.log("oprimiste camara");

							this
                             .pickImage()
                             .then((mediaItem) => {
                               resolve([mediaItem,'camara']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                               });
                             });
						}
					} : null,
          
				(pick_video) ?

					{
						text: 'Seleccionar un vídeo',
						icon: 'videocam',
						handler: () => {
              if(this.formservice.publication_type !== 'LK MUSIC'){
                this
                .pickVideo()
                .then((mediaItem) => {
                  resolve([mediaItem,'camvideo']);
                })
                .catch((error) => {
                  Swal2.fire({
                    title: 'Error al capturar video',
                    text: error,
                    icon: 'warning',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
                  });
                });
              }else{
                Swal2.fire({
                  title: 'Error al capturar video',
                  text: 'Ahora, no está disponible subir videos en LK MÚSICA',
                  icon: 'warning',
                  confirmButtonText: 'Entendido',
                  color: '#ffffff',
                  background: '#010f4e',
                });
              }
							console.log("oprimiste video");

						}
					} : null,
          (pick_AI) ?
					{
						text: 'Crear foto de portada cuántica',
						icon: 'images',
						handler: () => {
							console.log("oprimiste texto a imagen");

							this.AI().then((mediaItem) => {
                               resolve([mediaItem,'images']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                               });
                             });
						}
					} : null,
          
          (pick_GOOGLE) ?
          {
            
						text: 'Google Drive',
						icon: 'logo-google',
						handler: () => {
							console.log("oprimiste texto a imagen");
if( localStorage.getItem('fileId')){
  Swal2.fire({
    title: 'Solo puedes agregar una portada',
    text: 'Ya agregasete una portada',
    icon: 'error',
    confirmButtonText: 'Entendido',
    color: '#ffffff',
    background: '#010f4e',
  });
  return;
}
							this.loadGooglePicker().then((mediaItem) => {
                               resolve([mediaItem,'images']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                               });
                             });
						}
					} : null,

				{
					text: 'Cancelar',
					icon: 'close',
					role: 'cancel',
					handler: () => {
					}
				}
			];

			botonesDisponibles = botonesDisponibles.filter((boton) => {
				if (boton != null) {
					return true;
				}
				return false;
			});


			this.actionSheetController.create({
				header: '¿Desde donde deseas añadir multimedia?',
				cssClass: 'options-sheet',
				buttons: botonesDisponibles
			}).then((actionSheet) => {
				actionSheet.present();
			});
		});
  }
});
	}

  public async AI(){
    this.modalController.dismiss();
    // Use the Router to navigate to the "messages-page"
    this.router.navigate(['home/messages-page']);
    this.formservice.fromCreatePublication=true;
    this.router.navigateByUrl('home/messages-page', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });
  }
  onUbicacionChange(event: any) {
    
    this.formservice.ubicacion = event.detail.value;
    this.formservice.normalpublication.ubicacion=this.formservice.ubicacion;
    this.formservice.lkpublication.ubicacion = this.formservice.ubicacion;
    this.formservice.landpublication.ubicacion = this.formservice.ubicacion;
    this.formservice.epochpublication.ubicacion = this.formservice.ubicacion;
    this.formservice.eventospublication.ubicacion = this.formservice.ubicacion;
    this.formservice.earthpublication.ubicacion = this.formservice.ubicacion;
    console.log('ubicacion:', this.formservice.ubicacion)
  }
  postEntrelazamiento(){
    if(this.formservice.normalpublication.blobsMultimedia.length > 1 && this.formservice.description){

	  this.formservice.normalpublication.blobsMultimedia.forEach(element => {
      console.log("Elemento",element);
      this.uploadMultimediaService.post(element.blob, element.filename).then(({ link, mimeType }) => {
        const ruta = link;
       // const ruta = r[0];
        //const mimeType = r[1];
        console.log('ruta', ruta);
        let pub_type = '';
        //console.log(this.multimedia[0].typeData);
        if(mimeType.includes("image")) {
          pub_type= 'imagen';
        }else if(mimeType.includes("video")){
          pub_type= 'video';
        }
        let is_premium_m: boolean;
        console.log('is premium', element.isPremium);
        if(element.isPremium === undefined){
          console.log('current multimedia status', this.formservice.normalpublication.current_multimedia_status);
          is_premium_m = this.formservice.normalpublication.current_multimedia_status;
        }else{
          is_premium_m = element.isPremium;
        }
        const objmedia = {
          route: ruta,
          size: 800,
          type:pub_type,
          bio:this.formservice.description
        //  publication_type: this.formservice.publication_type,
    //      publication_id: publication_ids[0],
     //     is_premium: is_premium_m
        };
        console.log('objmedia', objmedia);
        this.request.createRequestPost('PostingEntrelazamientoContnent',objmedia).then((final_data) => {
          console.log('request history final data',final_data);
          this.modalController.dismiss();
        }).catch((error) => {

          Swal2.fire({
            title: 'Error al subir multimedia!',
            text: error,
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });

        });

      });
    });
  }else{
    Swal2.fire({
      title: 'Error al subir contenido!',
      text: 'Asegúrate de seleccionar 2 imagenes como mínimo y una biografía',
      icon: 'warning',
      confirmButtonText: 'Entendido',
      color: '#ffffff',
      background: '#010f4e',
    });
  }
  }
  async loadGooglePicker() {
    Swal2.fire({
			title: 'Usuario de google',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false,
      background: '#010f4e',
		  });
    this.request.createRequestPost('getAuthYouTubeToken', {})
    .then((data) => {
      this.user_id=data.user_id;
      console.log('user_id', this.user_id);
    // Open the login URL in the system browser
  
    console.log(data);
   
    if(data.success){
    
    this.oauthToken=data.token;
      Swal2.fire({
        title: 'Usuario de GOOGLE',
        text: 'El usuario ha sido autenticado',
        icon: 'success',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    this.setDrive_media=  setInterval(() => {
      this.get_drive_image();
    }, 2100); 
      this.onPickerApiLoad();
    }else{
      Swal2.fire({
        title: 'Usuario de GOOGLE',
        html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate.',
        icon: 'error',
        toast: true,
        position: 'top-start',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      

    }

  });
 
  }



  onPickerApiLoad() {
 
  
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = () => {
        gapi.load('picker', {
          'callback': () => {
            this.createPicker();

          }
        });
      };

      document.body.appendChild(script);


  }


  createPicker() {
    if (this.oauthToken) {
      const picker = new google.picker.PickerBuilder()
        .addView(google.picker.ViewId.DOCS)
        .setOAuthToken(this.oauthToken)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId('laniakea-393417')
        .setDeveloperKey(this.apiKey)
        .setCallback(this.pickerCallback.bind(this))
        .build();
      picker.setVisible(true);
    }
  }

  async pickerCallback(data: any) {
    if (data.action === google.picker.Action.PICKED) {
      const fileId = data.docs[0].id;
      console.log('Selected File ID:', fileId);

      console.log('Selected File ID:', data.docs[0]);
      localStorage.setItem('fileId', fileId);
      localStorage.setItem('file_type', data.docs[0].type);
      localStorage.setItem('file_url', data.docs[0].embedUrl);
        const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
        const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
        console.log('get_blob', this.oauthToken);
     //get_blob(fileId, data.docs[0])
    }
  }

  addPrice(event) {
    const inputPrice = parseInt(event.target.value);
    const limitedPrice = Math.min(inputPrice, 999);
    this.formservice.price = limitedPrice > 999 ? 999 : limitedPrice;
  }


}
async function get_blob(fileId, fileData) {
 let profile_id =localStorage.getItem('profile_id');
  try {
      const request = {
          fileId: fileId,
         // profile_id: profile_id
      };
      
   // Construct the URL with query parameters
   let loginUrl = `https://9jkrdd2hdl.execute-api.us-east-1.amazonaws.com/get_blob_from_drive_file?fileId=${encodeURIComponent(fileId)}&profile_id=${encodeURIComponent(profile_id)}`;
      let response = await fetch(loginUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(request)
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      let binary = await response.text();
      let l = binary.length;
      let array = new Uint8Array(l);
      for (let i = 0; i < l; i++) {
          array[i] = binary.charCodeAt(i);
      }
      let blob = new Blob([array], { type: fileData.mimeType });

      if (fileData.mimeType.startsWith('image/')) {
          const imagePreviewElement = document.getElementById("imagePreview");
          imagePreviewElement.style.display = 'block';
        //  imagePreviewElement.src = URL.createObjectURL(blob);
      } else if (fileData.mimeType.startsWith('video/')) {
          const videoPreviewElement = document.getElementById("videoSource");
          videoPreviewElement.style.display = 'block';
        //  videoPreviewElement.src = URL.createObjectURL(blob);
      }

      // Additional logic to handle the blob (e.g., upload to server, etc.)
  } catch (error) {
      console.error('Error fetching the blob:', error);
  }
  
}

