import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import { NavigationExtras,  ActivatedRoute } from '@angular/router';
import {ActionSheetController, ModalController, NavController, Platform, IonSlides} from '@ionic/angular';
import { Utils } from 'src/app/Models/Classes/utils';
import { FriendshipService } from 'src/app/services/relations/relations.service';
import { CameraServiceService } from 'src/app/services/multimedia/camera-service.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { PublicationService } from 'src/app/services/publications/publication.service';
import { InputAlertControllerService } from 'src/app/services/utils/input-alert-controller.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import {ActualizacionServiceService} from "../../../services/actualizacion/Actualizacion-service.service";
import {ProfilePage} from "../../../pages/profile/profile.page";
import {UserContact} from "../../../Models/Classes/User/user-contact";
import {ChatService} from "../../../services/chat/chat.service";
import {Conversation} from "../../../Models/Classes/Chat/conversation";
import {MessagesListComponent} from "../../chat/messages-list/messages-list.component";
import {isImage} from "../../../utils";
import Swal2 from "sweetalert2";
import {UploadMultimediaService} from "../../../services/upload/upload-multimedia.service";
import {Capacitor} from "@capacitor/core";
import {RequestService} from "../../../services/request/request.service";
import {FormServiceService} from "../../../services/publications/form-service.service";
//import { Profile } from './../../../Models/Classes/User/profile';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import { ChangeDetectorRef } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { LocalNotifications } = Plugins;
import { Router } from '@angular/router'; // Import the Router
import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {Title, Meta } from '@angular/platform-browser';

import { PopoverController } from '@ionic/angular';
import { identifierModuleUrl } from '@angular/compiler';

interface ExperienceData {
  position: string;
  company: string;
  duration: string;
  description: string;
  logo: string | ArrayBuffer | null; // Use appropriate type for the logo (base64 data URL)
}
interface EducationData {
  institution: string;
  degree: string;
  year: string;
  logo: string | ArrayBuffer | null; // The logo can be a string (Data URL), ArrayBuffer, or null
}

@Component({
	selector: 'app-sections-profile',
	templateUrl: './sections-profile.component.html',
	styleUrls: ['./sections-profile.component.scss'],
})
export class SectionsProfileComponent implements OnInit, AfterViewInit {
  causes: string[] = [
   
  ];
  experiences = [
   
  ];

  educationList = [

  ];

  languages = [];

  skills = [];
  change_story_activated;
  video_is_playing;//stories
  index=0; //stories
 // checkIntervalId;
	@Input() stories;
  @ViewChild('slides') slides: IonSlides;
  public typeTextCalled:boolean=false;
  public web : boolean=false;
  public available_publication_sections: Array<string> = ["Popular", "Reciente", "Proliferado"];
	public icons_for_publication_sections: Array<string> = [ "podium", "time-sharp", "git-compare" ];
  public actual_publication_section: string = "Reciente";
  ///start main pubs
  public main_available_publication_sections: Array<string> = ["EN LA APP", "TERCEROS", ];
	public main_icons_for_publication_sections: Array<string> = [ "aperture-sharp", "git-network-sharp",  ];
  public main_actual_publication_section: string = "EN LA APP";
  //en main pubs

  editedDescription: string;
  editedWebPage: string;
isEditing: boolean = false;
  type:any;
  public formattedURLs: any;
  public counters: any;
  public filtros_disponibles = new Array<string>();
	public filtro_actual: string = "Cuentas";
	public texto_buscado: string;
	public refrescar: number = 0;
	public publications: any[] = [];
	@Input() multimedia: Array<MediaItem>;
	@Input() actualSection: string;
	@Input() profile_id: number;
	@Input() owner: boolean;
	@Input() profile: any;
	@Output() reloadProfile = new EventEmitter();
  profile_photo: string = null;
  mostrar:boolean=true
  profileinfo:any;
  isKYCValid: boolean = false;
	private paginatorIndex: number = 1;

	public available_sections: Array<string> = [ "Amigos", "Siguiendo", "Seguidores", "Súper Amigos"];
	public friendships_filter: string = "Amigos";
	public relations: Array<any>;
  public filteredRelations: Array<any>;
  public filteringRelation:boolean=false;
	mostrar_enlace='Mostrar';
  mediaType: string;
  private friendsArrayHeader = Array<string>();
  private friendsArrayMessage = Array<string>();

  private superArrayHeader = Array<string>();
  private superArrayMessage = Array<string>();

	constructor(
		public publicationService: PublicationService,
		private profileService: ProfileService,
		private navCtrl: NavController,
    private camera: CameraServiceService,
    public friendshipService: FriendshipService,
		private utils: Utils,
		private cameraService: CameraServiceService,
		private inputAlertController: InputAlertControllerService,
    private actualizacionService: ActualizacionServiceService,
    public profilePage: ProfilePage,
    private chatService: ChatService,
    private inputAlert: InputAlertControllerService,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private uploadMedia: UploadMultimediaService,
    private request: RequestService,
    private formService: FormServiceService,
    private route: ActivatedRoute,
		private plaftorm: Platform,
    public walletService: WalletServiceService,
    private ref: ChangeDetectorRef,
    public router: Router,
    private clipboard: Clipboard,
    private metaService: Meta,
    private titleService: Title,
     private popoverCtrl:PopoverController
  ) {
    this.plaftorm.ready().then(()=>{
			this.route.queryParams.subscribe(params => {

				if (params['filter']) {
					this.filtro_actual = params['filter'];
					this.refrescar += 1;
					
				}
	
			});
		});

   }
   call_emitChange(event){
	
		this.main_actual_publication_section=event.detail.value;
	}
   async emitChange(event){
     this.publications=[];
		//this.optionChange.emit(event.detail.value);
		this.actual_publication_section = event.detail.value;
    if (this.actual_publication_section==='Popular'){
      this.fetchPublications(true, 'Popular' );
    }else if (this.actual_publication_section==='Reciente'){
      this.fetchPublications(true, 'Reciente' );
    }else if (this.actual_publication_section==='Proliferado'){
      this.fetchPublications(true, 'Proliferado' );
    }
    
	
	  }
    changeMedia(){
      console.log('changeMediaPeriodically called');
    }
	ngOnInit() {
    if (Capacitor.getPlatform() ==='web'){
      this.web=true;
    }
    this.displayURLs();
    console.log('owner', this.profile.owner);
    this.fetchPublications(true);
    this.walletService.KYC_validation3(this.profile.id).then((result_kyc) => {
			console.log('KYC_validation result:', result_kyc);
		  if (result_kyc['permit']) {
			this.formService.isKYCValid = true;
		  }else {
        this.formService.isKYCValid = false;
      }
		});
    this.fetchCounters();
    console.log('profile in sections profile', this.profile);
    this.formService.titleHeader = '@' + this.profile.username;
    this.friendsArrayHeader = [
      "Enviar solicitud de amistad",
      "Cancelar solicitud de amistad",
      "Re-enviar solicitud de amistad",
      "Aceptar solicitud de amistad",
      "Eliminar relación de amistad"];
    this.friendsArrayMessage = [
      "¿Deseas enviar una solicitud de amistad a @" + this.profile.username + "?",
      "¿Deseas cancelar tu solicitud de amistad enviada a @" + this.profile.username + "?",
      "¿Deseas enviar nuevamente una solicitud de amistad a @" + this.profile.username + "?",
      "¿Deseas aceptar la solicitud de amistad de @" + this.profile.username + "?",
      "¿Deseas eliminar tu relación de amistad con @" + this.profile.username + "?"];

    this.superArrayHeader = [
      "Enviar solicitud de súper amigos",
      "Cancelar solicitud de súper amigos",
      "Re-enviar solicitud de súper amigos",
      "Aceptar solicitud de súper amigos",
      "Eliminar relación de súper amigos"];
    this.superArrayMessage = [
      "¿Deseas enviar una solicitud de súper amigos a @" + this.profile.username + "?",
      "¿Deseas cancelar tu solicitud de súper amigos enviada a @" + this.profile.username + "?",
      "¿Deseas enviar nuevamente una solicitud de súper amigos a @" + this.profile.username + "?",
      "¿Deseas aceptar la solicitud de súper amigos de @" + this.profile.username + "?",
      "¿Deseas eliminar tu relación de súper amigos con @" + this.profile.username + "?"];

      this.getprofile()
      this.filtros_disponibles.push("Cuentas");
      document.title = this.profile.username;
      // Find the meta description tag

      const newDescription = `${this.profile.description}${this.formatCounter(this.counters?.userCountVentas)} Ventas. ${this.formatCounter(this.counters?.relationCount)} Seguidores. ${this.formatCounter(this.counters?.totalViews)} Vistas. ${this.formatCounter(this.counters?.reactionsCount)} Likes. ${this.formatCounter(this.counters?.userCountRegalos)} Regalos.`;
      let metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement;

      if (metaDescription) {
        // Update existing meta tag
        metaDescription.setAttribute("content", newDescription);
      } else {
        // Create a new meta description tag if it doesn't exist
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute("name", "description");
        newMetaDescription.setAttribute("content", newDescription);
        document.head.appendChild(newMetaDescription);
      }
      
this.metaService.updateTag({ name: 'description', content: this.profile.description + this.formatCounter(this.counters?.userCountVentas) + ' Ventas. ' + this.formatCounter(this.counters?.relationCount) + ' Seguidores. ' + this.formatCounter(this.counters?.totalViews) + ' Vistas. ' + this.formatCounter(this.counters?.reactionsCount) + ' Likes. '+ this.formatCounter(this.counters?.userCountRegalos) + ' Regalos. '});
      this.titleService.setTitle(this.profile.username);
    
	}

  getprofile(){
    
    this.request.createRequestGet("profile-all",null,{id:this.profile.id}).then((data=>{
      this.profileinfo=JSON.parse(data)
      console.log("profile info",this.profileinfo);
      console.log("email:",this.profileinfo.user.email);
      
      
    }))
      

      
   
  }

  async ionViewDidEnter() {
    this.walletService.KYC_validation3(this.profile.id).then((result_kyc) => {
			console.log('KYC_validation result:', result_kyc);
		  if (result_kyc['permit']) {
			this.formService.isKYCValid = true;
		  }else {
        this.formService.isKYCValid = false;
      }
		});
    //this.publications = await this.fetchPublications(true);
    console.log('this profile in sections profile', this.profile);
  }

  Ocultar(){
    if(this.mostrar == true){
      this.Returncss()
      this.mostrar = false
      
      
    }else{
      this.Returncss()
      this.mostrar = true
      
   
    }
  }

  CssClass(){
    this.typeText(this.profile.description,99);
    const contenedor = document.getElementById('masinfo')
    if (contenedor.style.opacity === "0") {
     
      contenedor.style.display = "grid";
      setTimeout(() => {

        
        contenedor.style.opacity = "1";
      }, 200);
      
      console.log("opacity 1");
     
      
      
    } else {
    
      contenedor.style.opacity = "0";
      setTimeout(() => {

        contenedor.style.display = "none";
        
      }, 500);
      

    }
  
  }

  Returncss(){
    return "masinfo"
  }

  async fetchPublications(isrefresh: boolean, pubSection?:any){
    const result = await this.actualizacionService.getProfilePublications(null, isrefresh, this.profile_id, false, null, pubSection) ;
    const fetchedPublications = result || [];

    
    this.filterPublications(fetchedPublications);
    
  }
  async filterPublications(publications) {
  console.log('filering');
    if (publications.length > 0) {
      this.publications = publications;
      this.formService.publications = publications;
    
    }
   // if (publications.length < 1) {
     // console.log('pub < x');
     // this.actualizacionService.current_page++;
     // await this.fetchPublications(false);
    //}
  
    console.log('siguiendo length of pub', this.publications.length);
  
  
  }

  async fetchPublications2(isrefresh: boolean){
    console.log('pr',this.profile.id);
    const result = await this.actualizacionService.getPublications(null, isrefresh, this.profile.id, false, null);
    if (result.length > 0) {
      return  result;
    }
    if (result.length < 1) {
      console.log('pub < x');
      this.actualizacionService.current_page++;
      await this.fetchPublications(true);
    }
    
  }

	ngOnChanges(changes: SimpleChange) {
		if (changes['actualSection'] != undefined) {
			//console.log("Cambio a la seccion de: " + changes['actualSection'].currentValue);
			this.updateSection();
      if(this.actualSection =='Historias'){
        setTimeout(() => { ///until stories are completely rendered
      this.change_stories_periodically();
    }, 6300);
      }else if(this.actualSection=='Fachada'){
this.profile_facade_sections_controller(true)
      }
		}
	}
  change_stories_periodically(){

      if(!this.video_is_playing){
     
    Swal2.fire({
      title: 'Siguiente',
      text: ``,
      icon: 'info',
      timer: 6900, // Show the alert for 6.9 seconds
      timerProgressBar: true,
      toast: true,
      position: 'bottom-end',
      showCancelButton: true,
    showConfirmButton:true,
      cancelButtonText: '<ion-icon name="reload"></ion-icon>No',
      confirmButtonText: '<ion-icon name="play-skip-forward-sharp"></ion-icon>Si',
      color: 'black',
      background: 'white',
      }).then((result) => {
       

        clearTimeout(this.change_story_activated);
        clearInterval(this.change_story_activated);
this.change_story_activated=false;
      if (result.isConfirmed) {


        this.nextPub();
    

      }else{
    


      }

      });

      this.change_story_activated = setTimeout(() => {
      
          this.nextPub();

     
        }, 6300); 
  }

  }
  updateCurrentTime(event: Event){
    const video = event.target as HTMLVideoElement;
    this.video_is_playing=true;
    if (video.currentTime >= video.duration - 6 ) {
      this.video_is_playing=false;
      if(!this.change_story_activated){
        this.change_stories_periodically();
      }
   
    }
  }
  updateDuration(event: Event){
    const video = event.target as HTMLVideoElement;
    const dictationTimer = setInterval(() => {

    const publication = this.stories[this.index];
    if (publication) {

      if (publication.multimediaContainer[0].type === 'Video') {
        const publicationUrl = publication.multimediaContainer[0].url;


  
          // Play the video
          if (video) {
            const videoAbsoluteUrl = new URL(video.currentSrc, window.location.href).href;
            const matchingMediaAbsoluteUrl = new URL(publicationUrl, window.location.href).href;
  
            if (videoAbsoluteUrl === matchingMediaAbsoluteUrl) {
              video.muted = false;
              video.play();
              this.formService.actual_publication=publication;
            }else{
              video.muted = true;
              video.pause();
            }
          }
        
      }
    }
  }, 1000); // Update every second
    console.log('duration', video.duration);
  }
  async slideChanged() {
    this.formService.actual_publication=null;
    this.slides.getActiveIndex().then((index: number) => {
    this.index=index;
    this.change_story_activated=true;
    setTimeout(() => {
    this.change_stories_periodically();
  }, 1200);
  });


  }
  typeText(text: string, delay: number) {

    if (this.typeTextCalled) {
      return;
      }
      this.typeTextCalled = true;


      setTimeout(() => {
        this.typeTextCalled=false;
        }, 69999); 
   
    let index = 0;
    this.profile.description='_';
    console.log( 'typeTextCalled', text);
    const interval = setInterval(() => {
      if (index < text.length) {
        this.profile.description += text.charAt(index);
        index++;
      } else {
        clearInterval(interval);
      }
    }, delay);
  

  }
  async updateSection() {
    this.paginatorIndex = 1;
    switch (this.actualSection) {
      case "Fachada":
        this.reloadProfile.emit();
        this.walletService.KYC_validation3(this.profile.id).then((result_kyc) => {
          console.log('KYC_validation result:', result_kyc);
          if (result_kyc['permit']) {
          this.formService.isKYCValid = true;
          }else {
            this.formService.isKYCValid = false;
          }
        });
        this.formService.titleHeader = '@' + this.profile.username;

          //this.typeText(this.profile.description, 69);
          
       
        break;
      case "Publicaciones":
        this.fetchPublications(true);
       // this.publications = await this.fetchPublications(true);
        this.walletService.KYC_validation3(this.profile.id).then((result_kyc) => {
          console.log('KYC_validation result:', result_kyc);
          if (result_kyc['permit']) {
          this.formService.isKYCValid = true;
          }else {
            this.formService.isKYCValid = false;
          }
          this.formService.titleHeader = '@' + this.profile.username;
        });
        break;
      case "Relaciones":
        this.getFriends();
        this.walletService.KYC_validation3(this.profile.id).then((result_kyc) => {
          console.log('KYC_validation result:', result_kyc);
          if (result_kyc['permit']) {
          this.formService.isKYCValid = true;
          }else {
            this.formService.isKYCValid = false;
          }
          this.formService.titleHeader = '@' + this.profile.username;
        });
        break;

      default:
        break;
    }
  }

  swipe(){
    if(this.profilePage.mostrar == true){
      this.profilePage.mostrar=false;
      this.formService.mostrar = false;
      console.log('swiped',this.formService.mostrar );
    }else{
      this.profilePage.mostrar=true;
      this.formService.mostrar = true;
      console.log('swiped',this.formService.mostrar );
    }
  }

	async moreData(event?) {
		switch (this.actualSection) {
			case "Fachada":
				break;
			case "Publicaciones":
				//this.utils.loadingPresent();
        this.fetchPublications(false);
				//this.publications = await this.fetchPublications(false);
        event.target.complete();
				break;
			case "Relaciones":
				this.getFriends();
				break;

			default:
				break;
		}
	}

	async doRefresh(event) {
		this.updateSection();

		setTimeout(() => {

            event.target.complete();
        }, 2000);
    }

	/* PUBLICACIONES */
	public goToPublications(type: string) {

		let title = "";
		let maxImages = 0;

		switch (type) {
			case 'Feed': {

				title = "Nueva Publicación";
				maxImages = 10;
				break;
			}
			case 'Event': {
				title = "Nuevo Evento";
				maxImages = 1;
				break;
			}
			default: {
				title = "Nueva Publicación";
				break;
			}
		}

		let navigationExtras: NavigationExtras = {
			queryParams: {
				type: type,
				title: title,
				maxImages: maxImages,
			}
		};

		this.navCtrl.navigateForward('publications', navigationExtras);
	}

	/*FRIENDS */
	friends_filter_change(filtro){
		this.friendships_filter = filtro;
		this.getFriends();
	}

	public getFriends() {
		this.utils.loadingPresent().then(()=>{
			let relationType = [2];
			let relationDirection = 3;
			let relationStatus = 2;
			switch (this.friendships_filter) {
				case "Amigos":
					relationDirection = 3;
					relationType  = [1];
					break;
				case "Súper Amigos":
					relationDirection = 3;
					relationType  = [2];
					break;
				case "Siguiendo":
					relationDirection = 1;
					relationType  = [3];
					break;
				case "Seguidores":
					relationDirection = 2;
					relationType  = [3];
					break;

				default:
					break;
			}

			let request = {
				queryParams: {
					'relationDirection': relationDirection.toString(),  // 1 Envio, 2 Recibido, 3 Todos
					'relationType': relationType,   // 1 Amigo, 2 S Amigos, 3 Seguidores
					'relationStatus': relationStatus.toString(),  // 1 Pendiente, 2 Confirmado
          //'user_id': this.profile_id,
				}
			};

			this.friendshipService.getAllRelations(request.queryParams).then((data) => {
       
				this.relations = JSON.parse(data);

        if (Capacitor.getPlatform() === 'web'){
          console.log('this.relations', this.relations);
          this.relations= this.relations;
        }
 
        console.log('relations', this.relations);
			}).catch((error) => {
			}).finally(()=>{
				this.utils.loadingDismiss();
			});
		});

	}

	//ACERDA DE
	updateProfile(attrib_name:string){
		let array_profile_id = [];
		array_profile_id.push(this.profile_id);
		switch (attrib_name) {
			case "name":
			case "last_name":
			case "rfc":
			case "curp" :
				this.editAlertPrompt(this.profile[attrib_name], attrib_name, 'text');
				break;
			case "birth":
				this.editAlertPrompt(this.profile.birth, attrib_name, 'date');
				break;
			case "number_ine":
				this.editAlertPrompt(this.profile.number_ine, attrib_name, 'number');
				break;
			case "back_ine":
			case "front_ine":

				if(this.profile.kyc != "Validado" && this.profile.kyc != "En Validación"){
					this.cameraService.takePhoto().then((data)=>{
						let array = [];
						array.push(data);

						this.cameraService.postArrayMultimedia(array, array_profile_id, 'profile', attrib_name)
						.finally(()=>{
							this.updateSection();
						});
					}).catch((error)=>{
						console.error(error);
					});
				}

				break;
			default:
				break;
		}
	}

	editAlertPrompt(actualValue, attribName, attribType) {
		this.inputAlertController.InputAlertPrompt(
			"Edita tu información",										//Header
			"",															//Message
			actualValue, 												//Input Value
			attribType, 												//Input Type
			"Actualizar"												//Ok Button Text
		).then((result)=>{
			if(result['action']){
				if (!result['value'].match(/^ *$/)) {
					let updateAttrib = {
						attribute: attribName,
						value: result['value']
					}
					this.profileService.postProfile(updateAttrib).then((data)=>{
						//console.log(data);
						this.profile[attribName] = updateAttrib['value'];
					}).catch((error)=>{
						console.error(error);
					});
				}
			}
		});
	}

	borrarPublicacion(publication){
        /*let index = this.publicationResponse.indexOf(publication, 0);
        if (index > -1) {
            this.publicationResponse.splice(index, 1);
        }*/
    }

  async ngAfterViewInit(): Promise<void> {
    this.paginatorIndex = 1;
    //this.publications = await this.fetchPublications(true);
    this.fetchPublications(true);
  }

  private openConversation(conversation: Conversation) {
    this.modalController.create({
      component: MessagesListComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps: {
        conversation: conversation
      }
    }).then((modal)=>{
      modal.present();
    });
  }

  public newConversation(contact: UserContact) {
    this.chatService.newConversation(contact).then((conversation:Conversation)=>{
      this.openConversation(conversation);
    }).catch((error)=>{
      console.error(error);
    }).finally(()=>{
      this.utils.loadingDismiss();
    });
  }

  sendMessage(){
    this.utils.loadingPresent().then(()=>{
      let contact = new UserContact();
      contact.setByResponse(this.profile);
      this.chatService.findUserInChatService(contact).then((userResponse)=>{
        if(userResponse){
          this.newConversation(contact);
        }
        else{
          console.error("error: "+userResponse);
          this.inputAlert.OkInfoMessage(
            "Este usuario no te ha habilitado en sus mensajes directos", //Header
            "No eres amigo del usuario, por ello sus mensajes directos no están habilitados, deben ser amigos para comenzar a enviarse mensajes directos ", //Message
            "Esperar a que el usuario te habilite"//Ok Button Text
          );

          this.utils.loadingDismiss();
        }
      }).catch((error)=>{
        console.error(error);
        this.utils.loadingDismiss();
      });
    });

  }

  click_FriendButton(button_info) {
    let header;
    let mensaje;
    let presentConfirmation = true;

    switch (this.profile.buttons_info.friendsButton['text']) {
      case "Agregar":
        this.buttonsActions(button_info);
        presentConfirmation = false;

        //header = this.friendsArrayHeader[0];
        //mensaje = this.friendsArrayMessage[0];
        break;
      case "Pendiente":
        this.buttonsActions(button_info);
        presentConfirmation = false;
        //header = this.friendsArrayHeader[1];
        //mensaje = this.friendsArrayMessage[1];
        break;
      case "Rechazado":
        this.buttonsActions(button_info);
        presentConfirmation = false;
        //header = this.friendsArrayHeader[2];
        //mensaje = this.friendsArrayMessage[2];
        break;
      case "Aceptar":
        this.buttonsActions(button_info);
        presentConfirmation = false;

        //header = this.friendsArrayHeader[3];
        //mensaje = this.friendsArrayMessage[3];
        break;
      case "Amigos":
        header = this.friendsArrayHeader[4];
        mensaje = this.friendsArrayMessage[4];
        break;
    }

    if(presentConfirmation){
      this.actionMessage(header, mensaje, button_info);
    }

  }

  actionMessage(header, mensaje, button_info) {
    this.inputAlertController.BinarySelectorAlert(header,mensaje, 'Aceptar').then((result)=>{
      if(result['action']){
        this.buttonsActions(button_info);
      }
    }).catch((error)=>{
      console.error(error);
    });
  }




  click_FollowButton(button_info) {
    switch (this.profile.buttons_info.followButton['text']) {
      case "Seguir":
        this.buttonsActions(button_info);
        break;
      case "Siguiendo":
        this.buttonsActions(button_info);
        break;
    }
  }

  buttonsActions(button_info){
    if(button_info['type'] == 1){
      switch (button_info['text']) {
        case "Agregar":
          this.createRelation(1).then((data)=>{
            this.utils.presentToast("Se envio la solicitud a "+ this.profile.username);
            this.profile.buttons_info.friendsButton['text']="Pendiente";
          });
          break;

        case "Pendiente":
          this.deleteRelation(button_info['relation_id']).then((data)=>{
            this.utils.presentToast("Se elimino la solicitud enviada a "+ this.profile.username);
            this.profile.buttons_info.friendsButton['text']="Agregar";
          });

          break;

        case "Rechazado":
          this.updateRelation(1, button_info['relation_id']).then((data)=>{
            this.utils.presentToast("Se envio la solicitud a "+ this.profile.username);
            this.profile.buttons_info.friendsButton['text']="Pendiente";
          });
          break;

        case "Aceptar":
          this.updateRelation(2, button_info['relation_id']).then((data)=>{
            this.utils.presentToast("Se acepto la solicitud a "+ this.profile.username);
            this.profile.buttons_info.friendsButton['text']="Amigos";
          });
          break;

        case "Amigos":
          this.deleteRelation(button_info['relation_id']).then((data)=>{
            this.utils.presentToast("Se elimino la relacion con "+ this.profile.username);
            this.profile.buttons_info.friendsButton['text']="Agregar";
          });
          break;
      }
    }else if(button_info['type'] == 3){
      switch (button_info['text']) {
        case "Seguir":
          this.createRelation(3).then((data)=>{
            this.utils.presentToast("Ahora sigues al usuario"+ this.profile.username);
            this.profile.buttons_info.followButton['text']="Siguiendo";
          });
          break;
        case "Siguiendo":
          this.deleteRelation(button_info['relation_id']).then((data)=> {
            this.utils.presentToast("Dejaste de seguir al usuario"+ this.profile.username);
            this.profile.buttons_info.followButton['text']="Seguir";
          });
          break;
      }
    }
  }

  createRelation(relation_type): Promise<any>{
    return new Promise((resolve, reject) => {
      let newRelation = {
        'relationUserId': this.profile.id.toString(),
        'relationType': relation_type.toString()
      };
this.sendNotification( );
      this.friendshipService.createRelation(newRelation).then((result)=>{
        result = JSON.parse(result);
        switch (relation_type) {
          case 1:
            this.profile.buttons_info.friendsButton.relation_id = result.relation_id;
            break;
          case 3:
            this.profile.buttons_info.followButton.relation_id = result.relation_id;
            break;

          default:
            break;
        }
        resolve(result);
      }).catch((error) =>{
        reject(error);
      });
    });

  }
  sendNotification() {
   
    return new Promise((resolve, reject) => {
      const notification = {
        title: 'Nuevo vínculo asequido',
        body: `¡Has recibido una nueva relación en tu perfil!`,
        user_id:this.profile.id,

      };
  
      this.request.createRequestPost('GreatAtractorNotifications', notification)
        .then((data) => {
          Swal2.fire({
            title: 'Vínculo generado',
            text: 'Nueva relación asequida exitosamente',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
    });
  }
  
  updateRelation(status_id, relation_id): Promise<any>{
    return new Promise((resolve, reject) => {
      let relationInfo = {
        'relationStatus': status_id.toString()
      };
      let params = {
        'id': relation_id.toString(),
      }
      this.friendshipService.updateRelation(relationInfo, params).then((result) => {
        resolve(result);
      }).catch((error) =>{
        reject(error);
      });
    });

  }

  deleteRelation(relation_id):Promise<any>{
    return new Promise((resolve, reject) => {
      let params = {
        'id': relation_id.toString(),
      };
      this.friendshipService.deleteRelation(params).then((result) => {
        resolve(result);
      }).catch((error) =>{
        reject(error);
      });
    });

  }

  setMultimedia(mediaItem: MediaItem) {
    return new Promise ((resolve,rejects)=> {
      this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
      this.uploadMedia.readBlobFile(mediaItem.mediaPath + mediaItem.nameFile).
      then((result)=>{
        resolve(true);
      });
    });
  }

  update_photo_cover( take_photo: boolean = true,pick_media: boolean = true) {
    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
    this.type= 'cover_photo';
    console.log('type in upadte_photo', this.type );
    return new Promise((resolve, reject) => {
    if(this.profile.owner) {
      let botonesDisponibles = [
     //   (take_photo) ?
     //     {
     ///       text: 'Tomar una Foto',
        //    icon: 'camera',
    //        handler: () => {
    //          this.takePhoto(type)
    //            .then((mediaItem) => {
    //              resolve([mediaItem,'camera']);
                 // this.setMultimedia(mediaItem).
                 /// then((result)=>{
                    //if(isImage(this.uploadMedia.image)==false){
                      //Swal2.fire({
                        //title: 'Imagen de perfil erronea',
                       // text: 'Asegurate de usar una imagen',
                       // icon: 'warning',
                       // confirmButtonText: 'Entendido',
                      //  color: '#ffffff',
                     //   background: '#010f4e',
                    //  });
                    //}else{
                     // this.uploadMedia.post().then((r)=>{
                       // if(!r[1].includes('image')){
                        //}else{
                         // this.profile_photo= r[0];
                       //   this.postProfilePict(type);
                        //}
                      //}).catch((error)=>{
                      //  console.log(error);
                      //});
           ///         });
                 // });
             //   })
               // .catch((error) => {
                 // console.error(error);
           //     });
          //  }
         // } : null,
        (pick_media) ?
          {
            text: 'Seleccionar desde mi galería',
            icon: 'images',
            handler: () => {
              //this.uploadMedia.image = null;
              this.pickImage().
              then((result)=>{
                resolve([result,'library']);
                //if(isImage(this.uploadMedia.image)==false){
                  //Swal2.fire({
                   // title: 'Imagen de perfil erronea',
                 //   text: 'Asegurate de usar una imagen',
                 //   icon: 'warning',
                //    confirmButtonText: 'Entendido',
               //     color: '#ffffff',
              //      background: '#010f4e',
             //     });
             //   }else{
                 // this.uploadMedia.post().then((r)=>{
                    //if(!r[1].includes('image')){
                    //}else{
                   //   this.profile_photo= r[0];
                   //   this.postProfilePict(type);
                    //}
                 // }).catch((error)=>{
                   // console.log(error);
                 // });
               // }
                          
              });
            }
          } : null,


        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //reject(false);
            //console.log('Cancel clicked');
          }
        }
      ];

      botonesDisponibles = botonesDisponibles.filter((boton) => {
        if (boton != null) {
          return true;
        }
        return false;
      });


      this.actionSheetController.create({
        header: '¿Desde donde deseas añadir multimedia?',
        cssClass: 'options-sheet',
        buttons: botonesDisponibles
      }).then((actionSheet) => {
        actionSheet.present();
      });
    } else {
      return;
    }
  });

  }

  postProfilePict( ruta, pub_type,){
    console.log('type in postprofilepict', this.type);
    console.log('type in postProfilePict', this.type);
    console.log('ruta in postProfilePict', ruta);
    console.log('pub_type in postProfilePict', pub_type);
    const requestBody = {
      route: ruta,
      size: 800,
      type: 'imagen',
      publication_type: this.type,
      id:this.profile.id,
    };
    this.request.createRequestPost('profileMultimedia', requestBody)
    .then(() => {
      Swal2.fire({
        title: 'Foto actualizada con éxito',
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
    })
    .catch((error) => {
      console.log("posting new photo profile error", error);
    })
    .finally(() => {
      this.profileService.getProfileInfo().then((data) => {
        this.profile = JSON.parse(data);
      });
    });
  }  

  editar2(){

    Swal2.fire({
      title: 'Edita tu descripción',
      input: 'text',
      inputValue:  this.profile.description,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Cancelar',
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      preConfirm: (description) => {
        this.request.createRequestPost("profile", {'description':description}).then(result=>{

          console.log("json profile description",result);
          this.profile.description = description;
          Swal2.fire({
            title:"Descripcion actualizada con exito",
            timer: 1500,
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });
        });
      }


    })

  }

  obtenerJson(){
     this.profile.forEach(element => {

      console.log("json profile G",element);


     });
  }
  fetchCounters(){
    this.request.createRequestGet("CountersFachada", null, { id: this.profile_id })
	  .then((result) => {
		if (result && result.length > 0) {
		  console.log("Successfully fetched Hubble info.");
		  console.log("Hubble info:", result);
  
		  const counters = JSON.parse(result);
		 
      this.counters = counters; 
      if (Capacitor.getPlatform() === 'web') {
        if (typeof result === 'string') {
					result = JSON.parse(result);
					   console.log('json result publication service.ts', result);
             this.counters = result; 
					 }else {
            this.counters = result; 
           }
      }
		 
		} else {
		  console.log("No results found.");
		}
	  })
	  .catch((error) => {
		console.error("Error fetching Hubble info:", error);
	  });
  
  }
  formatCounter(value: number): string  {
    if (isNaN(value)) {
      return '0';
    }
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1) + 'T';
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B';
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      return value.toFixed(0);
    }
  }
  
  cambio_busqueda(texto) {
		
		this.texto_buscado = texto;
	}

	cambio_filtro(filtro) {
		
		this.filtro_actual = filtro;
	}
  async pickImage(){
    console.log('type in pickimage', this.type );
    //const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {
        console.log('Media picked successfully:', media);
        const imageUrl = Capacitor.convertFileSrc(media.path);
     // imagePreviewElement.src = imageUrl;
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
        const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
  
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        if (Capacitor.getPlatform() !== 'web') {
          this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
    }else{
      const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
      this.uploadMedia.filename = currentTime + '.png';
    }

        this.uploadMedia.post(blobfile,  this.uploadMedia.filename).then(({ link, mimeType }) => {
          const ruta = link;
          console.log('ruta', ruta);
          let pub_type = '';
          if(mimeType.includes("image")) {
            pub_type= 'imagen';
          }else if(mimeType.includes("video")){
            pub_type= 'video';
          }
          //this.profile_photo= r[0];
          this.postProfilePict( ruta, pub_type);

         }).catch((error)=>{
           console.log(error);
         });
       
      
        
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  public takePhoto(type): Promise<string> {
    return new Promise((resolve, reject) => {
      this.cameraService
        .askForMediCapture()
        .then((data: [MediaItem, string]) => {
          if (data[1].toString() === 'camara') {
           
            this.setMultimediaCamera(data[0], type);
            resolve(data[0].mediaPath + data[0].nameFile);
          } else if (data[1].toString() === 'camvideo') {
           
            console.log("Estoy en video");
            this.setMultimediaVideo(data[0], type);
            resolve(data[0].mediaPath + data[0].nameFile);
          }
         
        })
        .catch((error) => {
          reject(error);
        });
    });
    
  }
  public setMultimediaCamera(mediaItem: MediaItem, type) {
    
    console.log("setMultimediaCamera function called");
    const imagePath = mediaItem.mediaPath + mediaItem.nameFile;
    console.log("Captured image path:", imagePath);
    this.uploadMedia.image = Capacitor.convertFileSrc(imagePath);

    this.uploadMedia.blob = mediaItem.mediaPath;
    this.uploadMedia.filename =  mediaItem.nameFile;

   
    this.uploadMedia.post(mediaItem.mediaPath, mediaItem.nameFile).then(({ link, mimeType }) => {
      const ruta = link;
      console.log('ruta', ruta);
      //this.profile_photo= r[0];
      let pub_type = '';
      if(mimeType.includes("image")) {
        pub_type= 'imagen';
      }else if(mimeType.includes("video")){
        pub_type= 'video';
      }
      this.postProfilePict( ruta, pub_type);

  }).catch((error)=>{
    console.log(error);
  });
  
  }
  setMultimediaVideo(mediaItem: MediaItem, type) {
    
    this.uploadMedia.image = Capacitor.convertFileSrc(mediaItem.mediaPath + mediaItem.nameFile);
    const videoSourceElement = document.getElementById("videoSource") as HTMLSourceElement;
  
  videoSourceElement.src = this.uploadMedia.image;
  this.uploadMedia.blob = mediaItem.mediaPath;
  this.uploadMedia.filename =  mediaItem.nameFile;
  this.uploadMedia.post(mediaItem.mediaPath, mediaItem.nameFile).then(({ link, mimeType }) => {
    const ruta = link;
    console.log('ruta', ruta);
    //this.profile_photo= r[0];
    let pub_type = '';
      if(mimeType.includes("image")) {
        pub_type= 'imagen';
      }else if(mimeType.includes("video")){
        pub_type= 'video';
      }
    this.postProfilePict( ruta, pub_type);

}).catch((error)=>{
  console.log(error);
});
 
  }
  update_photo_profile( text_to_image: boolean = true,pick_media: boolean = true) {
    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
     this.type= 'profile_photo';
    console.log('type in upadte_photo', this.type );
    return new Promise((resolve, reject) => {
    if(this.profile.owner) {
      let botonesDisponibles = [
        (text_to_image) ?
          {
            text: 'Crear foto cuántica',
            icon: 'images',
            handler: () => {
              this.AI()
                .then((mediaItem) => {
                  resolve([mediaItem,'camera']);
          
                    });
        
            }
          } : null,
        (pick_media) ?
          {
            text: 'Seleccionar desde mi galería',
            icon: 'camera',
            handler: () => {
              //this.uploadMedia.image = null;
              this.pickImage().
              then((result)=>{
                resolve([result,'library']);
       
                          
              });
            }
          } : null,


        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            //reject(false);
            //console.log('Cancel clicked');
          }
        }
      ];

      botonesDisponibles = botonesDisponibles.filter((boton) => {
        if (boton != null) {
          return true;
        }
        return false;
      });


      this.actionSheetController.create({
        header: '¿Desde donde deseas añadir multimedia?',
        cssClass: 'options-sheet',
        buttons: botonesDisponibles
      }).then((actionSheet) => {
        actionSheet.present();
      });
    } else {
      return;
    }
  });

  }
  public async AI(){
    
    // Use the Router to navigate to the "messages-page"
    this.router.navigate(['home/messages-page']);
    this.formService.fromCreatePublication=true;
    this.router.navigateByUrl('home/messages-page', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });
  }
  async navigateToWebPage(url) {
    if (!url) {
      Swal2.fire({
        title: 'Sin enlace',
        text: 'El usuario no tiene enlace todavía',
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
    //let url = this.profile.WebPage;
  
    // Check if "https://" is already present in the URL
    if (!url.startsWith('https://')) {
      url = 'https://' + url;
    }
  
    await Browser.open({ url });
  }
  

 
    
    editar() {
      Swal2.fire({
        title: 'Edita tu descripción y página web',
        html: `
          <label for="descriptionInput">Descripción:</label>
          <input type="text" id="descriptionInput" class="swal2-input" [value]="${this.profile.description}" placeholder="Descripción" [(ngModel)]="profile.description">
    
          <label for="webPageInput">Página web:</label>
          <input type="text" id="webPageInput0" class="swal2-input" [value]="${this.profile.WebPage[0]}" placeholder="Página web" [(ngModel)]="this.profile.WebPage[0]">


          <label for="webPageInput">Aplicación App Store :</label>
          <input type="text" id="webPageInput1" class="swal2-input" [value]="${this.profile.WebPage[1]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[1]">

          <label for="webPageInput">Aplicación Play Store :</label>
          <input type="text" id="webPageInput2" class="swal2-input" [value]="${this.profile.WebPage[2]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[2]">

          <label for="webPageInput">Perfil de Facebook :</label>
          <input type="text" id="webPageInput3" class="swal2-input" [value]="${this.profile.WebPage[3]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[3]">

          <label for="webPageInput">Perfil de Instagram :</label>
          <input type="text" id="webPageInput4" class="swal2-input" [value]="${this.profile.WebPage[4]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[4]">

          <label for="webPageInput">Perfil de Snapchat :</label>
          <input type="text" id="webPageInput5" class="swal2-input" [value]="${this.profile.WebPage[5]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[5]">

          <label for="webPageInput">Perfil de TikTok :</label>
          <input type="text" id="webPageInput6" class="swal2-input" [value]="${this.profile.WebPage[6]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[6]">

          <label for="webPageInput">Perfil de Twitch :</label>
          <input type="text" id="webPageInput7" class="swal2-input" [value]="${this.profile.WebPage[7]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[7]">

          <label for="webPageInput">Perfil de Twitter :</label>
          <input type="text" id="webPageInput8" class="swal2-input" [value]="${this.profile.WebPage[8]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[8]">

          <label for="webPageInput">Canal de YouTube :</label>
          <input type="text" id="webPageInput9" class="swal2-input" [value]="${this.profile.WebPage[9]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[9]">

          <label for="webPageInput">Perfil de WhatsApp :</label>
          <input type="text" id="webPageInput10" class="swal2-input" [value]="${this.profile.WebPage[10]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[10]">

          <label for="webPageInput">Perfil de Reddit :</label>
          <input type="text" id="webPageInput11" class="swal2-input" [value]="${this.profile.WebPage[11]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[11]">

          <label for="webPageInput">Perfil de Wechat :</label>
          <input type="text" id="webPageInput12" class="swal2-input" [value]="${this.profile.WebPage[12]}" placeholder="Enlace" [(ngModel)]="profile.WebPage[12]">
        `,
        showCancelButton: true,
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
          const description = (document.getElementById('descriptionInput') as HTMLInputElement).value;
          const webPages = [
            (document.getElementById('webPageInput0') as HTMLInputElement).value,
            (document.getElementById('webPageInput1') as HTMLInputElement).value,
            (document.getElementById('webPageInput2') as HTMLInputElement).value,
            (document.getElementById('webPageInput3') as HTMLInputElement).value,
            (document.getElementById('webPageInput4') as HTMLInputElement).value,
            (document.getElementById('webPageInput5') as HTMLInputElement).value,
            (document.getElementById('webPageInput6') as HTMLInputElement).value,
            (document.getElementById('webPageInput7') as HTMLInputElement).value,
            (document.getElementById('webPageInput8') as HTMLInputElement).value,
            (document.getElementById('webPageInput9') as HTMLInputElement).value,
            (document.getElementById('webPageInput10') as HTMLInputElement).value,
            (document.getElementById('webPageInput11') as HTMLInputElement).value,
            (document.getElementById('webPageInput12') as HTMLInputElement).value,
            // Add similar lines for other web page links
          ];
          return {
            description,
            webPages
          };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const { description, webPages } = result.value;
          this.saveDescription(description, webPages).then(() => {
            Swal2.fire({
              title: 'Descripción y página web actualizadas con éxito',
              timer: 1500,
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
            });
          });
        }
      });
    }


    displayURLs() {
      if (this.profile.WebPage && typeof this.profile.WebPage === 'string') {
        try {
          const urlsArray = JSON.parse(this.profile.WebPage);
          if (Array.isArray(urlsArray)) {
            this.formattedURLs = urlsArray.map(url => url ? url.trim() : null);
            console.log('Profile urls:', this.formattedURLs);
          }
        } catch (error) {
          console.error('Error parsing WebPage property:', error);
        }
      }
    }
    getIconName(index: number): string {
      // Implement logic to return the appropriate icon name based on the index
      // For example:
      const iconNames = [
        'link-outline', 'logo-apple-appstore', 'logo-google-playstore',
        'logo-facebook', 'logo-instagram', 'logo-snapchat',
        'logo-tiktok', 'logo-twitch', 'logo-twitter',
        'logo-youtube', 'logo-whatsapp', 'logo-reddit', 'logo-wechat'
      ];
      return iconNames[index] || 'link-outline'; // Default to link-outline if index is out of range
    }
        
    
    
    
    
    
    
    
    saveDescription(description: string, webPages): Promise<void> {
      // Perform the necessary actions to save the edited description and webpage
      //this.isEditing = false;
    
      const object = {
        WebPage: webPages,
        Description: description,
        profile_id: this.profile.id
      };
    
      return this.request.createRequestPost('saveWebPageAndDescription', object)
        .then((data) => {
          console.log('Web page and description saved:', data);
        })
        .catch((error) => {
          console.log('API error:', error);
          throw error;
        });
    }

    
    async presentProfileActionSheet(){
      Swal2.fire({
        title: '',
        text: '',
        icon: 'success',
        timer: 9999, // Show the alert for 45 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false
        });
      setTimeout(() => {
        this.actionSheetController.dismiss();
        }, 9999); 
      const actionSheet = await this.actionSheetController.create({
        header: 'Acerca del perfil',
        cssClass: 'title-action',
        buttons: [ {
          text: 'Copiar enlace del perfil',
          icon: 'link-outline',
          cssClass: 'icon',
          handler: () => {
            if (Capacitor.getPlatform() !== 'web') {   
this.click_on_share();

}else{
  const profileId = this.profile_id;
  const url ='App web: '+ `https://supercumulo.laniakea.tv/home/profile/${profileId}`
  'App ios/android: ' + `laniakea://supercumulo.laniakea.tv/home/profile${profileId}`;
  navigator.clipboard.writeText(url).then(() => {
    Swal2.fire({
      title: 'Respuesta copiada!',
      text: 'La respuesta ha sido copiado en el portapapeles.',
      icon: 'success',
      timer: 2000, // Show the alert for 2 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
      });
  }).catch((error) => {
    //console.error(error);
  });
}
          }
        }, {
          text: 'Compartir con otras aplicaciones',
          icon: 'analytics-outline',
          cssClass: 'icon',
          handler: () => {
            if (Capacitor.getPlatform() !== 'web') {   
            this.Share();
           
          
          }else{
            Swal2.fire({
              title: 'Funcionalidad no disponible en el navegador',
              text: `Accede a esta funcionalidad en la aplicación de Play Store o App Store`,
              icon: 'info',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
              }).then(() => {
              console.log("Alert displayed successfully.");
              }).catch(error => {
              console.error("Error displaying alert:", error);
              });
          }
          }
        }, 
  
       
      ]
      });
      await actionSheet.present();
  
      const { role } = await actionSheet.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    
    }
    click_on_share() {
      const profileId = this.profile_id;
      const url ='App web: '+ `https://supercumulo.laniakea.tv/home/profile/${profileId}`
      'App ios/android: ' + `laniakea://supercumulo.laniakea.tv/home/profile${profileId}`;
    
      // Copy the URL to clipboard
      this.clipboard.copy(url)
        .then(() => {
          console.log('URL copied to clipboard:', url);
          Swal2.fire({
            title: '¡URL copiado!',
            text: 'El URL ha sido copiado en el portapapeles.',
            icon: 'success',
            timer: 2000, // Show the alert for 2 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
          });
        })
        .catch((error) => {
          console.error('Error copiando el URL en el portapapeles:', error);
          Swal2.fire({
            title: 'Error',
            text: 'Error copiando el URL en el portapapeles.',
            icon: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000 // Show the alert for 2 seconds
          });
        });
    }
    async Share(){
      const profileId = this.profile_id;
      const url ='App web: '+ `https://supercumulo.laniakea.tv/home/profile/${profileId}`
      'App ios/android: ' + `laniakea://supercumulo.laniakea.tv/home/profile${profileId}`;
      await Share.share({
        url: url,
      });
    }

// Handle the search bar input change
onSearchChange(searchValue: string) {
  if (searchValue) {
      this.filteringRelation = true;

      // Filter the relations based on the search input
      this.filteredRelations = this.relations.filter((relation) => {
          // Perform your filtering logic here, e.g., by user name or other properties
          const buttonsInfo = relation[0]?.buttons_info as { [key: string]: any };
          return (
              relation[0]?.user_info?.full_name.toLowerCase().includes(searchValue.toLowerCase()) ||
              relation[0]?.user_info?.username.toLowerCase().includes(searchValue.toLowerCase()) ||
              // Check if any of the buttons' text includes the search value
              Object.values(buttonsInfo).some((button) =>
                  button && typeof button.text === 'string' && button.text.toLowerCase().includes(searchValue.toLowerCase())
              )
          );
      });
  } else {
      this.filteringRelation = false;
  }
}
BlockUser() {
  Swal2.fire({
    title: `Bloquear a usuario: ${this.profile.username}`,
    text: `¿Estás seguro que deseas bloquear al usuario ${this.profile.username}? Se van a suprimir los mensajes directos y comentarios en publicaciones entre sí.`,
    icon: 'info',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    color: '#ffffff',
    background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
  }).then((result) => {
    if (result.isConfirmed) {
      const notification = {
        user_id: this.formService.profile.id,
      };
      // Call your desired function here
      this.request.createRequestPost('toggleBlockStatus', notification)
        .then((data) => {
          Swal2.fire({
            title: 'El status ha sido actualizado',
            text: 'La lista de tus usuarios bloqueados ha sido actualizada con éxito.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });
          this.navCtrl.navigateForward('search');
          // Handle the success case
        })
        .catch((error) => {
          console.error('The user might be indeed blocked:', error);
          Swal2.fire({
            title: 'Error al actualizar el status de bloqueo',
            text: 'Si el error persiste, comunícate con el soporte de Laniakea',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });
        });
    }
  });
}

nextPub() {

  this.slides.slideNext(); // Method to move to the next slide
  
}
backPub(){
  this.slides.slidePrev(); // Method to move to the next slide
}
eraseExperience(index: number, id?) {
  this.experiences.splice(index, 1);
  // Optionally, you can also make an API call here to persist the change
  this.permanently_delete_profile_facade_section_record(id);
}

eraseEducation(index: number, id?) {
  this.educationList.splice(index, 1);
  // Optionally, persist the change
  this.permanently_delete_profile_facade_section_record(id);
}

eraseLanguage(index: number, id?) {
  this.languages.splice(index, 1);
  this.permanently_delete_profile_facade_section_record(id);
  // Optionally, persist the change
}

eraseSkill(index: number, id?) {
  this.skills.splice(index, 1);
  // Optionally, persist the change
  this.permanently_delete_profile_facade_section_record(id);
}
eraseCause(index: number, id?) {
  this.causes.splice(index, 1);
  // Optionally, persist the change
  this.permanently_delete_profile_facade_section_record(id);
}
permanently_delete_profile_facade_section_record(id){
  this.request.createRequestPost('profile_facade_sections_controller', {id:id, delete:true})
  .then((final_data) => {
console.log('final_data',final_data);
Swal2.fire({
  title: 'perfil actualizado',
  text: 'El perfil se actualizará en 30 segundos.',
  icon: 'success',
  confirmButtonText: 'Entendido',
  color: '#ffffff',
  background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
});
});
}

ADD_TO_PROFILE_SECTION(SECTION: string) {
  if (SECTION === 'EDUCATION') {
    Swal2.fire({
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      title: 'Agregar Educación',
      html: `
        <input id="institution" class="swal2-input" placeholder="Institución">
        <input id="degree" class="swal2-input" placeholder="Título o Grado">
        <input id="year" class="swal2-input" placeholder="Año de finalización (ej. 2023)">
        <input id="logoUrl" class="swal2-input" placeholder="URL del logo de la institución">
      `,
      focusConfirm: false,
      preConfirm: () => {
        const institution = (document.getElementById('institution') as HTMLInputElement).value;
        const degree = (document.getElementById('degree') as HTMLInputElement).value;
        const year = (document.getElementById('year') as HTMLInputElement).value;
        const logoUrl = (document.getElementById('logoUrl') as HTMLInputElement).value;

        if (!institution || !degree || !year || !logoUrl) {
          Swal2.showValidationMessage('Por favor, complete todos los campos');
          return false;
        }

        return { institution, degree, year, logoUrl };
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const { institution, degree, year, logoUrl } = result.value;
        console.log('Educación agregada:', { institution, degree, year, logoUrl });
        this.profile_facade_sections_controller(false, result.value, 'EDUCATION');
      }
    });
  } else if (SECTION === 'LANGUAGES') {
    Swal2.fire({
      title: 'Agregar Idioma',
      input: 'text',
      inputLabel: 'Por favor, ingrese el idioma',
      inputPlaceholder: 'Ingrese el nombre del idioma',
      showCancelButton: true,
      confirmButtonText: 'Agregar',
      cancelButtonText: 'Cancelar',
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      preConfirm: (language) => {
        if (!language) {
          Swal2.showValidationMessage('Por favor, ingrese un idioma');
        }
        return language;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const language = result.value;
        console.log('Idioma agregado:', language);
        this.profile_facade_sections_controller(false, result.value, 'IDIOMAS');
      }
    });
  } else if (SECTION === 'SKILLS') {
    Swal2.fire({
      title: 'Agregar Habilidad',
      input: 'text',
      inputLabel: 'Por favor, ingrese la habilidad',
      inputPlaceholder: 'Ingrese el nombre de la habilidad',
      showCancelButton: true,
      confirmButtonText: 'Agregar',
      cancelButtonText: 'Cancelar',
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      preConfirm: (skill) => {
        if (!skill) {
          Swal2.showValidationMessage('Por favor, ingrese una habilidad');
        }
        return skill;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const skill = result.value;
        this.profile_facade_sections_controller(false, result.value, 'HABILIDADES');
        console.log('Habilidad agregada:', skill);
      }
    });
  } else if (SECTION === 'EXPERIENCE') {
    Swal2.fire({
      title: 'Agregar Experiencia',
      html: `
        <input id="position" class="swal2-input" placeholder="Cargo">
        <input id="company" class="swal2-input" placeholder="Empresa">
        <input id="duration" class="swal2-input" placeholder="Duración (ej. Feb 2022 - Jun 2022)">
        <textarea id="description" class="swal2-textarea" placeholder="Descripción"></textarea>
        <input id="companyLogoUrl" class="swal2-input" placeholder="URL del logo de la empresa">
      `,
      focusConfirm: false,
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      preConfirm: () => {
        const position = (document.getElementById('position') as HTMLInputElement).value;
        const company = (document.getElementById('company') as HTMLInputElement).value;
        const duration = (document.getElementById('duration') as HTMLInputElement).value;
        const description = (document.getElementById('description') as HTMLTextAreaElement).value;
        const logoUrl = (document.getElementById('companyLogoUrl') as HTMLInputElement).value;
    
        if (!position || !company || !duration || !description || !logoUrl) {
          Swal2.showValidationMessage('Por favor, complete todos los campos');
          return false; // This ensures that the form will not be submitted if validation fails
        }
    
        return {
          position,
          company,
          duration,
          description,
          logoUrl
        };
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        // Now safely access the properties since the result is confirmed and valid
        const { position, company, duration, description, logoUrl } = result.value;
        console.log('Experiencia agregada:', { position, company, duration, description, logoUrl });
        this.profile_facade_sections_controller(false, result.value, 'EXPERIENCE');
      }
    });
    
  } else if (SECTION === 'CAUSAS') {
    Swal2.fire({
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      title: 'Causas benéficas',
      html: `
        <ul style="list-style:none; padding:0; text-align:left;" id="checklist">
          <li><input type="checkbox" id="animal"> Protección de animales</li>
          <li><input type="checkbox" id="art"> Arte y cultura</li>
          <li><input type="checkbox" id="childhood"> Infancia</li>
          <li><input type="checkbox" id="civil-rights"> Derechos civiles y acción social</li>
          <li><input type="checkbox" id="economic"> Potenciamiento económico</li>
          <li><input type="checkbox" id="education"> Educación</li>
          <li><input type="checkbox" id="environment"> Medio ambiente</li>
          <li><input type="checkbox" id="health"> Salud</li>
          <li><input type="checkbox" id="human-rights"> Derechos humanos</li>
          <li><input type="checkbox" id="politics"> Política</li>
          <li><input type="checkbox" id="science"> Ciencia y tecnología</li>
          <li><input type="checkbox" id="social-services"> Servicios sociales</li>
          <li><input type="checkbox" id="veterans"> Asistencia para veteranos del ejército</li>
        </ul>
      `,
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        const selectedOptions: string[] = [];
        const unselectedOptions: string[] = [];

        const options = document.querySelectorAll('#checklist input[type="checkbox"]');
        options.forEach(option => {
          const checkbox = option as HTMLInputElement;

          if (checkbox.checked) {
            selectedOptions.push(checkbox.parentElement?.textContent?.trim() || '');
          } else {
            unselectedOptions.push(checkbox.parentElement?.textContent?.trim() || '');
          }
        });

        return {
          selected: selectedOptions,
          unselected: unselectedOptions
        };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Selected:', result.value.selected);
        console.log('Unselected:', result.value.unselected);
        this.profile_facade_sections_controller(false, result.value.selected, 'CAUSAS');
        Swal2.fire({
          title: 'Selection Summary',
          background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
          html: `
            <strong>Selected:</strong> <br> ${result.value.selected.join(', ')} <br><br>
            <strong>Unselected:</strong> <br> ${result.value.unselected.join(', ')}
          `
        });
      }
    });
  }
}
profile_facade_sections_controller( get?, data_chunk?, section_type?){
  if(data_chunk)
{
  this.request.createRequestPost('profile_facade_sections_controller', { data_chunk:data_chunk, section_type:section_type})
  .then((final_data) => {
console.log('final_data',final_data);
Swal2.fire({
  title: 'perfil actualizado',
  text: 'El perfil se actualizará en 30 segundos.',
  icon: 'success',
  confirmButtonText: 'Entendido',
  color: '#ffffff',
  background: '#010f4e',
  position: 'top-end',
  timerProgressBar: true,
  toast: true,
});
  })

}else if(get){
  this.request.createRequestPost('profile_facade_sections_controller', { get: true })
  .then((final_data) => {
    if (final_data && typeof final_data === 'string') {
      try {
        final_data = JSON.parse(final_data);
        console.log('getRecordByToken ios:',final_data);
      } catch (error) {
        console.error("Error parsing data:", error);
      }
      }
    console.log('final_data', final_data);

    if (final_data.title) {
      final_data.title.forEach((item) => {
        switch (item.section_type) {
          case 'EXPERIENCE':
            this.experiences.push(item);
            console.log('Experience:', item.data_chunk);
            // Handle EXPERIENCE section logic here
            break;

          case 'CAUSAS':
            this.causes.push(item);
            
            console.log('Causas Benéficas:', item.data_chunk);
            // Handle CAUSAS section logic here
            break;

          case 'IDIOMAS':
            this.languages.push(item);
            console.log('Idiomas:', item.data_chunk);
            // Handle IDIOMAS section logic here
           
            break;

          case 'HABILIDADES':
            console.log('Habilidades:', item.data_chunk);
            this.skills.push(item);
            // Handle HABILIDADES section logic here
            break;

          case 'EDUCATION':
               this.educationList.push(item);
           
            console.log('Educación:', item.data_chunk);
            // Handle EDUCATION section logic here
            break;

          default:
            console.log('Unknown section type:', item.section_type);
        }
      });
    }
  });

}
}
}




