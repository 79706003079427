import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { ModalController } from '@ionic/angular';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { PagoVerificacionComponent } from 'src/app/components/pago-verificacion/pago-verificacion.component';
import { create } from 'domain';
import { Message } from 'src/app/Models/Classes/Chat/message';
import {isNumeric} from "rxjs/internal-compatibility";
import { HttpClient } from '@angular/common/http';
import { transaction,createTransactionDTO } from 'src/app/Models/Interfaces/transaction_stripe.model';
import {input} from "aws-amplify";
//import { Wallet } from 'src/app/Models/Classes/Wallet/wallet';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
// import { environment } from './../../../../environments/environment.prod';

import { environment } from 'src/environments/environment';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
//import { Key } from 'protractor';
import { Capacitor } from '@capacitor/core';
import{FingerprintAIO}from "@ionic-native/fingerprint-aio/ngx";
  declare function validateCreditCard(): any;

 
  const KEY = environment.apikeystripe;
  @Component({
    selector: 'app-Recarga-stripe',
    templateUrl: './Recarga-stripe.html',
    styleUrls: ['./Recarga-stripe.scss'],
  })
  export class RecargaStripe implements OnInit, OnChanges {
    endpoint = 'Deposit';
    @Input() objmedia: any;
    @Input() publication_id: any;


  @Input() amount: number;
  @Input() currency: string;
  @Input() profile_id : number;

    cardNum: string;
    titular: string;
    month: number;
    year: number;
    cvc: string;
    image_brand: string = '';

  
    object;

    constructor(
      private NgZone: NgZone,
      private router: Router,
      private request: RequestService,
      private modalcontroller: ModalController,
      private profileService: ProfileService,
      private walletService:WalletServiceService,
      private stripe:Stripe,
      private faio:FingerprintAIO,


    ) {
      this.stripe.setPublishableKey(KEY)
  
    }
  
    ngOnInit() {
      console.log(this.walletService.amount);
      if(this.publication_id){
        this.amount=(this.amount + 9) *1.06
      }
    }
    
    regresar(){
      this.modalcontroller.dismiss()
    }
    get_card_type()
    {
      console.log('refactoring card');
      this.image_brand = "";
  
      //Remove all spaces and dashes from the passed string
      const CardNo = this.cardNum.replace("-", "").replace(" ", "");
  
      //Check that the minimum length of the string isn't less
      //than fourteen characters and -is- numeric
      if (CardNo.length < 14 || !isNumeric(CardNo)) {
        this.image_brand = ''
      }
  
      //Check the first two digits first
      if(parseInt(this.cardNum.substring(0,2)) === 34 || parseInt(this.cardNum.substring(0,2)) === 37) {
        this.image_brand = 'assets/icon/pagos/amex.png';
  
      }else if(parseInt(this.cardNum.substring(0,2)) >= 51 && parseInt(this.cardNum.substring(0,2)) <= 55){
        this.image_brand = 'assets/icon/pagos/master_card.png';
      }else if(parseInt(this.cardNum.substring(0,1)) === 4) {
        this.image_brand = 'assets/icon/pagos/visa.png';
      }
  
  
  
  
  
  
  
  
      console.log(this.image_brand);
  
      this.cardNum = this.cardNum.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
  
    }
  
  RecargaStripe(){
    if (Capacitor.getPlatform() === 'web') {
      this.pagar();
    }else{
      this.faio.show({
        title:"Leyendo huella Digital",
        subtitle:"Use su huella para permitir el acceso a retiros",
      }).then((res)=>{
        console.log(res);
        this.pagar();
      }).catch((err)=>{
        console.log(err);
        alert('Acceso denegado');
      })
    }
  }
  called;
   async pagar(){
    if(this.called){
      return;
    }
    this.called=true;


     if(this.amount < 21){
      Swal2.fire({
        title: 'Pago Rechazado',
        text: 'Por favor, intente otro pago, ya que solo se pueden realizar pagos con tarjeta mayores a 10 pesos.',
        icon: 'error',
        confirmButtonText: 'Ok',
        color: '#ffffff',
        background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
      })
       return;
     }
      		Swal2.fire({
			title: 'Procesando...',
			text: 'Hubble está procesando.',
			icon: 'success',
			timer: 8100, // Show the alert for 3.9 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
      let card = {
        number: this.cardNum.replace(' ', ''),
        expMonth: this.month,
        expYear: this.year,
        cvc: this.cvc
       }
       if(this.objmedia&&this.objmedia.publication_type ==='Alibaba'){
        this.endpoint='UnAuthStripe';
      }else{
        this.endpoint='Deposit';
      }
       
       if (Capacitor.getPlatform() !== 'web') {
       this.stripe.createCardToken(card)
       .then((token) =>{
        const token_id=token.id
        console.log("Token obtenido",token_id);
  
        let object={
          objmedia:this.objmedia,
          publication_id:this.publication_id,     
            amount:this.amount,
            currency:this.currency,
            description:"Validacion",
            tokenid:token_id,
            profile_id: this.profile_id  // Pass the profile id
          }
          this.request.createRequestPost(this.endpoint,object).then((result)=>{
            const parsedResult = JSON.parse(result);  // Parse the result string into a JSON object
            console.log("resultado de respuesta back stripe",result);
            console.log(JSON.stringify(result));
  
            if (parsedResult.code == 200) {
              if(this.objmedia&&this.objmedia.publication_type ==='Alibaba'){
                let endPoint;
                if(this.profile_id){
                  endPoint='ControladorDeCarritoDeCompras';
                }else{
                  endPoint='AlibabaProductHandler';
                }
                this.request.createRequestPost(endPoint, { create_order_and_pay: true, cart: this.objmedia.item, order_id:parsedResult.order_id })
                .then((data) => {
                  if (data && typeof data === 'string') {
                    try {
                      data = JSON.parse(data);
                      console.log('getRecordByToken ios:',data);
                    } catch (error) {
                      console.error("Error parsing data:", error);
                    }
                    }
                  console.log('data', data);
                  Swal2.fire({
                    title: 'Compra realizada con éxito',
                    text: 'Tu orden ha sido creada y procesada correctamente. Gracias por tu compra.',
                    icon: 'success',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
                  });
                })
                .catch((error) => {
                  console.error('Error occurred:', error);
                  Swal2.fire({
                    title: 'Error',
                    text: 'Ocurrió un problema al procesar tu solicitud. Por favor, intenta de nuevo más tarde.',
                    icon: 'error',
                    confirmButtonText: 'Entendido',
                    color: '#ffffff',
                    background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
                  });
                });
              }else{
                Swal2.fire({
                  title: 'Depósito a Hubble procesado con éxito!',
                  text: `Tu depósito por monto de $${this.amount}${this.currency} se procesó con éxito`,
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  color: '#ffffff',
                  background: '#010f4e',
                  position: 'top-end',
                  timerProgressBar: true,
                  toast: true,
                }).then((r) =>{
                  this.modalcontroller.dismiss();
                  this.profileService.getProfileInfo().then((profile) => {
                    console.log("promesa del perfile en verificación", profile);
                    this.modalcontroller.dismiss(JSON.parse(profile));
                  })
                  this.modalcontroller.dismiss();
    }).catch((error) => {
      console.log("Error de stripe",error);
    });
              }
           
              
              
            } else {
              Swal2.fire({
                title: 'Pago Rechazado',
                text: 'Por favor, intentelo de nuevo o de lo contrario utilize otra tarjeta',
                icon: 'error',
                confirmButtonText: 'Ok',
                color: '#ffffff',
                background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
              })
  
              
            }
  
            
           
            
          })
            
          .catch(error => console.error(error));
       })
      }else{

const stripe = require('stripe')('pk_live_51KGxgHCVJMP5YqiYzADXUzozYAEmRFPrTkPzRacQ3EELXgN05NVG1GvklRwKtAif5ijbqavUn0Qw4d7ygGQEf7E600LtFRhx6k');
const token_id = await stripe.tokens.create({
  card: {
    number: this.cardNum.replace(' ', ''),
    exp_month: this.month,
    exp_year: this.year,
    cvc: this.cvc
  },
});
console.log(token_id);

let object={
  objmedia:this.objmedia,
  publication_id:this.publication_id,     
    amount:this.amount,
    currency:this.currency,
    description:"Validacion",
    tokenid:token_id['id'],
    profile_id: this.profile_id  // Pass the profile id
  }
  if(this.objmedia&&this.objmedia.publication_type ==='Alibaba'){
    this.endpoint='UnAuthStripe';
  }else{
    this.endpoint='Deposit';
  }
  this.request.createRequestPost(this.endpoint,object).then((result)=>{
    if (result && typeof result === 'string') {
      try {
        result = JSON.parse(result);
        console.log('getRecordByToken ios:',result);
      } catch (error) {
        console.error("Error parsing data:", error);
      }
      }
    //const parsedResult = JSON.parse(result);  // Parse the result string into a JSON object
    console.log("resultado de respuesta back stripe",result);
   // console.log(JSON.stringify(result));

    //if (parsedResult.code == 200) {

      if(this.objmedia&&this.objmedia.publication_type ==='Alibaba'){
        this.request.createRequestPost('AlibabaProductHandler', { create_order_and_pay: true, cart: this.objmedia.item, order_id:result.message })
        .then((data) => {
          if (data && typeof data === 'string') {
            try {
              data = JSON.parse(data);
              console.log('getRecordByToken ios:',data);
            } catch (error) {
              console.error("Error parsing data:", error);
            }
            }
this.sendTrackingProductEmail(result.message);
          console.log('data', data);
          Swal2.fire({
            title: 'Compra realizada con éxito',
            text: 'Tu orden ha sido creada y procesada correctamente. Gracias por tu compra.',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
        }).then((r) =>{
          this.modalcontroller.dismiss();
          this.profileService.getProfileInfo().then((profile) => {
            console.log("promesa del perfile en verificación", profile);
            this.modalcontroller.dismiss(JSON.parse(profile));
          })
          this.modalcontroller.dismiss();
        }).catch((error) => {
          console.log("Error de stripe",error);
          });
        })
        .catch((error) => {
          console.error('Error occurred:', error);
          Swal2.fire({
            title: 'Error',
            text: 'Ocurrió un problema al procesar tu solicitud. Por favor, intenta de nuevo más tarde.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
          });
        });
      }else{
        Swal2.fire({
          title: 'Depósito a cartera Hubble procesado con éxito!',
          text: `Tu depósito por monto de $${this.amount}${this.currency} se procesó con éxito`,
          icon: 'success',
          confirmButtonText: 'Ok',
          color: '#ffffff',
          background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
        }).then((r) =>{
          this.modalcontroller.dismiss();
          this.profileService.getProfileInfo().then((profile) => {
            console.log("promesa del perfile en verificación", profile);
            this.modalcontroller.dismiss(JSON.parse(profile));
          })
          this.modalcontroller.dismiss();
}).catch((error) => {
console.log("Error de stripe",error);
});
      }



   
      
      
  
  })
  .catch((error) => {
    console.error("Error al procesar el pago:", error);
    Swal2.fire({
      title: 'Error',
      text: 'Ha ocurrido un error al procesar tu pago. Por favor, intenta de nuevo.',
      icon: 'error',
      confirmButtonText: 'Ok',
      color: '#ffffff',
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
    });
  });
    

      }
    }
    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
      }

      
sendTrackingProductEmail(order_id){


  const trackingUrl = "https://supercumulo.laniakea.tv/feex-commerce-purchase/"+ order_id;

const emailBody = `Dear Valued Customer,

Thank you for your purchase! We appreciate your business and are excited to get your order to you as soon as possible.

You can track your order's status and estimated delivery time by clicking the link below:

${trackingUrl}

If you have any questions or need further assistance, feel free to contact our support team.

`;

this.sendEmail(emailBody, order_id);
}
  sendEmail(emailBody,order_id ) {
     
      return new Promise((resolve, reject) => {

      
        this.request.createRequestPost('AlibabaProductHandler', {get_purchases:true, order_id:order_id})
        .then((data) => {
          let order_email=data.success[0].email;
          const notification = {
            subject: 'Product purchase completed successfully',
            Cuerpo: emailBody,
            name:'Valued Customer',
            recipient_email: order_email,
    
          };
        this.request.createRequestPost('sendPragmaticCorporateMailUnAuth', notification)
          .then((data) => {
            if (data && typeof data === 'string') {
              try {
                data = JSON.parse(data);
                console.log('getRecordByToken ios:',data);
              } catch (error) {
                console.error("Error parsing data:", error);
              }
              }
            Swal2.fire({
              title: 'Correo enviado',
              text: 'El correo ha sido enviado exitosamente',
              icon: 'success',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
            });
       
            resolve(data);
          }).catch((error) => {
            console.error('Failed to send notification:', error);
            reject(error);
          });
        });


       
      });
    }     
      
}