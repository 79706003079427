import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import  Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
import { ShootingStar4Component} from 'src/app/components/publication/create/shooting-star/shooting-star4/shooting-star4.component';
@Component({
  selector: 'app-shooting-star',
  templateUrl: './shooting-star.component.html',
  styleUrls: ['./shooting-star.component.scss'],
})
export class ShootingStarComponent implements OnInit {
  @Input() startPoint_lat: any;
@Input() startPoint_lng: any;
@Input() destination_lat: any;
@Input() destination: any;
@Input() startPoint: any;
@Input() destination_lng: any;
@Input() Servicio: any;
propina;
selectedPaymentMethod: string;
slideHubblePayOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: 'auto',
  spaceBetween: 8,
  centeredSlides: false,
  freeMode: true,
  freeModeSticky: true,
  direction: 'horizontal'
};
public Vehiculos:any;
public pasajeros:any;
public informacionAdicional:any;
  public selectedVehicle: any;
  public TarifaPorHora:any=0;
  public showPasajeros:boolean=false;
  public NumeroDePasajeros: any=0;
  constructor( private modalcontroller:ModalController, private request: RequestService) { }

  ngOnInit() {
    this.Vehiculos=null;
  }
  onInformacionAdicionalChange(event: CustomEvent) {
    this.informacionAdicional = event.detail.value;
  }
  onPasajerosChange(event: CustomEvent){
    this.pasajeros=event.detail.value;
  }
  confirmar(){
    Swal2.fire({
			title: 'Procesando...',
			text: 'El comando se está procesando.',
			icon: 'success',
			timer: 45000, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
    if(!this.pasajeros){
      Swal2.fire({
        title: 'Numero de pasjaeros faltante.',
        text: 'Por favor, seleccione el numero de pasajeros.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
    if(!this.selectedPaymentMethod){
      Swal2.fire({
        title: 'Método de pago faltante.',
        text: 'Por favor, seleccione el método de pago.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
    if(!this.selectedVehicle){
      Swal2.fire({
        title: 'Tipo de vehículo faltante',
        text: 'Por favor, seleccione el tipo de vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
      });
      return;
    }
    if(!this.informacionAdicional){
      Swal2.fire({
        title: 'Información faltante',
        text: 'Por favor, añade información adicional relevante para el servicio.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }

    this.request.createRequestPost('clientRequestsShootingStarsTrip',{ Servicio: this.Servicio, destination: this.destination, startPoint: this.startPoint,  selectedVehicle: this.selectedVehicle, informacionAdicional: this.informacionAdicional, Pasajeros: this.pasajeros, propina:this.propina, metodo_de_pago:this.selectedPaymentMethod, startPoint_lat:this.startPoint_lat, startPoint_lng:this.startPoint_lng, destination_lat:this.destination_lat, destination_lng:this.destination_lng}) .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      console.log(data);
      this.Vehiculos=data.vehicles;
      Swal2.fire({
        title: 'Viaje en proceso',
        text: 'Esperando a que un conductor acepte el viaje. El viaje se ha añadido a "Casa/Pedidos Fugaces". Ahi podrás monitorear el viaje y el status.',
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      this.modalcontroller.create({
        component: ShootingStar4Component,
        cssClass: 'detailsInModal',
        swipeToClose: true,


      }).then((modal) => {
        modal.present();
      })

    }).catch((error) => {
      console.error('Failed to send request:', error);
    });

  }
  dismiss(){
    this.modalcontroller.dismiss();
  }
  onVehicleChange(event: CustomEvent) {
    // Handle the selected vehicle here
    const selectedVehicle = event.detail.value;
    console.log('Selected Vehicle:', selectedVehicle);
    this.selectedVehicle=selectedVehicle;
    this.showPasajeros=false;
    // Update TarifaPorHora based on the selected vehicle
    switch (selectedVehicle) {
      case 'Motocicleta':
        this.TarifaPorHora = 132.3; // 65 per person. Set the TarifaPorHora value for Motocicleta
        this.showPasajeros=true;
        this.NumeroDePasajeros= 2;
        break;
      case 'Automovil':
        this.TarifaPorHora = 423; // 105.75 per person. Set the TarifaPorHora value for Automovil
        this.showPasajeros=true;
        this.NumeroDePasajeros= 4;
        break;
      case 'Camioneta SUV':
        this.TarifaPorHora = 540; //90 per person. Set the TarifaPorHora value for Camioneta
        this.showPasajeros=true;
        this.NumeroDePasajeros= 6;
        break;
      case 'Camioneta Van':
        this.TarifaPorHora = 810; // Set the TarifaPorHora value for Vagoneta
        this.showPasajeros=true;
        this.NumeroDePasajeros= 9;
        break;
      case 'Autobús':
        this.TarifaPorHora = 2100; // Set the TarifaPorHora value for Autobús
        this.showPasajeros=true;
        this.NumeroDePasajeros= 33;
        break;
      case 'Camión unitario':
        this.TarifaPorHora = 1200; // Set the TarifaPorHora value for Camión Unitario
        break;
      case 'Camión remolque':
        this.TarifaPorHora = 1800; // Set the TarifaPorHora value for Camión con remolque
        break;
      case 'Avión privado':
        this.TarifaPorHora = 42000; // Set the TarifaPorHora value for Avión privado
        this.showPasajeros=true;
        this.NumeroDePasajeros= 18;
        break;
      case 'Helicóptero':
        this.TarifaPorHora = 18000; // Set the TarifaPorHora value for Helicóptero
        this.showPasajeros=true;
        this.NumeroDePasajeros= 9;
        break;
      case 'Lancha':
        this.TarifaPorHora = 2100; // Set the TarifaPorHora value for Lancha
        this.showPasajeros=true;
        this.NumeroDePasajeros= 12;
        break;
      case 'Yate':
        this.TarifaPorHora = 6900; // Set the TarifaPorHora value for Yate
        this.showPasajeros=true;
        this.NumeroDePasajeros= 18;
        break;
      case 'Bicicleta':
        this.TarifaPorHora = 69; // Set the TarifaPorHora value for Bicicleta
        break;
      case 'Patineta':
        this.TarifaPorHora = 30; // Set the TarifaPorHora value for Patineta
        break;
      case 'Triciclo':
        this.TarifaPorHora = 45; // Set the TarifaPorHora value for Triciclo
        break;
      default:
        this.TarifaPorHora = 0; // Default value if none of the cases match
        break;
    }
  }
  onPropinaChange(event: CustomEvent){
    this.propina=event.detail.value;
  }
  selectPaymentMethod(method: string) {
    this.selectedPaymentMethod = method;
}
}
