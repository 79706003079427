import { Component, OnInit, Input } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
@Component({
  selector: 'app-bienes6',
  templateUrl: './bienes6.component.html',
  styleUrls: ['./bienes6.component.scss'],
})
export class Bienes6Component implements OnInit {
// Define the input property to receive the value from the parent component
@Input() show_save_address_button: boolean = false;
  constructor(
    private formservice : FormServiceService,
    private request: RequestService,
  ) { }

  ngOnInit() {}
  saveAddress(){
    this.request.createRequestPost('ShippingAddressesController', {insert:true,latitud:this.formservice.feexpublication.latitud, longitud:this.formservice.feexpublication.longitud, country:this.formservice.feexpublication.country, postal_code :this.formservice.feexpublication.postal_code,  calle: this.formservice.feexpublication.calle, name:this.formservice.feexpublication.name, Ciudad: this.formservice.feexpublication.Ciudad,Estado: this.formservice.feexpublication.Estado, ubicacion: this.formservice.feexpublication.location })
    .then((data) => {
      Swal2.fire({
        title: 'datos guardados',
        text: 'Se ha sido enviado exitosamente',
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    
    }).catch((error) => {
      console.error('Failed to send notification:', error);
   
    });
  }
}
