import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventPublication } from 'src/app/Models/Classes/Publications/Event/event-publication';
import { NormalPublication } from 'src/app/Models/Classes/Publications/Normal/normal-publication';
import { PublicationClass } from 'src/app/Models/Classes/Publications/publication-class';
import { StoryPublication } from 'src/app/Models/Classes/Publications/Story/story-publication';
import { UrlPublication } from 'src/app/Models/Classes/Publications/Url/url-publication';
import { ReactionContainerClass } from 'src/app/Models/Classes/Reactions/ReactionContainer/reaction-container-class';
import { ReactionClass } from 'src/app/Models/Classes/Reactions/SingleReaction/reaction-class';
import { SellerProfile } from 'src/app/Models/Classes/User/Store/seller-profile';
import { RequestResponse } from '../../Models/Classes/request-response';
import { RequestService } from '../request/request.service';
import { newnormal } from 'src/app/Models/Classes/Publications/New-Normal/newnormal';
import { LkfilmPublication } from 'src/app/Models/Classes/Publications/Lkfilm/lkfilm-publication';
import { Eventos } from 'src/app/Models/Classes/Publications/Eventos/eventos';
import { Land } from 'src/app/Models/Classes/Publications/Land/land';
import { Epoch } from 'src/app/Models/Classes/Publications/Epoch/epoch';
import { Earth } from 'src/app/Models/Classes/Publications/Earth/earth';
import { Capacitor } from '@capacitor/core';
import { LkmusicPublication } from 'src/app/Models/Classes/Publications/lk-music/lkmusic-publication';
import { Feex } from 'src/app/Models/Classes/Publications/Feex/feex';
@Injectable({
	providedIn: 'root'
})
export class PublicationService {

	public UserProfileList = new Array<SellerProfile>();
	public GlobalPublicationsList = new Array<PublicationClass>();
	public ReactionList = new Array<ReactionClass>();

	public MyStoriesList = new Array<PublicationClass>();
	public MyFeedList = new Array<PublicationClass>();

	public MylkfilmList = new Array<PublicationClass>();
	public MylandList = new Array<PublicationClass>();
	public MyepochList = new Array<PublicationClass>();
	public MyeventosList = new Array<PublicationClass>();
	public MyearthList = new Array<PublicationClass>();

	public MyPublicationsList = new Array<PublicationClass>();
	public TemporalPublicationList = new Array<PublicationClass>();
	public StatusPublicationList = new Array<PublicationClass>();
	public COLECCIONCOMPRASLIST = new Array<PublicationClass>();
	public COLECCIONREGALOSLIST = new Array<PublicationClass>();
	constructor(private request: RequestService) { }

	public postPublication(publication: any): Promise<RequestResponse> {
		return new Promise((resolve, reject) => {
			this.request.createRequestPost('publications', publication).then((data) => {



				resolve(data);
			}).catch((error) => {
				//console.error(error);
				reject(error);
			});
		});
	}

	public getPublications(id_publication?: number): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			var params;
			id_publication ? params = { "id": id_publication } : params = null;

			this.request.createRequestGet('publications', null, params).then((data) => {

				if (data == "") {
					reject("Unvaliable");
				}
				else {
					resolve(JSON.parse(data));
				}

			}).catch((error) => {

				reject(error);
			})
		});
	}

	public getStories(id_publication?: number): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			var params;
			id_publication ? params = { "id": id_publication } : params = null;

			this.request.createRequestGet('stories', null).then((data) => {
				try {
					let json = JSON.parse(data);
          console.log('stories', json);
					if(json){
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public getAllPublications(page?: number, user_id?: number, publication_type?: string): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let user_id_str = (user_id) ? user_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let publication_type_str = (publication_type) ? publication_type : "";
			var params = {
					"page": page_str,
					"user_id": user_id_str,
          "publication_type": publication_type_str
			};


			this.request.createRequestGet('publications', params).then((data) => {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public putPublication(publication: any, id_publication: number): Promise<RequestResponse> {

		return new Promise((resolve, reject) => {
			var params = { "id": id_publication };
			this.request.createRequestPut('publications', publication, params).then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public deletePublications(id_publication: number): Promise<RequestResponse> {
		return new Promise((resolve, reject) => {
			var params = { "id": id_publication };
			this.request.createRequestPost('DeletePublication', params).then((client) => {
				this.DeleteLocalPublicationByID(id_publication);
				resolve(client);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public postSavePublication(id_publication: number) {
		return new Promise((resolve, reject) => {
			let params = { "id": id_publication };
			this.request.createRequestPost('publications', {}, params, 'save').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});

		});
	}

	public deleteSavePublication(id_publication: number) {
		return new Promise((resolve, reject) => {
			var params = {
				"id": id_publication,
				"id2": 0
			}
			this.request.createRequestDelete('publications', null, params, 'save').then((client) => {
				resolve(client);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public getSavedPublications(page?: number) {
		return new Promise((resolve, reject) => {
			let page_str;
			page ? page_str = page.toString() : page_str = "1";
			let publication_id = 0;

			var params = {
				'id': publication_id.toString(),
				'queryParams': {
					"page": page_str
				}
			};

			this.request.createRequestGet('publications', params.queryParams, params, 'save').then((data) => {
				resolve(JSON.parse(data));
			}).catch((error) => {
				reject(error);
			});

		});
	}

	public postBuyPublication(id_publication: number) {
		return new Promise((resolve, reject) => {
			let params = { "id": id_publication };
			this.request.createRequestPost('publications', {}, params, 'payment').then((data) => {
				resolve(data);
			}).catch((error) => {
				reject(error);
			});

		});
	}

	public getUserProfile_Publications(objectPublication: any) {

		return new Promise<SellerProfile>((resolve, reject) => {

			if (objectPublication == null) {
				resolve(null); // Resolve with null instead of rejecting
			}

			let user_object = {
				user_id: objectPublication.user_id,
				username: objectPublication.username,
				profile_photo: {
					url: objectPublication.user_photo
				}
			};

			let found = this.UserProfileList.find(element => element.user_id == user_object.user_id);
			if (found != undefined) {
				found.profile_photo = objectPublication.user_photo;
				resolve(found);
			}
			else {
				let userProfile = new SellerProfile(user_object);
				this.UserProfileList.push(userProfile);
				resolve(userProfile);
			}
		});
	}

	public getReactionClass(reactionInfo: { reaction_group_id: number, reaction_icon: string, reaction_name: string }) {
		return new Promise<ReactionClass>((resolve) => {
			let found = this.ReactionList.find(element => element.reaction_group_id == reactionInfo.reaction_group_id);
			if (found != undefined) {
				resolve(found);
			}
			else {
				let reaction = new ReactionClass(reactionInfo);
				this.ReactionList.push(reaction);
				resolve(reaction);
			}
		});
	}

	public getReactionContainer_Publications(objectPublication: any) {
		console.log('1');
		return new Promise<Array<ReactionContainerClass>>((resolve) => {
			console.log('2');
			let ReactionContainer = new Array<ReactionContainerClass>();
			console.log('3');
			objectPublication.reactionContainer.forEach(reactionObject => {
				console.log('4');
				this.getReactionClass(reactionObject).then((reactionGroup) => {
					console.log('5');
					let container = new ReactionContainerClass(reactionGroup, reactionObject.reaction_quantity);
					console.log('6');
					ReactionContainer.push(container);
				
				});
			});
			resolve(ReactionContainer);
		});
	}

	public getOwnReaction_Publications(objectPublication: any) {
		return new Promise<{ reaction_id: number, reactionClass: ReactionClass }>((resolve) => {
			if (objectPublication.ownReaction != null) {
				this.getReactionClass(objectPublication.ownReaction).then((reactionGroup) => {
					let ownReaction = {
						reaction_id: objectPublication.ownReaction.reaction_id,
						reactionClass: reactionGroup
					};
					resolve(ownReaction);
				});
			}
			else {
				resolve(null);
			}
		});



	}

	public updatePublicationList(listName: string, objectPublication: any, setInTop?: boolean) {
		return new Promise<PublicationClass>((resolve, reject) => {
			this.getUserProfile_Publications(objectPublication).then((userProfile: SellerProfile) => {
				this.getReactionContainer_Publications(objectPublication).then((reactionContainer: Array<ReactionContainerClass>) => {
					this.getOwnReaction_Publications(objectPublication).then((ownReaction) => {
						let publication: any;
						console.log('7');
						if (!(objectPublication instanceof PublicationClass)) {
							console.log('8', objectPublication.publication_type);
							switch (objectPublication.publication_type) {
								case 'normal': {
									publication = new NormalPublication(objectPublication, userProfile, reactionContainer, ownReaction);
								} break;
								case 'lkmusic': {
									publication = new LkmusicPublication(objectPublication, userProfile, reactionContainer, ownReaction);
									console.log('lkmusic_media',objectPublication);
								} break;
								case 'feex': {
									publication = new Feex(objectPublication, userProfile, reactionContainer, ownReaction);
									console.log('lkmusic_media',objectPublication);
								} break;
								case 'lkfilm': {
									publication = new  LkfilmPublication(objectPublication, userProfile, reactionContainer, ownReaction);
								} break;
								case 'eclipse': {
									console.log('9');
									publication = new  Eventos(objectPublication, userProfile, reactionContainer, ownReaction);
								} break;
								case 'land': {
									publication = new  Land(objectPublication, userProfile, reactionContainer, ownReaction);
								} break;
								case 'epoch': {
									publication = new  Epoch(objectPublication, userProfile, reactionContainer, ownReaction);
								} break;
								case 'earth': {
									publication = new  Earth(objectPublication, userProfile, reactionContainer, ownReaction);
								} break;
								//case 'event': {
								
								//	publication = new EventPublication(objectPublication, userProfile, reactionContainer, ownReaction);
								//} break;
								case 'url': {
									publication = new UrlPublication(objectPublication, userProfile, reactionContainer, ownReaction);
								} break;
								case 'story':
                  console.log('Es historia');
									publication = new StoryPublication(objectPublication, userProfile, reactionContainer, ownReaction);
                  console.log(publication);
									break;
								default: {
								} break;
							}
						}else{
							publication = objectPublication;
						}
						// this.GlobalPublicationsList.push(publication);

						switch (listName) {
							case 'MyFeedList': {
								let publicationInList = this.MyFeedList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.MyFeedList.unshift(publication) : this.MyFeedList.push(publication);
								}
								this.orderPublicationList(this.MyFeedList);
								resolve(publication);
							} break;
							case 'MylkfilmList': {
								let publicationInList = this.MylkfilmList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.MylkfilmList.unshift(publication) : this.MylkfilmList.push(publication);
								}
								this.orderPublicationList(this.MylkfilmList);
								resolve(publication);
							} break;
							case 'MylandList': {
								let publicationInList = this.MylandList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.MylandList.unshift(publication) : this.MylandList.push(publication);
								}
								this.orderPublicationList(this.MylandList);
								resolve(publication);
							} break;
							case 'MyepochList': {
								let publicationInList = this.MyepochList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.MyepochList.unshift(publication) : this.MyepochList.push(publication);
								}
								this.orderPublicationList(this.MyepochList);
								resolve(publication);
							} break;
							case 'MyeventosList': {
								let publicationInList = this.MyeventosList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.MyeventosList.unshift(publication) : this.MyeventosList.push(publication);
								}
								this.orderPublicationList(this.MyeventosList);
								resolve(publication);
							} break;
							case 'MyearthList': {
								let publicationInList = this.MyearthList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.MyearthList.unshift(publication) : this.MyearthList.push(publication);
								}
								this.orderPublicationList(this.MyearthList);
								resolve(publication);
							} break;
							case 'MyStoriesList': {
								let publicationInList : any = this.MyStoriesList.find(element => element.id == publication.id);
                console.log('publicationList', publicationInList)
								if (publicationInList == undefined) {
									(setInTop) ? this.MyStoriesList.unshift(publication) : this.MyStoriesList.push(publication);
								}else{
									publicationInList.states = publication.states
								}
								this.orderPublicationList(this.MyStoriesList);
								resolve(publication);
							} break;
							case 'MyPublicationsList': {
								let publicationInList = this.MyPublicationsList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.MyPublicationsList.unshift(publication) : this.MyPublicationsList.push(publication);
								}
								this.orderPublicationList(this.MyPublicationsList);
								resolve(publication);
							} break;
							case 'TemporalPublicationList': {
								let publicationInList = this.TemporalPublicationList.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.TemporalPublicationList.unshift(publication) : this.TemporalPublicationList.push(publication);
								}
								this.orderPublicationList(this.TemporalPublicationList);
								resolve(publication);
							} break;
							case 'COLECCIONREGALOSLIST': {
								let publicationInList = this.COLECCIONREGALOSLIST.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.COLECCIONREGALOSLIST.unshift(publication) : this.COLECCIONREGALOSLIST.push(publication);
								}
								this.orderPublicationList(this.COLECCIONREGALOSLIST);
								resolve(publication);
							} break;
							case 'COLECCIONCOMPRASLIST': {
								let publicationInList = this.COLECCIONCOMPRASLIST.find(element => element.id == publication.id);
								if (publicationInList == undefined) {
									(setInTop) ? this.COLECCIONCOMPRASLIST.unshift(publication) : this.COLECCIONCOMPRASLIST.push(publication);
								}
								this.orderPublicationList(this.COLECCIONCOMPRASLIST);
								resolve(publication);
							} break;
							default: {
								resolve(publication);
							} break;
						}
					}).catch((error) => {
						console.log('Error in getOwnReaction_Publications:', error);
						reject({ 'error': 'Error in getOwnReaction_Publications' });
					  });
					}).catch((error) => {
					  console.log('Error in getReactionContainer_Publications:', error);
					  reject({ 'error': 'Error in getReactionContainer_Publications' });
					});
				  }).catch((error) => {
					console.log('Error in getUserProfile_Publications:', error);
					reject({ 'error': 'Error in getUserProfile_Publications' });
				  });
				});
			  }

	public getPublicationByID(publication_id: number, getFromServer?: boolean) {
		getFromServer = (getFromServer) ? getFromServer : false;
		return new Promise<PublicationClass>((resolve, reject) => {
			let publication = this.GlobalPublicationsList.find(element => element.id == publication_id);
			if (publication == undefined || getFromServer == true) {

				this.getPublications(publication_id).then((data: any) => {
					this.updatePublicationList('none', data, false).then((publicationFromServer: PublicationClass) => {
						publication = publicationFromServer;

						resolve(publication);
					});
				}).catch((error) => {
					/*if (error == "Unvaliable") {
						reject(error);
					}
					else {
						console.error(error);

					}*/
					reject(error);
				});
			}
			else {
				//console.log("Recuperada de RAM");
				resolve(publication);
			}
		});
	}

	public DeleteLocalPublicationByID(publication_id: number) {
		return new Promise<string>((resolve, reject) => {
			let publication = this.MyFeedList.find(element => element.id == publication_id);

			if (publication != null) {
				let index = this.MyFeedList.indexOf(publication, 0);
				if (index > -1) {
					this.MyFeedList.splice(index, 1);
				}

				index = this.TemporalPublicationList.indexOf(publication, 0);
				if (index > -1) {
					this.TemporalPublicationList.splice(index, 1);
				}

				index = this.MyPublicationsList.indexOf(publication, 0);
				if (index > -1) {
					this.MyPublicationsList.splice(index, 1);
				}

				index = this.GlobalPublicationsList.indexOf(publication, 0);
				if (index > -1) {
					this.GlobalPublicationsList.splice(index, 1);
				}
				publication = undefined;
				resolve("Publicación eliminada de memoria");

			}
			else {
				reject("La publicación no esta en memoria");
			}

		});
	}

	public orderPublicationList(publicationsList: Array<PublicationClass>) {
		/*
		publicationsList = publicationsList.sort((p1, p2) => {
			return (p1.unixTime_created_at > p2.unixTime_created_at) ? -1 : 1;
		});
		*/
	}
	public getAllSavedPublications(page?: number, user_id?: number, publication_type?: string): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let user_id_str = (user_id) ? user_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let publication_type_str = (publication_type) ? publication_type : "";
			var params = {
					"page": page_str,
					"user_id": user_id_str,
          "publication_type": publication_type_str
			};


			this.request.createRequestGet('publications_saved', params).then((data) => {
				if (Capacitor.getPlatform() !== 'web') {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}else{ 
				try {
					// Ensure the data is in the correct format for web
				   // Ensure the data is in the correct format for web
				   if (typeof data === 'string') {
					   let response = JSON.parse(data);
					   console.log('json result publication service.ts', response);
					   resolve(response);
					 } else {
					   resolve(data);
					 }
					   }catch(e){
						   reject(e)
					   }
			}
			}).catch((error) => {
				reject(error);
			})
		});
	}
	public getAllGiftedPublications(page?: number, user_id?: number, publication_type?: string): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let user_id_str = (user_id) ? user_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let publication_type_str = (publication_type) ? publication_type : "";
			var params = {
					"page": page_str,
					"user_id": user_id_str,
          "publication_type": publication_type_str
			};


			this.request.createRequestGet('publications_gifted', params).then((data) => {
				if (Capacitor.getPlatform() !== 'web') {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}else{
				try {
					// Ensure the data is in the correct format for web
				   // Ensure the data is in the correct format for web
				   if (typeof data === 'string') {
					   let response = JSON.parse(data);
					   console.log('json result publication service.ts', response);
					   resolve(response);
					 } else {
					   resolve(data);
					 }
					   }catch(e){
						   reject(e)
					   }
			}
			}).catch((error) => {
				reject(error);
			})
		});
	}


	public getAllPaidPublications(page?: number, user_id?: number, publication_type?: string): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let user_id_str = (user_id) ? user_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let publication_type_str = (publication_type) ? publication_type : "";
			var params = {
					"page": page_str,
					"user_id": user_id_str,
          "publication_type": publication_type_str
			};


			this.request.createRequestGet('publications_paid', params).then((data) => {
				if (Capacitor.getPlatform() !== 'web') {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}else{
				try {
					// Ensure the data is in the correct format for web
				   // Ensure the data is in the correct format for web
				   if (typeof data === 'string') {
					   let response = JSON.parse(data);
					   console.log('json result publication service.ts', response);
					   resolve(response);
					 } else {
					   resolve(data);
					 }
					   }catch(e){
						   reject(e)
					   }
			}
			}).catch((error) => {
				reject(error);
			})
		});
	}
	public getAllAIPublications(page?: number, user_id?: number, publication_type?: string): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let user_id_str = (user_id) ? user_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let publication_type_str = (publication_type) ? publication_type : "";
			var params = {
					"page": page_str,
					"user_id": user_id_str,
          "publication_type": publication_type_str
			};


			this.request.createRequestGet('publications_AI', params).then((data) => {
				if (Capacitor.getPlatform() !== 'web') {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}else{
				try {
					// Ensure the data is in the correct format for web
				   // Ensure the data is in the correct format for web
				   if (typeof data === 'string') {
					   let response = JSON.parse(data);
					   console.log('json result publication service.ts', response);
					   resolve(response);
					 } else {
					   resolve(data);
					 }
					   }catch(e){
						   reject(e)
					   }
			}
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public getUniversalLinkPublications(page?: number, publication_id?: number, publication_type?: string): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let publication_id_str = (publication_id) ? publication_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let publication_type_str = (publication_type) ? publication_type : "";
			var params = {
					"page": page_str,
					"id": publication_id_str,
          "publication_type": publication_type_str
			};


			this.request.createRequestGet('publications_UL', params).then((data) => {
				if (Capacitor.getPlatform() !== 'web') {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}else{
				try {
					// Ensure the data is in the correct format for web
				   // Ensure the data is in the correct format for web
				   if (typeof data === 'string') {
					   let response = JSON.parse(data);
					   console.log('json result publication service.ts', response);
					   resolve(response);
					 } else {
					   resolve(data);
					 }
					   }catch(e){
						   reject(e)
					   }
			}
			}).catch((error) => {
				reject(error);
			})
		});
	}
	public getProfilePublications(page?: number, profile_id?: number, publication_type?: string,  pubSection?:any): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let profile_id_str = (profile_id) ? profile_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let publication_type_str = (publication_type) ? publication_type : "";
	  let pubSection_str = (pubSection) ? pubSection : "";
			var params = {
					"page": page_str,
					"user_id": profile_id_str,
          "publication_type": publication_type_str,
		  "pubSection": pubSection_str
			};


			this.request.createRequestGet('publications_Profiles', params).then((data) => {
				if (Capacitor.getPlatform() !== 'web') {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}else {
				try {
			 // Ensure the data is in the correct format for web
			// Ensure the data is in the correct format for web
			if (typeof data === 'string') {
				let response = JSON.parse(data);
				console.log('json result publication service.ts', response);
				resolve(response);
			  } else {
				resolve(data);
			  }
				}catch(e){
					reject(e)
				}
				}
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public getAllPlaylistPublications(page?: number, user_id?: number, playlist?: string): Promise<Observable<RequestResponse>> {
		return new Promise((resolve, reject) => {
			//Obtiene todas las publicaciones para mostrar en myFeed,
			//Al enviar user_id se obtienen solo publicaciones de ese usuario
			let page_str = (page) ? page.toString() : "";
			//page? page_str = page.toString(): page_str = "";
			let user_id_str = (user_id) ? user_id.toString() : "";
			//user_id? user_id_str= user_id.toString(): user_id_str = "";
      let playlist_str = (playlist) ? playlist : "";
			var params = {
					"page": page_str,
					"user_id": user_id_str,
          "playlist": playlist_str
			};


			this.request.createRequestGet('publications_from_playlist', params).then((data) => {
				if (Capacitor.getPlatform() !== 'web') {
				try {
					let json = JSON.parse(data);
					if(json){
            console.log('json result publication service.ts', json);
						resolve(json);
					}
				}catch(e){
					reject(e)
				}
			}else{
				try {
					// Ensure the data is in the correct format for web
				   // Ensure the data is in the correct format for web
				   if (typeof data === 'string') {
					   let response = JSON.parse(data);
					   console.log('json result publication service.ts', response);
					   resolve(response);
					 } else {
					   resolve(data);
					 }
					   }catch(e){
						   reject(e)
					   }
			}
			}).catch((error) => {
				reject(error);
			})
		});
	}

}
