import { Component, OnInit,Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { PasswordComponent } from '../password/password.component';
import { ProfileService } from 'src/app/services/profile/profile.service';
import Swal2 from "sweetalert2";
import {RequestService} from "../../../services/request/request.service";
import { Plugins } from '@capacitor/core';
const { LocalNotifications } = Plugins;
import {ForgotPassword} from 'src/app/components/email-confirm/OlvidoContraseña/ForgotPassword';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-seguridad',
  templateUrl: './seguridad.component.html',
  styleUrls: ['./seguridad.component.scss'],
})
export class SeguridadComponent implements OnInit {
	configuring_FaceID:boolean=false;
	@Input() face_id:boolean=false;
	@Input() login:boolean=false;
	@Input() email_login;
	face_id_image;
	face_id_url:any='https://faceid.laniakea.tv/?username=_';
  pr:any
  phonenumber;
  constructor(public modalController: ModalController, private profile:ProfileService, private request: RequestService, private navCtrl: NavController,
	public formService: FormServiceService, private sanitizer: DomSanitizer,) { }
private profile_id:any;
private email: any;
  ngOnInit() { 
	  if(!this.login){
		this.getProfile();
	  }

	window.addEventListener('message', this.handleMessage.bind(this));
	if(!this.login){
	this.request.createRequestPost('insert_face_identifaction',{get: true, }) .then((data) => {
		console.log(data);
	
	if(data.image !== null){
		this.face_id_image=data.image;
		this.face_id_url='https://faceid.laniakea.tv/?username=_&source_image_url='+data.image;
	}else{
		this.face_id_image='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/1309534.png';
	}
	this.face_id_url=this.sanitizer.bypassSecurityTrustResourceUrl(this.face_id_url);
	if(data.success=='active'){
		this.formService.face_id_active=true;
	}else{
		this.formService.face_id_active=false;
	}
	console.log('face_id_image',this.face_id_image);
	}).catch((error) => {
		console.error('Failed to send request:', error);
	});

}else{
	this.request.createRequestPost('get_face_identifaction',{get: true, email:this.email_login }) .then((data) => {
		console.log(data);
	
	if(data.image !== null){
		this.face_id_image=data.image;
		this.face_id_url='https://faceid.laniakea.tv/?username=_&source_image_url='+data.image;
	}else{
		this.face_id_image='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/1309534.png';
	}
	this.face_id_url=this.sanitizer.bypassSecurityTrustResourceUrl(this.face_id_url);
	if(data.success=='active'){
		this.formService.face_id_active=true;
	}else{
		this.formService.face_id_active=false;
	}
	console.log('face_id_image',this.face_id_image);
	}).catch((error) => {
		console.error('Failed to send request:', error);
	});
}
  }
  ngOnDestroy(){

	window.removeEventListener('message', this.handleMessage.bind(this));
  }
  CHANGE_PIN(){
	Swal2.fire({
		title: 'Corro de confirmación',
		text: `Se enviará token al correo ${this.email}.  `,
		icon: 'info',
		showCancelButton: true,
		confirmButtonText: 'Confirmar',
		cancelButtonText: 'Cancelar',
		color: '#ffffff',
		background: '#010f4e',
	  }).then((result) => {
		if (result.isConfirmed) {
		  // Call your desired function here
		  this.requestemail_for_pin();
		}
	  });

  }
  requestemail_for_pin(){
	console.log('email', this.email);
	let data = {
	  email: this.email,
	};
	this.request.createRequestPost('send-email-to-reset-password', { email: this.email })
	  .then(response => {
		console.log('response', response);

		console.log('response code', response.code);
			
	  console.log('req made');
		if (response.code === 200) {
			console.log('resp 200');
/*
		  const notificationTime = new Date();
			  notificationTime.setMinutes(notificationTime.getMinutes() + 6);
	  
			  LocalNotifications.schedule({
				notifications: [{
				  title: 'PIN actualizado con éxito',
				  body: '¡Felicitaciones! Ahora puedes acceder a Hubble',
				  id: 1,
				  schedule: { at: notificationTime },
				  sound: null,
				  attachments: null,
				  actionTypeId: '',
				  extra: null,
				  channelId: null,
				}],
			  });*/
	  
			  Swal2.fire({
				title: 'Correo Electrónico con PIN',
				text: `PIN de correo enviado a ${this.email}. Ese PIN permanecerá activo hasta que envíes un siguiemnte PIN. Solo un PIN puede estar activo a la vez.`,
				icon: 'success',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  }).then(() => {
				console.log("Alert displayed successfully.");
			  }).catch((error) => {
				console.error("Error displaying alert:", error);
			  });
			
			
  } 
}
)
		.catch(error => {
			console.error('Error from API call:', error);  // Log error from API call
			console.error("Error:", error);
			Swal2.fire({
			  title: 'Confirmación de correo no procesada',
			  text: `Confirmación no enviada a la cuenta de tu correo ${this.email}`,
			  icon: 'error',
			  confirmButtonText: 'Entendido',
			  color: '#ffffff',
			  background: '#010f4e',
			}).then(() => {
			  console.log("Alert displayed successfully.");
			}).catch((error) => {
			  console.error("Error displaying alert:", error);
			});
			
		});
		
			
  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  getProfile() {
		this.profile.getProfileInfo().then((data) => {
			data = JSON.parse(data);
			// this.profile_photo = data.profile_photo.route;
      this.profile_id = data.id;
		
      this.email =data.email;
   
      
		}).catch((error) => {
			console.error(error);
		}).finally(() => {

		});
	}

  password() {
	Swal2.fire({
		title: 'Corro de confirmación',
		text: `Se enviará token al correo ${this.email}. 
		Tiene 1 minuto para ingresar el token . `,
		icon: 'info',
		showCancelButton: true,
		confirmButtonText: 'Confirmar',
		cancelButtonText: 'Cancelar',
		color: '#ffffff',
		background: '#010f4e',
	  }).then((result) => {
		if (result.isConfirmed) {
		  // Call your desired function here
		  this.requestemail();
		}
	  });

  
  }

  requestemail(){
	console.log('email', this.email);
	let data = {
	  email: this.email,
	};
	this.request.createRequestPost('send-email-to-reset-password', { email: this.email })
	  .then(response => {
		console.log('response', response);
		const responseData = JSON.parse(response);
		console.log('response code', responseData.code);
			
	  console.log('req made');
		if (responseData.code === 200) {
			console.log('resp 200');
	this.modalController.create({
	  component: ForgotPassword,
	  cssClass: 'detailsInModal',
	  swipeToClose: true,
	  componentProps: {
		email: this.email,
		id: this.pr.id,
	  },
	}).then((modal)=>{
	  modal.present();
	});
		  const notificationTime = new Date();
			  notificationTime.setMinutes(notificationTime.getMinutes() + 6);
	  
			  LocalNotifications.schedule({
				notifications: [{
				  title: 'Contraseña actualizada con éxito',
				  body: '¡Felicitaciones! Ahora puedes acceder a tu perfil',
				  id: 1,
				  schedule: { at: notificationTime },
				  sound: null,
				  attachments: null,
				  actionTypeId: '',
				  extra: null,
				  channelId: null,
				}],
			  });
	  
			  Swal2.fire({
				title: 'Confirmación de Correo Electrónico',
				text: `Confirmación de correo enviada a ${this.email}`,
				icon: 'success',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
				background: '#010f4e',
			  }).then(() => {
				console.log("Alert displayed successfully.");
			  }).catch((error) => {
				console.error("Error displaying alert:", error);
			  });
			
			
  } 
}
)
		.catch(error => {
			console.error('Error from API call:', error);  // Log error from API call
			console.error("Error:", error);
			Swal2.fire({
			  title: 'Confirmación de correo no procesada',
			  text: `Confirmación no enviada a la cuenta de tu correo ${this.email}`,
			  icon: 'error',
			  confirmButtonText: 'Entendido',
			  color: '#ffffff',
			  background: '#010f4e',
			}).then(() => {
			  console.log("Alert displayed successfully.");
			}).catch((error) => {
			  console.error("Error displaying alert:", error);
			});
			
		});
		
			
		  
		} 

		VerUsuariosBloqueados(){

			this.dismiss();
			this.navCtrl.navigateForward('search');
			this.dismiss();
			setTimeout(() => {
				this.dismiss();
			  }, 999);
			
		}
		face_id_toggled($event){
			let status;
			setTimeout(() => {
			if(this.formService.face_id_active){
				status='active';
			}else{
				status='unactive';
			}
			this.request.createRequestPost('insert_face_identifaction',{status: status, }) .then((data) => {
				console.log(data);
			}).catch((error) => {
				console.error('Failed to send request:', error);
			});
		}, 99);
		}

		FacialID(){
			this.request.createRequestPost('AuthSMSCodeVerification',{SendSMS: true, }) .then((data) => {
				console.log(data);
				this.phonenumber=data.phone;
				Swal2.fire({
					title: 'Envío de SMS exitoso.',
					text: 'Se ha enviado un SMS para confirmar la configuración.',
					icon: 'success',
					input: 'text', // Enable text input field
					timer: 300000, // Show the alert for 300 seconds
					timerProgressBar: true,
					inputPlaceholder: 'Ingrese el código alfanumérico',
					showCancelButton: true,
					confirmButtonText: 'Confirmar',
					cancelButtonText: 'Cancelar',
					inputValidator: (value) => {
						if (!value) {
							return '¡Debes ingresar un código!';
						}
						if (!/^[a-zA-Z0-9]+$/.test(value)) {
							return '¡Solo se permiten caracteres alfanuméricos!';
						}
					}
				}).then((result) => {
					if (result.isConfirmed) {
						const code = result.value;
						console.log('Código ingresado:', code);
						// Handle the alphanumeric code here
						this.request.createRequestPost('SMSCodeVerification',{ValidateCode: true, token: code, phone_number:this.phonenumber}) .then((data) => {
							console.log(data);
							if(data.code=== 200){
								this.face_id=true;
			this.formService.face_id_active=true;
			this.configuring_FaceID=true;
								Swal2.fire({
								title: 'Autenticación exitosa',
								text: '',
								icon: 'success',
								timer: 3900, // Show the alert for 3.9 seconds
								timerProgressBar: true,
								toast: true,
								position: 'top-end',
								showConfirmButton: false
							  });
							}else{
								Swal2.fire({
									title: 'Autenticación fallida',
									text: '',
									icon: 'error',
									timer: 3900, // Show the alert for 3.9 seconds
									timerProgressBar: true,
									toast: true,
									position: 'top-end',
									showConfirmButton: false
								  });
							}
						}).catch((error) => {
							Swal2.fire({
								title: 'Autenticación fallida',
								text: '',
								icon: 'error',
								timer: 3900, // Show the alert for 3.9 seconds
								timerProgressBar: true,
								toast: true,
								position: 'top-end',
								showConfirmButton: false
							  });
							console.error('Failed to send request:', error);
						});
					}
				});
				
			}).catch((error) => {
				Swal2.fire({
					title: 'Autenticación fallida',
					text: '',
					icon: 'error',
					timer: 3900, // Show the alert for 3.9 seconds
					timerProgressBar: true,
					toast: true,
					position: 'top-end',
					showConfirmButton: false
				  });
				console.error('Failed to send request:', error);
			});
			
		}
		handleMessage(event: MessageEvent) {
			// Validate the origin of the message

			if (event.origin !== 'https://faceid.laniakea.tv') {
				console.warn('Invalid origin:', event.origin);
				return;
			  }
			// Process the data received from the iframe
			const data = event.data;
			console.log('Received data from iframe:', data);
			if(data.type==='image/png'){

			
					   const ruta = 'https://face-identifiaction-records.s3.ap-northeast-1.amazonaws.com/'+data.filename;
						 console.log('ruta', ruta);
						 let source_image
			if(this.configuring_FaceID){
				 source_image ='source_image';
			}else{
				source_image ='target_image';
			}
			setTimeout(() => {
	
					   this.request.createRequestPost('insert_face_identifaction',{status: source_image, url: ruta}) .then((data) => {
						   console.log(data);
					   }).catch((error) => {
						   console.error('Failed to send request:', error);
					   });
				
			
					}, 9000);
			}
			else if(data.Status==="SUCCEEDED"){
				this.face_id=false;
				Swal2.fire({
					title: 'Autenticación exitosa',
					text: '',
					icon: 'success',
					timer: 3900, // Show the alert for 3.9 seconds
					timerProgressBar: true,
					toast: true,
					position: 'top-end',
					showConfirmButton: false
				  });
				  this.modalController.dismiss({
					role: 'success',
					data: { hello: true } // Your output data here
				  }).catch((error) => {
					console.error('Error dismissing modal:', error);
				  });
			}else{
				Swal2.fire({
					title: 'Autenticación procesandose...',
					text: '',
					icon: 'warning',
					timer: 3900, // Show the alert for 3.9 seconds
					timerProgressBar: true,
					toast: true,
					position: 'top-end',
					showConfirmButton: false
				  });
				  return;
			}
	
	
	
		
			// You can perform additional logic here
		  }


}
