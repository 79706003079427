import { Component, OnInit,Input } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Bienes6Component } from 'src/app/components/publication/create/bienes/bienes6/bienes6.component';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-alibaba-checkout-cart',
  templateUrl: './alibaba-checkout-cart.component.html',
  styleUrls: ['./alibaba-checkout-cart.component.scss'],
})

export class AlibabaCheckoutCartComponent implements OnInit {
  shippingOptions = [
  
  ];
  isExpanded = false; // Initially, details are hidden
  tax;
  selectionOption;
  name?: string;
  postal_code?: string;
  calle?: string;
  Ciudad?: string;
  Estado?: string;
  country?: string;
  mail?: string;
  phone_country?: string;
  mobile_no?: string;
  address;
  @Input() cart: any[] = [];
  TotalPayment =0;
  shipping_cost:number=0;
  @Input() product_name;
  metodoPagoSeleccionado='tarjeta';
  constructor(   
    private formservice:FormServiceService,
    private request: RequestService,
    private modalController: ModalController,
    private router:Router,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
   
    this.request.createRequestPost('ShippingAddressesController', {get:true })
    .then((data) => {
      console.log('fetching address',data)
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      }
      console.log('ShippingAddressesController',data);
     this.address=data.title;
     this.name=data.title.name;
     this.postal_code=data.title.postal_code;
     this.calle=data.title.calle;
     this.Ciudad=data.title.Ciudad;
     this.Estado=data.title.Estado;
     this.country=data.title.country;
     this.mail=data.title.mail;
     this.phone_country=data.title.phone_country;
     
     this.mobile_no=data.title.mobile_no;
  
    });
     this.cart.forEach((item) => {
      console.log('fetching freight')
      this.request.createRequestPost('AlibabaProductHandler', { 
        
        freight_query: true, 
        cart: item ,// Send one item at a time
        cityCode:this.Ciudad,
        provinceCode:this.Estado

      }).then((data) => {
        if (data && typeof data === 'string') {
          try {
            data = JSON.parse(data);
          } catch (error) {
            console.error("Error parsing data:", error);
          }
        }
        console.log('Response for item:', item, data       );
        this.shippingOptions=data?.success?.aliexpress_ds_freight_query_response?.result?.delivery_options?.delivery_option_d_t_o  ;
      }).catch((error) => {
        console.error('Error for item:', item, error);
      });
    });

  }
  ngAfterViewInit(){
    console.log('cart', this.cart);
    if(this.cart?.length ==0){
      this.cart=this.formservice.AlibabaCheckoutCart;
      this.request.createRequestPost('ControladorDeCarritoDeCompras', {update_cart:true,add_items:true, cart_items: this.cart})
      .then((data) => {
        if (data && typeof data === 'string') {
          try {
            data = JSON.parse(data);
          } catch (error) {
            console.error("Error parsing data:", error);
          }
        }
        console.log('data',data);
  
     
      });
    }else{
    //nothing as they just want to buy a product inmediatley
    }

    
    

  }
  // Calculate the total price of the cart
  calculateTotal(): number {
     this.TotalPayment= this.cart.reduce(
      (total, item) => total + item.price * item.selectedQuantity,
      0
    );
    this.tax =this.TotalPayment + this.shipping_cost;
    this.tax=Math.round(this.tax *  this.formservice.DS_fee);



    this.TotalPayment= Math.round(this.TotalPayment+this.shipping_cost +this.tax );

    return this.TotalPayment;
  }

  // Checkout logic
  checkout(): void {
    if(!this?.name || !this.postal_code || !this.calle || !this.Ciudad || !this.Estado || !this.country){
      Swal2.fire({
        title: 'Error',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
        text: 'Ocurrió un problema al procesar tu solicitud. Por favor, añade una dirección de envío.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    if(!this.selectionOption){
      Swal2.fire({
        title: 'Error',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
        text: 'Ocurrió un problema al procesar tu solicitud. Por favor, selecciona una opción de envío.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    if(!this.mobile_no){
      Swal2.fire({
        title: 'Error',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
        text: 'Ocurrió un problema al procesar tu solicitud. Por favor, añade un número de celular en "Añadir nueva dirección".',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    this.seleccionarMetodoPago();
    /*
    console.log('Checkout:', this.cart);
    this.request.createRequestPost('AlibabaProductHandler', { create_order_and_pay: true, cart: this.cart })
    .then((data) => {
      console.log('data', data);
      Swal2.fire({
        title: 'Compra realizada con éxito',
        text: 'Tu orden ha sido creada y procesada correctamente. Gracias por tu compra.',
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    })
    .catch((error) => {
      console.error('Error occurred:', error);
      Swal2.fire({
        title: 'Error',
        text: 'Ocurrió un problema al procesar tu solicitud. Por favor, intenta de nuevo más tarde.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    });
    */
  
    // Logic to handle checkout process, e.g., API call or navigation
  }
  removeItem(item){
    this.request.createRequestPost('ControladorDeCarritoDeCompras', {update_cart:true,delete_items:true, item: item})
    .then((data) => {
      console.log('data',data);
      this.request.createRequestPost('ControladorDeCarritoDeCompras', {update_cart:true,get_items:true, })
      .then((data) => {
        if (data && typeof data === 'string') {
          try {
            data = JSON.parse(data);
            console.log('getRecordByToken ios:',data);
          } catch (error) {
            console.error("Error parsing data:", error);
          }
          }
        console.log('data',data);
        this.cart=data.cart_items;
        this.formservice.AlibabaCheckoutCart=this.cart;
  
     
      });
   
    });
  }
  toggleAddressDetails() {
    this.isExpanded = !this.isExpanded;
  }
  seleccionarMetodoPago(){
    this.router.navigate(['/home/ecosystem-page']);
    setTimeout(() => {
      let address ={
        name:this.name,
        postal_code:this.postal_code,
        calle:this.calle,
        Ciudad:this.Ciudad,
        Estado:this.Estado,
        country:this.country,
        mail:this.mail,
        phone_country:this.phone_country,
        mobile_no:this.mobile_no

      }
      // Extract product_id from the first item in the array
let product_id = this.cart[0].product_id;
console.log('product_id', product_id);

console.log('product_id', product_id); // Outputs the product_id if available
      let objmedia = {
        publication_type:'Alibaba',
        address:address,
        item:this.cart,
        costo_total:this.TotalPayment,
        costo_de_envio:this.shipping_cost,
        product_id:product_id // used for Payment Controller web hooks only
      }
    this.modalController.create({
      component: WalletAppComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps: {
        shortcut:'pagoDePublicacion',
       // publication_id:this.public.id,
        objmedia:objmedia,
      //profile_id: this.profile_id,

      TotalPayment: this.TotalPayment,
  
      },
    }).then((modal) => {
      
      modal.present();
     
    });
  }, 999);
  }
  onShippingOptionChange(selectedCode: string) {
    const selectedOption = this.shippingOptions.find(option => option.code === selectedCode);
    if (selectedOption) {
      this.shipping_cost = Number(selectedOption.shipping_fee_cent ?? 0) ?? 0; 
    }
  }
  dismiss(){
    this.modalController.dismiss();
  }
  async handleShippingAddressClick() {
    const popover = await this.popoverController.create({
      component: Bienes6Component,
      componentProps: {
        show_save_address_button: true,
      },
      translucent: true, // Optional: makes the popover semi-transparent
      cssClass: 'my-custom-popover', // Optional: custom styling class
    });
  
    await popover.present();
  
    // Listen for popover dismissal
    const { data } = await popover.onDidDismiss();

    this.name=this.formservice?.feexpublication?.name;
    this.postal_code=this.formservice.feexpublication.postal_code;
    this.calle=this.formservice.feexpublication.calle;
    this.Ciudad=this.formservice.feexpublication.Ciudad;
    this.Estado=this.formservice.feexpublication.Estado;
    this.country=this.formservice.feexpublication.country;
    this.mail=this.formservice.feexpublication.mail;
    this.phone_country=this.formservice.feexpublication.lada;
    this.mobile_no=this.formservice.feexpublication.celular;
    let address ={
      name:this.name,
      postal_code:this.postal_code,
      calle:this.calle,
      Ciudad:this.Ciudad,
      Estado:this.Estado,
      country:this.country,
      mail:this.mail,
      phone_country:this.phone_country,
      mobile_no:this.mobile_no

    }
    this.address=address;
    console.log(' .address', this.address)
    // Handle the dismissal event
    if (data) {
      console.log('Popover dismissed with data:', data);
      // Add your logic here to handle the data returned from the popover


     
    } else {
      console.log('Popover dismissed without data');
    }
  }
  

}
