import { Component, OnInit, Input } from '@angular/core';
import { ModalController, IonToggle } from '@ionic/angular';
import { CuentaComponent } from '../cuenta/cuenta.component';
import { PrivacidadComponent } from '../privacidad/privacidad.component';
import { SeguridadComponent } from '../seguridad/seguridad.component';
import { PublicacionesColeccionComponent } from 'src/app/components/publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { ViewChild } from '@angular/core';
import Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss'],
})
export class ConfiguracionComponent implements OnInit {
@Input() profile_id: any;
@ViewChild('Lia_toggleElement') Lia_toggleElement: IonToggle;
  constructor(
    private modalController: ModalController,
    private formService:FormServiceService,
    private request: RequestService,
  ) { }

  ngOnInit() {
    console.log('config pr', this.profile_id);

  }
  ngAfterViewInit(){
    this.Lia_toggleElement.checked = this.formService.heyLia;
  }
  selectLanguage(event: any) {
    const selectedLang = event.detail.value;
    console.log('Selected language:', selectedLang);
    console.log('Selected language:', this.formService.selectedLiaLanguage);
    this.request.createRequestPost('handle_hey_Lia', {update:true, language: selectedLang})
		.then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
		console.log(data);
		
		if(data.success){
		
			Swal2.fire({
        title: 'Lia modificada',
        text: 'Lia se ha actualizado exitosamente.',
        icon: 'success',
        timer: 4500, // Show the alert for 45 seconds
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
       // position: 'top-end',
        showConfirmButton: false
        });
			
		}
		})
		.catch((error) => {
		
		  console.error('An error occurred:', error);
		  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
		});
    // Implement your logic to handle the selected language
    // For example, you might want to save it to the database or update the UI
  }
  cambio($event){
    console.log($event);
		this.request.createRequestPost('handle_hey_Lia', {update:true, hey_lia: this.formService.heyLia})
		.then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
		console.log(data);
		
		if(data.success){
		
			Swal2.fire({
        title: 'Lia modificada',
        text: 'Oye Lia se ha actualizado exitosamente.',
        icon: 'success',
        timer: 4500, // Show the alert for 45 seconds
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
        showConfirmButton: false
        });
			
		}
		})
		.catch((error) => {
		
		  console.error('An error occurred:', error);
		  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
		});
  }
  InfoUser(App:String){
    switch(App ){
      case "Privacidad":
      this.modalController.create({
        component: PrivacidadComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
      }).then((modal) => {
        modal.present();
      }).catch((error) => {
        console.error(error);
      });
      break;
      case "Seguridad":
        this.modalController.create({
          component: SeguridadComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
        }).then((modal) => {
          modal.present();
        }).catch((error) => {
          console.error(error);
        });
        break;
        case "Cuenta":
          this.modalController.create({
            component:CuentaComponent,
            cssClass: 'detailsInModal',
            swipeToClose: true,
          }).then((modal) => {
            modal.present();
          }).catch((error) => {
            console.error(error);
          });
          break;
        case "Regalos":
        this.formService.sectionVer="regalos"  
        this.modalController.create({
          component:PublicacionesColeccionComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            profile_id: this.profile_id,
          }
        }).then((modal) => {
          modal.present();
        }).catch((error) => {
          console.error(error);
        });
        break;

    }
  }
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
