import { Injectable } from '@angular/core';
import { MediaCapture, MediaFile } from '@ionic-native/media-capture/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {input, Storage} from 'aws-amplify';
import { MultimediaService } from './multimedia.service';
import { ActionSheetController, ModalController, Platform, ToastController } from '@ionic/angular';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { MultimediaInChatService } from '../chat/multimedia-in-chat.service';
import { MultimediaViewComponent } from 'src/app/components/publication/single-elements/multimedia-view/multimedia-view.component';
import { Filesystem } from '@capacitor/filesystem';
import Swal2 from 'sweetalert2';



@Injectable({
	providedIn: 'root'
})

export class CameraServiceService {

	toast: any; //Variable para no encimar mensajes
	allowEdit: boolean=false;

	constructor(
		private camera: Camera,
		private mediaCapture: MediaCapture,
		private multimediaService: MultimediaService,
		private modalController: ModalController,
		private toastController: ToastController,
		private platform: Platform,
		private localNotification: LocalNotifications,
		private actionSheetController: ActionSheetController,
		private chatElementService: MultimediaInChatService
	) { }

	takePhoto(): Promise<MediaItem> {
		return new Promise((resolve, reject) => {
			const options: CameraOptions = {
				quality: 50,
				destinationType: this.camera.DestinationType.FILE_URI,
				encodingType: this.camera.EncodingType.JPEG,
				mediaType: this.camera.MediaType.PICTURE,
				correctOrientation: true

			};

			this.camera.getPicture(options).then((imagePath) => {
        console.log('image pathe', imagePath);
				const name = imagePath.substr(imagePath.lastIndexOf('/') + 1);
				const mediaItem = new MediaItem({
					type: 'image/' + name.substr(name.lastIndexOf('.') + 1),
					mediaPath: imagePath.substr(0, imagePath.lastIndexOf('/') + 1),
					name
				});
				resolve(mediaItem);
			}, (error) => {
				reject(error);
			});
		});
	}

	CargarMedia(): Promise<MediaItem> {
		return new Promise((resolve, reject) => {
			const options: CameraOptions = {
				quality: 80,
				sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
				destinationType: this.camera.DestinationType.FILE_URI,
				mediaType: this.camera.MediaType.PICTURE,
				correctOrientation: true,
			};
	
			this.camera.getPicture(options).then((imagePath) => {
				this.convertUriToBlob(imagePath).then((blob) => {
					const mediaItem = new MediaItem({
						mediaPath: imagePath,
						data: blob
					});
	
					resolve(mediaItem);
				}).catch((error) => {
					reject(error);
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	CargarMediaVideo(): Promise<MediaItem> {
		return new Promise((resolve, reject) => {
			const options: CameraOptions = {
				quality: 80,
				sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
				destinationType: this.camera.DestinationType.FILE_URI,
				mediaType: this.camera.MediaType.VIDEO,
				correctOrientation: true,
			};
	
			this.camera.getPicture(options).then((imagePath) => {
				this.convertUriToBlob(imagePath).then((blob) => {
					const mediaItem = new MediaItem({
						mediaPath: imagePath,
						data: blob
					});
	
					resolve(mediaItem);
				}).catch((error) => {
					reject(error);
				});
			}, (error) => {
				reject(error);
			});
		});
	}

	convertUriToBlob(uri: string): Promise<Blob> {
		return new Promise((resolve, reject) => {
			fetch(uri)
				.then((response) => {
					if (response.ok) {
						return response.blob();
					} else {
						throw new Error('Failed to fetch URI');
					}
				})
				.then((blob) => {
					resolve(blob);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	pickMedia(specificMedia?: string): Promise<MediaItem> {
		return new Promise((resolve, reject) => {
			let selectedMedia = this.camera.MediaType.ALLMEDIA;
			if (specificMedia) {
				if (specificMedia == 'Video') {
					selectedMedia = this.camera.MediaType.VIDEO;
				}
				else if (specificMedia == 'Imagen') {
					selectedMedia = this.camera.MediaType.PICTURE;
				}
			}
			const options = {
				quality: 50,
				destinationType: this.camera.DestinationType.FILE_URI,
				sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
				mediaType: selectedMedia
			};
			this.camera.getPicture(options).then((mediaPath) => {
				mediaPath = mediaPath.includes('file://') ? mediaPath : 'file://' + mediaPath;
				mediaPath = this.tryToFixPath(mediaPath);

				const type = this.getMediaType(mediaPath);
				if (type != null) {
					const name = mediaPath.substr(mediaPath.lastIndexOf('/') + 1);
					const mediaItem = new MediaItem({
						type,
						mediaPath: mediaPath.substr(0, mediaPath.lastIndexOf('/') + 1),
						name
					});
					resolve(mediaItem);
				}
				else {
					this.presentToast('El archivo seleccionado aun no es compatible con Laniakea');
					reject('No compatible, PATH: ' + mediaPath);
				}

			}).catch((error) => {
				reject(error);
			});
		});

	}

	takeVideo() {
		return new Promise<MediaItem>((resolve, reject) => {
			const options = {
				limit: 1,
				quality: 10,
				duration: 30
			};

			this.mediaCapture.captureVideo(options).then((data: MediaFile[]) => {
				const name = data[0].name;
				const mediaItem = new MediaItem({
					size: this.bytesToSize(data[0].size),
					type: 'video/' + name.substr(name.lastIndexOf('.') + 1),
					mediaPath: data[0].fullPath.substr(0, data[0].fullPath.lastIndexOf('/') + 1),
					name: data[0].name
				});
				resolve(mediaItem);
			}).catch((error) => {
				reject(error);
			});
		});

	}


	bytesToSize(bytes) {
		if (bytes == 0) {return 0;}
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
		return bytes / Math.pow(1024, i);
	}

	
	upload_to_s3(mediaItem: MediaItem, idNotification: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const foo = this;
			const customPrefix = {
				public: 'public/',
				protected: 'protected/',
				private: 'private/'
			};
	
			
			let blob;
			if(mediaItem.blob){
blob=mediaItem.blob;
			}else{// Convert base64 data to Blob
			const byteCharacters = atob(mediaItem.mediaPath);
			const byteArrays = [];
			for (let offset = 0; offset < byteCharacters.length; offset += 512) {
				const slice = byteCharacters.slice(offset, offset + 512);
				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}
			 blob = new Blob(byteArrays, { type: mediaItem.type });
		}
			// Update mediaItem size
			mediaItem.size = blob.size;
	
			Storage.put(
				mediaItem.nameFile,
				blob,
				{
					progressCallback(progress) {
						if (foo.platform.is('android') || foo.platform.is('ios')) {
							foo.localNotification.update({
								id: idNotification,
								progressBar: { value: ((progress.loaded * 100) / progress.total) },
							});
						}
						if (progress.loaded == mediaItem.size) {
							if (foo.platform.is('android') || foo.platform.is('ios')) {
								foo.localNotification.update({
									id: idNotification,
									progressBar: { value: 100 },
								});
							}
							resolve(true);
						}
					},
					contentType: mediaItem.type,
					customPrefix  // For Customize path
				}
			).catch((error) => {
				reject(error);
			});
		});
	}
	

	postArrayMultimedia(multimedia: Array<MediaItem>, publication_ids: Array<number>, endPoint: string, publication_type?: string) {
		return new Promise((resolve, reject) => {
			if (this.platform.is('android') || this.platform.is('ios')) {
				this.localNotification.schedule({
					title: 'Subiendo multimedia al servidor',
					text: 'No cierres la app hasta que esta notificación desaparezca',
					foreground: true,
				});
			}

			this.presentToastWithOptions('Subiendo multimedia al servidor', 'No cierres la app hasta que esta una nueva notificación aparezca');

			let idNotification = 0;
			Promise.all(multimedia.map((media) => {
					idNotification++;
					return this.postSingleMedia(media, publication_ids, endPoint, idNotification, publication_type);
				})
			).then((result) => {
				if (this.platform.is('android') || this.platform.is('ios')) {
					this.localNotification.cancelAll();
				}
				this.presentToastWithOptions();
        console.log('result after post single media', result);
				resolve(result);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	postSingleMedia(media: MediaItem, publication_ids: Array<number>, endPoint: string, idNotification: number, publication_type?: string) {
		return new Promise((resolve, reject) => {
			const dataToSend: any = {
				route: 'laniakea-multimedia/' + media.nameFile,
				size: media.size/1024,
				type: (media.typeData == 'video/mp4') ? 'Video' : 'Imagen',
				publication_type: (publication_type) ? publication_type : null,
			};

			if (publication_ids.length > 1) {
				dataToSend.state_id = publication_ids[1];
			}
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.localNotification.schedule({
          title: (media.typeData == 'video/mp4') ? 'Subiendo Video' : 'Subiendo Foto',
          text: 'Subiendo el archivo: ' + media.nameFile,
          foreground: true,
          progressBar: { value: 0 },
          id: idNotification
        });
      }
      this.upload_to_s3(media, idNotification).then((result_s3) => {
        if(result_s3) {
          resolve({route: 'https://laniakea-multimedia/public/'+media.nameFile});
        }else {
          resolve(false);
        }
      }).catch((error) => {
        reject(error);
      });
		});
	}

	postMultimediaForChat(media: MediaItem, element_type: string, graph_ql_id?: string) {
		return new Promise((resolve, reject) => {
			if (this.platform.is('android') || this.platform.is('ios')) {
				this.localNotification.schedule({
					title: 'Subiendo multimedia al servidor',
					text: 'No cierres la app hasta que esta notificación desaparezca',
					foreground: true,
				});
			}

			this.presentToastWithOptions('Subiendo multimedia al servidor', 'No cierres la app hasta que esta una nueva notificación aparezca');

			let dataToSend;
			if (graph_ql_id) {
				dataToSend = {
					route: 'public/' + media.nameFile,
					size: media.size,
					type: (media.typeData == 'video/mp4') ? 'Video' : 'Imagen',
					element_type,
					graph_ql_id
				};
			}
			else {
				dataToSend = {
					route: 'public/' + media.nameFile,
					size: media.size,
					type: (media.typeData == 'video/mp4') ? 'Video' : 'Imagen',

					element_type
				};
			}

			this.chatElementService.postChatElement(dataToSend).then((data: any) => {
				const chatElement_id = JSON.parse(data).chat_element_id;

				if (this.platform.is('android') || this.platform.is('ios')) {
					this.localNotification.schedule({
						title: (media.typeData == 'video/mp4') ? 'Subiendo Video' : 'Subiendo Foto',
						text: 'Subiendo el archivo: ' + media.nameFile,
						foreground: true,
						progressBar: { value: 0 },
						id: chatElement_id
					});
				}
				this.upload_to_s3(media, chatElement_id).then((result_s3) => {
					const status = { status: 'activa' };
					this.chatElementService.putChatElement(status, chatElement_id).then((result) => {
						if (this.platform.is('android') || this.platform.is('ios')) {
							this.localNotification.cancelAll();
						}
						this.presentToastWithOptions();

						resolve(result);
					}).catch((error) => {
						console.error(error);
						reject(error);
					});

				}).catch((error) => {
					console.error(error);
					reject(error);
				});
			}).catch((error) => {
				console.error(error);
				reject(error);
			});
		});
	}

	private tryToFixPath(path: string) {
		if (path.includes('?')) {
			const final = path.indexOf('?');
			path = path.substring(0, final);
		}
		return (path);
	}

	private getMediaType(path: string) {
		const rules = /(?:\.([^.]+))?$/;
		let fileExtension = rules.exec(path)[0];
		fileExtension = fileExtension.toLowerCase();

		if (fileExtension === '.jpeg' || fileExtension === '.jpg' || fileExtension === '.png' || fileExtension === '.gif') {
			return 'image/jpg';
		}
		else if (fileExtension === '.mp4') {
			return 'video/mp4';
		}
		return null;
	}

	get_Random_Name() {
		const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
		let random = '';
		const mediaName = Math.floor((new Date().getTime() / 1000)).toString();

		for (let i = 0; i < 4; i++) {
			random += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return mediaName + random;
	}

	openMediaInModal(multimediaContainer: Array<MediaItem>) {
		this.modalController.create({
			component: MultimediaViewComponent,
			swipeToClose: true,
			componentProps: {
				multimedia: multimediaContainer
			}
		}).then((modal) => {
			modal.present();
		});

	}

	presentToastWithOptions(header?, message?) {

		try {
			this.toast.dismiss();
		} catch (e) { }

		this.toastController.create({
			header: (header) ? header : 'Completando trascendencia',
			message: (message) ? message : 'El proceso de trascender multimedia finalizó correctamente, desliza hacia abajo para refrescar',
			position: 'top',
			buttons: [
			/*{
			  side: 'start',
			  icon: 'star',
			  text: 'Favorite',
			  handler: () => {
				console.log('Favorite clicked');
			  }
			}, */{
					text: 'Ok',
					role: 'cancel',
					handler: () => {
					}
				}
			]
		}).then((toast) => {
			this.toast = toast;
			this.toast.present();
		});

	}

	presentToast(mensaje: string) {
		try {
			this.toast.dismiss();
		} catch (e) { }

		this.toastController.create({
			message: mensaje,
			duration: 2000
		}).then((toast) => {
			this.toast = toast;
			this.toast.present();
		});

	}

	askForMediCapture(take_photo: boolean = true, take_video: boolean = true, pick_media: boolean = true,pick_video: boolean = true) {
		Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {

			let botonesDisponibles = [
				(take_photo) ?
					{
						text: 'Tomar una Foto',
						icon: 'camera',
						handler: () => {
							console.log("oprimiste camara");

							this
                             .takePhoto()
                             .then((mediaItem) => {
                               resolve([mediaItem,'camara']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                               });
                             });
						}
					} : null,
				(take_video) ?
					{
						text: 'Grabar un Vídeo',
						icon: 'videocam',
						handler: () => {
							console.log("oprimiste video");
							this
                            .takeVideo()
                            .then((mediaItem) => {
                              resolve([mediaItem,'camvideo']);
                            })
                            .catch((error) => {
                              Swal2.fire({
                                title: 'Error al capturar video',
                                text: error,
                                icon: 'warning',
                                confirmButtonText: 'Entendido',
                                color: '#ffffff',
                                background: '#010f4e',
                              });
                            });
						}
					} : null,
				{
					text: 'Cancelar',
					icon: 'close',
					role: 'cancel',
					handler: () => {
					}
				}
			];

			botonesDisponibles = botonesDisponibles.filter((boton) => {
				if (boton != null) {
					return true;
				}
				return false;
			});


			this.actionSheetController.create({
				header: '¿Desde donde deseas añadir multimedia?',
				cssClass: 'options-sheet',
				buttons: botonesDisponibles
			}).then((actionSheet) => {
				actionSheet.present();
			});
		});
	}
	// Add this method to your CameraServiceService class
  
}
