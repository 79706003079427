import { Component, OnInit } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';


@Component({
  selector: 'app-bienes2',
  templateUrl: './bienes2.component.html',
  styleUrls: ['./bienes2.component.scss'],
})
export class Bienes2Component implements OnInit {

  constructor(private formservice: FormServiceService,
    private modalcontroller: ModalController) { }

  ngOnInit() { }

  addAdditional_price(event) {
    this.formservice.feexpublication.additional_price=event.target.value
  }

  addAccessories(event) {
    this.formservice.feexpublication.accessories=event.target.value
  }

  validar:boolean=true;

  cambio($event){
    this.validar=!this.validar
  
  }
}
