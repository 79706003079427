import { HttpHeaders , HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {HTTP} from "@ionic-native/http/ngx";
import {RequestService} from "../request/request.service";
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ReconoseridServiceService {

  url = 'https://demorcs.olimpiait.com:6314/';
  guid_convenio = 'f19f5ffd-ca0d-4e98-a26d-6ceb4109763b';
clientid='Laniakea';
client_secret='P@$$w0rd';
  constructor(private http: HTTP,
              private request: RequestService,
              private httpclient: HttpClient) {

  }
  private create_headers(access_token? : string) {
    if(Capacitor.getPlatform()!== 'web'){
    let headers = { 'Content-Type': 'application/json', 'Authorization': '', 'Access-Control-Allow-Origin': '*' };
    if (access_token !== undefined) {
      headers.Authorization = 'Bearer ' + access_token;
    }
    return headers;
  }else{
       // Web version using HttpHeaders
       let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': '',
        'Access-Control-Allow-Origin': '*' // This might not be necessary if the server doesn't allow CORS requests.
      });
  
      if (access_token) {
        headers = headers.set('Authorization', 'Bearer ' + access_token);
      }
  
      return headers;
  }
  }

  public solicitaValidacion(access_token: string, curp, email, nombre, celular){
    return new Promise((resolve, reject) => {
      const headers = this.create_headers(access_token);
      //faltan mas campos (nombre, apellido, curp, etc)
      const object = {
        "guidConv":this.guid_convenio,
        "tipoValidacion":4,
        "asesor":"LaniakeaM_2022",
        "sede":686863,
        "tipoDoc":"CURP",
        "numDoc": curp,
        "email": email,
        "nombreCompleto": nombre,
        "celular": celular,
        "usuario":'Laniakea_2022',
        "clave":'Laniakea.2022*',
        "validarProcesoActivo":true,
        "prefCelular":"52",
        "procesoWhatsapp":null
      };
      this.http.setDataSerializer('json');
      console.log("body", object);
      console.log(headers);
      this.http.post(this.url+'Validacion/SolicitudValidacion',object, headers).then((data) => {
        console.log(data);
        const result = JSON.parse(data.data);
        //guardar en lad BD
        const reconoser_object = {
          'reconoser_guid': result['data']['procesoConvenioGuid'],
          'reconoser_url': result['data']['url']
        };
        this.request.createRequestPost('profile/update_reconoserid',reconoser_object).then(() =>{
          resolve(result);
        }).catch((error)=>{
          console.log(error);
        });
      }).catch((error) => {
        console.error(error);
      });
    });
  }

  public getToken() {
    if(Capacitor.getPlatform()!== 'web'){
    return new Promise((resolve, reject) => {
      const headers = this.create_headers();
      const object = {
        'clientId': this.clientid,
        'clientSecret':this.client_secret
      };
      this.http.setDataSerializer('json');
      console.log("body", object);
      this.http.post(this.url+'TraerToken',object, headers).then((data) => {
        const result = JSON.parse(data.data);
          resolve(result['accessToken']);
      }).catch((error) => {
        console.error(error);
      });
    });
  }else{
   // Web platform (Angular HttpClient)
   return new Promise((resolve, reject) => {
    // Create headers properly for HttpClient
    const headers = this.create_headers(); // Make sure this returns HttpHeaders instance

    // Convert the parameters to a URL-encoded format if needed
    const object = new HttpParams()
      .set('clientId', this.clientid)
      .set('clientSecret', this.client_secret);

    console.log("Web request params:", object.toString());

    const requestBody = {
  
      clientId: this.clientid, // Use the desired model name
      clientSecret: this.client_secret,

    };
    // Use HttpClient post request
    this.httpclient.post(this.url + 'TraerToken',requestBody, { headers })
      .subscribe(
        (data: any) => {
          console.log('Web result:', data);
          resolve(data['accessToken']);
        },
        (error) => {
          console.error('Error in web HTTP request:', error);
          reject(error);
        }
      );
  });
}
}


  public solicitaStatus(access_token, guid) {
    return new Promise((resolve, reject) => {
      const headers = this.create_headers(access_token);
      //faltan mas campos (nombre, apellido, curp, etc)
      const object = {
        "guidConv":this.guid_convenio,
        "procesoConvenioGuid":guid,
        "usuario":'Laniakea_2022',
        "clave":'Laniakea.2022*'
      };
      this.http.setDataSerializer('json');
      console.log("body solicitastatus", object);
      console.log(headers);
      this.http.post(this.url+'Validacion/ConsultarValidacion',object, headers).then((data) => {
        console.log(data);
        const result = JSON.parse(data.data);
        resolve(result);
      }).catch((error) => {
        console.error(error);
      });
    });
  }


}
