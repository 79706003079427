import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, ActionSheetController } from '@ionic/angular';
import { Utils } from 'src/app/Models/Classes/utils';
import {RequestService} from "src/app/services/request/request.service";
import { NavController } from '@ionic/angular';
import { FriendshipService } from 'src/app/services/relations/relations.service';
import { GoogleMap } from '@capacitor/google-maps';
import { Plugins } from '@capacitor/core';
const { CapacitorGoogleMaps } = Plugins;
import { Share } from '@capacitor/share';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import Swal2 from 'sweetalert2';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-Ver-detalles',
  templateUrl: './Ver-detalles.component.html',
  styleUrls: ['./Ver-detalles.component.scss']
})



export class VerDetallesComponent implements OnInit {
	public MapButtons:boolean=false;
	public showAddress:boolean=false;
	public showCalendar:boolean=false;
	public EarthCalendar:boolean=false;
	public Address: any;
	private markerObjects: any[] = [];

	@ViewChild('map', { static: false }) mapRef: ElementRef<HTMLElement>;
	newMap: GoogleMap;
	private apiKey: string = 'AIzaSyDotFU2YUDcy3nIlgiv-sEqFSpCFzRZQ-A';
	public mapCreated: boolean = false;
  @Input() public;
	@Input() profilePhoto: string;
  public timeSincePublish: string = "Justo ahora";
	completePictureUrl: string;
  constructor(
    private modalController: ModalController,
    private utils: Utils,
    private request: RequestService,
		private friendshipService: FriendshipService,
    private navCtrl: NavController,
	private clipboard: Clipboard,
	public actionSheetController: ActionSheetController,
	private http:HttpClient,
	private alertController: AlertController
  ) { }

  ngOnInit() {
	  console.log('pub_type', this.public.publication_type);
	  console.log('lat', this.public.latitud);
	  console.log('lng', this.public.longitud);
	  if (this.public.publication_type === 'land' ) {
		this.showAddress = true;
		this.showCalendar = true;
		this.reverseGeocode();
	}else if(this.public.publication_type === 'eclipse'){
		this.showAddress = true;
		this.reverseGeocode();
	}else if (this.public.publication_type === 'earth'){
		this.showAddress = true;
		this.showCalendar = true;
		this.EarthCalendar=true;
		this.reverseGeocode();
	}
	
    console.log('followButton2', this.public.Relative.followButton.error);
		this.utils.calcularTiempo(this.public.createdAt).then(time => {
			this.timeSincePublish = time;
			
		});
		this.completePictureUrl = 'https://laniakea-multimedia.s3.amazonaws.com/' + this.public.userProfile.profile_photo;
    console.log("description en ver detalle", this.public);
    this.typeText(this.public.title, 99);
	this.typeTextDescription(this.public.description, 99);
  }
  typeText(text: string, delay: number) {
	let index = 0;
	this.public.title='';
	const interval = setInterval(() => {
	  if (index < text.length) {
		this.public.title += text.charAt(index);
		index++;
	  } else {
		clearInterval(interval);
	  }
	}, delay);
  }
  typeTextDescription(text: string, delay: number) {
	let index = 0;
	this.public.description='';
	const interval = setInterval(() => {
	  if (index < text.length) {
		this.public.description += text.charAt(index);
		index++;
	  } else {
		clearInterval(interval);
	  }
	}, delay);
  }
  ngOnDestroy(): void {
	this.DestroyMap();
	  }

  dismiss() {
    this.modalController.dismiss();
	this.DestroyMap();
  }
  async DestroyMap(){
 this.mapCreated = false;
    const result = await CapacitorGoogleMaps.hide();
    // Set the map reference to null
    this.newMap = undefined;

      //const mapElement = document.getElementById('map');
      //if (mapElement && mapElement.parentNode) {
    //    mapElement.parentNode.removeChild(mapElement);
     // }
    
  }

  Click_On_User() {
    this.dismiss();
    console.log('click header to send to user profile page');
		//if(!this.discardInteractions){
			this.navCtrl.navigateForward('home/profile/' + this.public.userProfile.user_id);
		//}
	}
	click_FollowButton(button_info) {

		//switch (this.followButton['text']) {
		//	case "Seguir":
				this.buttonsActions(button_info);
		//		break;
		//	case "Siguiendo":
				//this.buttonsActions(button_info);
		//		break;
		//}
	}
	buttonsActions(button_info){
		console.log('click header follow');
console.log(button_info);
		//if(button_info['type'] == 3){
			//switch (button_info.text) {
				//case "Seguir":
					this.createRelation(3).then((data)=>{
						this.utils.presentToast("Ahora sigues al usuario");
						this.public.Relative['text']="Siguiendo";
				//	});
				//	break;
				//case "Siguiendo":
				//	this.deleteRelation(button_info['relation_id']).then((data)=> {
				//		this.utils.presentToast("Dejaste de seguir al usuario");
				//		this.followButton['text']="Seguir";
				//	});
				//	break;
			})
		//}
	}
	createRelation(relation_type): Promise<any>{
		return new Promise((resolve, reject) => {
			let newRelation = {
				'relationUserId': this.public.userProfile.user_id.toString(),
				'relationType': '3'
			};
			this.sendNotification( );
			this.friendshipService.createRelation(newRelation).then((result)=>{
				result = JSON.parse(result);
				switch (relation_type) {
					case 3:
						this.public.Relative.relation_id = result.relation_id;
						break;

					default:
						break;
				}
				resolve(result);
			}).catch((error) =>{
				reject(error);
			});
		});

	}
	sendNotification() {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: 'Nuevo seguidor asequido',
			body: `¡Has recibido un nuevo seguidor en tu perfil!`,
			user_id:this.public.userProfile.user_id,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
				
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }
	  deleteRelation(relation_id):Promise<any>{
		return new Promise((resolve, reject) => {
			let params = {
				'id': relation_id.toString(),
			};
			this.friendshipService.deleteRelation(params).then((result) => {
				resolve(result);
			}).catch((error) =>{
				reject(error);
			});
		});

	}


	//map
	async createMap() {
		if (Capacitor.getPlatform() === 'web') {
			Swal2.fire({
			  title: 'Función no disponible',
			  text: 'Función solo disponible en aplicación de Play Store o App Store.',
			  icon: 'info',
			  confirmButtonText: 'Aceptar',
			  background: '#010f4e',
			  position: 'top-end',
			  timerProgressBar: true,
			  toast: true,
			});
		  }else{
this.mapCreated = true;
		const apiKey = this.apiKey;

	
		//const mapRef = document.getElementById('map');
		const mapRefElement = this.mapRef.nativeElement;
		 this.newMap = await GoogleMap.create({
		  id: 'magnanimous-map', // Unique identifier for this map instance
		  element: mapRefElement, // reference to the capacitor-google-map element
		  apiKey: apiKey, // Your Google Maps API Key
		  config: {
			center: {
			  // The initial position to be rendered by the map
			  lat:  23.634501,
			  lng: -102.552784
			},
			zoom: 3, // The initial zoom level to be rendered by the map
		  },
		});

		 // Move the map programmatically
		 await this.newMap.setCamera({
			coordinate: {
			  lat: 23.634501, // You can set the map center here if needed
			  lng: -102.552784
			}
		  });
	
	await this.getMapData();
	
	
	
	const mapType = 'hybrid'; // Specify the map type as 'hybrid'
	  const result =  await CapacitorGoogleMaps.setMapType({ type: mapType });
		
		  //this.getMapData();
	
		  CapacitorGoogleMaps.addListener('didTapInfoWindowOf', (data: any) => {
			// Data contains information about the clicked marker
			const markerData = data.result;
			console.log('Marker clicked:', markerData);
		  
			// Call your custom method to handle the marker click
		
			this.onMarkerClick(markerData);
			
			this.DestroyMap();
		  });
		  CapacitorGoogleMaps.addListener('didLongPressInfoWindowOf', (data: any) => {
			// Data contains information about the clicked marker
			const markerData = data.result;
			console.log('Marker clicked:', markerData);
		  
			// Call your custom method to handle the marker click

			this.onMarkerClick(markerData);
		  
			this.DestroyMap();
		  });
	
	CapacitorGoogleMaps.setTrafficEnabled();
	setTimeout(() => {
		this.MapButtons = true;
	  }, 999); 
			  
	}
	  }
	
	  
	  
	
	onMarkerClick(markerData: any) {
	  // Extract the information you need from the markerData object
	  const markerId = markerData.id;
	  const coordinates = markerData.coordinates;
	  const title = markerData.title;
	  const snippet = markerData.snippet;
	  const metadata = markerData.metadata;
	  console.log('markerId', markerId);
	  console.log('coordinates', coordinates);
	  console.log('title', title);
	  console.log('snippet', snippet);
	  console.log('metadata', metadata);
	
	this.presentActionSheet(markerData);
	  // Do whatever you want to do when a marker is clicked
	  // For example:
	  
	}
	
	  
	async getMapData() {
		if (this.showAddress===false){
		const ubicacion = decodeURIComponent(this.public.ubicacion);
		
		// Mapping of country names to their coordinates
		const countryCoordinates = {
		  "México": { lat: 23.634501, lng: -102.552784 },
		  "Argentina": { lat: -38.416097, lng: -63.61667199999999 },
		  "Brasil": { lat: -14.235004, lng: -51.92528 },
		  "Chile": { lat: -35.675147, lng: -71.542969 },
		  "Colombia": { lat: 4.570868, lng: -74.297333 },
		  "Costa Rica": { lat: 9.748916999999999, lng: -83.753428 },
		  "Ecuador": { lat: -1.831239, lng: -78.18340599999999 },
		  "El Salvador": { lat: 1.0468089, lng: -81.3914138 },
		  "Guatemala": { lat: 15.783471, lng: -90.23075899999999 },
		  "Panamá": { lat: 8.537981, lng: -80.782127 },
		  "Perú": { lat: -9.189967, lng: -75.015152 },
		  "Venezuela": { lat: 6.42375, lng: -66.58973 },
		  // Add more countries and their coordinates here
		};
	  
		// Find the coordinates for the given 'ubicacion'
		const coordinates = countryCoordinates[ubicacion];
		if (!coordinates) {
		  console.log(`⚡️  [log] - Coordinates not available for ${ubicacion}`);
		  return;
		}

	  
		console.log('Coordinates:', coordinates);
	  
		// Create and add a marker to the map
		const addedMarker = await CapacitorGoogleMaps.addMarker({
		  latitude: coordinates.lat,
		  longitude: coordinates.lng,
		  title: this.public['title'],
		  snippet: this.public['description'],
		  media: "Your Marker Media",
		  Publication_id: this.public.id
		});
	  
		this.markerObjects.push(addedMarker);
	}else{
		const latitud = parseFloat(this.public.latitud);
        const longitud = parseFloat(this.public.longitud);
		// Create and add a marker to the map
		const addedMarker = await CapacitorGoogleMaps.addMarker({
			latitude: latitud,
			longitude: longitud,
			title: this.public['title'],
			snippet: this.public['description'],
			media: "Your Marker Media",
			Publication_id: this.public.id
		  });
		  this.markerObjects.push(addedMarker);
	}
	  }
	  
	  
	  async presentActionSheet(pub){
		Swal.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
	  const actionSheet = await this.actionSheetController.create({
		header:  pub.title,
		cssClass: 'title-action',
		buttons: [{
		  text: 'Ir a la publicación',
		  icon: 'telescope-outline',
		  cssClass: 'icon',
		  handler: () => {
	   
	this.dismiss();
	
		  }
		} , {
		  text: 'Copiar enlace de publicación',
		  icon: 'link-outline',
		  cssClass: 'icon',
		  handler: () => {
		   
	this.click_on_share();
	this.AugmentSharesCounter();
	
		  }
		}, {
		  text: 'Compartir con otras aplicaciones',
		  icon: 'analytics-outline',
		  cssClass: 'icon',
		  handler: () => {
			this.Share();
			this.AugmentSharesCounter();
		  }
		}, 
		{
			text: 'Ir al perfil del usuario',
			icon: 'person-circle-outline',
			cssClass: 'icon',
			handler: () => {
			 
	this.Click_On_User();
	  
			}
		  }
	
	   
	  ]
	  });
	  await actionSheet.present();
	
	  const { role } = await actionSheet.onDidDismiss();
	  console.log('onDidDismiss resolved with role', role);
	
	}

	async Share(){
	  const publicationId = this.public.id;
	  const url =  `https://supercumulo.laniakea.tv/home/ecosystem-page?p=${publicationId}`;
	  await Share.share({
		url: url,
	  });
	}
	click_on_share() {
	  const publicationId = this.public.id;
	  const url =  `https://supercumulo.laniakea.tv/home/ecosystem-page?p=${publicationId}`;
	
	  // Copy the URL to clipboard
	  this.clipboard.copy(url)
		.then(() => {
		  console.log('URL copied to clipboard:', url);
		  Swal.fire({
			title: '¡URL copiado!',
			text: 'El URL ha sido copiado en el portapapeles.',
			icon: 'success',
			timer: 2000, // Show the alert for 2 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });
		})
		.catch((error) => {
		  console.error('Error copiando el URL en el portapapeles:', error);
		  Swal.fire({
			title: 'Error',
			text: 'Error copiando el URL en el portapapeles.',
			icon: 'error',
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 2000 // Show the alert for 2 seconds
		  });
		});
	}
	AugmentSharesCounter(){
	  return new Promise((resolve, reject) => {
	 
	  
		this.request.createRequestPost('AugmentShareCounter', {id: this.public.id})
		.then((data) => {
		  resolve(data);
		}).catch((error) => {
		  console.error('Failed to send notification:', error);
		  reject(error);
		});
	  });
	
	}
	async startNavigation(): Promise<any> {
		if (this.showAddress ===false){
		const ubicacion = decodeURIComponent(this.public.ubicacion);
	
		// Mapping of country names to their coordinates
		const countryCoordinates = {
			"México": { lat: 23.634501, lng: -102.552784 },
			"Argentina": { lat: -38.416097, lng: -63.61667199999999 },
			"Brasil": { lat: -14.235004, lng: -51.92528 },
			"Chile": { lat: -35.675147, lng: -71.542969 },
			"Colombia": { lat: 4.570868, lng: -74.297333 },
			"Costa Rica": { lat: 9.748916999999999, lng: -83.753428 },
			"Ecuador": { lat: -1.831239, lng: -78.18340599999999 },
			"El Salvador": { lat: 1.0468089, lng: -81.3914138 },
			"Guatemala": { lat: 15.783471, lng: -90.23075899999999 },
			"Panamá": { lat: 8.537981, lng: -80.782127 },
			"Perú": { lat: -9.189967, lng: -75.015152 },
			"Venezuela": { lat: 6.42375, lng: -66.58973 },
			// Add more countries and their coordinates here
		};
		const coordinates = countryCoordinates[ubicacion];
		if (!coordinates) {
			console.log(`⚡️  [log] - Coordinates not available for ${ubicacion}`);
			return;
		}
		
		const apiKey = this.apiKey; // Replace this with your actual Google Maps API key
	
		try {
			const result = await CapacitorGoogleMaps.startNavigation({
				latitude: coordinates.lat,
				longitude: coordinates.lng,
				title: ubicacion,
				apiKey: apiKey
			});
			return result;
		} catch (error) {
			console.error('Error while starting navigation:', error);
			throw error;
		}
	}else{
		try {
			const latitud = parseFloat(this.public.latitud);
        const longitud = parseFloat(this.public.longitud);
			const result = await CapacitorGoogleMaps.startNavigation({
				latitude: latitud,
				longitude: longitud,
				title: this.Address,
				
			});
			return result;
		} catch (error) {
			console.error('Error while starting navigation:', error);
			throw error;
		}
	}
	}
	

	public reverseGeocode(){
		const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.public.latitud},${this.public.longitud}&key=${this.apiKey}`;

        this.http.get(geocodingUrl).toPromise().then((response: any) => {
          console.log('response:', response);
            const firstResult = response.results[0];
  
            if (firstResult && firstResult.formatted_address) {
                this.Address = firstResult.formatted_address;
                console.log("Address:", this.Address);
            } else {
                console.error("Error reverse geocoding: No formatted address found in response.");
                this.Address = "Address not available";
            }
        }).catch((error: any) => {
            console.error("Error reverse geocoding:", error);
            this.Address = "Address not available";
        });
	}
	  
	 // Function to generate an array of icon names based on value
	 getStarIcons(value: number): string[] {
		const icons: string[] = [];
		const fullStars = Math.floor(value);
		const halfStar = value - fullStars >= 0.5;
	
		for (let i = 0; i < fullStars; i++) {
		  icons.push('star');
		}
	
		if (halfStar) {
		  icons.push('star-half');
		}
	
		while (icons.length < 9) {
		  icons.push('star-outline');
		}
	
		return icons;
	  }
	  async presentStarsSelectionAlert() {
		const alert = await this.alertController.create({
		  header: 'Selecciona tu valoración',
		  inputs: [
			{
			  type: 'radio',
			  label: '1 estrella',
			  value: 1,
			},
			{
			  type: 'radio',
			  label: '2 estrellas',
			  value: 2,
			},
			{
			  type: 'radio',
			  label: '3 estrellas',
			  value: 3,
			},
			{
			  type: 'radio',
			  label: '4 estrellas',
			  value: 4,
			},
			{
			  type: 'radio',
			  label: '5 estrellas',
			  value: 5,
			},
			{
				type: 'radio',
				label: '6 estrellas',
				value: 6,
			  },
			  {
				type: 'radio',
				label: '7 estrellas',
				value: 7,
			  },
			  {
				type: 'radio',
				label: '8 estrellas',
				value: 8,
			  },
			  {
				type: 'radio',
				label: '9 estrellas',
				value: 9,
			  },
		  ],
		  buttons: [
			{
			  text: 'Cancelar',
			  role: 'cancel',
			  cssClass: 'secondary',
			  handler: () => {
				console.log('Cancelar clicked');
			  },
			},
			{
			  text: 'Aceptar',
			  handler: (selectedValue) => {
				console.log('Selected value:', selectedValue);
				this.enviarValoracion(selectedValue);
				// You can update the selected value to your component property here
				// For example: this.valoracion = selectedValue;
			  },
			},
		  ],
		});
	  
		await alert.present();
	  }


	  enviarValoracion(value){
	
   
			return new Promise((resolve, reject) => {
			  const notification = {
			value: value,
			publication_id:this.public.id,
		
			  };
		  
			  this.request.createRequestPost('ValorarPublicaciones', notification)
				.then((data) => {
					Swal2.fire({
						title: 'Valoración enviada exitosamente',
				
						icon: 'success',
						confirmButtonText: 'OK',
						color: '#ffffff',
						background: '#010f4e',
						position: 'top-end',
						timerProgressBar: true,
						toast: true,
					  });
				  resolve(data);
				}).catch((error) => {
					Swal2.fire({
						title: 'No se pudo procesar tu valoración',
				
						icon: 'error',
						confirmButtonText: 'OK',
						color: '#ffffff',
						background: '#010f4e',
						position: 'top-end',
						timerProgressBar: true,
						toast: true,
					  });
				  console.error('Failed to send notification:', error);
				  reject(error);
				});
			});
		  
	  }
}
