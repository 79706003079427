import { AccessUserData } from '../../Models/Classes/User/access-user-data';
import { Utils } from '../../Models/Classes/utils';
import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { RequestResponse } from 'src/app/Models/Classes/request-response';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import Swal2 from 'sweetalert2';
/*
import { AuthService } from 'src/app/services/auth/auth.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import {  NavController } from '@ionic/angular';*/
//import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class RequestService {
	//private AUTH_SERVER_ADDRESS = 'http://127.0.0.1:8000/api/';
	//  private AUTH_SERVER_ADDRESS = 'http://18.119.70.56/api/';
	//private AUTH_SERVER_ADDRESS = 'http://192.168.100.249:8000/api/';
	//private AUTH_SERVER_ADDRESS = 'http://192.168.100.21:8000/api/';
	//private AUTH_SERVER_ADDRESS = 'http://192.168.0.8:8000/api/';

	// private AUTH_SERVER_ADDRESS = 'http://192.168.1.66:8000/api/';
  	//private AUTH_SERVER_ADDRESS = 'http://192.168.1.82:8000/api/';
	//private AUTH_SERVER_ADDRESS = 'https://9jkrdd2hdl.execute-api.us-east-1.amazonaws.com/api/';
	private AUTH_SERVER_ADDRESS = 'https://supercluster-api.laniakea.tv/api/';





	

	//private AUTH_SERVER_ADDRESS = 'https://01jia6a6e9.execute-api.us-east-1.amazonaws.com/api/';


	private HEADERS = { 'Content-Type': 'application/json', 'Authorization': '', 'Access-Control-Allow-Origin': '*' };

	private END_POINTS = [
		['login', '0'],
		['register', '0'],
		['logout', '1'],
		['publications', '1'],
		['profile', '1'],
		['relations', '1'],
		['products', '1'],
		['comments', '1'],
		['getStatuses', '1'],
		['chatElement', '1'],
		['getCorpPublications', '1'],
		['getVersion', '1'],
		['notifications', '1'],
		['payments/newPayment', '1'],
		['payments/transactions', '1'],
		['productCategories', '1'],
		['search', '1'],
		['users', '1'],
		['user/publications/newPublication', '1'],
		['payments/getCurrencyValue', '1'],
		['publications.multimedia', '1'],
		['s3_form', '1'],
		['profileMultimedia', '1'],
		['fakerpay','1'],
		['fakerpay/getID','1'],
    ['comments.multimedia','1'],
    ['filterAll','1'],
    ['stripe-pay','1'],
    ['profile/update_reconoserid','1'],
    ['save_publication','1'],
    ['delete_save_publication','1'],
    ['publications_saved','1'],
	['profile-all', '1'],
	['categories', '1'],
	['regalos', '1'],
	['contador-regalos', '1'],
	['show_regalos', '1'],
	['send-email-retiros', '1'],
	['Onboard', '1'],
	['CheckConnectStatus', '1'],
	['incrementViews', '1'],
	['showViews', '1'],
	['send-email', '1'],
	['verified-confirmation', '1'],
	['UsuarioVerificado', '1'],
	['Deposit', '1'],
	['Hubble', '1'],
	['Conversiones', '1'],
	['saveAIData', '1'],
	['getAIData', '1'],
	['publications_gifted', '1'],
	['publications_paid', '1'],
	['MiMonedaDeRegalos', '1'],
	['VerMiMonedaDeRegalos', '1'],
	['publications_AI', '0'],
	['PagoDePublicaciones', '1'],
	['IN-APP-PURCHASE-1', '1'],
	['RegalosNotifications', '1'],
	['VentasNotifications', '1'],
	['CountersFachada', '1'],
	['Payout', '1'],
	['PayStripeURL', '1'],
	['getExchangeValue', '1'],
	['exchangeAssets', '1'],
	['IN-APP-PURCHASE-2', '1'],
	['StripeIdentified', '1'],
	['publications_UL', '0'],
	['publications_Profiles', '1'],
	['GreatAtractorNotifications', '1'],
	['saveDeviceToken', '1'],
	['saveWebPageAndDescription', '1'],
	['reset-password', '0'],
	['send-email-to-reset-password', '0'],
	['DeletePublication', '1'],
	['AugmentShareCounter', '1'],
	['AugmentSaveCounter', '1'],
	['FGA2FA', '0'],
	['verified-confirmation-password', '0'],
	['modifyUsernameAndEmail', '1'],
	['GetSuperclusterMap', '1'],
	['CupoDisponible', '1'],
	['categoriesEpoch', '1'],
	['categoriesEclipse', '1'],
	['categoriesLand', '1'],
	['categoriesEarth', '1'],
	['PagoDePublicacionesCalendario', '1'],
	['ValorarPublicaciones', '1'],
	['collectionOfReservations', '1'],
	['scanReservations', '1'],
	['CupoDisponibleEarth', '1'],
	['createChatRequest', '1'],
	['updateChatRequest', '1'],
	['live_stream_regalos', '1'],
	['UpdateDiamonds', '1'],
	['ProliferarPublicacion', '1'],
	['GenaralNotificationsCounter', '1'],
	['UpdateNotificationRecord', '1'],
	['LaniakeaConversation', '1'],
	['validateThatTheUserIsUnblockedInMessage', '1'],
	['toggleBlockStatus', '1'],
	['myVehiclesData', '1'],
	['arrendadorBuscaViajes', '1'],
	['clientRequestsShootingStarsTrip', '1'],
	['getShootingStarsOrders', '1'],
	['openPayDeposit', '1'],
	['openPayPayout', '1'],
	['HubbleToHubbleTransfer', '1'],
	['SMSCodeVerification', '0'],
	['ModifyPhoneNumber', '1'],
	['EliminarCuenta', '1'],
	['reviewAndRateShootingStarsTrip', '1'],
	['sendPragmaticCorporateMail', '1'],
	['VehicleCoordinates', '1'],
	['YourArtificialVoices', '1'],
	['Register_with_BBVA_account', '0'],
	['AuthSMSCodeVerification', '1'],
	['create_BBVA_ACCOUNT', '1'],
	['bindPhysicalCardToBBVA_account', '1'],
	['certifyMercado', '0'],
	['fetchBBVA_account_data', '1'],
	['requestMortgage', '1'],
	['requestAutomobileLoan', '1'],
	['requestBBVA_ATM_or_branches_locations', '1'],
	['PostingEntrelazamientoContent', '1'],
	['EntanglementInteraction', '1'],
	['createAWSTranscribe', '1'],
	['ProtoInternetData', '0'],
	['Historic_calls', '1'],
	['getHistoric_AI_CHAT', '1'],
	['redirectToInstagramForThirdPartyEntanglement', '1'],
	['postToInstagram', '1'],
	['redirectToXForPostsEntanglement', '1'],
	['handleUserIDInstagramCallback', '0'],
	['getting_TP_POSTS', '1'],
	['uploadThirdPartyMedia', '1'],
	['redirectToLinkedInForEntanglement', '1'],
	['redirectToTikTokForEntanglement', '1'],
	['redirectToYoutubeEntanglement', '1'],
	['postMediaToLinkedIn', '1'],
	['postMediaToX', '1'],
	['checkTikTokPostStatus', '1'],
	['getAuthTikTokProfile', '1'],
	['getAuthInstagramProfile', '1'],
	['getAuthLinkedInProfile', '1'],
	['getAuthXProfile', '1'],
	['postToTikTok', '1'],
	['get_BBVA_Locations', '1'],
	['getProfileByUser_Id', '1'],
	['getTTSAIAvatar', '1'],
	['getAuthWixProfile', '1'],
	['postUserObservations', '1'],
	['save_content_preferences_component', '1'],
	['graphUser', '1'],
	['getConversationTagsForAuthenticatedUser', '1'],
	['toggleConversationTags', '1'],
	['get_stories_by_user', '1'],
	['voice_note_instance', '1'],
	['enterToWalletWithPIN', '1'],
	['getAuthYouTubeToken', '1'],
	['VerificationWithOpenPay', '1'],
	['playlist_controller', '1'],
	['publications_from_playlist', '1'],
	['handle_hey_Lia', '1'],
	['insert_face_identifaction', '1'],
	['handleShippingWithAmazon', '1'],
	['requestAnUberRide', '1'],
	['handleShipping', '1'],
	['get_face_identifaction', '0'],
	['redirectToAmazonLoginForShipping', '0'],
	['createWireTransfer', '1'],
	['smsMesagesHandler', '1'],
	['WhatsappMesagesHandler', '1'],
	['postToThreadsProfile', '1'],
	['redirectToThreadsLoginForEntanglement', '1'],
	['redirectToThreadsLoginForGettingThreads', '1'],
	['profile_facade_sections_controller', '1'],
	['ShippingAddressesController', '1'],
	['getTextAutocomplePredictionGenerativePretrainingTransformer', '1'],
	['getAlibabaProducts', '0'],
	['AlibabaProductHandler', '0'],
	['ControladorDeCarritoDeCompras', '1'],
	['GetAuthUser', '1'],
	['PersistentLiveStreamIPTVController', '1'],
	['UnAuthStripe', '0'],
	['getRecordByToken', '0'],

	];
	constructor(
		//private http2: HttpClient,
		private utils: Utils,
		private http: HTTP,
		private router:Router,
		//private navCtrl: NavController,
		//private authService: AuthService,
		//private nativeStorage: NativeStorage,
		//private chatService: ChatService,
	) { }

	private selectEnpoint(endPoint: string): Array<string> {
		switch (endPoint) {
			case 'login':
				return this.END_POINTS[0];
			case 'register':
				return this.END_POINTS[1];
			case 'logout':
				return this.END_POINTS[2];
			case 'publications':
				return this.END_POINTS[3];
			case 'profile':
				return this.END_POINTS[4];
			case 'relations':
				return this.END_POINTS[5];
			case 'products':
				return this.END_POINTS[6];
			case 'comments':
				return this.END_POINTS[7];
			case 'stories':
				return this.END_POINTS[8];
			case 'chatElement':
				return this.END_POINTS[9];
			case 'getCorpPublications':
				return this.END_POINTS[10];
			case 'getVersion':
				return this.END_POINTS[11];
			case 'notifications':
				return this.END_POINTS[12];
			case 'payment':
				return this.END_POINTS[13];
			case 'transactions':
				return this.END_POINTS[14];
			case 'productCategories':
				return this.END_POINTS[15];
			case 'search':
				return this.END_POINTS[16];
			case 'users':
				return this.END_POINTS[17];
			case 'newPublication':
				return this.END_POINTS[18];
			case 'getCurrencyValue':
				return this.END_POINTS[19];
			case 'publications.multimedia':
				return this.END_POINTS[20];
			case 's3_form':
				return this.END_POINTS[21];
			case 'profileMultimedia':
				return this.END_POINTS[22];
			case 'fakerpay':
				return this.END_POINTS[23];
			case 'fakerpay/getID':
				return this.END_POINTS[24];
            case 'comments.multimedia':
                return this.END_POINTS[25];
			case 'publications/filterAll':
				return this.END_POINTS[26];
      case 'stripe-pay':
        return this.END_POINTS[27];
      case 'profile/update_reconoserid':
        return this.END_POINTS[28];
      case 'save_publication':
        return this.END_POINTS[29];
      case 'delete_save_publication':
        return this.END_POINTS[30];
      case 'publications_saved':
        return this.END_POINTS[31];
	  case 'profile-all':
	    return this.END_POINTS[32];
	  case 'categories':
		console.log(this.END_POINTS[33]);
		return this.END_POINTS[33];
	  case 'regalos':
		return this.END_POINTS[34]
	  case 'contador-regalos':
		return this.END_POINTS[35]
	  case 'show_regalos':
		return this.END_POINTS[36];
	  case 'send-email-retiros':
		  return this.END_POINTS[37];
	  case 'Onboard':
		  return this.END_POINTS[38];
	  case 'CheckConnectStatus':
		  return this.END_POINTS[39];
	  case 'incrementViews':
		  return this.END_POINTS[40];
	  case 'showViews':
		  return this.END_POINTS[41];
	  case 'send-email':
		  return this.END_POINTS[42];
	  case 'verified-confirmation':
		  return this.END_POINTS[43];
	  case 'UsuarioVerificado':
		  return this.END_POINTS[44];
	  case 'Deposit':
		  return this.END_POINTS[45];
	  case 'Hubble':
		  return this.END_POINTS[46];
	  case 'Conversiones':
		  return this.END_POINTS[47];
	  case 'saveAIData':
		  return this.END_POINTS[48];
	  case 'getAIData':
		  return this.END_POINTS[49];
	  case 'publications_gifted':
		  return this.END_POINTS[50];
	  case 'publications_paid':
		  return this.END_POINTS[51];
	  case 'MiMonedaDeRegalos':
		  return this.END_POINTS[52];
	  case 'VerMiMonedaDeRegalos':
		  return this.END_POINTS[53];
	  case 'publications_AI':
		  return this.END_POINTS[54];
	  case 'PagoDePublicaciones':
		  return this.END_POINTS[55];
	  case 'IN-APP-PURCHASE-1':
		  return this.END_POINTS[56];
	  case 'RegalosNotifications':
		  return this.END_POINTS[57];
	  case 'VentasNotifications':
		  return this.END_POINTS[58];
	  case 'CountersFachada':
		  return this.END_POINTS[59];
	  case 'Payout':
		  return this.END_POINTS[60];
	  case 'PayStripeURL':
		  return this.END_POINTS[61];
	  case 'getExchangeValue':
		  return this.END_POINTS[62];
	  case 'exchangeAssets':
		  return this.END_POINTS[63];
	  case 'IN-APP-PURCHASE-2':
		  return this.END_POINTS[64];
	  case 'StripeIdentified':
		  return this.END_POINTS[65];
	  case 'publications_UL':
		  return this.END_POINTS[66];
	  case 'publications_Profiles':
		  return this.END_POINTS[67];
	  case 'GreatAtractorNotifications':
		  return this.END_POINTS[68];
	  case 'saveDeviceToken':
		  return this.END_POINTS[69];
	  case 'saveWebPageAndDescription':
		  return this.END_POINTS[70];
	  case 'reset-password':
		  return this.END_POINTS[71];
	  case 'send-email-to-reset-password':
		  return this.END_POINTS[72];
	  case 'DeletePublication':
		  return this.END_POINTS[73];
	  case 'AugmentShareCounter':
		  return this.END_POINTS[74];
	  case 'AugmentSaveCounter':
		  return this.END_POINTS[75];
	  case 'FGA2FA':
		  return this.END_POINTS[76];
	  case 'verified-confirmation-password':
		  return this.END_POINTS[77];
	  case 'modifyUsernameAndEmail':
		  return this.END_POINTS[78];
	  case 'GetSuperclusterMap':
		  return this.END_POINTS[79];
	  case 'CupoDisponible':
		  return this.END_POINTS[80];
	  case 'categoriesEpoch':
		  return this.END_POINTS[81];
	  case 'categoriesEclipse':
		   return this.END_POINTS[82];
	  case 'categoriesLand':
		   return this.END_POINTS[83];
	  case 'categoriesEarth':
	       return this.END_POINTS[84];
	  case 'PagoDePublicacionesCalendario':
		   return this.END_POINTS[85];
	  case 'ValorarPublicaciones':
		   return this.END_POINTS[86];
	  case 'collectionOfReservations':
		   return this.END_POINTS[87];
	  case 'scanReservations':
		   return this.END_POINTS[88];
	  case 'CupoDisponibleEarth':
		   return this.END_POINTS[89];
						case 'createChatRequest':
						return this.END_POINTS[90];
						case 'updateChatRequest':
						return this.END_POINTS[91];
						case 'live_stream_regalos':
						return this.END_POINTS[92];
						case 'UpdateDiamonds':
							return this.END_POINTS[93];
							case 'ProliferarPublicacion':
							return this.END_POINTS[94];
							case 'GenaralNotificationsCounter':
								return this.END_POINTS[95];
								case 'UpdateNotificationRecord':
									return this.END_POINTS[96];
									case 'LaniakeaConversation':
										return this.END_POINTS[97];
										case 'validateThatTheUserIsUnblockedInMessage':
											return this.END_POINTS[98];
											case 'toggleBlockStatus':
											return this.END_POINTS[99];
											case 'myVehiclesData':
											return this.END_POINTS[100];
											case 'arrendadorBuscaViajes':
											return this.END_POINTS[101];
											case 'clientRequestsShootingStarsTrip':
												return this.END_POINTS[102];
												case 'getShootingStarsOrders':
												return this.END_POINTS[103];
												case 'openPayDeposit':
												return this.END_POINTS[104];
												case 'openPayPayout':
												return this.END_POINTS[105];
												case 'HubbleToHubbleTransfer':
												return this.END_POINTS[106];
												case 'SMSCodeVerification':
												return this.END_POINTS[107];
												case 'ModifyPhoneNumber':
												return this.END_POINTS[108];
												case 'EliminarCuenta':
												return this.END_POINTS[109];
												case 'reviewAndRateShootingStarsTrip':
												return this.END_POINTS[110];
												case 'sendPragmaticCorporateMail':
												return this.END_POINTS[111];
												case 'VehicleCoordinates':
													return this.END_POINTS[112];
													case 'YourArtificialVoices':
													return this.END_POINTS[113];
													case 'Register_with_BBVA_account':
													return this.END_POINTS[114];
													case 'AuthSMSCodeVerification':
													return this.END_POINTS[115];
													case 'create_BBVA_ACCOUNT':
														return this.END_POINTS[116];
case 'bindPhysicalCardToBBVA_account':
return this.END_POINTS[117];
case 'certifyMercado':
return this.END_POINTS[118];
case 'fetchBBVA_account_data':
return this.END_POINTS[119];
case 'requestMortgage':
return this.END_POINTS[120];
case 'requestAutomobileLoan':
return this.END_POINTS[121];
case 'requestBBVA_ATM_or_branches_locations':
return this.END_POINTS[122];
case 'PostingEntrelazamientoContent':
return this.END_POINTS[123];
case 'EntanglementInteraction':
return this.END_POINTS[124];
case 'createAWSTranscribe':
return this.END_POINTS[125];
case 'ProtoInternetData':
return this.END_POINTS[126];
case 'Historic_calls':
return this.END_POINTS[127];
case 'getHistoric_AI_CHAT':
return this.END_POINTS[128];
case 'redirectToInstagramForThirdPartyEntanglement':
return this.END_POINTS[129];
case 'postToInstagram':
return this.END_POINTS[130];
case 'redirectToXForPostsEntanglement':
return this.END_POINTS[131];
case 'handleUserIDInstagramCallback':
return this.END_POINTS[132];
case 'getting_TP_POSTS':
return this.END_POINTS[133];
case 'uploadThirdPartyMedia':
return this.END_POINTS[134];
case 'redirectToLinkedInForEntanglement':
return this.END_POINTS[135];
case 'redirectToTikTokForEntanglement':
return this.END_POINTS[136];
case 'redirectToYoutubeEntanglement':
return this.END_POINTS[137];
case 'postMediaToLinkedIn':
return this.END_POINTS[138];
case 'postMediaToX':
return this.END_POINTS[139];
case 'checkTikTokPostStatus':
return this.END_POINTS[140];
case 'getAuthTikTokProfile':
return this.END_POINTS[141];
case 'getAuthInstagramProfile':
return this.END_POINTS[142];
case 'getAuthLinkedInProfile':
return this.END_POINTS[143];
case 'getAuthXProfile':
return this.END_POINTS[144];
case 'postToTikTok':
return this.END_POINTS[145];
case 'get_BBVA_Locations':
return this.END_POINTS[146];
case 'getProfileByUser_Id':
return this.END_POINTS[147];
case 'getTTSAIAvatar':
return this.END_POINTS[148];
case 'getAuthWixProfile':
return this.END_POINTS[149];
case 'postUserObservations':
return this.END_POINTS[150];
case 'save_content_preferences_component':
return this.END_POINTS[151];
case 'graphUser':
return this.END_POINTS[152];
case 'getConversationTagsForAuthenticatedUser':
return this.END_POINTS[153];
case 'toggleConversationTags':
return this.END_POINTS[154];
case 'get_stories_by_user':
return this.END_POINTS[155];
case 'voice_note_instance':
return this.END_POINTS[156];
case 'enterToWalletWithPIN':
return this.END_POINTS[157];
case 'getAuthYouTubeToken':
return this.END_POINTS[158];
case 'VerificationWithOpenPay':
return this.END_POINTS[159];
case 'playlist_controller':
return this.END_POINTS[160];
case 'publications_from_playlist':
return this.END_POINTS[161];
case 'handle_hey_Lia':
return this.END_POINTS[162];
case 'insert_face_identifaction':
return this.END_POINTS[163];
case 'handleShippingWithAmazon':
return this.END_POINTS[164];
case 'requestAnUberRide':
return this.END_POINTS[165];
case 'handleShipping':
return this.END_POINTS[166];
case 'get_face_identifaction':
return this.END_POINTS[167];
case 'redirectToAmazonLoginForShipping':
return this.END_POINTS[168];
case 'createWireTransfer':
return this.END_POINTS[169];
case 'smsMesagesHandler':
return this.END_POINTS[170];
case 'WhatsappMesagesHandler':
return this.END_POINTS[171];
case 'postToThreadsProfile':
return this.END_POINTS[172];
case 'redirectToThreadsLoginForEntanglement':
return this.END_POINTS[173];
case 'redirectToThreadsLoginForGettingThreads':
return this.END_POINTS[174];
case 'profile_facade_sections_controller':
return this.END_POINTS[175];
case 'ShippingAddressesController':
return this.END_POINTS[176];
case 'getTextAutocomplePredictionGenerativePretrainingTransformer':
return this.END_POINTS[177];
case 'getAlibabaProducts':
return this.END_POINTS[178];
case 'AlibabaProductHandler':
return this.END_POINTS[179];		
case 'ControladorDeCarritoDeCompras':
return this.END_POINTS[180];		
case 'GetAuthUser':
return this.END_POINTS[181];																							
case 'PersistentLiveStreamIPTVController':
return this.END_POINTS[182];	
case 'UnAuthStripe':
return this.END_POINTS[183];																					
case 'getRecordByToken':
return this.END_POINTS[184];	

	  
  

	  

	  
	

	


			default:
				break;
		}
		return null
	}

	private createHeaders(endPoint: string): Promise<any> {
		return new Promise((resolve, reject) => {
			let endPointArray = this.selectEnpoint(endPoint);
			if (endPointArray[1] == '1') {
				this.utils.getAccessData().then((AccessUserData: AccessUserData) => {
				
					this.HEADERS.Authorization = AccessUserData.getAuthorization();
					
					resolve(this.HEADERS);
				}).catch((error) => {
					reject(error);
				});
			} else {
				resolve(this.HEADERS);
			}

		});
	}

	private parseObjectToQueryParams(queryParams: any): string {
		let queryParamsBuild = "";
		if (queryParams) {
			Object.keys(queryParams).forEach((key, index) => {
				if (index == 0) {
					queryParamsBuild = "?"
				} else {
					queryParamsBuild += "&"
				}
				queryParamsBuild += key + "=" + queryParams[key];
			});
			return queryParamsBuild;
		}
		return queryParamsBuild;
	}

	private createEndpoint(endPoint: string, params?: any, secondEndPoint?: string, queryParams?: any): Promise<string> {
    console.log('endpoint' , endPoint);
    console.log('params', params);
    console.log('second endpoin', secondEndPoint);
    console.log('query params', queryParams);
		return new Promise((resolve) => {
			let endPointBody;
			if (secondEndPoint) {
				if ((params["id2"]) != undefined) {
					endPointBody = (this.AUTH_SERVER_ADDRESS + this.selectEnpoint(endPoint)[0] + '/' + params["id"] + '/' + secondEndPoint + '/' + params["id2"]) + this.parseObjectToQueryParams(queryParams);
				}
				else {
					endPointBody = (this.AUTH_SERVER_ADDRESS + this.selectEnpoint(endPoint)[0] + '/' + params["id"] + '/' + secondEndPoint) + this.parseObjectToQueryParams(queryParams);
				}
				resolve(endPointBody);
			}
			else if (params) {
				if (params["id"]) {
          endPointBody = (this.AUTH_SERVER_ADDRESS + this.selectEnpoint(endPoint)[0] + '/' + params["id"] + this.parseObjectToQueryParams(queryParams));
        }
				else {
					endPointBody = (this.AUTH_SERVER_ADDRESS + this.selectEnpoint(endPoint)[0] + '/' + '0' + this.parseObjectToQueryParams(queryParams));
				}
				resolve(endPointBody);
			}
			else {
				endPointBody = (this.AUTH_SERVER_ADDRESS + this.selectEnpoint(endPoint)[0] + this.parseObjectToQueryParams(queryParams));
				resolve(endPointBody);
			}
		});
	}

	public createRequestPost(endPoint: string, object?: any, params?: any, secondEndPoint?: string, queryParams?: any): Promise<any> {
		if (Capacitor.getPlatform() !== 'web'){
		return new Promise((resolve, reject) => {
			this.createHeaders(endPoint).then((headers) => {
				this.http.setDataSerializer('json');
        console.log('llega aqui', endPoint);
        console.log('llega aqui params', params);
				this.createEndpoint(endPoint, params, secondEndPoint, queryParams).then((endPointBody: string) => {
          if (endPoint === 'publications.multimedia') {
            if(object['publication_id']){
              endPointBody = endPointBody + '/'+ object.publication_id;
            }
          }else if(endPoint == 'profile/multimedia'){
            if(object['profile_id'] ){
              endPointBody = endPointBody.replace('profile/multimedia','profile/'+object['profile_id']+'/multimedia');
            }
          }else if(endPoint == 'comments.multimedia') {
            if (object['comment_id']) {
              endPointBody = endPointBody +'/' + object.comment_id;
            }
          }
					console.log("endpointBody", endPointBody);
          console.log("body", object);

					this.http.post(endPointBody, object, headers).then((data) => {
						resolve(data.data);
					}).catch((error) => {
						console.error(error);
						this.utils.checkErrors(error).then((requestResponse) => {
							//this.utils.alertPresent('Errors', requestResponse, 'OK');
							reject(error);
						}).catch((error) => {
							reject(error);
						});
					});
				}).catch((error) => {
					console.error(error)
					reject(error);
				});
			}).catch((error) => {
				console.error(error);

				reject(error);
			});
		});
	}else {
		return this.createWebRequestPost(endPoint, object, params, secondEndPoint, queryParams);
	}
	}
	public createWebRequestPost(endPoint: string, object?: any, params?: any, secondEndPoint?: string, queryParams?: any): Promise<any> {
		return new Promise((resolve, reject) => {
		  this.createHeaders(endPoint).then((headers) => {
			this.createEndpoint(endPoint, params, secondEndPoint, queryParams).then((endPointBody: string) => {
			  if (endPoint === 'publications.multimedia') {
				if (object?.publication_id) {
				  endPointBody = endPointBody + '/' + object.publication_id;
				}
			  } else if (endPoint === 'profile/multimedia') {
				if (object?.profile_id) {
				  endPointBody = endPointBody.replace('profile/multimedia', 'profile/' + object.profile_id + '/multimedia');
				}
			  } else if (endPoint === 'comments.multimedia') {
				if (object?.comment_id) {
				  endPointBody = endPointBody + '/' + object.comment_id;
				}
			  }
	  
			  console.log("endpointBody", endPointBody);
			  console.log("body", object);
	  
			  const requestOptions: RequestInit = {
				method: 'POST',
				headers: headers,
				body: JSON.stringify(object)
			  };
	  
			  fetch(endPointBody, requestOptions)
				.then(response => response.json())
				.then(data => resolve(data))
				.catch(error => {
				  console.error(error);
				  this.utils.checkErrors(error)
					.then(requestResponse => {
					  //this.utils.alertPresent('Errors', requestResponse, 'OK');
					  reject(error);
					})
					.catch(error => reject(error));
				});
			}).catch((error) => {
			  console.error(error);
			  reject(error);
			});
		  }).catch((error) => {
			console.error(error);
			reject(error);
		  });
		});
	  }
	  

  public createRequestGet(endPoint: string, queryParams?: any, params?: any, secondEndPoint?: string): Promise<any> {
	if (Capacitor.getPlatform() !== 'web'){
  return new Promise((resolve, reject) => {
			this.createHeaders(endPoint).then((headers) => {
				this.http.setDataSerializer('json');
				this.createEndpoint(endPoint, params, secondEndPoint).then((endPointBody: string) => {
          console.log('endpointbody', endPointBody);
					this.http.get(endPointBody, queryParams, headers).then((data) => {
            // console.log("params",params);
            // console.log("Data",data.data);

						resolve(data.data);
					}).catch((error) => {
						if (error.status === 419) {
                            console.log('Response status is 419');
                            this.showUnauthenticatedAlert(); // Handle 419 with SwalAlert
                            console.log('showUnauthenticatedAlert() called');
                        }
						this.utils.checkErrors(error).then((requestResponse) => {
							//this.utils.alertPresent('Errors', requestResponse, 'OK');
							reject(error);
						}).catch((error) => {
							reject(error);
						});
					
					});
				}).catch((error) => {
					reject(error);
				});
			}).catch((error) => {
				reject(error);
			});
		});
	}else {
			return this.createWebRequestGet(endPoint, queryParams, params, secondEndPoint);
		}
	}
	public createWebRequestGet(endPoint: string, queryParams?: any, params?: any, secondEndPoint?: string): Promise<any> {
		return new Promise((resolve, reject) => {
		  this.createHeaders(endPoint).then((headers) => {
			this.createEndpoint(endPoint, params, secondEndPoint).then((endPointBody: string) => {
			  console.log('endpointbody', endPointBody);
	  
			  const requestOptions: RequestInit = {
				method: 'GET',
				headers: headers
			  };
	  
			  let url = new URL(endPointBody);
			  if (queryParams) {
				Object.keys(queryParams).forEach(key => {
				  url.searchParams.append(key, queryParams[key]);
				});
			  }
	  
			  fetch(url.toString(), requestOptions)
				.then(response => {
				  if (!response.ok) {
					if (response.status === 419) {
						console.log('Response status is 419');
						this.showUnauthenticatedAlert(); // Handle 419 with SwalAlert
						console.log('showUnauthenticatedAlert() called');
					}
					throw new Error(`Network response was not ok: ${response.status} - ${response.statusText}`);

				  }
				  return response.text(); // Use response.text() for non-JSON responses
				})
				.then(data => resolve(data)) // Resolve with the raw data
				.catch(error => {
					this.utils.checkErrors(error).then((requestResponse) => {
						//this.utils.alertPresent('Errors', requestResponse, 'OK');
						reject(error);
					}).catch((error) => {
						reject(error);
					});

				
				});
			}).catch((error) => {
			  reject(error);
			});
		  }).catch((error) => {
			reject(error);
		  });
		});
	  }
	  
	  
	public createRequestPut(endPoint: string, object?: any, params?: any, secondEndPoint?: string, queryParams?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.createHeaders(endPoint).then((headers) => {
				this.http.setDataSerializer('json');
				this.createEndpoint(endPoint, params, secondEndPoint, queryParams).then((endPointBody: string) => {
					this.http.put(endPointBody, object, headers).then((data) => {
						resolve(data.data);
					}).catch((error) => {
						this.utils.checkErrors(error).then((requestResponse) => {
							this.utils.alertPresent('Errors', requestResponse, 'OK');
							reject(error);
						}).catch((error) => {
							reject(error);
						});
					});
				}).catch((error) => {
					reject(error);
				});
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public createRequestDelete(endPoint: string, object?: any, params?: any, secondEndPoint?: string, queryParams?: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.createHeaders(endPoint).then((headers) => {
				this.http.setDataSerializer('json');
				this.createEndpoint(endPoint, params, secondEndPoint, queryParams).then((endPointBody) => {
          console.log('delete endpoint',endPoint);
          console.log('delete endpointbody', endPointBody);
					this.http.delete(endPointBody, (object != null) ? object.queryParams : undefined, headers).then((data) => {
						resolve(data.data);
					}).catch((error) => {
						this.utils.checkErrors(error).then((requestResponse: RequestResponse) => {
							this.utils.alertPresent('Errors', requestResponse, 'OK');
							reject(error);
						}).catch((error) => {
							reject(error);
						});
					});
				}).catch((error) => {
					reject(error);
				});
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private showUnauthenticatedAlert() {
		Swal2.fire({
			title: 'No autenticado',
			text: 'La sesión no está activa. Porfavor inicia sesión.',
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText:'Cancelar',
			confirmButtonColor: 'green',
			color: '#ffffff',
			background: '#010f4e',
			confirmButtonText: 'OK'
		}).then((result) => {
			if (result.isConfirmed) {
				// Handle logout process here
				//this.logout_button();
				this.router.navigate(['/login']);
			}
		});
	}
		/*
	logout_button() {
	
		this.utils.loadingPresent().then(() => {
			this.authService.logout().finally(() => {
				this.chatService.logoutSuscribers();
				this.nativeStorage.clear(); // Just clear native storage
				this.utils.loadingDismiss();
				this.navCtrl.navigateRoot('starting-page');
				window.location.reload();
			});
		});
	}*/
	
}
