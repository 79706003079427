import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { FormServiceService } from "src/app/services/publications/form-service.service";
import  Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
@Component({
  selector: 'app-principal-bienes-feex',
  templateUrl: './principal-bienes-feex.component.html',
  styleUrls: ['./principal-bienes-feex.component.scss'],
})
export class PrincipalBienesFeexComponent implements OnInit {

  public step: number = 1;
  public buttonSee: string = "ver";
  constructor(public formservice: FormServiceService,
    public modalController: ModalController,
    public navCtrl: NavController,
    public alertController: AlertController,
    private request: RequestService,
    ) {
    this.formservice.feexpublication.title = this.formservice.title
    this.formservice.feexpublication.description = this.formservice.description
    this.formservice.feexpublication.publication_type = this.formservice.publication_type
    this.formservice.feexpublication.quienpuedever = this.formservice.quienpuedever
    this.formservice.feexpublication.is_feed = this.formservice.is_feed;
    this.formservice.feexpublication.ubicacion=this.formservice.normalpublication.ubicacion;
  }

  ngOnInit() { 

  }

  //funcion para el boton siguiente
  next() {
    
    console.log(this.formservice.feexpublication)
    if (this.step < 7) {
      if( !this.formservice.feexpublication.title  ||     !this.formservice.feexpublication.description ||     !this.formservice.feexpublication.publication_type  ||    !this.formservice.feexpublication.ubicacion){
        Swal2.fire({
          title: 'Error',
          text: 'Hubo un error inesperado',
          icon: 'error',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        return;
      }
      if(this.step===1){
if( this.formservice.feexpublication.category && this.formservice.feexpublication.marca && this.formservice.feexpublication.stock){
  this.step++;
}else{
  Swal2.fire({
    title: 'Error',
    text: 'Por favor llena todos los campos, ya que son esenciales.',
    icon: 'error',
    confirmButtonText: 'Entendido',
    color: '#ffffff',
    background: '#010f4e',
  });
}
      }else if(this.step===2){
        if( this.formservice.feexpublication.accessories && this.formservice.feexpublication.additional_price){
          this.step++;
        }else{
          Swal2.fire({
            title: 'Error',
            text: 'Por favor llena todos los campos, ya que son esenciales.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }
      }else if(this.step===3){
        if( this.formservice.feexpublication.envio && this.formservice.feexpublication.envio_gratis){
          if(this.formservice.feexpublication.envio === 'Enviar con Shooting Stars'){
     
                this.step++;
  
          }else{
            this.step++;
          }

        }else{
          Swal2.fire({
            title: 'Error',
            text: 'Por favor llena todos los campos, ya que son esenciales.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }
      }else if(this.step===4){
        if( this.formservice.feexpublication.producto && this.formservice.feexpublication.garantia && this.formservice.feexpublication.Alto && this.formservice.feexpublication.Largo && this.formservice.feexpublication.Ancho && this.formservice.feexpublication.weight){


        if(this.formservice.feexpublication.garantia ==='Sin garantía'){
          this.step=6;
        }else{
          this.step++;
        }
      }else{
        Swal2.fire({
          title: 'Error',
          text: 'Por favor llena todos los campos, ya que son esenciales.',
          icon: 'error',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
      }
      }else if(this.step===5){
        if( this.formservice.feexpublication.tiempo_garantia && this.formservice.feexpublication.dia_mes){
          this.step++;
          this.formservice.feexpublication.tiempo_garantia = this.formservice.feexpublication.tiempo_garantia + this.formservice.feexpublication.dia_mes;
        }else{
          Swal2.fire({
            title: 'Error',
            text: 'Por favor llena todos los campos, ya que son esenciales.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }

      }else if(this.step===6){
        if(this.formservice.feexpublication.location && this.formservice.feexpublication.latitud && this.formservice.feexpublication.longitud && this.formservice.feexpublication.country && this.formservice.feexpublication.postal_code && this.formservice.feexpublication.calle && this.formservice.feexpublication.name && this.formservice.feexpublication.Ciudad && this.formservice.feexpublication.Estado){
          Swal2.fire({
            title: 'Procesando...',
            text: 'La dirección se está procesando.',
            icon: 'success',
            timer: 45000, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
          this.request.createRequestPost('handleShipping', {getRates:true,name:this.formservice.feexpublication.name, calle:this.formservice.feexpublication.calle, Ciudad:this.formservice.feexpublication.Ciudad, Estado:this.formservice.feexpublication.Estado, country:this.formservice.feexpublication.country, postal_code:this.formservice.feexpublication.postal_code, publication_verification:true })
          .then((data) => {
            console.log('data',data);
            if(data.payload.rates){
              this.step=7;
              this.buttonSee = "oculto";
            }
         
          });
    
         
        }else{
          Swal2.fire({
            title: 'Error',
            text: 'Por favor llena todos los campos, ya que son esenciales.',
            icon: 'error',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }

      }
   

    }
    if (this.step === 7) {
      this.buttonSee = "oculto";
    }
   
  }

  //cerrar el modal
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  //abrir un mensaje de salir de la publicacion
  async back() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Salir de la publicación',
      message: 'Si desea salir se eliminará todo su avance',
      buttons: [
        {
          text: 'Salir',
          handler: () => {
            this.dismiss();
          }
        },
        {
          text: 'Continuar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }

}
