import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import {UploadMultimediaService} from "src/app/services/upload/upload-multimedia.service";
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import Swal2 from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Platform, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
declare const TiKTok: any; 
@Component({
  selector: 'app-tp-post',
  templateUrl: './tp-post.component.html',
  styleUrls: ['./tp-post.component.scss'],
})

export class TpPostComponent implements OnInit {
  posts;
  constructor(
    private request: RequestService,
    private uploadMultimedia: UploadMultimediaService,
    private formService:FormServiceService,
    private http: HttpClient,
    public modalController:ModalController,
    public router:Router,
  ) { 
    
  }

  ngOnInit() {
  
  }
  getPosts(){
    this.request.createRequestPost('uploadThirdPartyMedia', { popup_section: this.formService.popup_section, get: true })
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      if(data.mediaResponse.length == 0){
        Swal2.fire({
          title: 'Error al encontrar publicaciones!',
          text: 'No pudimos encontrar tus publicaciones',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
        });
      }else{
        console.log(data);
        this.formService.tp_publications=data.mediaResponse;
        this.posts=data.mediaResponse;
        this.chunkPublications();
        this.router.navigate(['/home/ecosystem-page']);
        this.formService.myTP_POSTS=true;
     
      }
      this.modalController.dismiss();


     
    });
  }
  
  private chunkPublications() {
    const chunkSize = 4;
    const chunkedPublications = [];
    let index = 0;
  
    while (index <  this.posts.length) {
      const chunk =  this.posts.slice(index, index + chunkSize);
      if (chunk.length > 0) {
        chunkedPublications.push(chunk);
      }
      index += chunkSize; // Update the index by the chunkSize, not the chunk length
    }
  
    this.formService.tp_ChunkedPublications = chunkedPublications;
  }
 
  /*
  postMediaToS3(){
    const posts=this.posts;
    posts.forEach(post => {
      this.downloadMedia(post.id, post.media_url).then(s3Url => {
        post.media_url = s3Url; // Update the media_url with the new S3 URL
        this.request.createRequestPost('uploadThirdPartyMedia', { link: s3Url, id: post.id })
          .then((finalData) => {
            console.log('Request history final data:', finalData);
            Swal2.fire({
              title: 'Exito al subir multimedia!',
              text: 'Publicaciones realizadas exitosamente',
              icon: 'success',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
          })
          .catch((error) => {
            console.error('Error uploading media:', error);
            Swal2.fire({
              title: 'Error al subir multimedia!',
              text: error,
              icon: 'warning',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
            });
          });
      });
    });
  }
  
  downloadMedia(id: number, mediaUrl: string): Promise<string> {
    // Make an HTTP GET request to download the media
    return this.http.get(mediaUrl, { responseType: 'blob' })
      .toPromise()
      .then(response => {
        // Assuming you have a method to upload the blob to S3 and get the new URL
        return this.postMedia(response, id);
      })
      .catch(error => {
        console.error('Error downloading media:', error);
        return null; // Return null or handle error accordingly
      });
  }
  
  postMedia(blob: Blob, id: number): Promise<string> {
    // Implement the logic to upload the media to S3 and get the new URL
    return this.uploadMultimedia.post(blob, 'filename').then(({ link }) => {
      console.log('Uploaded media URL:', link);
      return link; // Return the S3 URL
    });
  }
  */
}
