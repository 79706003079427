export class MediaItem {

    mediaPath: string;

	typeData: string;
	nameFile: string;
	size: number;
blob?:any;
    id: number;
    index: number;
    route: string;
    status: string;
    url: string;
  localUrl: string;
  type: string;
  is_premium: number;

    constructor(objectMedia? ){
        if(objectMedia != null){
            this.id = (objectMedia.id)? parseInt(objectMedia.id) : undefined;
            this.index =  (objectMedia.index)? parseInt(objectMedia.index): 0;
            this.nameFile = (objectMedia.name)? objectMedia.name : undefined;
            this.route = (objectMedia.route)? objectMedia.route: undefined;
            this.size = (objectMedia.size)? parseFloat(objectMedia.size): 0;
            this.status =  (objectMedia.status)? objectMedia.status: "Esperando Media";
            this.typeData = (objectMedia.type)? objectMedia.type: undefined;
            this.url = (objectMedia.url)? objectMedia.url: undefined;
            this.mediaPath = (objectMedia.mediaPath)? objectMedia.mediaPath: undefined;
            this.localUrl = (objectMedia.localUrl)? objectMedia.localUrl: undefined;
            this.type = (objectMedia.type)? objectMedia.type: undefined;
            this.is_premium = (objectMedia.is_premium)? objectMedia.is_premium: 0;
        }
        else{
            this.id = undefined;
            this.index = 0;
            this.nameFile = undefined;
            this.route = undefined;
            this.size = 0;
            this.status = "Esperando Media";
            this.typeData = undefined;
            this.url = undefined;
            this.mediaPath = undefined;
            this.type = undefined;
        }
    }
}
