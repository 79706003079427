import { MediaItem } from "../../Media/media-item";
import { ReactionContainerClass } from "../../Reactions/ReactionContainer/reaction-container-class";
import { SellerProfile } from "../../User/Store/seller-profile";
import { CommentClass } from "../Comments/comment-class";
import { PublicationClass } from "../publication-class";

export class Feex extends PublicationClass {
    name;
    calle;
    Ciudad;
    Estado;
    country;
    weight;
    Largo;
    Ancho;
    Alto;
    latitud;
    longitud;
    location;
    postal_code;
    //Multimedia
    doesItHaveMultimedia: boolean;
    multimediaCounter: number;
    multimediaContainer: Array<MediaItem>;
    public multimedia: Array<MediaItem> = new Array<MediaItem>()
    //Feex Publication Data 
    title: string;
    description: string;
    quienpuedever: string;
    publication_type: string;
    is_feed:boolean;

    //feex1
    category: string;
    marca: string;
    ubicacion: string;
    stock: string;
    //feex2
    accessories: string;
    additional_price: number;
    //feex3
    envio: string;
    envio_gratis: string;
    //feex4
    producto: string;
    garantia: string;
    //feex5
    tiempo_garantia: string;
    dia_mes: string;
    price;
    constructor(publication?: any, userProfile?: SellerProfile, reactionContainer?: Array<ReactionContainerClass>, ownReaction?: any) {
        super(publication, userProfile, reactionContainer, ownReaction);
        if (publication && publication.container) {
            //Multimedia
            this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
            this.multimediaCounter = (publication.container.multimediaCounter) ? parseInt(publication.container.multimediaCounter) : 0;
            this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
                this.multimediaContainer = multimedia;
            });
            //Feex Publication Data
            this.title = (publication.container.title) ? publication.container.title : null;
            this.description = (publication.container.description) ? publication.container.description : null;
            this.quienpuedever = (publication.container.quienpuedever) ? publication.container.quienpuedever : null;
            this.is_feed = (publication.container.is_feed) ? publication.container.is_feed : null;
            //feex1
            this.category = (publication.container.category) ? publication.container.category : null;
            this.marca = (publication.container.marca) ? publication.container.marca : null;
            this.ubicacion = (publication.container.ubicacion) ? publication.container.ubicacion : null;
            this.stock = (publication.container.stock) ? publication.container.stock : null;

            //feex2
            this.accessories = (publication.container.accessories) ? publication.container.accessories : null;
            this.additional_price = (publication.container.additional_price) ? publication.container.additional_price : null;
            this.price=this.additional_price;

            //feex3
            this.envio = (publication.container.envio) ? publication.container.envio : null;
            this.envio_gratis = (publication.container.envio_gratis) ? publication.container.envio_gratis : null;

            //feex4
            this.producto = (publication.container.producto) ? publication.container.producto : null;
            this.garantia = (publication.container.garantia) ? publication.container.garantia : null;

            //feex5
            this.tiempo_garantia = (publication.container.tiempo_garantia) ? publication.container.tiempo_garantia : null;
            this.dia_mes = (publication.container.dia_mes) ? publication.container.dia_mes : null;

        } else {
            //Multimedia
            this.doesItHaveMultimedia = false;
            this.multimediaCounter = 0;
            this.multimediaContainer = new Array<MediaItem>();
            //Feex Publication Data
            this.title = undefined;
            this.description = undefined;
            this.quienpuedever = undefined;
            this.is_feed = undefined;
            this.category = undefined;
            this.marca = undefined;
            this.ubicacion = undefined;
            this.stock = undefined;
            this.accessories = undefined;
            this.additional_price = undefined;
            this.envio = undefined;
            this.envio_gratis = undefined;
            this.producto = undefined;
            this.garantia = undefined;
            this.tiempo_garantia = undefined;
        }
    }

    public updatePublication(publication: any, userProfile: SellerProfile, reactionContainer: Array<ReactionContainerClass>, ownReaction: any) {
        //Multimedia
        this.doesItHaveMultimedia = (publication.multimediaCounter == 0) ? false : true;
        this.multimediaCounter = (publication.multimediaCounter) ? parseInt(publication.multimediaCounter) : this.multimediaCounter;
        this.transformMediaToMediaItem(publication.container.multimediaContainer).then((multimedia) => {
            this.multimediaContainer = multimedia;
        });
        //Publication
        this.id = (publication.id) ? parseInt(publication.id) : this.id;
        this.publication_type = (publication.publication_type_string) ? publication.publication_type_string : this.publication_type;
        this.status_string = (publication.status_string) ? publication.status_string : this.status_string;
        //Interaction
        this.reactionCounter = (publication.reactionCounter) ? parseInt(publication.reactionCounter) : this.reactionCounter;
        this.reactionContainer = reactionContainer;
        this.ownReaction = ownReaction;
        this.commentCounter = (publication.commentCounter) ? parseInt(publication.commentCounter) : this.commentCounter;
        //User
        this.userProfile = userProfile;
        this.corp = (publication.corp) ? publication.corp : this.corp;
        this.is_saved = (publication.is_saved) ? publication.is_saved : this.is_saved;
        this.owner = (publication.owner) ? publication.owner : this.owner;
    }


    setMultimedia(mediaItem) {
        if (this.publication_type == "event") {
            if (this.doesItHaveMultimedia) {
                this.deleteMultimedia(0);
            }
        }
        this.multimediaContainer.push(mediaItem);
        this.doesItHaveMultimedia = true;
    }

    deleteMultimedia(index: number) {
        this.multimediaContainer.splice(index, 1);
        if (this.multimediaContainer.length == 0) {
            this.doesItHaveMultimedia = false;
        }
    }

    getPublicationsDataToSend() {
        let data = {
            doesItHaveMultimedia: this.doesItHaveMultimedia,
            publication_type: this.publication_type,
            data: {
                title: this.title,
                description: this.description,
                price: this.additional_price, 
                stock: this.stock,
                isNew: this.producto,
                ubicacion: this.ubicacion,
                postal_code: this.postal_code,
                location: this.location,
                branch: this.marca,
                shipping: this.envio,
                envio_gratis: this.envio_gratis,
                sub_categorie: this.category,
                tiempo_garantia: this.tiempo_garantia,
                garantia: this.garantia,
            
             
                accessories: this.accessories,
                latitud:this.latitud,
                longitud:this.longitud,
                Largo: this.Largo,
                Ancho:this.Ancho,
                Alto:this.Alto,
                weight:this.weight,
                name:this.name,
                calle:this.calle,
                Ciudad:this.Ciudad,
                Estado:this.Estado,
                country:this.country
            }
        }
        
        return data;
    }

    transformMediaToMediaItem(objectMedia) {
        return new Promise<Array<MediaItem>>((resolve) => {
            let MediaContainer = new Array<MediaItem>();
            if (objectMedia) {
                objectMedia.forEach(media => {
                    MediaContainer.push(new MediaItem(media));
                });
                resolve(MediaContainer);
            }
            else {
                resolve(MediaContainer);
            }

        });
    }
}
