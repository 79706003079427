import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { element } from 'protractor';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ElementRef,
  ViewChild,
  Inject,
  EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy
} from '@angular/core';
import { GooglemapsService } from './googlemaps.service';
import { ModalController } from '@ionic/angular';
import { Geolocation, PositionOptions } from '@capacitor/geolocation';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestService } from 'src/app/services/request/request.service';
declare var google;

// interface Marker {
//       position: {
//         lat: number;
//         lng: number;
//       };
//       titulo: string;
//       subtitulo?:string;
//     }

@Component({
  selector: 'google-maps',
  templateUrl: './googlemaps.component.html',
  styleUrls: ['./googlemaps.component.scss'],
})
export class GooglemapsComponent implements OnInit, OnChanges, OnDestroy {
  driverlat;
  driverlng;
  UberCoordinates;
  Driver_Address;
  // Define a variable to hold the previous marker
previousMarker: google.maps.Marker | null = null;
  @Input() getposition_oninit;
  @Input() BBVA_locations:any;
  @Input() showDismiss:boolean=false;
  public vehicleMarker:any;
  private MapsIsOpened:boolean=false;
  private directionsService: google.maps.DirectionsService;
  private directionsRenderer: google.maps.DirectionsRenderer;
  @Input() Partida:any;
  @Input() Pedidos:any;
  @Input() ConductorEnCamino:any;
  @Input() Destino:any;
  // coordenadas cancun Q.Roo
  @Input() ConductorFugaz:any;
  @Input() position = {
    lat: 21.16193,
    lng: -86.851632,
  };

  label = {
    titulo: 'Producto/Servicio',
    subtitulo: 'Ubicación del producto o servicio',
  };
  private apiKey: string = 'AIzaSyDotFU2YUDcy3nIlgiv-sEqFSpCFzRZQ-A';
  map: any;
  marker: any;
  infowindow: any;
  positionSet:any;

  location: any={
      latitude: 21.16193,
      longitude: -86.851632,

  };
  keys: string[] = ["latitude","longitude"];

  keysAdress: string = "Dirección";
  Address: string = "";

  @ViewChild('map') divMap: ElementRef;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document,
    private googlemapsService: GooglemapsService,
    public modalController: ModalController,
    private formService:FormServiceService,
    private http:HttpClient,
    private request: RequestService,
  ) {}

  ngOnInit(): void {
    this.MapsIsOpened=true;
    this.init();
    this.formService.googleMapOpened=true;
    console.log('position ->', this.position);
    Geolocation.requestPermissions();
 
    if (this.ConductorFugaz===true){
      this.getposition();
      // Initialize a variable to hold the user's location marker
let userLocationMarker = null;
      // Check if the Geolocation API is available in the browser
if ('geolocation' in navigator) {
  // Watch the user's current position and update the marker
  const watchId = navigator.geolocation.watchPosition((position) => {
    const userLatLng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    
    // If the user location marker is already created, update its position
    if (userLocationMarker) {
      userLocationMarker.setPosition(userLatLng);
    } else {
      // Create a new marker if it doesn't exist
      userLocationMarker = new google.maps.Marker({
        position: userLatLng,
        map: this.map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE, // Use a circle as the icon
          fillColor: 'blue', // Blue color for the dot
          fillOpacity: 1,
          scale: 6, // Adjust the size as needed
          strokeWeight: 0,
        },
        zIndex: google.maps.Marker.MAX_ZINDEX + 1, // Ensure the user's location marker is on top
      });
    }
    setInterval(() => {
    if(this.formService.selectedVehicle && this.ConductorFugaz){

      
       this.ReverseGeoCodeAndReturn(position);
      if (this.Driver_Address){

     
      this.request.createRequestPost('VehicleCoordinates', { method:'postCoordinates' ,lat: position.coords.latitude, lng:position.coords.longitude, id:this.formService.selectedVehicle.id, Address:this.Driver_Address})
      .then(response => {
    
          if (response) {


          } else {
              console.error('No response from API call.');
          }
      })
      .catch(error => {
          console.error('Error from API call:', error);  // Log error from API call
      });
    }
    }else if(this.Pedidos && this.ConductorEnCamino){
          //  setInterval(() => {
      if(this.MapsIsOpened && !this.UberCoordinates){
        this.request.createRequestPost('VehicleCoordinates', { method:'getCoordinates' })
        .then(response => {
          if (response && typeof response === 'string') {
            try {
              response = JSON.parse(response);
              console.log('getRecordByToken ios:',response);
            } catch (error) {
              console.error("Error parsing data:", error);
            }
            }
            if (response) {
  // Assuming response contains latitude and longitude
  this.updateVehicleMarker(response.lat, response.lng);
  
            } else {
                console.error('No response from API call.');
            }
        })
        .catch(error => {
            console.error('Error from API call:', error);  // Log error from API call
        });
      }
   // }, 24300);
    }
  }, 24300);
    // Optionally, you can also center the map on the user's location
   // map.setCenter(userLatLng);

    // Optionally, you can also zoom in to a specific level
    //map.setZoom(15); // Adjust the zoom level as needed
  });

  // To stop watching the user's location, you can use the following code:
  // navigator.geolocation.clearWatch(watchId);
} else {
  // Geolocation is not available in this browser
  console.error('Geolocation is not available in this browser.');
}
     // setInterval(() => {
				this.getposition();
			 // }, 300300);
    }

    if(this.BBVA_locations){
      this.request.createRequestPost('get_BBVA_Locations', {})
.then((data) => {
console.log(data);

})
.catch((error) => {

  console.error('An error occurred:', error);
  // Handle the error here, such as displaying a message to the user or logging it for further investigation.
});/*
      this.getposition();
      if (this.location.latitude ){
        this.request.createRequestPost('requestBBVA_ATM_or_branches_locations', {LocationType: this.BBVA_locations, lat: this.location.latitude, lng: this.location.longitude})
        .then(response => {
          console.log(response);
          let image;
          if (this.BBVA_locations === 'atms'){
            if (response && response.success.data) {
              const locations = response.success.data;
        
              // Iterate through each location and add a marker
              locations.forEach(locationData => {
                const location = locationData.Brand[0].ATM[0];
                const position = {
                  lat: parseFloat(location.Location.PostalAddress.GeoLocation.GeographicCoordinates.Latitude),
                  lng: parseFloat(location.Location.PostalAddress.GeoLocation.GeographicCoordinates.Longitude)
                };
           // Set a different icon for ATMs and branches
           image= 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/atm+Background+Removed.png';
                // Create a marker for each location
                const marker = new google.maps.Marker({
                  position,
                  map: this.map,
                  title: location.Location.PostalAddress.AddressLine,
                  icon: {
                    url: image,
                    scaledSize: new google.maps.Size(81, 81) // Set the size of the marker
                  }
                });
        
             
                // Set the icon for the marker
               // marker.setIcon(image);
              });
           
          }else{
            console.log('no data')
          }
          }else if (this.BBVA_locations === 'branches'){
      // Assuming this.data contains the retrieved data
  if (response && response.result.code === 200) {
    const branches = response.data.branches;
  
    // Iterate through each branch and add a marker
    branches.forEach(branch => {
      const position = {
        lat: branch.address.geolocation.latitude,
        lng: branch.address.geolocation.longitude
      };
  
      // Create a marker for each branch
      const marker = new google.maps.Marker({
        position,
        map: this.map,
        title: branch.description
      });
      image = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/branches+Background+Removed.png';
      // Optionally, you can add additional information to the marker, such as a custom icon
       marker.setIcon(image);
    });
  }
          }
      })
      .catch(error => {
          console.error('Error from API call:', error);  // Log error from API call
      });
      }*/
 
    }
  }
  updateVehicleMarker(lat, lng) {
    const newPosition = { lat, lng };
    if (this.vehicleMarker) {
      // Marker exists, update its position
      this.vehicleMarker.setPosition(newPosition);
    } else {
      // Marker does not exist, create it
      this.vehicleMarker = new google.maps.Marker({
        position: newPosition,
        map: this.map,
        icon: {
          url: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/Frame_506.png',
          scaledSize: new google.maps.Size(69, 69) // Set the size of the icon
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.MapsIsOpened=false;
  }
  async init() {
    this.googlemapsService
      .init(this.renderer, this.document)
      .then(() => {
        this.initMap();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  initMap() {
    const position = this.position;

    let latLng = new google.maps.LatLng(position.lat, position.lng);

    let mapOptions = {
      center: latLng,
      zoom: 9,
      clickableIcons: false,
    };
    /**Crando un marker en el mapa */
    this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);
    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      draggable: true,
     
    });
    setTimeout(() => {
      if (this.Partida && this.Destino){

        this.handlePlaceChanged();
        
            }   
    this.clickHandleEvent();
    this.infowindow = new google.maps.InfoWindow();
    
    this.addMarker(position);
    this.setInfoWindow(this.marker, this.label.titulo, this.label.subtitulo);
    

    // Registrando el evento drag, en este caso imprime
    // en consola la latitud y longitud

  }, 900); 

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.Partida || changes.Destino) {
      // Remove existing markers
      this.removeMarkers();
      // Call the handlePlaceChanged function when Partida or Destino inputs change
      this.handlePlaceChanged();
      if(this.ConductorFugaz===true){
        this.marker= null;
        this.marker = new google.maps.Marker({
          map: this.map,
          animation: google.maps.Animation.DROP,
          draggable: true,
         
        });
        this.clickHandleEvent();
      this.infowindow = new google.maps.InfoWindow();
      this.setInfoWindow(this.marker, this.label.titulo, this.label.subtitulo);
      //this.getposition();
  
      }
    }
  }
  private removeMarkers() {
    // Clear all markers on the map
    if (this.map) {
      this.map = new google.maps.Map(this.divMap.nativeElement, {
        center: { lat: 0, lng: 0 }, // Center the map at a neutral location
        zoom: 2, // Adjust the zoom level as needed
        clickableIcons: false,
      });
    }
  }
  ngAfterViewInit(){
    if(this.getposition_oninit){
      this.getposition();
    }
  }
  private handlePlaceChanged() {
    // Get the map
    const map = this.map;
  
    // Get the addresses for points A and B
    const startPointAddress = this.Partida;
    const destinationAddress = this.Destino;
  
    // Create geocoder instance
    const geocoder = new google.maps.Geocoder();
  
    // Define the size of the custom markers
    const markerSize = new google.maps.Size(45, 45); // Adjust the size as needed
  
    // Initialize an empty bounds object
    const bounds = new google.maps.LatLngBounds();
  
    // Geocode the addresses to get coordinates for points A and B
    geocoder.geocode({ address: startPointAddress }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0].geometry) {
        const startPoint = results[0].geometry.location;
        const markerA = new google.maps.Marker({
          map: map,
          position: startPoint,
          title: 'Partida', // You can customize the title
          icon: {
            url: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/inicio_punto_de_partida.png',
            scaledSize: markerSize,
          },
        });
  
        // Extend the bounds to include marker A
        bounds.extend(startPoint);
  
        // Add click event listener to display an InfoWindow when marker A is clicked
        markerA.addListener('click', () => {
          const infowindowA = new google.maps.InfoWindow({
            content: startPointAddress,
          });
          infowindowA.open(map, markerA);
        });
        
      } else {
        console.error('Geocoding for startPoint failed:', status);
      }
    });
  
    geocoder.geocode({ address: destinationAddress }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0].geometry) {
        const destination = results[0].geometry.location;
        const markerB = new google.maps.Marker({
          map: map,
          position: destination,
          title: 'Destino', // You can customize the title
          icon: {
            url: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/meta_destion_icono.png',
            scaledSize: markerSize,
          },
        });
  
        // Extend the bounds to include marker B
        bounds.extend(destination);
  
        // Add click event listener to display an InfoWindow when marker B is clicked
        markerB.addListener('click', () => {
          const infowindowB = new google.maps.InfoWindow({
            content: destinationAddress,
          });
          infowindowB.open(map, markerB);
        });
  
        // Set the map's center to fit the bounds
        map.fitBounds(bounds);
     
      } else {
        console.error('Geocoding for destination failed:', status);
      }
    });
    
  }
  
  
  
  
  
  
  clickHandleEvent() {
    this.map.addListener('click', (event: any) => {
      console.log('evento:', event);

      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      console.log("posicion actual:",position);
      this.location= {
            latitude:event.latLng.lat(),
            longitude:event.latLng.lng()
      }
 
this.ReverseGeocode(position);
      this.addMarker(position);
      this.formService.landpublication.latitud=event.latLng.lat()
      this.formService.landpublication.longitud=event.latLng.lng()
      this.formService.earthpublication.latitud=event.latLng.lat()
      this.formService.earthpublication.longitud=event.latLng.lng()
      this.formService.eventospublication.latitud=event.latLng.lat()
      this.formService.eventospublication.longitud=event.latLng.lng()

      
    });
  }

  addMarker(position: any): void {
    // If there's a previous marker, remove it from the map
    if (this.previousMarker) {
        this.previousMarker.setMap(null);
    }

    let latLng = new google.maps.LatLng(position.lat, position.lng);

    // Create the new marker
    this.marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: 'blue',
            fillOpacity: 1,
            scale: 8,
            strokeColor: 'blue',
            strokeWeight: 1
        }
    });
    this.formService.feexpublication.latitud=position.lat;
    this.formService.feexpublication.longitud=position.lng;
    this.formService.landpublication.latitud=position.lat
    this.formService.landpublication.longitud=position.lng;
    this.formService.earthpublication.latitud=position.lat
    this.formService.earthpublication.longitud=position.lng;
    // Save the current marker as the previous marker for next use
    this.previousMarker = this.marker;

    // Pan to the new marker position
    this.map.panTo(position);
    this.positionSet = position;
}

  async getposition() {
    var options: PositionOptions = {
      enableHighAccuracy: false,
    };
    Geolocation.getCurrentPosition(options).then(
      (res) => {
        this.location = res.coords;
        console.log('location:', this.location);
        this.position = this.location;

      //   this.keys = Object.keys(this.location);
      //   console.log('key:', this.keys);

        const position = {
          lat: this.location.latitude,
          lng: this.location.longitude,
        };
        //     titulo: this.label.titulo,

        this.ReverseGeocode(position);
        this.formService.feexpublication.latitud=this.location.latitude;

 this.formService.feexpublication.longitud=this.location.longitude;
        //   console.log('ubicacion:', ubicacion);

        this.addMarker(position);
        this.formService.landpublication.latitud=this.location.latitude
        this.formService.landpublication.longitud=this.location.longitude
        this.formService.earthpublication.latitud=this.location.latitude
        this.formService.earthpublication.longitud=this.location.longitude
        this.formService.feexpublication.latitud=this.location.latitude;
        this.formService.feexpublication.longitud=this.location.longitude;
        this.formService.feexpublication.location=this.Address;
      },
      (err) => {
        //alert(JSON.stringify(err));
      }
    );
  }


  setInfoWindow(marker: any, titulo: string, subtitulo: string) {
    const contentString =
      '<div id="contentInsideMap">' +
      '<div>' +
      '</div>' +
      '<p style="font-weight: bold; margin-bottom: 5px; color: black">' +
      titulo +
      '</p>' +
      '<div id="bodyContent">' +
      '<p class"normal m-0">' +
      subtitulo +
      '</p>' +
      '</div>' +
      '</div>';
    this.infowindow.setContent(contentString);
    this.infowindow.open(this.map, marker);
  }

  ReverseGeocode(position){
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${this.apiKey}`;

        this.http.get(geocodingUrl).toPromise().then((response: any) => {
          console.log('response:', response);
            const firstResult = response.results[0];
  
            if (firstResult && firstResult.formatted_address) {
                this.Address = firstResult.formatted_address;
                this.formService.address=this.Address;
                console.log("Address:", this.Address);
                this.formService.feexpublication.location=this.Address;
            } else {
                console.error("Error reverse geocoding: No formatted address found in response.");
                this.Address = "Address not available";
            }
        }).catch((error: any) => {
            console.error("Error reverse geocoding:", error);
            this.Address = "Address not available";
        });
  }
  ReverseGeoCodeAndReturn(position){
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${ position.coords.latitude},${position.coords.longitude}&key=${this.apiKey}`;

    this.http.get(geocodingUrl).toPromise().then((response: any) => {
      console.log('response:', response);
        const firstResult = response.results[0];

        if (firstResult && firstResult.formatted_address) {
          let Address;
            Address = firstResult.formatted_address;
            console.log("Address:", Address);
          this.Driver_Address =Address;
        } else {
            console.error("Error reverse geocoding: No formatted address found in response.");
            this.Address = "Address not available";
        }
    }).catch((error: any) => {
        console.error("Error reverse geocoding:", error);
        this.Address = "Address not available";
    });
  }
  dismiss(){
    this.modalController.dismiss();
  }
}
