import { Component, OnInit, Input } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
import {   ModalController } from '@ionic/angular';
import { Chart, registerables, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { CandlestickController, CandlestickElement } from 'chartjs-chart-financial';
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  dateCounts: { [key: string]: number } = {};
  userData;
  userCount;
  diamonds
	@Input() menu: boolean=false;
  @Input() option;
  constructor(private formService:FormServiceService,
    private request: RequestService,
    private modalctrl:ModalController,

		) { 
      Chart.register(...registerables, CandlestickController, CandlestickElement);

    }
    dismiss(){
      this.modalctrl.dismiss();
    }

  ngOnInit() {
    if(!this.menu && this.option){
      
      this.request.createRequestPost('graphUser', {option:this.option  })
      .then((final_data) => {
        if (final_data && typeof final_data === 'string') {
          try {
            final_data = JSON.parse(final_data);
            console.log('getRecordByToken ios:',final_data);
          } catch (error) {
            console.error("Error parsing data:", error);
          }
          }
     console.log('final_data',final_data);
     this.diamonds=final_data.Diamonds;
     this.userCount=final_data.userCount;
     this.userData=final_data.userData;
     this.createGraph();
      
      })
    }
  }
  graph_data(item: string) {
    console.log('Graph data for:', item);
    // Your logic to handle the graph data based on the item clicked
    this.formService.open_stats_option(item);
  }
  createGraph() {
    // Initialize dateCounts object
    this.dateCounts = {};
  
    // Accumulate records for each date
    this.userData.forEach(item => {
      const date = new Date(item.created_at).toISOString().split('T')[0];
      if (this.dateCounts[date]) {
        if(item.views){
          this.dateCounts[date]+= +item.views;
        }else if(item.instances){
          this.dateCounts[date]+= +item.instances;
        }else{
          this.dateCounts[date]++;
        }
      } else {
        if(item.views){ 
           this.dateCounts[date] =+item.views;  
        }else if(item.instances){ 
          this.dateCounts[date] =+item.instances;  
       }else{
          this.dateCounts[date] = 1;
        }
      
      }
    });
  console.log(this.dateCounts)
    // Create dataPoints array for the chart
    const dataPoints = Object.keys(this.dateCounts).map(date => ({
      x: new Date(date).getTime(), // Convert date to timestamp (number)
      o: 0,
      h: this.dateCounts[date],
      l: 0,
      c: this.dateCounts[date]
    }));
  
    // Sort the dataPoints by date
    dataPoints.sort((a, b) => a.x - b.x);
  
    // Create unique, sorted labels array (convert timestamps back to Date objects)
    const labels = dataPoints.map(point => new Date(point.x));
  
    // Get the context of the canvas element
    const ctx = (document.getElementById('myChart') as HTMLCanvasElement).getContext('2d');
  
    // Create the Chart
    new Chart(ctx, {
      type: 'candlestick',
      data: {
        labels: labels,
        datasets: [{
          label: 'Number of Records Over Time',
          data: dataPoints,
          borderColor: '#010f41', // Color of the candle borders
          backgroundColor: '#010f41', // Color of the candles
        }]
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              /*
              displayFormats: {
                day: 'MMM d',
                week: 'MMM d',
                month: 'MMM yyyy',
                year: 'yyyy'
              }*/
            },
            ticks: {
              color: 'white' // White color for x-axis labels
            },
            grid: {
              color: 'gray' // Lighter white for grid lines
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: 'white', // White color for y-axis labels
              stepSize: 1 // Each record has a value of 1
            },
            grid: {
              color: 'gray' // Lighter white for grid lines
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: 'white' // White color for legend text
            }
          }
        }
      }
    });
  }
  
  
}