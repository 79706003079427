import { Injectable, NgModule } from '@angular/core';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { RequestService } from '../request/request.service';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import moment from 'moment';
import { HTTP } from '@ionic-native/http/ngx';
import {HttpClient, HttpEvent, HttpEventType, HttpRequest} from '@angular/common/http';
import * as xmlparse2 from 'xml2js';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {Platform} from '@ionic/angular';
import {Observable} from 'rxjs';
import {CameraServiceService} from "../multimedia/camera-service.service";
import {Capacitor} from "@capacitor/core";
import {FilePath} from '@ionic-native/file-path/ngx';
import {FileChooser} from '@ionic-native/file-chooser/ngx';
//import {Base64} from '@ionic-native/base64/ngx';
import Swal2 from 'sweetalert2';
import { isVideo,isImage, video_extensions, image_extensions } from 'src/app/utils';
import * as uuid from 'uuid';
import { HttpHeaders } from '@angular/common/http';
import { Key } from 'protractor';
import { File } from '@ionic-native/file/ngx';
interface UploadResponse {
  link: string;
  mimeType: string;
}
@Injectable({
  providedIn: 'root'
})


export class UploadMultimediaService {
  image = '';
  multimediaImage:Array<object>=[]
  blob: any;
  filename = '';
  type = '';
  video: string | null = null;
  Blob2
  blobAR:Array<object>=[]

  constructor(
    private transfer: FileTransfer,
    private androidPermissions: AndroidPermissions,
    private request: RequestService,
    private fileOpener: FileOpener,
    private http: HTTP,
    private httpc: HttpClient,
    private localNotification: LocalNotifications,
    private platform: Platform,
    private cameraService: CameraServiceService,
    public filePath: FilePath,
    public fileChooser: FileChooser,
    private file: File
    //public base64: Base64,
  ) {}

  ngOnInit() {
    this.image = null;
    this.blob = null;
    this.type = '';
    this.filename = '';

  }
  askForPermission() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)
      .then((result) => {
        if (result.hasPermission) {
          console.log('Permissions already done');
        } else {
          this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)
            .then((result2) => {
              if (result2.hasPermission) {
                console.log('Permissions already done by request');
              } else {
                console.log('Permissions denied');
              }
            });
        }
      });
  }

  public getS3Form(mimeType: string, filename: string) {
    return new Promise((resolve, reject) => {
      const params = {
        mimeType: mimeType,
        filename: filename,
      };
  
      this.request.createRequestPost('s3_form', params)
            .then((result) => {
              if (Capacitor.getPlatform() !== 'web') {
                result = JSON.parse(result);
              }else{
                if (typeof result === 'string') {
                  result = JSON.parse(result);
                     console.log('json result publication service.ts', result);
                   
                   }else {
                    result = result; 
                   }
              }
                
                // Log the received pre-signed URL
                console.log('Received pre-signed URL:', result.s3_form.url);
                
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
    });
}
  

  //public uploadFile(file, filename?) {
    //return new Promise((resolve, reject) => {
      //primera notificacion para indicar el usuario que no cierre
      //if (this.platform.is('android') || this.platform.is('ios')) {
        //this.localNotification.schedule({
          //title: 'Operación: subiendo multimedia al servidor',
          //text: 'No cierres la app hasta que esta notificación desaparezca',
          //foreground: true,
        //});
     // }
      //this.cameraService.presentToastWithOptions('Subiendo multimedia al servidor',
        //'No cierres la app hasta que esta una nueva notificación aparezca');
     // const idNotification = 0;
     // this.getS3Form().then((result) => {
       // const params = result;
        //const fields = params['s3_form'];
        //const formdata = new FormData();
        //const url = 'https://s3-us-east-1.amazonaws.com/laniakea-multimedia/';
       // if(filename != null){
         // this.filename = filename;
        //}
        //this.uploadToS3(file, this.filename,formdata,url, fields)
          //.then((stringMedia)=>{
            //if (this.platform.is('android') || this.platform.is('ios')) {
              //this.localNotification.cancelAll();
            //}
           // this.cameraService.presentToastWithOptions();
         //   this.image = '';
        //    resolve(stringMedia);
      //    }).catch((errorS3Upload) => {
    //        reject(errorS3Upload);
     //     });
    //  });
  //  });
 // }

 uploadToS3(blobfile: Blob, filename: string, formdata: FormData, url: string, fields: any): Promise<UploadResponse> {
  console.log("s3 functione", blobfile)
  return new Promise((resolve, reject) => {
    if (!blobfile || !blobfile.type) {
      console.error('File object is undefined or has no type property');
      reject('File object is undefined or has no type property');
      return;
    }
    
    const idNotification = 0;
    const fileExtension = blobfile.type.split('/').pop();
    const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, '');
    const key = 'public/' + moment().format('YYMMDDHHmmss') + '_' + filenameWithoutExtension + '.' + fileExtension;
    const encodedKey = encodeURIComponent(key);
    const uploadUrl = url.replace('{filename}', encodedKey);

    console.log('Upload URL:', uploadUrl);

    const headers = new HttpHeaders().set('Content-Type', blobfile.type);

    const req = new HttpRequest('PUT', uploadUrl, blobfile, {
      reportProgress: true,
      headers: headers,
    });

    this.httpc.request(req).subscribe(
      (event: HttpEvent<any>) => {
        console.log('HTTP event:', event);

        if (event.type === HttpEventType.UploadProgress) {
          if (this.platform.is('android') || this.platform.is('ios')) {
            this.localNotification.update({
              id: idNotification,
              progressBar: { value: ((event.loaded * 100) / event.total) },
            });
          }
          if (event.loaded == event.total) {
            if (this.platform.is('android') || this.platform.is('ios')) {
              this.localNotification.update({
                id: idNotification,
                progressBar: { value: 100 },
              });
            }
          }
        } else if (event.type === HttpEventType.Response) {
          const uploadedUrl = `${uploadUrl.split('?')[0]}`;
          console.log('Uploaded URL:', uploadedUrl);
          resolve({
            link: uploadedUrl,
            mimeType: blobfile.type,
          });
        }
      },
      (error) => {
        console.error('HTTP request error:', error);
        reject('Error al subir multimedia: ' + error);
      }
    );
  });
}


  uploadFileHttp(file: any, formData: FormData, url: any): Observable<HttpEvent<any>>{
    return this.httpc.post(url , formData, {responseType: 'text', reportProgress: true, observe: 'events'
    });
  }

  pickmultimedia(){
    return new Promise((resolve, reject) =>{
      this.fileChooser.open().then((fileuri)=>{
        this.filePath.resolveNativePath(fileuri).then((resolvenativePath)=>{
          this.image= Capacitor.convertFileSrc(resolvenativePath);
          if (isVideo(this.image)==false && isImage(this.image)==false){
            Swal2.fire({
              title: 'Error de archivo',
              text: 'El formato del archivo no es valido solo videos o imagenes',
              icon: 'warning',
              confirmButtonText: 'Entendido',
              color: '#ffffff',
              background: '#010f4e',
              position: 'top-end',
              timerProgressBar: true,
              toast: true,
            });
          }else{
            this.readBlobFile(resolvenativePath)
              .then((response)=>{
               resolve(true);
            });
          }
        });
      });
    });
  }

  // Update the readBlobFile method in UploadMultimediaService class
  public readBlobFile(src: string): Promise<void> {
    console.log("Source file:", src);
    return new Promise((resolve, reject) => {
      this.file
        .resolveLocalFilesystemUrl(src)
        .then((entry) => {
          if (entry.isFile) {
            return this.readFile(entry.nativeURL);
          } else {
            throw new Error("The provided source is not a file.");
          }
        })
        .then((arrayBuffer) => {
          const ext = src.substr(src.lastIndexOf('.') + 1);
          const filename = uuid.v4() + '.' + ext;
          const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
          this.blob = blob;
          this.filename = filename;
          console.log("Blob and filename after reading file:", this.blob, this.filename);
          resolve();
        })
        .catch((error) => {
          console.log("Error reading blob file:", error);
          reject(error);
        });
    });
  }
  
  private readFile(path: string): Promise<ArrayBuffer> {
    console.log("Reading file:", path);
    return new Promise((resolve, reject) => {
      window['resolveLocalFileSystemURL'](path,
        (fileEntry) => {
          fileEntry.file(
            (file) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result as ArrayBuffer);
              };
              reader.onerror = (error) => {
                console.log("Error reading file:", error);
                reject(error);
              };
              reader.readAsArrayBuffer(file);
            },
            (error) => {
              console.log("Error getting file:", error);
              reject(error);
            }
          );
        },
        (error) => {
          console.log("Error resolving local file system URL:", error);
          reject(error);
        }
      );
    });
  }
  
  
  

  


  public uploadFile(blobfile, filename?): Promise<UploadResponse> {
    console.log("Filename before calling uploadtoS3:", filename);
    console.log('File object before calling uploadToS3:', blobfile);
    return new Promise((resolve, reject) => {
      //primera notificacion para indicar el usuario que no cierre
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.localNotification.schedule({
          title: 'Subiendo multimedia al servidor',
          text: 'No cierres la app hasta que esta notificación desaparezca',
          foreground: true,
        });
      }
      this.cameraService.presentToastWithOptions('La multimedia se está trascendiendo...',
        'codificando: no cierres la app hasta que una nueva notificación aparezca');
      const idNotification = 0;
      console.log('About to call this.getS3Form() with file.type:', blobfile.type);
      this.getS3Form(blobfile.type, filename).then((result) => {
        const params = result;
        const fields = params['s3_form'];
        const formdata = new FormData();
        const url = params['s3_form']['url'];
        if(filename != null){
          this.filename = filename;
        }
        console.log('About to call this.uploadToS3() with file:', blobfile, 'filename:', filename, 'formdata:', formdata, 'url:', url, 'fields:', fields);
        this.uploadToS3(blobfile, filename,formdata,url, fields)
        .then(({ link, mimeType }) => {
            if (this.platform.is('android') || this.platform.is('ios')) {
              this.localNotification.cancelAll();
            }
            this.cameraService.presentToastWithOptions();
            this.image = '';
            resolve({ link: link, mimeType: blobfile.type });
          }).catch((errorS3Upload) => {
            reject(errorS3Upload);
          });
      });
    });
  }
  


  //async uploadToS3(file: any, filename: string, url: string): Promise<any> {
    
    //if (!file) {
      //console.error('File object is undefined');
 //     return Promise.reject('File object is undefined');
 //   }
  
   // console.log('Uploading file:', file, 'with filename:', filename, 'to URL:', url);
   // const headers = new HttpHeaders().set('Content-Type', file.type);
//    const req = new HttpRequest('PUT', url, file, {
  //    reportProgress: true,
 //     headers: headers,
   // });
//    console.log('File type:', file.type);
 //   console.log('File name:', filename);
 //   console.log('Headers:', headers);
    
  //  return new Promise((resolve, reject) => {
  //    this.httpc.request(req).subscribe(
  //      (event: HttpEvent<any>) => {
   //       if (event.type === HttpEventType.UploadProgress) {
   //         console.log(`Progress: ${(event.loaded * 100) / event.total}%`);
   //       } else if (event.type === HttpEventType.Response) {
  //          const uploadedUrl = `${url.split('?')[0]}`;
  //          console.log('File uploaded successfully:', uploadedUrl);
   //         resolve(uploadedUrl);
 //         }
 //       },
 //       (error) => {
  //        console.error('Error uploading file:', error);
 //         reject('Error uploading file: ' + error);
 //       }
 //     );
 //   });
  //}
  
  
  post(blobfile?, filename?): Promise<UploadResponse> {
    return new Promise((resolve, reject) => {
      //if (blobfile != null) {
        //this.blob = blobfile;
      //}else {
        //blobfile = this.blob;
        //filename = this.filename;
      //}
      console.log("Uploading blob:", blobfile);
      this.uploadFile(blobfile, filename)
        .then((response: UploadResponse) => {
          console.log('Uploaded successfully. Link:', response.link, 'Type:', response.mimeType);
          resolve(response);
        }).catch((error) => {
          console.error('Error in post method:', error);
          reject(error);
        });
    });
  }
  


    

}

