import { Component, Input, OnInit } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Platform, PopoverController } from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
@Component({
	selector: 'app-pop-options-list',
	templateUrl: './pop-options-list.component.html',
	styleUrls: ['./pop-options-list.component.scss'],
})
export class PopOptionsListComponent implements OnInit {
	select_dates;
	@Input() title;
	@Input() optionList: Array<any>;
	fromDate;
	toDate;
	selected_option;
	public main_available_sections: Array<string> = ["EN LA APP", "TERCEROS"];
	public main_icons_for_sections: Array<string> = [ "cloud-upload-outline", "cloud-done-outline"];
	constructor(
		private popoverController: PopoverController,
		private vibrationCtrl: Vibration,
		private platform: Platform,
		public formservice:FormServiceService
	) { }
	call_emitChange(event){
	
		this.formservice.popup_section=event.detail.value;
		if(this.formservice.popup_section==='EN LA APP'){
			
		}
	}
	ngOnInit() {
		this.optionList.push({text: "Cancelar", icon: "close-circle-sharp"});
		if (this.platform.is('android') || this.platform.is('ios')) {
			this.vibrationCtrl.vibrate(400);
		}

    console.log("option list",this.optionList);


	}

	selectOption(option) {
		if(this.formservice.popup_section==='TERCEROS' && option.img=='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/9fcef7b1abac126b507befcc77a0c277.png' ){
			this.select_dates=true;
			this.selected_option=option;
		}else{

		if(option.text != "Cancelar"){
			this.popoverController.dismiss(option);
			
		}
		else{
			this.popoverController.dismiss();
		}
			
	}
	}
	onDateChange(type: string, event: any) {
		if (type === 'from') {
		  this.fromDate = event.detail.value;
		  console.log('From Date:', this.fromDate); // Output will be in 'YYYY-MM-DD' format
		} else if (type === 'to') {
		  this.toDate = event.detail.value;
		  console.log('To Date:', this.toDate); // Output will be in 'YYYY-MM-DD' format
		}
	  }
	  finished_selecting_dates(){
		  let option=this.selected_option;
		  option['fromDate']=this.fromDate;
		  option['toDate']=this.toDate;
		this.popoverController.dismiss(option);
	  }
}
