import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { FormBuilder,FormGroup,Validators } from "@angular/forms";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { FormServiceService } from "src/app/services/publications/form-service.service";
import Swal2 from "sweetalert2"

@Component({
  selector: 'app-principal-land',
  templateUrl: './principal-land.component.html',
  styleUrls: ['./principal-land.component.scss'],
})
export class PrincipalLandComponent implements OnInit {
  public step: number = 1;
  public buttonSee:string="ver";
  public formApp: FormGroup;

  constructor(
    private formBuilder:FormBuilder,
    private formservice:FormServiceService,
    public modalController:ModalController,
    public navCtrl: NavController,
    public alertController: AlertController) {
    this.formservice.landpublication.title=this.formservice.title
    this.formservice.landpublication.description=this.formservice.description
    this.formservice.landpublication.publication_type=this.formservice.publication_type
    this.formservice.landpublication.quienpuedever=this.formservice.quienpuedever
    this.formservice.landpublication.is_feed=this.formservice.is_feed
    this.formservice.landpublication.multimedia=this.formservice.multimedia
   }
   

  ngOnInit() {

    this.formApp= this.formBuilder.group({
      app1:['',[Validators.required]]





    })
  }

  //Funcion para el boton de siguiente
  next() {
      console.log(this.formservice.landpublication)
    switch (this.step < 5) {
      case this.step === 1:
        
        if (this.formservice.landpublication.category && this.formservice.landpublication.type_of_service && this.formservice.landpublication.service) {
          this.step++
          
        }else{
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Asegurate de seleccionar categorías y clasificar la publicación',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }
        
        break;

      case this.step === 2:
         
      if (this.formservice.landpublication.IsReservaCalendario === true) {
       
          this.step++
        
      }else{
        console.log(this.formservice.landpublication.zonaHoraria);
        console.log(this.formservice.landpublication.IsReservaCalendario);
        Swal2.fire({
          title: 'Advertencia!',
          text: 'Asegurate de elegir una Zona Horaria y horario de disponibilidad',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
      }
      
      break;

      case this.step === 3:
       
        if (this.formservice.landpublication.latitud && this.formservice.landpublication.longitud) {
          
          this.step++
          
        }else{
          Swal2.fire({
            title: 'Advertencia!',
            text: ' Asegurate de poner tu ubicacion en el mapa',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }
        
        break;
      case this.step === 4:
       
        if ((this.formservice.landpublication.cantidad)) {
          this.step++
          
        }else{
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Asegurate de poner el costo que tendra tu servicio',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
        }
        
        break;  
  
      default:
        break;
    }
    
  
    // if (this.step <= 5) {
    //   this.step++;
    // }
    // if(this.step===5){
    //   this.buttonSee="oculto"
    // }

  
  }

  //cerrar el modal
  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  //abrir un mensaje de salir de la publicacion
  async back() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Salir de la publicación',
      message: 'Si desea salir se eliminará todo su avance',
      buttons: [
        {
          text: 'Salir',
          handler: () => {
            this.formservice.title="";
            this.formservice.description="";
            this.formservice.is_feed=false;
            this.formservice.quienpuedever="Para todos";
            this.formservice.landpublication.price=0;
            this.formservice.landpublication.cantidad=undefined;
            this.formservice.landpublication.category=undefined;
            this.formservice.landpublication.service=undefined;
            this.formservice.landpublication.type_of_service= undefined;
            this.formservice.landpublication.agenda=undefined;
            this.formservice.landpublication.ubicacion=undefined;
            this.formservice.landpublication.zonaHoraria=undefined;
            this.formservice.landpublication.capacidad=undefined;
            this.formservice.landpublication.title2="";
            this.formservice.landpublication.description2="";
            this.formservice.landpublication.price2=0;
            this.formservice.landpublication.fecha_inicial=undefined;
            this.formservice.landpublication.fecha_final=undefined;
            this.formservice.landpublication.hora_inicial=undefined;
            this.formservice.landpublication.hora_final=undefined;
            this.dismiss();
          }
        },
        {
          text: 'Continuar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });
  
    await alert.present();
  }



  Boton2(){

    
  }


  /** Apartado de validaciones */

  validar1(){

   
    
    if (this.step <= 5 && this.formservice.landpublication.category) {
      
      this.step++;
    }
    else{
      alert("Falta seleccionar una categoria")

    }
    if(this.step===5){
      this.buttonSee="oculto"
    }

    
  }

  validar2(){

    
    
    if (this.step <= 5 ) {
      
      this.step++;
    }
    else{
      alert("Falta llenar los campos")

    }
    if(this.step===5){
      this.buttonSee="oculto"
    }

    
  }

  validar4(){

  
    
    if (this.step <= 5) {
      if((this.formservice.landpublication.cantidad)&&(this.formservice.landpublication.title2)&&(this.formservice.landpublication.description2)&&(this.formservice.landpublication.price)){
        
        this.step++;

      }
      else{
        alert("Falta llenar los campos")
  
      }
      
      
    }
    
    if(this.step===5){
      this.buttonSee="oculto"
    }

    
  }

  

 
}
