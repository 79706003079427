import { Component, OnInit, Input } from '@angular/core';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import Swal2 from 'sweetalert2';
import { RequestService } from 'src/app/services/request/request.service';
import { Platform, PopoverController } from '@ionic/angular';
@Component({
  selector: 'app-bienes6',
  templateUrl: './bienes6.component.html',
  styleUrls: ['./bienes6.component.scss'],
})
export class Bienes6Component implements OnInit {
// Define the input property to receive the value from the parent component
@Input() show_save_address_button: boolean = false;
  constructor(
    private formservice : FormServiceService,
    private request: RequestService,
    private popoverController: PopoverController,
  ) { }

  ngOnInit() {}
  saveAddress(){

console.log(this.formservice.feexpublication.name);
console.log(this.formservice.feexpublication.postal_code);
console.log(this.formservice.feexpublication.calle);
console.log(this.formservice.feexpublication.Ciudad);
console.log(this.formservice.feexpublication.Estado);
console.log(this.formservice.feexpublication.country);
console.log(this.formservice.feexpublication.mail);
console.log(this.formservice.feexpublication.lada);
console.log(this.formservice.feexpublication.celular);
    this.request.createRequestPost('ShippingAddressesController', {insert:true,latitud:this.formservice.feexpublication.latitud, longitud:this.formservice.feexpublication.longitud, country:this.formservice.feexpublication.country, postal_code :this.formservice.feexpublication.postal_code,  calle: this.formservice.feexpublication.calle, name:this.formservice.feexpublication.name, Ciudad: this.formservice.feexpublication.Ciudad,Estado: this.formservice.feexpublication.Estado, ubicacion: this.formservice.feexpublication.location, email:this.formservice.feexpublication.mail,mobile_no:this.formservice.feexpublication.celular, phone_country: this.formservice.feexpublication.lada})
    .then((data) => {
      Swal2.fire({
        title: 'datos guardados',
        text: 'Se ha sido enviado exitosamente',
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
    
    }).catch((error) => {
      console.error('Failed to send notification:', error);
   
    });
    this.popoverController.dismiss();
  }
  getNumberFlag(number: string): string {
    // Define a mapping of country codes to flag emojis
    const flagEmojis: { [key: string]: string } = {
      '+93': '🇦🇫', //Afghanistan
      '+335':'🇦🇱',//Albania
      '+213':'🇩🇿', //Algeria
      '+376':'🇦🇩',//Andorra
      '+244':'🇦🇴',//Angola
      '+1268':'🇦🇬',//Antigua and Barbuda
      '+54':'🇦🇷',//Argentina
      '+374':'🇦🇲',//Armenia
      '+297':'🇦🇼',//Aruba
      '+61':'🇦🇺',//Australia
      '+43':'🇦🇹',//Austria
      '+994':'🇦🇿',//Azerbaijan
      '+1242':'🇧🇸',//Bahamas
      '+973':'🇧🇭',//Bahrain
      '+880':'🇧🇩',//Bangladesh
      '+1246':'🇧🇧',//Barbados
      '+375':'🇧🇾',//Belarus
      '+32':'🇧🇪',//Belgium
      '+501':'🇧🇿',//Belize
      '+229':'🇧🇯',//Benin
      '+975':'🇧🇹',//Bhutan
      '+591':'🇧🇴',//Bolivia
      '+387':'🇧🇦',//Bosnia and Herzegovina
      '+267':'🇧🇼',//Botswana
      '+55':'🇧🇷',//Brazil
      '+246':'🇮🇴',//British Indian Ocean Territory
      '+673':'🇧🇳', //Brunei
      '+359':'🇧🇬',//Bulgari
      '+226':'🇧🇫',//Burkina Faso
      '+257':'🇧🇮',//Burundi
      '+855':'🇰🇭', //Cambodia
      '+237':'🇨🇲',//Cameroon
      '+238':'🇨🇻',//Cape Verde
      '+599':'🇧🇶',//Caribbean Netherlands
      '+236':'🇨🇫',//Central African Republic
      '+234':'🇹🇩🇳🇬',//Chad AND NIGERIA
      '+56':'🇨🇱',//Chile
      '+86':'🇨🇳',//China
      '+57':'🇨🇴',//Colombia
      '+269':'🇰🇲',//COMOROS
      '+243':'🇨🇩',//CONGO
      '+242':'🇨🇬',//CONGO
      '+506':'🇨🇷',//COSTA RICA
      '+225':'🇨🇮',//cote d'ivoire
      '+385':'🇭🇷',//CROATIA
      '+53':'🇨🇺',//CUBA
      '+559':'🇨🇼',//Curaçao
      '+357':'🇨🇾',//CYPRUS
      '+420':'🇨🇿',//CZECH REPUBLIC
      '+45':'🇩🇰',//DENMARK
      '+253':'🇩🇯',//DJIBOUTI
      '+1767':'🇩🇲',//DOMINICANA
      '+593':'🇪🇨',//ECUADOR
      '+20':'🇪🇬', //EGIPTO
      '+503':'🇸🇻',//EL SALVADOR
      '+240':'🇬🇶',//EQUATORIAL GUINEA
      '+291':'🇪🇷',//ERITREA
      '+372':'🇪🇪',//ESTONIA
      '+251':'🇪🇹',//ETHIOPIA
      '+679':'🇫🇯',//FIJI
      '+358':'🇫🇮',//FINLAND
      '+33': '🇫🇷', // France
      '+594':'🇬🇫',//FRENCH GUIANA
      '+689':'🇵🇫',//FRENCH POLYNESIA
      '+241':'🇬🇦',//GABON
      '+220':'🇬🇲',//GAMBIA
      '+995':'🇬🇪',//GEORGIA
      '+49': '🇩🇪', // Germany
      '+233':'🇬🇭',//GHANA
      '+30':'🇬🇷',//GREECE
      '+1473':'🇬🇩',//GRENADA
      '+590':'🇬🇵',//GUADALOUPE
      '+1671':'🇬🇺',//GUAM
      '+502':'🇬🇹',//GUATEMALA
      '+224':'🇬🇳',//GUINEA
      '+245':'🇬🇼',//GUINEA BISSAU
      '+592':'🇬🇾',//GUYANA
      '+509':'🇭🇹',//HAITI
      '+504':'🇭🇳',//HONDURAS
      '+852':'🇭🇰',//HONG KONG
      '+36':'🇭🇺',//HUNGARY
      '+354':'🇮🇸',//ICELAND
      '+91':'🇮🇳',//INDIA
      '+62':'🇮🇩',//INDONESIA
      '+98':'🇮🇷',//IRAN
      '+964':'🇮🇶',//IRAQ
      '+353':'🇮🇪',//ireland
      '+972':'🇮🇱',//ISRAEL
      '+39':'🇮🇹🇻🇦',//ITALY AND VATICAN CITY
      '+1876':'🇯🇲',//JAMAICA
      '+81': '🇯🇵', // Japan
      '+962':'🇯🇴',//JORDAN
      '+254':'🇰🇪',//KENYA
      '+686':'🇰🇮',//KIRIBATI
      '+383':'🇽🇰',//KOSOVO
      '+965':'🇰🇼',//KUWAIT
      '+996':'🇰🇬',//KYRGYZSTAN
      '+856':'🇱🇦',//LAOS
      '+371':'🇱🇻',//LATVIA
      '+961':'🇱🇧',//LEBANON
      '+266':'🇱🇸',//LESOTHO
      '+231':'🇱🇷',//LIBERIA
      '+281':'🇱🇾', //LIBYA
      '+423':'🇱🇮',//LIECHTENSTEIN
      '+370':'🇱🇹',//LITHUANIA
      '+352':'🇱🇺', //LUXEMBOURG
      '+853':'🇲🇴',//MACAU
      '+389':'🇲🇰',//MACEDONIA
      '+261':'🇲🇬',//MADAGASCAR
      '+265':'🇲🇼',//MALAWI
      '+60':'🇲🇾',//MALAYSIA
      '+960':'🇲🇻',//MALDIVES
      '+223':'🇲🇱',//MALI
      '+356':'🇲🇹',//MALTA
      '+692':'🇲🇭',//MARSHALL ISLANDS
      '+596':'🇲🇶',//MARTINIQUE
      '+222':'🇲🇷',//MAURITANIA
      '+230':'🇲🇺',//MAURITIUS
      '+262':'🇾🇹🇷🇪',//Mayotte and Réunion
      '+52': '🇲🇽', // Mexico
      '+691':'🇫🇲',//MICRONESIA
      '+373':'🇲🇩',//MOLDOVA
      '+377':'🇲🇨',//MONACO
      '+976':'🇲🇳',//MONGOLIA
      '+382':'🇲🇪',//MONTENEGRO
      '+1664': '🇲🇸',  // Montserrat
      '+212':'🇪🇭🇲🇦',//Western Sahara and Morocco
      '+258':'🇲🇿',//MOZAMBIQUE
      '+95':'🇲🇲',//MYANMAR
      '+264':'🇳🇦', //NAMIBIA
      '+674':'🇳🇷',//NAURU
      '+977':'🇳🇵',//NEPAL
      '+31':'🇳🇱', //NETHERLANDS
      '+687':'🇳🇨',//NEW CALEDONIA
      '+64':'🇳🇿',//NEW ZEALAND
      '+505':'🇳🇮', //NICARAGUA
      '+227':'🇳🇪',//NIGER
      '+850':'🇰🇵',//NORTH KOREA
      '+47':'🇳🇴',//NORWAY
      '+968':'🇴🇲',//OMAN
      '+92':'🇵🇰',//PAKISTAN
      '+680':'🇵🇼',//PALAU
      '+970':'🇵🇸',//PALESTINE
      '+507':'🇵🇦',//PANAMA
      '+675':'🇵🇬',//PAPUA NEW GUINEA
      '+595':'🇵🇾',//PARAGUAY
      '+51':'🇵🇪',//PERU
      '+63':'🇵🇭',//PHILIPPINES
      '+48':'🇵🇱',//POLAND
      '+351':'🇵🇹',//PORTUGAL
      '+1787': '🇵🇷',  // Puerto Rico
      '+974':'🇶🇦',//QATAR
      '+40':'🇷🇴',//ROMANIA
      '+7':'🇷🇺',//RUSSIA AND KAZAKHSTAN FLAG
      '+250':'🇷🇼',//RWANDA
      '+1869':'🇰🇳',//SAINT KITTS AND NEVIS
      '+1758':'🇱🇨',//SAINT LUCIA
      '+1784':'🇻🇨',//SAINT VINCENT AND THE GRENADINES
      '+685':'🇼🇸',//SAMOA
      '+378':'🇸🇲',//SAN MARINO
      '+239':'🇸🇹',//São Tomé and Príncipe
      '+966':'🇸🇦',//SAUDI ARABIA
      '+221':'🇸🇳',//SENEGAL
      '+381':'🇷🇸',//SERBIA
      '+248':'🇸🇨',//SEYCHELLES
      '+232':'🇸🇱',//SIERRA LEONE
      '+65':'🇸🇬',//SINGAPORE
      '+421':'🇸🇰',//SLOVAKIA
      '+386':'🇸🇮',//SLOVENIA
      '+677':'🇸🇧',// SOLOMON ISLANDS
      '+252':'🇸🇴',//SOMALIA
      '+27':'🇿🇦', //SOUTH AFRICA
      '+82':'🇰🇷',//SOUTH KOREA
      '+211':'🇸🇸',//SOUTH SUDAN
      '+34':'🇪🇸',//SPAIN
      '+94':'🇱🇰',//SRI LANKA
      '+249':'🇸🇩',//SUDAN
      '+597':'🇸🇷',//SURINAME
      '+268':'🇸🇿',//SWAZILAND
      '+46':'🇸🇪',//SWEDEN
      '+41':'🇨🇭',//SWITZERLAND
      '+963':'🇸🇾',//SYRIA
      '+886':'🇹🇼',//TAIWAN
      '+992':'🇹🇯',//TAJIKISTAN
      '+255':'🇹🇿',//TANZANIA
      '+66':'🇹🇭',//THAILAND
      '+670':'🇹🇱',//TIMOR-LESTE
      '+228':'🇹🇬',//TOGO
      '+690': '🇹🇰',  // Tokelau
      '+676':'🇹🇴',//TONGA
      '+1868':'🇹🇹',//TRINIDAD AND TOBAGO
      '+216':'🇹🇳',//TUNISIA
      '+90':'🇹🇷',//TURKEY
      '+993':'🇹🇲',//TURKMENISTAN
      '+1649': '🇹🇨',  // Turks and Caicos Islands
      '+688':'🇹🇻',//TUVALU
      '+256':'🇺🇬',//UGANDA
      '+380':'🇺🇦',//UKRAINE
      '+971':'🇦🇪',//UNITED ARAB EMIRATES
      '+44': '🇬🇧🇮🇲🇯🇪🇬🇸🇸🇭', // // United Kingdom, Isle of Man, Jersey, South Georgia and the South Sandwich Islands, and Saint Helena, Ascension and Tristan da Cunha
      '+598':'🇺🇾',//URUGUAY
      '+998':'🇺🇿',//UZBEKISTAN
      '+678':'🇻🇺',//VANUATU
      '+58':' 🇻🇪',//VENEZUELA
      '+84':'🇻🇳',//VIETNAM
      '+967':'🇾🇪',//YEMEN
      '+260':'🇿🇲',//ZAMBIA
      '+263':'🇿🇼',//ZIMBABWE
      '+1': '🇺🇸🇨🇦🇩🇴🇵🇷', // United States
    };
  
 // Loop through the keys of flagEmojis to find a matching country code
 for (const countryCode in flagEmojis) {
  if (number.startsWith(countryCode)) {
      return flagEmojis[countryCode];
  }
}
    
    // Return a default flag emoji or handle the case when the country code is not recognized
    return '🌍'; // Earth globe emoji as a default
  }
}
