import { Component, OnInit, Input, SimpleChanges, OnChanges , OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestService } from 'src/app/services/request/request.service';
import Swal from 'sweetalert2';
import { ModalController } from '@ionic/angular';
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.page.html',
  styleUrls: ['./confirm-email.page.scss'],
})
export class ConfirmEmailPage implements OnInit, OnDestroy {
  @Input() amount: any;
  @Input() currency: any;
  @Input() destination: any;
  @Input() email: any;
  @Input() concepto: any;
  @Input() profile_id: any;
  @Input() metodo: any;
  public token: string;
  remainingTime: number;
  private countdownInterval: any;
  public rechargeMethod: string = "COINPAYMENT";
  @Input() dest_tag: any;
//CONFIRMACIÓN DE CORREO DE RETIROS
@Input() address: any;
@Input() currency2: any;

//CONFIRMACIÓN DE CORREO DE RETIROS
//CONFIRMACIÓN DE CORREO DE RETIROS
  confirmForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private request: RequestService,     private modalcontroller: ModalController,     private walletService: WalletServiceService,) { }

  ngOnInit() {
    this.startCountdown();
console.log('met_', this.rechargeMethod);
console.log('met__', this.metodo);

    
  }
 
  ngOnDestroy() {
    this.stopCountdown();
  }

  startCountdown() {
    this.remainingTime = 120; //299= 4 minutes and 59 seconds

    this.countdownInterval = setInterval(() => {
      this.remainingTime--;

      if (this.remainingTime <= 0) {
        // Stop the countdown when the remaining time reaches zero or below
        this.stopCountdown();
        // Perform any necessary actions when the countdown completes
        // For example, show a message to the user indicating the timeout
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.countdownInterval);
  }
  formatTime(time: number): string {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
  updateToken(event: any) {
    this.token = event.target.value;
  }

  Retirar(){
    if(this.metodo==='FIAT' || this.metodo==='CIE' || this.metodo==='VISA' || this.metodo==='BBVA' || this.metodo==='SPEI'){
      this.confirmEmailFIAT();
    }else if (this.metodo==='COINPAYMENT'){
      this.newPaymentCRIPTO();
    }
    
  }

  confirmEmailFIAT() {
    console.log('tok', this.token);
    console.log('profile_id', this.profile_id);
    console.log('amount', this.amount);
    console.log('currency', this.currency);
    console.log('destination', this.destination);
  
    if (this.token) {
      this.request.createRequestPost('verified-confirmation', { token: this.token, profile_id: this.profile_id, metodo: this.metodo, concepto: this.concepto })
        .then(response => {
          console.log('response', response);
          let responseData;
          if (Capacitor.getPlatform() !== 'web') {
           responseData = JSON.parse(response);
          }else{
            responseData = response;
          }
          console.log('response code', responseData.code);
  
          if (responseData.code === 200) {
            console.log('Token is correct, proceeding with fund transfer');
            let payoutData;
             payoutData = {
              amount: this.amount,
              //currency: this.currency,
              destination: this.destination,
              id: this.profile_id
              // Prepare your payout data here
            };
  if(this.metodo==='FIAT'){
          this.stripe_payout(payoutData);
            }else if(this.metodo==='SPEI'){
              payoutData = {
                amount: this.amount,
                //currency: this.currency,
                directionNumber: this.destination,
                id: this.profile_id
                // Prepare your payout data here
              };
              this.createWireTransfer(payoutData);
            }
          } else {
            console.log('Token is incorrect');
            Swal.fire({
              title: 'Email Confirmation Failed',
              icon: 'error',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger'
              },
              buttonsStyling: false,
              background: '#010f4e',
              iconColor: '#ffffff',
              text: responseData.title
            });
          }
        })
        .catch(error => {
          console.error('Verified confirmation request error', error);
          const errorData = JSON.parse(error);
          Swal.fire({
            title: 'Email Confirmation Failed',
            icon: 'error',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            background: '#010f4e',
            iconColor: '#ffffff',
            text: errorData.title
          });
        });
    }
  }
  dismiss(){
    this.modalcontroller.dismiss()
  }

  createWireTransfer(payoutData){
    this.request.createRequestPost('createWireTransfer', payoutData)
    .then(response => {
      console.log('Payout response', response);
      let payoutResponseData;
		  if (Capacitor.getPlatform() !== 'web') {
       payoutResponseData = JSON.parse(response);
      console.log('Payout response code', payoutResponseData.code);
      }else{
        payoutResponseData = response;
      }
      if (payoutResponseData.code === 200) {
        console.log('Fund transfer was successful');
        Swal.fire({
          title: 'Transferencia procesada con éxito',
          text: `Transferencia enviada de la cuenta de tu correo ${this.email}, por monto de $${this.amount}${this.currency}`,
          icon: 'success',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        }).then(() => {
          console.log("Alert displayed successfully.");
        }).catch((error) => {
          console.error("Error displaying alert:", error);
        });
        this.dismiss();
      } else {
        console.log('Fund transfer failed');
        // Handle failure here (e.g., show an error message to the user)
      }
    })
    .catch((error) => {
      console.error('Payout request error', error);
      // Handle network errors or other unexpected errors here
    });
  }
stripe_payout(payoutData){
  this.request.createRequestPost('Payout', payoutData)
  .then(response => {
    console.log('Payout response', response);
    const payoutResponseData = JSON.parse(response);
    console.log('Payout response code', payoutResponseData.code);

    if (payoutResponseData.code === 200) {
      console.log('Fund transfer was successful');
      Swal.fire({
        title: 'Transferencia procesada con éxito',
        text: `Transferencia enviada de la cuenta de tu correo ${this.email}, por monto de $${this.amount}${this.currency}`,
        icon: 'success',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      }).then(() => {
        console.log("Alert displayed successfully.");
      }).catch((error) => {
        console.error("Error displaying alert:", error);
      });
      this.dismiss();
    } else {
      console.log('Fund transfer failed');
      // Handle failure here (e.g., show an error message to the user)
    }
  })
  .catch((error) => {
    console.error('Payout request error', error);
    // Handle network errors or other unexpected errors here
  });
}
  newPaymentCRIPTO() {
    if (this.token) {
      this.request.createRequestPost('verified-confirmation', { token: this.token, profile_id: this.profile_id })
        .then(response => {
          console.log('response', response);
          const responseData = JSON.parse(response);
          console.log('response code', responseData.code);
  
          if (responseData.code === 200) {
            console.log('Token is correct, proceeding with fund transfer');
  
            return new Promise((resolve, reject) => {
              let deposit = {
                profile_id: this.profile_id,
                type: "WITHDRAW",
                qty: this.amount,
                address: this.address,
                currency2: this.currency2,
                method: this.rechargeMethod,
                currency: this.currency, 
                dest_tag: this.dest_tag
              }
              this.walletService.newWithDrawal(deposit).then((data) => {
                resolve(data);
                this.dismiss();
  
                // Show success message using SweetAlert
                Swal.fire({
                  title: 'Success',
                  text: 'Transfer successful',
                  icon: 'success',
                  confirmButtonText: 'OK'
                });
              }).catch((error) => {
                reject(error);
              });
            });
          }
        });
    }
  }
}  



