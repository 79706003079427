import { Injectable } from '@angular/core';
//import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Conversation } from 'src/app/Models/Classes/Chat/conversation';
import { Message } from 'src/app/Models/Classes/Chat/message';
import * as models from 'src/app/Models/Classes/graphql/models/models-graphql';
import { UserContact } from 'src/app/Models/Classes/User/user-contact';
import { Utils } from 'src/app/Models/Classes/utils';
import { FriendshipService } from '../relations/relations.service';
import { GraphQLAPIService } from '../request/GraphQl-Api.service';
import { CameraServiceService } from '../multimedia/camera-service.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { ForegroundService } from '@ionic-native/foreground-service/ngx';
import { promise } from 'selenium-webdriver';
import {CommentsService} from "../comments/comments.service";
import { ToastController } from '@ionic/angular';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import {Capacitor} from "@capacitor/core";
import { Howl } from 'howler';
import { ModalController } from '@ionic/angular';
import {ChimeContactComponent} from "src/app/chime-contact/meeting/chime-contact.component";
//import { Data } from 'phaser';
//import AWS from 'aws-sdk';
import {InlineBowsingComponent} from 'src/app/inline-bowsing/inline-bowsing.component';
@Injectable({
	providedIn: 'root'
})
export class ChatService {
		//chat tags start
		public main_actual_chat_tag: string = "normal";
		public main_available_chat_tags: Array<string> = ["archivados", "con llave", 'normal'];
		public main_icons_for_chat_tags: Array<string> = [ "archive-sharp", "finger-print-sharp", 'chatbox-outline'];
		//chat tags end
	locked_conversations: any[] = [];
	archived_conversations: any[] = [];
	soundTimeout;
	public sound: Howl;
	contentMessage;
	public Notificationuser_id: number= 0;
	public descriptionNotification: any= '';
	public isLogued: boolean = false;
	public index: number = 100;

	private isComplete: boolean = false;
	private timer: number = 100000; //100000
	public conversationsList: Array<Conversation> = new Array<Conversation>();
	public contactList: Array<UserContact> = new Array<UserContact>();
	private listSubscriptions: Array<any> = new Array<any>();
	public owner: UserContact;
	public conversationOpened: string;

	constructor(
		private Api: GraphQLAPIService,
		private utils: Utils,
		private localNotifications: LocalNotifications,
		//private backgroundMode: BackgroundMode,
		private nativeAudio: NativeAudio,
		private friendshipService: FriendshipService,
		private cameraService: CameraServiceService,
		private foreground: ForegroundService,
    private commentService: CommentsService,
	private request: RequestService,
	private modalController: ModalController,
	) {
		if (Capacitor.getPlatform() !== 'web') {
		this.getContactList().then((contactList) => {
			this.contactList = contactList;
	

			this.utils.getItem('ProfileData').then((profileData: string) => {
				try {
				
					this.owner = new UserContact(JSON.parse(profileData));
					
					this.resetConnection();
					this.resetConnections();
					this.buildService(this.owner).then((subscriptionList) => {
						this.isComplete = true;
					}).catch((error) => {
						console.error(error);
					});
				} catch (error) {
					console.error(error);
				}
			});
		});
	}else{
		this.getContactList();
		this.getFriends();
		this.utils.getItem('ProfileData').then((profileData: string) => {
			try {
		
					if (typeof profileData === 'string') {
						this.owner = new UserContact(JSON.parse(profileData));
						   console.log('json result publication service.ts', this.owner);
						 
						 }else {
							this.owner = new UserContact(profileData);
							console.log('else json result publication service.ts', this.owner);
						 }
				
				this.resetConnection();
				this.resetConnections();
				this.buildService(this.owner).then((subscriptionList) => {
					this.isComplete = true;
				}).catch((error) => {
					console.error(error);
				});
			} catch (error) {
				console.error(error);
			}
		});
	 
	}
	}

	/* ---------------------------- METODOS PARA VERIFICAR USARIOS CONECTADOS --------------------------------------------------------------------- */
	private resetConnection() {
		this.sendConnection()
		setInterval(() => {
			this.sendConnection()
		}, this.timer)
	}

	private resetConnections() {
		this.getIsUsersConnected();
		setInterval(() => {
			this.getIsUsersConnected();
		}, this.timer);
	}

	private getIsUsersConnected() {
		this.contactList.forEach(userContact => {
			let date = new Date();
			var diasdif = date.getTime() - userContact.getDateSince().getTime();
			if (diasdif > this.timer) {
				userContact.status = false;
			} else {
				userContact.status = true;
			}
		});
	}
	private sendConnection() {
		var input: models.CreateConnectionInput = {
			userID: this.owner?.id?.toString(),
			status: true
		}
		this.Api.CreateConnection(input).then((connectionResponse) => {
console.log('connectionResponse', connectionResponse);

		}).catch((error) => {
			console.error(error);
		})
	}
	/* ---------------------------- FIN METODOS PARA VERIFICAR USARIOS CONECTADOS --------------------------------------------------------------------- */



	/* ---------------------------- SUSCRIPCIONES PARA NUEVAS CONVERSACIONES QUE TE HAN AGREGADO DE FORMA MANUAL --------------------------------------------------------------------- */
	private startListenerNewUserConversation(owner: UserContact, conversationsList: Array<Conversation>): Promise<any> {
		return new Promise((resolve, reject) => {
			this.createUserConversationSubscriber(owner, conversationsList).then((newUserConversationSubscription) => {
				resolve(newUserConversationSubscription);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private createUserConversationSubscriber(owner: UserContact, conversationsList: Array<Conversation>): Promise<any> {
		return new Promise((resolve, reject) => {
			let conversationSubscription = this.Api.OnCreateUserConversationByUserListener(owner.id).subscribe((userConversationObserver: models.SubscriptionResponse<models.OnCreateUserConversationByUserSubscription>) => {
				this.checkUserConversationResponse(userConversationObserver).then((conversationResponse) => {
					this.getConversationFound(conversationResponse, owner, conversationsList).then((conversation) => {

					}).catch((error) => {
						reject(error);
					});
				}).catch((error) => {
					reject(error);
				});
			}, ((error) => {
				reject(error);
			}));
			resolve(conversationSubscription);
		});
	}
	private checkUserConversationResponse(userConversationObserver: models.SubscriptionResponse<models.OnCreateUserConversationByUserSubscription>): Promise<models.GetConversationQuery> {
		return new Promise((resolve, reject) => {
			if (userConversationObserver && userConversationObserver.value.data.onCreateUserConversationByUser.conversation) {
				resolve(userConversationObserver.value.data.onCreateUserConversationByUser.conversation);
			}
			reject(userConversationObserver);
		});
	}

	private getConversationFound(conversationResponse: models.GetConversationQuery, owner: UserContact, conversationsList: Array<Conversation>): Promise<Conversation> {
		return new Promise((resolve, reject) => {
			let conversationFound = conversationsList.find(conversationFound => (conversationFound.getID() == conversationResponse.id));
			if (conversationFound) {
			
				 resolve(conversationFound);
			} else {
				this.createConversation(conversationResponse, owner).then((conversation) => {
					console.log('conversationsList',this.conversationsList)
					this.conversationsList.push(conversation);
					resolve(conversation);
				}).catch((error) => {
					reject(error);
				});
			}
		});
	}
	/* ---------------------------- FIN SUSCRIPCIONES PARA NUEVAS CONVERSACIONES QUE TE HAN AGREGADO DE FORMA MANUAL ----------------------------------------------------------------- */


	/* SUSCRIPCIONES CUANDO AGREGAS A USUARIOS Y AVISARLE A TODOS QUE HAS AGREGADO A ALGUIEN */
	/* */


	/* ---------------------------- SUSCRIPCIONES PARA NUEVAS CONVERSACIONES -------------------------------------- */
	private startListenerNewConversations(owner: UserContact, conversationsList: Array<Conversation>): Promise<any> {
		return new Promise((resolve, reject) => {
			this.createNewConversationsSubscriber(owner, conversationsList).then((newConversationSubscriber) => {
				resolve(newConversationSubscriber);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private createNewConversationsSubscriber(owner, conversationsList): Promise<any> {
		return new Promise((resolve, reject) => {
			let conversationSubscription = this.Api.OnCreateConversationByUsersListListener.subscribe((conversationObserver: models.SubscriptionResponse<models.OnCreateConversationByUsersListSubscription>) => {

				this.checkConversationResponse(conversationObserver, owner).then((conversationResponse) => {
					this.getConversationFound(conversationResponse, owner, conversationsList).then((conversation) => {
						//resolve(conversation);
					}).catch((error) => {
						reject(error);
					});
				}).catch((error) => {
					reject(error);
				});
			}, ((error) => {
				reject(error);
			}));
			resolve(conversationSubscription);
		});
	}

	private checkConversationResponse(conversationObserver: models.SubscriptionResponse<models.OnCreateConversationByUsersListSubscription>, owner: UserContact): Promise<models.GetConversationQuery> {
		return new Promise((resolve, reject) => {
			if (conversationObserver && conversationObserver.value.data.onCreateConversationByUsersList) {
				if (!(conversationObserver.value.data.onCreateConversationByUsersList.userID == owner.id)) {
					let userFound = conversationObserver.value.data.onCreateConversationByUsersList.users.items.find(userItem => userItem.user.id == owner.id)
					if (userFound) {
						resolve(conversationObserver.value.data.onCreateConversationByUsersList);
					} else {
						reject(conversationObserver)
					}
				} else {
					reject(conversationObserver)
				}
			}
			reject(conversationObserver);
		});
	}
	/* ---------------------------- FIN SUSCRIPCIONES PARA NUEVAS CONVERSACIONES ---------------------------------- */



	/* ---------------------------- SUSCRIPCIONES PARA ACTUALIZAR CONVERSACIONES -------------------------------------- */
	private startListenerUpdateConversations(owner: UserContact, conversationsList: Array<Conversation>): Promise<any> {
		return new Promise((resolve, reject) => {
			let promises = [];
			conversationsList.forEach(conversation => {
				if (conversation.getIsGroup()) {
					promises.push(this.createUpdateConversationSubsriber(conversation, owner));
				}
			});
			Promise.all(promises).then((subscriberList) => {
				resolve(subscriberList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private createUpdateConversationSubsriber(conversation: Conversation, owner: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			let conversationSubscription = this.Api.OnUpdateConversationByConversationListener(conversation.getID()).subscribe((updateConversationSubscriber: models.SubscriptionResponse<models.OnUpdateConversationByConversationSubscription>) => {
				this.checkUpdateConversationListener(updateConversationSubscriber).then((updatedConversation) => {

				}).catch((error) => {
					console.error(error);
				});
			}, ((error) => {
				reject(error);
			}));
			resolve(conversationSubscription);
		});
	}

	private checkUpdateConversationListener(updateConversationSubscriber: models.SubscriptionResponse<models.OnUpdateConversationByConversationSubscription>): Promise<models.OnUpdateConversationByConversationSubscription> {
		return new Promise((resolve, reject) => {
			if (updateConversationSubscriber && updateConversationSubscriber.value.data.onUpdateConversationByConversation) {
				resolve(updateConversationSubscriber.value.data.onUpdateConversationByConversation)
			} else {
				reject(null);
			}
		});
	}
	/* ---------------------------- FIN SUSCRIPCIONES PARA ACTUALIZAR CONVERSACIONES ---------------------------------- */



	/* ---------------------------- SUSCRIPCIONES PARA NUEVAS CONEXIONES -------------------------------------- */
	private startListenerConnectionByUsers(cotactList: Array<UserContact>): Promise<any> {
		return new Promise((resolve, reject) => {
			let promises = [];
			cotactList.forEach(userContact => {
				promises.push(this.createConnectionByUserSubsriber(userContact));
			});
			Promise.all(promises).then((subscriberList) => {
				resolve(subscriberList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private createConnectionByUserSubsriber(userContact: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			let conversationSubscription = this.Api.OnCreateConnectionByUserListener(userContact.id).subscribe((connectionResponse: models.SubscriptionResponse<models.OnCreateConnectionByUserSubscription>) => {
				this.checkConnectionByUserListener(userContact, connectionResponse).then((createdAt: string) => {
					userContact.lastStatus = createdAt;
				}).catch((error) => {
					console.error(error);
				});
			}, ((error) => {
				reject(error);
			}));
			resolve(conversationSubscription);
		});
	}

	private checkConnectionByUserListener(userContact: UserContact, connectionResponse: models.SubscriptionResponse<models.OnCreateConnectionByUserSubscription>): Promise<string> {
		return new Promise((resolve, reject) => {
			userContact.status = connectionResponse.value.data.onCreateConnectionByUser.status;
			if (connectionResponse.value.data.onCreateConnectionByUser.status) {
				resolve(connectionResponse.value.data.onCreateConnectionByUser.createdAt);
			} else {
				reject(null);
			}
		});
	}
	/* ---------------------------- FIN SUSCRIPCIONES PARA NUEVAS CONEXIONES ---------------------------------- */



	/* ---------------------------- SUSCRIPCIONES PARA NUEVOS MENSAJES -------------------------------------------- */
	private startListenerNewMessages(owner: UserContact, conversationsList: Array<Conversation>): Promise<any> {
		return new Promise((resolve, reject) => {
			let promises = [];
			conversationsList.forEach(conversation => {
				promises.push(this.createMessageSubscriber(conversation, owner));
			});
			Promise.all(promises).then((subscriberList) => {
				resolve(subscriberList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private createMessageSubscriber(conversation: Conversation, owner: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			let subscriptionToMessages = this.Api.OnCreateMessageByConversationListener(conversation.getID()).subscribe((messageObserver: any) => {
				this.checkMessageByListener(owner, messageObserver.value.data.onCreateMessageByConversation).then((message) => {
					this.soundNotification(message, owner);
					
					if ( message.getUser().id != owner.id) { //this.backgroundMode.isActive() && 
						this.localNotifications.schedule({
							id: conversation.getNotificationID(),
							title: 'Nuevo MD',
							text: message.getText()
						});
						 this.contentMessage=message;
					
					}
					conversation.addMessage(message);
					conversation.setNewLastMessage(message);
					this.orderChatList(this.conversationsList);
					if (!message.getIsMine(this.owner.id)) {
						console.log('this.contentMessage',this.contentMessage.body);
						if (this.contentMessage.body.includes('Llamada entrelazada por')){
						
						// Stop the sound after 5.1 seconds if message stops looping
						clearTimeout(this.soundTimeout);
						// Stop the sound after 5.1 seconds if message stops looping
						this.soundTimeout = setTimeout(() => {

							this.stopAudio();
							Swal2.close();

						}, 5100);

						
							if(!this.sound ){ //indicator to not play twice
								const audioFile = 'assets/sounds/Moonlight.mp3';
								this.sound = new Howl({
									html5: true,
								 src: [audioFile],
								 autoplay: true,
								 volume: 1.0, // Initial volume level (0.0 to 1.0)
							   });
							 
							   
							   this.sound.play();

							   this.request.createRequestPost('Historic_calls', { getMeetingID:true, conversationID: conversation.getID()})
							   .then((final_data) => {
							    if (final_data && typeof final_data === 'string') {
									try {
										final_data = JSON.parse(final_data);
									  console.log('getRecordByToken ios:',final_data);
									} catch (error) {
									  console.error("Error parsing data:", error);
									}
									}
							   if (final_data){
								Swal2.fire({
									title: `<img src="${final_data.llamador_profile_photo}" style="width: 50px; height: 50px; border-radius: 9px; margin-right: 10px;" /> ${final_data.llamador_username}`,
									text: `¿Contestar? `,
									icon: 'info',
									showCancelButton: true,
								
									cancelButtonText: '<ion-icon style="color:red;font-size:36px" name="close"></ion-icon> Declinar',
									confirmButtonText: '<ion-icon style="color:green;font-size:36px" name="call-sharp"></ion-icon> Contestar',
									color: '#ffffff',
									background: '#010f4e',
									position: 'top-end',
									timerProgressBar: true,
									toast: true,
								}).then((result) => {


									if (result.isConfirmed) {
									
											if (Capacitor.getPlatform() === 'web'){
												this.modalController.create({
													//component: ModulesLockComponent,
													component: ChimeContactComponent,
													cssClass: 'detailsInModal',
													swipeToClose: true,
													componentProps: {
													  meetingID: final_data.meetingID,
													  answering: true
													  },
												  }).then((modal)=>{
													modal.present();
												  
												  });
											}else{
												let url= `https://supercumulo.laniakea.tv/loader?meeting=${final_data.meetingID}&username=null`;
												this.openBrowser(url);
											}
											this.request.createRequestPost('Historic_calls', { answerCall:true, id:final_data.meetingID})
										.then((final_data) => {
										// Handle cancel button action
									})
										// Handle confirm button action
									} else {
										this.request.createRequestPost('Historic_calls', { rejectCall:true, id:final_data.id})
										.then((final_data) => {
											this.stopAudio();
										// Handle cancel button action
									})
									}
								});
								
							   }
							   })
   
							}
						
						}
						conversation.addMessageToUnread(message);
						if (this.conversationOpened && this.conversationOpened === conversation.getID()) {
							this.updateSeenMessages(conversation).then((data) => {
								conversation.clearUnreadMessages();
							}).catch((error) => {
								console.error(error);
							});
						}
					}
				}).catch((error) => {
					reject(error);
				});
			}, (error) => {
				reject(error);
			});
			resolve(subscriptionToMessages);
		});
	}
	async openBrowser(url: string) {
		//await Browser.open({ url });
		const modal = await this.modalController.create({
			cssClass: 'detailsInModal',
			swipeToClose: true,
			component: InlineBowsingComponent,
			componentProps: {
				url: url,
			
			},
		  });
		  modal.onDidDismiss().then((data)=>{
			
		  });
		  return await modal.present();
		window.open(url, '_blank'); // Open the URL in a new tab or window
	  }

	private checkMessageByListener(owner: UserContact, messageObserver: models.GetMessageQuery): Promise<Message> {
		return new Promise((resolve, reject) => {
			if (owner && messageObserver) {
				let newMessage = new Message(messageObserver);
				resolve(newMessage);
			}
			reject("Mensaje inválido");
		});
	}
	/* ---------------------------- FIN SUSCRIPCIONES PARA NUEVOS MENSAJES ---------------------------------------- */

	stopAudio() {
		if (this.sound) {
		  this.sound.stop();
		}
	}

	/* ---------------------------- SUSCRIPCIONES PARA NUEVOS MENSAJES LEIDOS-------------------------------------------- */
	private startListenerNewMessagesSeen(owner: UserContact, conversationsList: Array<Conversation>): Promise<any> {
		return new Promise((resolve, reject) => {
			let promises = [];
			conversationsList.forEach(conversation => {
				promises.push(this.createMessageSeenSubscriber(conversation, owner));
			});
			Promise.all(promises).then((subscriberList) => {
				resolve(subscriberList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private createMessageSeenSubscriber(conversation: Conversation, owner: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			let subscriptionToMessagesSeen = this.Api.OnCreateSeenByConversationListener(conversation.getID()).subscribe((seenResponse: models.SubscriptionResponse<models.OnCreateSeenByConversationSubscription>) => {
				this.checkMessageSeenByListener(owner, seenResponse.value.data.onCreateSeenByConversation).then((parsendSeenResponse) => {
					parsendSeenResponse.messages.items.forEach((item) => {
						let messageFound = conversation.getMessagesList().find(message => (message.getId() == item.message.id));
						if (messageFound) {
							messageFound.clearUsers(item.message.users);
							messageFound.setSeen(conversation.getUsers());
						}
					});
				}).catch((error) => {
					reject(error);
				});
			}, (error) => {
				reject(error);
			});
			resolve(subscriptionToMessagesSeen);
		});
	}

	private checkMessageSeenByListener(owner: UserContact, seenResponse: models.OnCreateSeenByConversationSubscription): Promise<models.OnCreateSeenByConversationSubscription> {
		return new Promise((resolve, reject) => {
			if (owner && seenResponse) {
				resolve(seenResponse);
			}
			reject(null);
		});
	}
	/* ---------------------------- FIN SUSCRIPCIONES PARA NUEVOS MENSAJES LEIDOS ---------------------------------------- */



	/* ---------------------------- SUSCRIPCIONES PARA ELIMINAR MENSAJES -------------------------------------------- */
	private startListenerUpdateMessages(owner: UserContact, conversationsList: Array<Conversation>): Promise<any> {
		return new Promise((resolve, reject) => {
			let promises = [];
			conversationsList.forEach(conversation => {
				promises.push(this.updateMessageSubscriber(conversation, owner));
			});
			Promise.all(promises).then((subscriberList) => {
				this.listSubscriptions = this.listSubscriptions.concat(subscriberList);
				resolve(subscriberList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private updateMessageSubscriber(conversation: Conversation, owner: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			let subscriptionToMessages = this.Api.OnUpdateMessageByConversationListener(conversation.getID()).subscribe((messageObserver: any) => {
				this.checkUpdateMessageByListener(owner, conversation, messageObserver.value.data.onUpdateMessageByConversation).then((message) => {
					this.soundNotification(message, owner);
					
					if ( message.getUser().id != owner.id) { // this.backgroundMode.isActive() &&
						this.localNotifications.schedule({
							id: conversation.getNotificationID(),
							title: 'Nuevo MD',
							text: message.getText()
						});
					}
				}).catch((error) => {
					reject(error);
				});
			}, (error) => {
				reject(error);
			});
			resolve(subscriptionToMessages);
		});
	}

	private checkUpdateMessageByListener(owner: UserContact, conversation: Conversation, messageObserver: models.GetMessageQuery): Promise<Message> {
		return new Promise((resolve, reject) => {
			if (owner && messageObserver) {
				let message = conversation.getMessagesList().find(message => (message.getId() == messageObserver.id))
				if (message) {
					message.setText(messageObserver.body);
					message.setDelete(messageObserver.isDelete);

					let messagesFound: Array<Message>;
					try {
						messagesFound = conversation.getMessagesList().filter((messageAux: Message) => ((messageAux.getMessageResponse() && messageAux.getMessageResponse().getId()) == message.getId()));
					} catch (e) {
						console.log(e);
						reject(e);
					}

					if (messagesFound) {

						messagesFound.forEach((messageFound) => {
							messageFound.getMessageResponse().setText(messageObserver.body);
							messageFound.getMessageResponse().setDelete(messageObserver.isDelete);
						});
						resolve(message);
					} else {

						resolve(message);
					}
				} else {
					reject("Mensaje inválido");
				}
			}
			reject("Mensaje inválido");
		});
	}
	/* ---------------------------- FIN SUSCRIPCIONES PARA ELIMINAR MENSAJES ---------------------------------------- */



	/* ---------------------------- CREAR UNA LISTA DE CONVERSACIONES DEL USUARIO --------------------------------- */
	private startChatService(owner: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			this.getConversations(owner).then(conversationsByUser => {
				resolve(conversationsByUser);
			}).catch(error => {
				reject(error);
			});
		});
	}

	private getConversations(owner: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			this.Api.GetConversationsByUser(owner.id).then((userResponse: models.GetUserQuery) => {
				if (userResponse) {
					this.buildConversations(userResponse, owner).then((response) => {
						resolve(response);
					}).catch((error) => {
						console.error(error);
					});
				} else {
					reject(userResponse);
				}
			}).catch(error => {
				reject(error);
			});

		});
	}

	private buildConversations(userResponse: models.GetUserQuery, owner: UserContact): Promise<any> {
		return new Promise((resolve, reject) => {
			let conversationsList = [];
			userResponse.conversations.items.forEach((conversationResponse: models.GetConversationQuery) => {
				conversationsList.push(this.createConversation(conversationResponse.conversation, owner));
			});
			Promise.all(conversationsList).then((conversationsList) => {
				resolve(conversationsList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private createConversation(conversationResponse: models.GetConversationQuery, owner: UserContact): Promise<Conversation> {
		return new Promise((resolve, reject) => {
			if (conversationResponse && owner) {
				let conversation = new Conversation(conversationResponse, owner, this.contactList);
				this.getMessagesByConversation(conversation, 30, conversation.getNextToken()).then(() => {
					conversation.setNotificationID(this.index);
					this.index++;
					let promises = []

					promises.push(this.createMessageSubscriber(conversation, owner));
					promises.push(this.createMessageSeenSubscriber(conversation, owner));
					promises.push(this.updateMessageSubscriber(conversation, owner));
					promises.push(this.createUpdateConversationSubsriber(conversation, owner));

					Promise.all(promises).then((subscriptionsList) => {
						this.listSubscriptions = this.listSubscriptions.concat(subscriptionsList);
						resolve(conversation);
					}).catch((error) => {
						reject(error);
					});
				}).catch((error) => {
					reject(error);
				});
			} else {
				reject(null);
			}
		});
	}
	/* ---------------------------- FIN CREAR UNA LISTA DE CONVERSACIONES DEL USUARIO ------------------------------ */



	/* ---------------------------- FUNCIONES ESCENCIALES ---------------------------------------------------------- */
	public buildService(owner: UserContact) {
		return new Promise((resolve, reject) => {

			
			this.startChatService(owner).then((conversationList) => {
				  // Filter conversationList based on this.main_actual_chat_tag
	
				
				  this.request.createRequestPost('getConversationTagsForAuthenticatedUser', { }).then((response) => {
					if (response && typeof response === 'string') {
						try {
							response = JSON.parse(response);
						  console.log('getRecordByToken ios:',response);
						} catch (error) {
						  console.error("Error parsing data:", error);
						}
						}
					  console.log('1')
					if(response.success){

		
						console.log('2')

				  if (this.main_actual_chat_tag === 'con llave') {
					this.locked_conversations = response.success.filter(conversation => conversation.type === 'con llave');
					this.conversationsList = conversationList;
					this.archived_conversations = [];
					console.log('3')
					const lockedUserIds = new Set(this.locked_conversations.reduce((acc, convo) => {
						acc.push(convo.conversation_id);
						return acc;
					}, []));
				
					this.conversationsList = conversationList.filter(contact =>
						lockedUserIds.has(contact.id)
					);
				} else if (this.main_actual_chat_tag === 'archivados') {
// Assuming 'response.success' is the array of conversation data
this.archived_conversations = response.success.filter(conversation => conversation.tag === 'archivados');
console.log('this.archived_conversations', this.archived_conversations);

					this.conversationsList = conversationList;
					this.locked_conversations = [];
					
					const archivedUserIds = new Set(this.archived_conversations.reduce((acc, convo) => {
						acc.push(convo.conversation_id);
						return acc;
					}, []));
					console.log('4', archivedUserIds)
					console.log('4', conversationList
					)
					this.conversationsList = conversationList.filter(contact =>
						archivedUserIds.has(contact.id)
					);
				} else {
					// Handle default case or other tags if needed
					this.locked_conversations = [];
					this.archived_conversations = [];

					this.conversationsList = conversationList;
					console.log('5');
					// Assuming 'response.success' is the array of conversation data
const tagged_conversations = response.success.filter(conversation => conversation.tag !== null);


					this.conversationsList = conversationList;
					this.locked_conversations = [];
					
					const taggedUserIds = new Set(tagged_conversations.reduce((acc, convo) => {
						acc.push(convo.conversation_id);
						return acc;
					}, []));
					console.log('4', taggedUserIds)
					console.log('4', conversationList
					)
					this.conversationsList = conversationList.filter(contact =>
						!taggedUserIds.has(contact.id)
					);
					
				}
			}
	
		}).catch((error) => {
			console.error('Failed to send request:', error);
		});

			console.log()
				this.orderChatList(this.conversationsList);
				let promises = [];

				promises.push(this.startListenerConnectionByUsers(this.contactList));//Listo
				//promises.push(this.startListenerNewMessages(owner, this.conversationsList)); // Listo
				//promises.push(this.startListenerNewMessagesSeen(owner, this.conversationsList));// Listo
				//promises.push(this.startListenerUpdateMessages(owner, this.conversationsList));// Listo
				promises.push(this.startListenerNewUserConversation(owner, this.conversationsList));// Listo
				promises.push(this.startListenerNewConversations(owner, this.conversationsList)); // Listo
				//promises.push(this.startListenerUpdateConversations(owner, this.conversationsList));// Listo

				Promise.all(promises).then((listenersList) => {
					this.listSubscriptions.concat(listenersList);
					resolve(listenersList);
				}).catch((error) => {
					reject(error);
				});
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public logoutSuscribers() {
		this.foreground.stop();
		this.listSubscriptions.forEach(subscriber => {
			subscriber.unsubscribe();
		});
		this.isLogued = false;
		this.conversationsList = new Array<Conversation>();
		this.listSubscriptions = new Array<any>();
	}

	private soundNotification(message: Message, owner: UserContact) {
		if (message.getUser().id != owner.id) {
			this.nativeAudio.play('receive_sound').catch(() => { });
			const audioFile = 'assets/sounds/receive_sound.wav';
			const sound = new Howl({
			  src: [audioFile],
			  autoplay: true,
			  volume: 1.0, // Initial volume level (0.0 to 1.0)
			});
			sound.play();
		}
	}
	/* ---------------------------- FIN FUNCIONES ESCENCIALES ------------------------------------------------------ */



	/* ---------------------------- LISTA DE MENSAJES -------------------------------------------------------------- */
	public async getContactList(): Promise<Array<UserContact>> {
		if (Capacitor.getPlatform() !== 'web') {
		return new Promise((resolve, reject) => {
			this.getFriends().then((relations) => {
				let promises = []
				relations.forEach(relation => {
					promises.push(this.addFriendsToContactList(relation));
				});
				Promise.all(promises).then((contactList) => {
					resolve(contactList.filter((x): x is UserContact => x !== null));
				}).catch((error) => {
					reject(error);
				})
			}).catch((error) => {
				reject(error);
			});
		});
	} else {
		return new Promise(async(resolve, reject) => {
		try {
			const relations = await this.getFriends();
			const promises = relations.map(relation => this.addFriendsToContactList(relation));
		  
			Promise.all(promises)
			  .then(contactList => {
				console.log('contactList', contactList);
			
				resolve([]);
			  })
			  .catch(error => {
				console.error('Error while fetching contact list:', error);
				reject(error);
			  });
	
		  } catch (error) {
			console.error('Error while fetching contact list:', error);
			reject(error);
		  }
		  
		  
		});
	
	}
	}
	

	private addFriendsToContactList(relation: Array<any>): Promise<UserContact> {
		if (Capacitor.getPlatform() !== 'web') {
		return new Promise((resolve, reject) => {
			let relationFound = relation.find(relationFind => (relationFind.relationType == "Amigo"))
			if (relationFound) {
				this.Api.GetUser(relationFound.user_info.profile_id).then((userResponse: models.GetUserQuery) => {
					if (userResponse) {
						relationFound.user_info.lastConnection = (userResponse.connections.items && userResponse.connections.items.length > 0) ? userResponse.connections.items[0].createdAt : null;
						let userContact = new UserContact(relationFound.user_info);
						resolve(userContact);
					} else {
						resolve(null)
					}
				}).catch((error) => {
					resolve(null);
				});
			} else {
				reject(relation);
			}

		});
	}else {

			return new Promise((resolve, reject) => {
			  relation = Array.isArray(relation) ? relation : [relation]; // Ensure relation is an array
			 // console.log(relation);
		
			  if (Array.isArray(relation)) {
				let relationFound = relation[0]?.buttons_info?.friendsButton?.text;
			
				if (relationFound==='Amigos'){
					console.log('relation',relation);
					
				  // Assume that when friendsButton.text is "Amigos," the relation type is "Amigo."
				  this.Api.GetUser(relation[0]?.user_info?.profile_id)
					.then((userResponse: models.GetUserQuery) => {
					  if (userResponse) {
						console.log('relationFound', relationFound);
						let lastConnection = relation[0]?.user_info?.lastConnection
						lastConnection = (userResponse.connections.items && userResponse.connections.items.length > 0) ? userResponse.connections.items[0].createdAt : null;
						let userContact = new UserContact(relation[0]?.user_info);
						this.contactList.push(userContact);
						console.log( 'userContact', userContact);
						resolve(userContact);
					  } else {
						reject(new Error('User response not found'));
					  }
					})
					.catch((error) => {
					  reject(error);
					});
			
				} 
			  } else {
				reject(new Error('Relation is not an array'));
			  }
			});
		  }
		
	}
	

	private getFriends(): Promise<any> {
		if (Capacitor.getPlatform() !== 'web') {
		return new Promise((resolve, reject) => {
			let relationType = [1, 2];
			let relationDirection = 3;
			let relationStatus = 2;

			let request = {
				'relationDirection': relationDirection.toString(),  // 1 Envio, 2 Recibido, 3 Todos
				'relationType': relationType,   // 1 Amigo, 2 S Amigos, 3 Seguidores
				'relationStatus': relationStatus.toString()  // 1 Pendiente, 2 Confirmado
			};

			this.friendshipService.getAllRelations(request).then((relations) => {
			
			
			}).catch((error) => {
				reject(error);
			});
		});
	}else{
		return new Promise((resolve, reject) => {
			let relationType = [1, 2];
			let relationDirection = 3;
			let relationStatus = 2;

			let request = {
				'relationDirection': relationDirection,  // 1 Envio, 2 Recibido, 3 Todos
				'relationType': relationType,   // 1 Amigo, 2 S Amigos, 3 Seguidores
				'relationStatus': relationStatus  // 1 Pendiente, 2 Confirmado
			};

			this.friendshipService.getAllRelations(request).then((relations) => {
		
				
					if (typeof relations === 'string') {
						relations = JSON.parse(relations);
						
			
						 }else {
							resolve(relations);
							console.log('json result publication service.ts', relations);
			   }
						 
		
			}).catch((error) => {
				reject(error);
			});
		});
		}
	}

	

	public getMessagesByConversation(conversation: Conversation, limit: number, nextToken: string): Promise<any> {
		return new Promise((resolve, reject) => {
			if (!(nextToken === null) || nextToken === undefined) {
				this.Api.GetConversationMessages(conversation.getID(), limit, nextToken).then((conversationResponse: models.GetConversationQuery) => {
					conversation.setNextToken(conversationResponse.messages.nextToken);
					conversationResponse.messages.items.forEach(messageResponse => {
						let messageTemp = new Message(messageResponse)
						messageTemp.setSeen(conversation.getUsers());
						console.log('chat user_id',conversation.getUsers() );
						if (!messageTemp.getSeen() && !messageTemp.getIsMine(this.owner.id)) {
							conversation.addMessageToUnread(messageTemp);
						}
						conversation.addMessageTop(messageTemp);
					});
					resolve(true);
				}).catch(error => {
					reject(error);
				});
			}
		});
	}

	public sendMessage(conversation: Conversation, newMessage: Message, messageResponse?: Message): Promise<models.CreateMessageMutation> {
		return new Promise((resolve, reject) => {
      console.log('new message have multimedia', newMessage.getHaveMultimedia());
			if (!this.IsEmptyText(newMessage.getText()) || newMessage.getHaveMultimedia()) {
				let newMessageReq: models.CreateMessageInput = {
					userID: this.owner.id,
					conversationID: conversation.getID(),
					isDelete: false,
					isSeen: false,
					isSystemNotification: false,
					body: newMessage.getText()
				};
				if (messageResponse) {
					newMessageReq.messageID = messageResponse.getId();
				}
        //se reusa la variable blobsMultimediacomment ya que ahi se guarda la multimedia en tex-input-component
				if (this.commentService.blobsMultimediaComment.length>0) {
          this.commentService.blobsMultimediaComment.forEach(media => {
            console.log('media item in chat service', media);
						this.cameraService.postMultimediaForChat(media, "mensaje").then((data: any) => {
							data = JSON.parse(data);
							newMessageReq.multimediaID = data.chatElement.id;
							this.sendMessageOnGraphQl(newMessageReq).then((messageResponse) => {
							
								resolve(messageResponse);
							}).catch((error) => {
								console.error(error);
								reject(error);
							});
						}).catch((error) => {
							console.error(error);

							reject(error);
						});
					});
				}
				else {

					this.sendMessageOnGraphQl(newMessageReq).then((messageResponse) => {

						resolve(messageResponse);
					}).catch((error) => {
						console.error(error);

						reject(error);
					});
				}
			}
		});
	}

	public updateMessage(message: Message, messageID?:any, text?:any, ): Promise<models.CreateMessageMutation> {
		return new Promise((resolve, reject) => {
			let updateMessageReq: models.UpdateMessageInput ;
			if(messageID){
				 updateMessageReq= {
					id: messageID,
					isDelete: true,
					body: "Mensaje Eliminado"
				}
			}else{
				 updateMessageReq = {
					id: message.getId(),
					isDelete: true,
					body: "Mensaje Eliminado"
				}
			}
			
			
			this.updateMessageOnGraphQl(updateMessageReq).then((messageResponse) => {
				resolve(messageResponse);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private resendMessage(conversation: Conversation, message: Message) {
		return new Promise((resolve, reject) => {
			this.sendMessage(conversation, message).then((message) => {
				resolve(message);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public resendMessageByConversation(conversationList: Array<UserContact | Conversation>, message: Message) {
		return new Promise((resolve, reject) => {
			let promises = new Array<Promise<any>>();

			conversationList.forEach((conversation: Conversation) => {
				promises.push(this.resendMessage(conversation, message));
			});

			Promise.all(promises).then((messagesList) => {
				resolve(messagesList);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public sendMessageOnGraphQl(newMessageReq: models.CreateMessageInput): Promise<models.CreateMessageMutation> {
		return new Promise((resolve, reject) => {
			this.Api.CreateMessage(newMessageReq).then(messageResponse => {
				console.log(messageResponse);
				resolve(messageResponse);
			}).catch(error => {
				reject(error);
			}).finally(() => {
				this.nativeAudio.play('send_sound').catch(() => { });
				const audioFile = 'assets/sounds/send_sound.wav';
			const sound = new Howl({
			  src: [audioFile],
			  autoplay: true,
			  volume: 1.0, // Initial volume level (0.0 to 1.0)
			});
			sound.play();
			});
		})
	}

	private updateMessageOnGraphQl(newMessageReq: models.UpdateMessageInput): Promise<models.CreateMessageMutation> {
		return new Promise((resolve, reject) => {
			this.Api.UpdateMessage(newMessageReq).then(messageResponse => {
				resolve(messageResponse);
			}).catch(error => {
				reject(error);
			}).finally(() => {
				this.nativeAudio.play('send_sound').catch(() => { });
			});
		})
	}

	private IsEmptyText(textInput: string) {
		textInput = textInput.replace(/[\r\n\s]+/gm, "");
		if (textInput.length > 0) {
			return false;
		}
		else {
			return true;
		}
	}
	/* ---------------------------- FIN LISTA DE MENSAJES ---------------------------------------------------------- */



	/* ---------------------------- NUEVAS CONVERSACIONES ---------------------------------------------------------- */
	public newConversation(userRelation: UserContact): Promise<Conversation> {
		return new Promise((resolve, reject) => {
			let usersList = []
			usersList.push({ "userID": this.owner.id, "isDelete": false, "isAdmin": true });
			usersList.push({ "userID": userRelation.id, "isDelete": false, "isAdmin": false });

			this.Api.GetUserConversationsByUsers(usersList[0].userID, usersList[1].userID).then((userResponse: models.GetUserConversationsByUsersQuery) => { //Usuario -> Lista de conversaciones
				this.checkConversationOnServer(userResponse).then((conversationExist: models.GetConversationQuery) => {
					this.getConversationFound(conversationExist, this.owner, this.conversationsList).then((conversation: Conversation) => {
						resolve(conversation);
					}).catch((error) => {
						reject(error)
					});
				}).catch(() => {
					let conversationRequest: models.CreateConversationByUsersList = {
						usersList: usersList,
						isGroup: false,
						isDelete: false,
						name: 'Conversación privada'//userRelation.name
					}
					this.createNewConversation(conversationRequest).then((conversationResponse: any) => {
						this.createConversation(conversationResponse, this.owner).then((conversation) => {
							this.conversationsList.push(conversation);
							resolve(conversation);
						}).catch((error) => {
							reject(error);
						});
					}).catch((error) => {
						reject(error);
					});
				});
			}).catch(error => {
				reject(error);
			});
		});
	}

	public newGroupConversation(usersRelation: Array<UserContact>, chatName: string): Promise<Conversation> {
		return new Promise((resolve, reject) => {
			let usersList = [];
			usersList.push({ "userID": this.owner.id, "isDelete": false, "isAdmin": true });
			usersRelation.forEach(user => {
				usersList.push({ "userID": user.id, "isDelete": false, "isAdmin": false });
			});
			let conversationRequest: models.CreateConversationByUsersList = {
				usersList: usersList,
				isGroup: true,
				isDelete: false,
				name: chatName//userRelation.name
			}
			this.createNewConversation(conversationRequest).then((conversationResponse: any) => {
				this.createConversation(conversationResponse, this.owner).then((conversation) => {
					this.conversationsList.push(conversation);
					resolve(conversation);
				}).catch((error) => {
					reject(error);
				});
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private checkConversationOnServer(userResponse: models.GetUserConversationsByUsersQuery): Promise<models.GetConversationQuery> {
		return new Promise((resolve, reject) => {
			if (userResponse && userResponse.conversations) {
				let conversationResponse = userResponse.conversations.items.find(conversationItem => (conversationItem.conversation.users.items.length > 0 && !conversationItem.conversation.isGroup));
				if (conversationResponse) {
					resolve(conversationResponse.conversation);
				} else {
					reject(userResponse);
				}
			} else {
				reject(userResponse);
			}
		});
	}

	private createNewConversation(conversationRequest: models.CreateConversationByUsersList): Promise<models.CreateConversationByUsersListMutation> {
		return new Promise((resolve, reject) => {
			this.Api.CreateConversationByUsersList(conversationRequest, conversationRequest.usersList[0].userID).then((conversation: models.CreateConversationByUsersListMutation) => {

				resolve(conversation);
			}).catch((error) => {
				reject(error);
			});
		});

	}
	/* ---------------------------- FIN NUEVAS CONVERSACIONES ------------------------------------------------------- */



	/* ---------------------------- ACTUALIZAR CONVERSACIONES ------------------------------------------------------- */
	public uploadChatImagen(mediaItem: MediaItem, conversation: Conversation): Promise<any> {
		return new Promise((resolve, reject) => {
			this.cameraService.postMultimediaForChat(mediaItem, "Conversación", conversation.getID()).then((chatElementResponse: string) => {
				let chatResponseObject = JSON.parse(chatElementResponse);
				let promises = [];
				let newMesssageReq: models.CreateMessageInput = {
					userID: this.owner.id,
					conversationID: conversation.getID(),
					isSeen: false,
					body: "Cambió la imagen del grupo",
					isSystemNotification: true,
					isDelete: false,
				}
				promises.push(this.sendMessageOnGraphQl(newMesssageReq));
				promises.push(this.updateConversationInfo(conversation, chatResponseObject.chatElement.id));
				Promise.all(promises).then(() => {
					resolve(chatResponseObject);
				}).catch((error) => {
					reject(error);
				});
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public updateConversationName(conversation: Conversation): Promise<any> {
		return new Promise((resolve, reject) => {
			let promises = [];
			let newMessageReq: models.CreateMessageInput = {
				userID: this.owner.id,
				isDelete: false,
				isSeen: false,
				isSystemNotification: true,
				conversationID: conversation.getID(),
				body: "Cambió el nombre de la conversación"
			}
			promises.push(this.sendMessageOnGraphQl(newMessageReq));
			promises.push(this.updateConversationInfo(conversation));
			Promise.all(promises).then((promisesArray) => {
				resolve(promisesArray);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	private updateConversationInfo(conversation: Conversation, chatResponseObjectID?: string): Promise<models.GetConversationQuery> {
		return new Promise((resolve, reject) => {
			let conversationInput: models.UpdateConversationInput = {
				id: conversation.getID(),
				name: conversation.getName(),
				isGroup: true,
				isDelete: false,
				multimediaID: (chatResponseObjectID) ? chatResponseObjectID : null
			}
			this.Api.UpdateConversation(conversationInput).then((conversation: models.GetConversationQuery) => {
				resolve(conversation);
			}).catch((error) => {
				reject(error);
			});
		});
	}
	/* ---------------------------- FIN ACTUALIZAR CONVERSACIONES ------------------------------------------------------- */


	public findUserInChatService(user: UserContact): Promise<models.GetUserQuery> {
		return new Promise((resolve, reject) => {
			this.Api.GetUser(user.id).then((userResponse: models.GetUserQuery) => {
				resolve(userResponse);
			}).catch((error) => {
				reject(error);
			})
		});
	}

	public updateSeenMessages(conversation: Conversation): Promise<any> {
		return new Promise((resolve, reject) => {
			let input: models.CreateUserMessageByMessagesList = {
				userID: this.owner.id,
				conversationID: conversation.getID(),
				isDelete: false,
				messages: []
			}
			this.pushUnreadMessages(conversation).then((messagesUnread) => {
				if (messagesUnread && messagesUnread.length > 0) {
					input.messages = messagesUnread;
					this.Api.CreateSeenByMessages(input).then((messagesSeen: models.CreateSeenByMessagesMutation) => {

						conversation.clearUnreadMessages();
					}).catch((error) => {
						console.error(error);
					});

				} else {

				}
			}).catch((error) => {
				console.error(error);
			});
		});
	}

	public pushUnreadMessages(conversation: Conversation): Promise<Array<models.CreateMessageIdInput>> {
		return new Promise((resolve, reject) => {
			let promises = [];
			conversation.getUnreadMessages().forEach((message: Message) => {
				promises.push(this.createMessageUnread(message));
			});
			Promise.all(promises).then((messagesUnread) => {
				resolve(messagesUnread);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	public createMessageUnread(message): Promise<models.CreateMessageIdInput> {
		return new Promise((resolve, reject) => {
			let messageId: models.CreateMessageIdInput = {
				messageID: message.getId()
			}
			resolve(messageId);
		});
	}


	public orderChatList(chatList: Array<Conversation>) {
		chatList = chatList.sort((p1, p2) => {
			let firstDate = (p1.getLastMessage()) ? p1.getLastMessage().getDate() : p1.getDate();
			let secondDate = (p2.getLastMessage()) ? p2.getLastMessage().getDate() : p2.getDate();
			return (firstDate > secondDate) ? -1 : 1;
		});
	}
	sendNotification(user, body) {
   
		return new Promise((resolve, reject) => {
		  const notification = {
			title: 'Nuevo MD',
			body: body,
			user_id: user,
	
		  };
	  
		  this.request.createRequestPost('GreatAtractorNotifications', notification)
			.then((data) => {
			  
			  resolve(data);
			}).catch((error) => {
			  console.error('Failed to send notification:', error);
			  reject(error);
			});
		});
	  }

}
