import { Component, OnInit, Input,  } from '@angular/core';
import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { Router } from '@angular/router';
import {
  NavController,
  AlertController,
  ModalController, IonicModule, ActionSheetController, IonSelect, IonSlides
} from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import { Capacitor, Plugins } from '@capacitor/core';
import { ChangeDetectorRef, ViewChild } from '@angular/core';
import { UploadMultimediaService } from 'src/app/services/upload/upload-multimedia.service';
import { MediaItem } from 'src/app/Models/Classes/Media/media-item';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {StockKeepingUnitSelectionComponent} from 'src/app/stock-keeping-unit-selection/stock-keeping-unit-selection.component';
import {AlibabaCheckoutCartComponent}  from 'src/app/alibaba-checkout-cart/alibaba-checkout-cart.component';
import { SortProdcutsOptionsPopoverComponent } from 'src/app/sort-prodcuts-options-popover/sort-prodcuts-options-popover.component';
import { PopoverController } from '@ionic/angular';
import { Bienes6Component } from 'src/app/components/publication/create/bienes/bienes6/bienes6.component';
import {MyDsPurchasesComponent} from 'src/app/my-ds-purchases/my-ds-purchases.component';
import { NewPublicationButtonComponent } from 'src/app/components/common-elements/buttons/new-publication-button/new-publication-button.component';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { MenuController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
declare var google;
declare var gapi;
@Component({
  selector: 'app-dropship-product-selection',
  templateUrl: './dropship-product-selection.page.html',
  styleUrls: ['./dropship-product-selection.page.scss'],
})
export class DropshipProductSelectionPage implements OnInit {
  search_bar:boolean=true;
  @Input() publication_template;
  main_sort='orders';
  predictions: string[] = [
    "Books",
    "Electronics",
    "Clothing",
    "Beauty",
    "Home",
    "Toys",
    "Health",
    "Sports",
    "Automotive"
  ];
  
    // This flag indicates whether the placeholder is active (no interaction yet)
    placeholderActive: boolean = false;
  min;
  max;
  reader: boolean=true;
  window_width;
  sortOption:string='desc';
  language:string='es';
  @Input() ecosytem;
  @ViewChild('slides') slides: IonSlides;
  @ViewChild('chunk_slides') chunk_slides: IonSlides;
  debounceTimer;
  BASE64;
  setDrive_media_called:boolean=false;
  private apiKey = 'AIzaSyDKzCRYNscPNGWIGhsVZdFk-q-QuQmDjEs';
  private oauthToken: string='sbjcbjdcs';
  user_id;
  setDrive_media;
products=[];
mediaType: string;
index=1;
slideOpts = {
    loop: false,
    direction: 'vertical',
    initialSlide: 0,
    speed: 420,
    autoplay: {
      delay: 4500,
      disableOnInteraction: true, // doesnt keeps autoplay enabled after user interaction
    }
  };
  titleQuery='ropa loro piana';
  constructor( 
    private clipboard: Clipboard,
    private request: RequestService,
    private formservice:FormServiceService,
    private router: Router,
    private actionSheetController: ActionSheetController,
    public modalController: ModalController,
    private ref: ChangeDetectorRef,
    public uploadMedia: UploadMultimediaService,
    public https: HttpClient,
    private NewPublication: NewPublicationButtonComponent,
    private route: ActivatedRoute,
    private menuController: MenuController,
    private popoverController: PopoverController) { 
      window.addEventListener('resize', this.onResize.bind(this));
    }
    openMenu() {
      console.log('Opening menu...');
      try {
        // Attempt to enable and toggle the menu
        this.menuController.enable(true, 'first');
        this.menuController.toggle('first');
      } catch (error) {
        // If an error occurs, log it and reload the page
        console.error('Error opening menu:', error);
        window.location.reload(); // Reload the page
      }
    }
    open_cart(){
      this.modalController.create({
        component: AlibabaCheckoutCartComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          cart:this.formservice.AlibabaCheckoutCart

        }
    }).then((modal)=>{
        modal.present();
    });
    }
    async convertImageUrlToBlob(imageUrl: string): Promise<void> {
      try {
       // console.log('Fetching image from URL:', imageUrl);
        const response = await fetch(imageUrl);
        //console.log('Response received:', response);
        const blob = await response.blob();
        //console.log('Blob created:', blob);
        
        this.uploadMedia.blob = blob;
        this.LaniakeaSearchIMAGE();
      //  console.log('Blob assigned to this.uploadMedia.blob');
      } catch (error) {
        console.error('Error converting image URL to Blob:', error);
        throw error;
      }
    }
    
    
    ngAfterViewInit(){
      this.route.queryParams.subscribe((queryParams) => {
        
        if (queryParams.hasOwnProperty('image_url')) {
          console.log('p query param exists:', queryParams['image_url']);
          let image=queryParams['image_url'];
        this.convertImageUrlToBlob(image)
      }
        if (queryParams.hasOwnProperty('order_id') || queryParams['order_id'] || 'order_id' in queryParams) {
          Swal2.fire({
            title: 'Espera un momento',
            text: 'Buscando información de tu compra',
            icon: 'info',
            timer: 9999, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-start',
            showConfirmButton: false
            });
          this.chunk_slides.stopAutoplay();
          console.log('p query param exists:', queryParams['order_id']);
          let order_id=queryParams['order_id'];
          this.request.createRequestPost('ControladorDeCarritoDeCompras', {get_purchases:true, order_id:order_id})
          .then((data) => {
            this.chunk_slides.stopAutoplay();
            if (data && typeof data === 'string') {
              try {
                data = JSON.parse(data);
             
              } catch (error) {
                console.error("Error parsing data:", error);
              }
              }
              console.log('getRecordByToken :',data);
                 //console.log(data)
                 let orders = Array.isArray(data.success) ? data.success : [data.success];
  
  
  
        this.modalController.create({
          component: MyDsPurchasesComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            orders:orders
        //  order_tracking: data,
      
          },
        }).then((modal) => {
          
          modal.present();
        });
         
          
          
         
          });
        
        
      }  else 
        if (queryParams.hasOwnProperty('p')) {
            console.log('p query param exists:', queryParams['p']);
            let publication_id=queryParams['p'];
            let title=queryParams['title'];
            this.getStockKeepingUnits(publication_id, title, 'details');
            // Handle the 'p' parameter as needed
        } else {
            console.log('p query param is missing');
        }
    });
    }
  ngOnInit() {
    
    const keywords = [
      'Adjustable', 'Bluetooth', 'Compact', 'Durable', 'Energy-Efficient', 'Foldable', 'Glow', 'High-Quality', 'Intelligent', 
      'Kinetic', 'Lightweight', 'Multi-Function', 'Noise-Canceling', 'Optical', 'Portable', 'Quiet', 'Rechargeable', 'Smart', 'Touchscreen',
      'Universal', 'Versatile', 'Waterproof', 'Xenon',  'Zippered',
      'Wireless', 'Digital', 'Professional', 'Eco-Friendly', 'Heavy-Duty', 'Premium', 'Ergonomic', 'USB', 'LED', 'Handheld',
      'Cordless', 'Stainless Steel', 'High-Speed', 'All-in-One',   'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 
      'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 
      'U', 'V', 'W', 'X', 'Y', 'Z'
  ];
  
  
  const randomIndex = Math.floor(Math.random() * keywords.length);
   this.titleQuery = keywords[randomIndex];
  
   
  
    this.calculateSlidesPerView();
    this.request.createRequestPost('ControladorDeCarritoDeCompras', {update_cart:true,get_items:true,  })
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
         // console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
    //  console.log('data',data);
      this.formservice.AlibabaCheckoutCart.push(...data.cart_items);

   
    });
    localStorage.removeItem('fileId');
    this.route.queryParamMap.subscribe(params => {
      if(params.get('title')){
        this.titleQuery = params.get('title'); // Provide a fallback value if 'title' is not present
      }
    

    this.request.createRequestPost('getAlibabaProducts', { 
      main_sort:this.main_sort,
      min:this.min,
      max:this.max, 
      searchTextInput: this.titleQuery,index:this.index,   sortOption:this.sortOption,
      language:this.language})
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      }
      if(!this.uploadMedia.blob){
   // console.log('data',data);
   this.products=data?.success?.aliexpress_ds_text_search_response?.data?.products?.selection_search_product;
 //  console.log('products',this.products);
   this.chunkedProducts = this.chunkArray(this.products, 4);
      }
  
    });
    console.log('Title Query:', this.titleQuery);
  });
  }
  go_to_lia(){
    if(!this.formservice.AI_message){
      Swal2.fire({
        title: 'Escribe en el buscador',
        text: 'Aseguráte de escribir en el buscador, si el error persiste comunícate con el soporte de Laniakea.',
        icon: 'error',
        timer: 9999, // Show the alert for 45 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false
        });
return;
    }
    this.router.navigate(['/home/messages-page']);
  
    this.formservice.AI=true;
    this.formservice.sendMessage_to_ai();
  }
  LaniakeaSearchQuery(event: any) {
    // Clear any existing timer so we don't trigger multiple requests
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.BASE64=null;
    // Capture the current input value
    const searchText = event.target.value;
  
    // Set a new timer; if no new input arrives within 1.2s, fire the request
    this.debounceTimer = setTimeout(() => {
      // You can show the SweetAlert2 popup here if desired
      Swal2.fire({
        title: 'Buscando productos',
        text: `Buscando productos similares a "${searchText}"`,
        icon: 'success',
        timer: 6900,
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        background: '#010f4e',
      });
  
      // Perform the actual request
      this.index = 0;
      this.formservice.AI_message = searchText;
      this.titleQuery=searchText;
     this.textQueryPrompt();
  
      // Clear the timer reference after request is triggered
      this.debounceTimer = null;
    }, 1200); // 1200 ms = 1.2 s
  }
  
  askForLibraryCapture(take_photo: boolean = true, pick_AI: boolean = true, pick_image: boolean = true,pick_video: boolean = true,  pick_GOOGLE: boolean = true,) {
    Swal2.fire({
      title: 'Escoge un archivo para subir desde tu dispositivo.',
      text: "Al subirlo, confirmas que la multimedia cumple con nuestros Términos y Condiciones, y que no infringe en los derechos de alguien más.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      background: '#010f4e',
      position: 'top-end',
      timerProgressBar: true,
      toast: true,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
     

    Swal2.fire({
			title: '',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false
		  });
    setTimeout(() => {
      this.actionSheetController.dismiss();
      }, 9999); 
		return new Promise((resolve, reject) => {

			let botonesDisponibles = [
				(pick_image) ?
					{
						text: 'Seleccionar una foto',
						icon: 'camera',
						handler: () => {
							console.log("oprimiste camara");

							this
                             .pickImage()
                             .then((mediaItem) => {
                               resolve([mediaItem,'camara']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                                 position: 'top-end',
                                 timerProgressBar: true,
                                 toast: true,
                               });
                             });
						}
					} : null,
          
			
          (pick_AI) ?
					{
						text: 'Crear foto de portada cuántica',
						icon: 'images',
						handler: () => {
							console.log("oprimiste texto a imagen");

							this.AI().then((mediaItem) => {
                               resolve([mediaItem,'images']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
                               });
                             });
						}
					} : null,
          
          (pick_GOOGLE) ?
          {
            
						text: 'Google Drive',
						icon: 'logo-google',
						handler: () => {
							console.log("oprimiste texto a imagen");
if( localStorage.getItem('fileId')){
  Swal2.fire({
    title: 'Solo puedes agregar una portada',
    text: 'Ya agregasete una portada',
    icon: 'error',
    confirmButtonText: 'Entendido',
    color: '#ffffff',
    background: '#010f4e',
    position: 'top-end',
    timerProgressBar: true,
    toast: true,
  });
  return;
}
							this.loadGooglePicker().then((mediaItem) => {
                               resolve([mediaItem,'images']);
                             })
                             .catch((error) => {
                               Swal2.fire({
                                 title: 'Error take photo',
                                 text: error,
                                 icon: 'warning',
                                 confirmButtonText: 'Entendido',
                                 color: '#ffffff',
                                 background: '#010f4e',
                                 position: 'top-end',
                                 timerProgressBar: true,
                                 toast: true,
                               });
                             });
						}
					} : null,

				{
					text: 'Cancelar',
					icon: 'close',
					role: 'cancel',
					handler: () => {
					}
				}
			];

			botonesDisponibles = botonesDisponibles.filter((boton) => {
				if (boton != null) {
					return true;
				}
				return false;
			});


			this.actionSheetController.create({
				header: '¿Desde donde deseas añadir multimedia?',
				cssClass: 'options-sheet',
				buttons: botonesDisponibles
			}).then((actionSheet) => {
				actionSheet.present();
			});
		});
  }
});
	}
  public async AI(){
    this.modalController.dismiss();
    // Use the Router to navigate to the "messages-page"
    this.router.navigate(['home/messages-page']);
    this.formservice.fromCreatePublication=true;
    this.router.navigateByUrl('home/messages-page', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });

  }
  async pickImage(){
    const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {

        console.log('Media picked successfully:', media);

      if (Capacitor.getPlatform() === 'web') {
        // For web, create a Blob from the media data and convert it to a data URL
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result as string;
          imagePreviewElement.src = imageUrl;
          this.formservice.Capacitor_convertFileSrc=imageUrl;
        };
        reader.readAsDataURL(blobfile);
      } else {
        // For iOS and Android, use Capacitor.convertFileSrc to get the public URL
        const imageUrl = Capacitor.convertFileSrc(media.path);
        imagePreviewElement.src = imageUrl;
        this.formservice.Capacitor_convertFileSrc=imageUrl;
      }
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
        const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        
  
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        //const filename = media.path.substring(media.path.lastIndexOf('/') + 1);

  this.uploadMedia.blob = blobfile;
  this.BASE64= this.convertBlobToBase64(this.uploadMedia.blob);
  this.LaniakeaSearchIMAGE();
  if (Capacitor.getPlatform() !== 'web') {
        this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
  }else{
    const currentTime = new Date()
    .toISOString()
    .replace(/[:.]/g, '-'); // Replaces both `:` and `.` in one step
  
  this.uploadMedia.filename = `${currentTime}.jpeg`;
  
  }
        if(this.formservice?.normalpublication?.blobsMultimedia?.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0]={blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename};
        }else {
          this.formservice?.normalpublication?.blobsMultimedia.push({blob:this.uploadMedia.blob,isPremium:false, filename: this.uploadMedia.filename});
        } 
        
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  async loadGooglePicker() {
    Swal2.fire({
			title: 'Usuario de google',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false,
      background: '#010f4e',
		  });
    this.request.createRequestPost('getAuthYouTubeToken', {})
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      this.user_id=data.user_id;
      console.log('user_id', this.user_id);
    // Open the login URL in the system browser
  
    console.log(data);
   
    if(data.success){
    
    this.oauthToken=data.token;
      Swal2.fire({
        title: 'Usuario de GOOGLE',
        text: 'El usuario ha sido autenticado',
        icon: 'success',
        toast: true,
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
    this.setDrive_media=  setInterval(() => {
      this.get_drive_image();
    }, 2100); 
      this.onPickerApiLoad();
    }else{
      Swal2.fire({
        title: 'Usuario de GOOGLE',
        html: 'El usuario no ha sido autenticado. Busca este <ion-icon name="apps-sharp"></ion-icon> icono en la esquina superior izquierda de supercumulo.laniakea.tv, y autenticate.',
        icon: 'error',
        toast: true,
        position: 'top-start',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      

    }

  });
 
  }
  onPickerApiLoad() {
 
  
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      gapi.load('picker', {
        'callback': () => {
          this.createPicker();

        }
      });
    };

    document.body.appendChild(script);


}
createPicker() {
  if (this.oauthToken) {
    const picker = new google.picker.PickerBuilder()
      .addView(google.picker.ViewId.DOCS)
      .setOAuthToken(this.oauthToken)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setAppId('laniakea-393417')
      .setDeveloperKey(this.apiKey)
      .setCallback(this.pickerCallback.bind(this))
      .build();
    picker.setVisible(true);
  }
}
async pickerCallback(data: any) {
  if (data.action === google.picker.Action.PICKED) {
    const fileId = data.docs[0].id;
    console.log('Selected File ID:', fileId);

    console.log('Selected File ID:', data.docs[0]);
    localStorage.setItem('fileId', fileId);
    localStorage.setItem('file_type', data.docs[0].type);
    localStorage.setItem('file_url', data.docs[0].embedUrl);
      const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
      const videoPreviewElement = document.getElementById("videoSource") as HTMLVideoElement;
      console.log('get_blob', this.oauthToken);
   //get_blob(fileId, data.docs[0])
  }
}
  get_drive_image(){
    Swal2.fire({
			title: 'Usuario de google',
			text: '',
			icon: 'success',
			timer: 9999, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false,
      background: '#010f4e',
		  });

    const fileId = localStorage.getItem('fileId');
    if (fileId) {
      if(this.setDrive_media_called){
        return;
       }else{
         this.setDrive_media_called=true;
       }
      const url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.oauthToken}`
    });
    console.log('Fetched Blob:');

    this.https.get(url, { headers, responseType: 'blob' }).toPromise()
    .then(result => {
      console.log('Fetched Blob:', result);
      let file_type=localStorage.getItem('file_type');
      let file_url= localStorage.getItem('file_url');
        // Create a MediaItem object from the File
           // Generate a timestamp for the filename
           if(file_type=='photo' || file_type =='video'){
          
         
      const timestamp = new Date().toISOString();
      const filename = `file_${timestamp}`;
        const mediaItem: MediaItem = {
          mediaPath: file_url,
          typeData: file_type,
          nameFile: filename,
          id: 1, // Assign a unique ID here if needed
          size: 500,
          index: 0, // Set the desired index value
          route: "", // Set the route value if needed
          status: "", // Set the initial status
          url: "", // Set the URL property
          localUrl: "", // Set the local URL property
          type: "", // Set the type property
          is_premium: 0, // Set the is_premium property
          // Add any other required properties
        };
    
        this.uploadMedia.blob = result;
        this.BASE64=  this.convertBlobToBase64(this.uploadMedia.blob);
        this.LaniakeaSearchIMAGE();
        this.uploadMedia.filename = filename;
        if(file_type=='photo'  ){
          Swal2.fire({
            title: '',
            text: '',
            icon: 'success',
            timer: 9999, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-start',
            showConfirmButton: false
            });
        }else{
          Swal2.fire({
            title: 'Solo puedes subir fotos ',
            text: 'Aseguráte de seleccionar una foto o un video, si el error persiste comunícate con el soporte de Laniakea.',
            icon: 'error',
            timer: 9999, // Show the alert for 45 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-start',
            showConfirmButton: false
            });
            return;
        }
        if (this.formservice.multimedia.length > 0) {
          this.formservice.multimedia[0] = mediaItem;
        } else {
          this.formservice.multimedia.push(mediaItem);
        }
  
        if(this.formservice.normalpublication.blobsMultimedia.length < 1){
          this.formservice.normalpublication.blobsMultimedia[0] = {blob: this.uploadMedia.blob, isPremium: false, filename: this.uploadMedia.filename};
        } else {
          this.formservice.normalpublication.blobsMultimedia.push({blob: this.uploadMedia.blob, isPremium: false, filename: this.uploadMedia.filename});
        }
  
        const media_URL = URL.createObjectURL(result);

        console.log(file_type);

        console.log(file_url);
        if(file_type=='photo'){
          
          const imagePreviewElement = document.getElementById("imagePreview") as HTMLImageElement;
if(imagePreviewElement){

          imagePreviewElement.src=media_URL;
          this.formservice.Capacitor_convertFileSrc=media_URL;
          clearInterval(this.setDrive_media);
            
}
        }

        
      }else{
        Swal2.fire({
          title: 'Error en contenido',
          text: 'Solo puedes subir fotos o videos',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
        });
       }
    })
    .catch(error => {
      console.error('Error fetching blob:', error);
    });
        
    

      
    }
   
  
  }
   async convertBlobToBase64(blob: Blob) {
    console.log('blob', blob);
  
    // Convert Blob to ArrayBuffer
    const buffer = new Uint8Array(await blob.arrayBuffer());
    console.log('buffer', buffer);
  
    // Convert ArrayBuffer to binary string
    let binaryString = '';
    buffer.forEach(byte => {
      binaryString += String.fromCharCode(byte);
    });
    console.log('binaryString', binaryString);
  
    // Encode binary string to Base64
    const base64String = btoa(binaryString);
    console.log('base64String', base64String);
  
    return base64String;
  }
  
  
  
  async LaniakeaSearchIMAGE(){
    Swal2.fire({
			title: 'Buscando productos',
			text: 'Buscando productos similares a la imagen adjunta',
			icon: 'success',
			timer: 6900, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-start',
			showConfirmButton: false,
      background: '#010f4e',
		  });
    
    this.BASE64=  await this.convertBlobToBase64(this.uploadMedia.blob);
    this.index=0;
   
    this.request.createRequestPost('getAlibabaProducts', {
      main_sort:this.main_sort,
      index:this.index, imageSearch:true, BASE64:this.BASE64,  sortOption:this.sortOption,
      language:this.language})
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      }
    //  console.log('data',data);
   this.products=data.success.aliexpress_ds_image_searchV2_response.result.data.data;
  // console.log('products',this.products);
   this.chunkedProducts = this.chunkArray(this.products, 4);
   console.log(this.chunkedProducts)
   if(this.publication_template){
    this.slides.slideTo(1); // Moves to index 1
    this.slides.startAutoplay();
   }

    });
  }
  dismiss(){
    this.router.navigate(['/home/ecosystem-page']);

   
  }
  async handleSlideChange() {
    let currentIndex;
    let length;
    if(this.publication_template){
       currentIndex = await this.slides.getActiveIndex();
       length=await this.slides.length() - 1;
    }else{
       currentIndex = await this.chunk_slides.getActiveIndex();
       length=await this.chunk_slides.length() - 1;
    }
   

    // Check if th e current slide is the last slide
    if (currentIndex === (length)) {
      Swal2.fire({
        title: 'fetching',
        text: 'Buscando  más productos similares',
        icon: 'success',
        timer: 3300, // Show the alert for 45 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        background: '#010f4e',
        });
      // Fetch more publications when reaching the last slide
      if(!this.BASE64){
        this.index++;
        this.request.createRequestPost('getAlibabaProducts', { 
          main_sort:this.main_sort,
          min:this.min,
          max:this.max,
          searchTextInput: this.titleQuery,index:this.index,   sortOption:this.sortOption,
          language:this.language})
        .then((data) => {
          if (data && typeof data === 'string') {
            try {
              data = JSON.parse(data);
            } catch (error) {
              console.error("Error parsing data:", error);
            }
          }
        //  console.log('data', data);
          const newProducts = data?.success?.aliexpress_ds_text_search_response?.data.products?.selection_search_product;
     //     console.log('new products', newProducts);

          // Push new products into existing array
          this.products = [...this.products, ...newProducts];
          this.chunkedProducts = this.chunkArray(this.products, 4);
          setTimeout(() => {
            this.slides.slideNext();
          }, 300); 
        });
      }else{
        this.index++;
        this.request.createRequestPost('getAlibabaProducts', {
          main_sort:this.main_sort,
          index:this.index, imageSearch:true, BASE64:this.BASE64,  sortOption:this.sortOption,
          language:this.language})
        .then((data) => {
          if (data && typeof data === 'string') {
            try {
              data = JSON.parse(data);
            } catch (error) {
              console.error("Error parsing data:", error);
            }
          }
        //  console.log('data', data);
          const newProducts = data.success.aliexpress_ds_image_searchV2_response.result.data.data;
     //     console.log('new products', newProducts);

          // Push new products into existing array
          this.products = [...this.products, ...newProducts];
          this.chunkedProducts = this.chunkArray(this.products, 4);
          setTimeout(() => {
            if(this.publication_template){
              this.slides.slideNext();
            }else{
              this.chunk_slides.slideNext();
            }
      

         
        }, 300); 

        });
      }
    
       //await this.new_page();
    }
  }
  getStockKeepingUnits(product_id, product_name, details?){
    console.log(product_id, product_name)
    Swal2.fire({
      title: 'Buscando detalles',
      text: `Buscando detalles del producto`,
      icon: 'success',
      timer: 6900,
      timerProgressBar: true,
      toast: true,
      position: 'top-start',
      showConfirmButton: false,
      background: '#010f4e',
    });
    this.request.createRequestPost('AlibabaProductHandler', {product_id:product_id})
    .then((data) => {
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      console.log('data',data.success);
      if(!details){
        this.modalController.create({
          component: StockKeepingUnitSelectionComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            data: data,
            product_id:product_id,
            product_name:product_name
  
          }
      }).then((modal)=>{
          modal.present();
      });
      }else{
        this.modalController.create({
          component: StockKeepingUnitSelectionComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            data: data,
            product_id:product_id,
            product_name:product_name, 
            product_details:true
  
          }
      }).then((modal)=>{
          modal.present();
      });
      }
     
   
    });
  }
  async open_sort_options(event: any) {
    const popover = await this.popoverController.create({
      component: SortProdcutsOptionsPopoverComponent,
      event: event,
      translucent: true,
    });
  
    popover.onDidDismiss().then((result) => {
      if (result.data) {
        console.log('Selected sort option:', result.data.sortOption);
        console.log('Selected language:', result.data.language);
        this.sortOption=result.data.sortOption;
        this.language=result.data.language;
        this.min=result.data.min;
        this.max=result.data.max;
        this.main_sort=result.data.main_sort
        // Handle the sort and language logic here
      }
    });
  
    await popover.present();
  }
  fetchOrders(){
    console.log('fetchOrders');
   
      //console.log(data)
      this.modalController.create({
        component: MyDsPurchasesComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {

      //  order_tracking: data,
    
        },
      }).then((modal) => {
        
        modal.present();
       
      });

    
  }
  async handleShippingAddressClick(){
    const popover = await this.popoverController.create({
      component: Bienes6Component,
      componentProps: {
        show_save_address_button:true,
      },
    //  event: ev,            // Positions the popover near the clicked element
      translucent: true,     // Optional: makes the popover semi-transparent
      cssClass: 'my-custom-popover' // Optional: custom styling class
    });
    await popover.present();
  }
  change_title(tab){
    
    if(tab==='Whisper'){

          this.router.navigate(['/home/messages-page']);

   
    }else if(tab==='profile'){

          this.router.navigate(['/home/profile']);


    
    
    }else if(tab==='Laniakea'){
      this.router.navigate(['/home/ecosystem-page']);
  
    
      
    }else if(tab=='crear'){
      if(!this.formservice.profile){
        Swal2.fire({
          title: 'Debes iniciar sesión',
          text: `Ve a https://supercumulo.laniakea.tv/login`,
          icon: 'error',
          timer: 6900,
          timerProgressBar: true,
          toast: true,
          position: 'top-start',
          showConfirmButton: false,
          background: '#010f4e',
        });
        return;
      }
      try{
        this.NewPublication.presentActionSheet();
      }catch{
        Swal2.fire({
          title: 'Debes iniciar sesión',
          text: `Ve a https://supercumulo.laniakea.tv/login`,
          icon: 'error',
          timer: 6900,
          timerProgressBar: true,
          toast: true,
          position: 'top-start',
          showConfirmButton: false,
          background: '#010f4e',
        });
      }
     
    }

   
  }
  slide_product(direction){
    this.slides.stopAutoplay();
    if(direction=='up'){
     
      if(!this.publication_template){
        this.chunk_slides.slidePrev();
      }else{
        this.slides.slidePrev();
      }
     
     
    }else if(direction=='down'){
      if(!this.publication_template){
        this.chunk_slides.slideNext();
      }else{
        this.slides.slideNext();
      }

   
    
    }

  }
  calculateSlidesPerView() {
    this.window_width = window.innerWidth;
  

  }
  onResize(event: Event) {
    //this.formService.mostrar=false;
this.calculateSlidesPerView();
  }
  private requestedProductIds = new Map<string,  string>(); // Store requested product IDs

  async handleImageError(event: Event, product_id: string, product) {
    console.log('Product ID:', product_id);

    const imgElement = event.target as HTMLImageElement;
  // Check if the product ID has already been requested
  if (this.requestedProductIds.has(product_id)) {
    const existingUrl = this.requestedProductIds.get(product_id);
    console.log(`Skipping duplicate request for product ID: ${product_id}, URL: ${existingUrl}`);
    imgElement.src=existingUrl;
    return;
  }
  


   
 
    try {
      let data = await this.request.createRequestPost('AlibabaProductHandler', { product_id: product_id });
      console.log('Product Data:', data);
      if (data && typeof data === 'string') {
        try {
          data = JSON.parse(data);
          console.log('getRecordByToken ios:',data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
      // Extract the mobileDetail string
      let detail = data.success?.aliexpress_ds_product_get_response?.result?.ae_item_base_info_dto.mobile_detail;

      // Parse JSON safely
      let parsedDetail;
      if(!detail){
        this.requestedProductIds.set(product_id, 'https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif');
        product.itemMainPic='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
        return;
      }
      try {
        parsedDetail = JSON.parse(detail);
      } catch (error) {
        console.error('Invalid JSON format:', error);
        return;
      }
  
      if (!parsedDetail?.moduleList) {
        this.requestedProductIds.set(product_id, 'https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif');
        product.itemMainPic='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
        console.error('No moduleList found in the response');
      
        return;
      }
  
      // Function to validate image URLs
      const validateImage = async (url: string): Promise<boolean> => {
        try {
          const response = await fetch(url, { method: 'HEAD' });
        
          return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve( response.ok); // If image loads successfully
            img.onerror = () => resolve(null); // If image fails to load
          });
        } catch (error) {
          return false;
        }
      };
  
    // Find and set the first valid image
    for (const module of parsedDetail.moduleList) {
      if (module.type === 'image' && module.images) {
        for (const image of module.images) {
            if (image.imgUrl) {
                const isValid = await validateImage(image.imgUrl);
                if (isValid) {
                  product.itemMainPic = image.imgUrl;
                    //imgElement.src = image.imgUrl;
                    console.log('Valid image set:', image.imgUrl);
                    this.requestedProductIds.set(product_id, image.imgUrl);
                    return;
                }
            }
        }
      }
      if (module.type === 'image' && module.data?.url) {
        const isValid = await validateImage(module.data.url);
        if (isValid) {
          product.itemMainPic = module.data.url;
          //imgElement.src = module.data.url;
          console.log('Valid image set:', module.data.url);
          this.requestedProductIds.set(product_id, module.data.url);
          return;
        }
      }
    
    }
    product.itemMainPic='https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif';
    console.warn('No valid image found for the product');

    this.requestedProductIds.set(product_id, 'https://laniakea-multimedia.s3.amazonaws.com/laniakea.tv/Sin+resultados-2.gif');
    console.warn('No valid image found for the product');
  
  
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  }
  testClick() { console.log('Div clicked!'); }

  toggleReaderDisplay(){
    console.log('toggleReaderDisplay')
    this.reader=!this.reader;
  }
  share(publication: any, title) {

    const publicationId = publication;
    const url =`https://supercumulo.laniakea.tv/feex-wholesale?p=${publicationId}&title=${title}`;
  
    if(Capacitor.getPlatform() !== 'web'){
    // Copy the URL to clipboard
    this.clipboard.copy(url)
      .then(() => {
        console.log('URL copied to clipboard:', url);
        Swal2.fire({
          title: '¡URL copiado!',
          text: 'El URL ha sido copiado en el portapapeles.',
          icon: 'success',
          timer: 2000, // Show the alert for 2 seconds
          timerProgressBar: true,
          toast: true,
          position: 'top-end',
          showConfirmButton: false
        });
      })
      .catch((error) => {
        console.error('Error copiando el URL en el portapapeles:', error);
        Swal2.fire({
          title: 'Error',
          text: 'Error copiando el URL en el portapapeles.',
          icon: 'error',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000 // Show the alert for 2 seconds
        });
      });
    }else{
      Swal2.fire({
        title: 'URL copiad!',
        text: url,
        icon: 'success',
        timer: 3900, // Show the alert for 2 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-end',
        showConfirmButton: false
        });
      navigator.clipboard.writeText(url).then(() => {
       
      }).catch((error) => {
        //console.error(error);
      });
    }
  }
  onFocus(): void {
    // When input gains focus, assume the user is about to type
    this.placeholderActive = true;
  }

  onBlur(): void {
    this.search_bar=false;
    // When the input loses focus, if there's no value then the placeholder is still active
    setTimeout(() => {
      this.placeholderActive = false;
    }, 300); 
    }
    prediction_click(prediction){
      console.log('prediction',prediction)
      this.titleQuery=prediction;
      this.textQueryPrompt();
    }
    textQueryPrompt(){
      Swal2.fire({
        title: 'Buscando productos',
        text: `Buscando productos similares a "${this.titleQuery}"`,
        icon: 'success',
        timer: 6900,
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        background: '#010f4e',
      });
      this.request.createRequestPost('getAlibabaProducts', {
        main_sort:this.main_sort,
        min:this.min,
        max:this.max,
        index: this.index,
        searchTextInput:   this.titleQuery,
        sortOption:this.sortOption,
        language:this.language
      }).then((data) => {
        if (data && typeof data === 'string') {
          try {
            data = JSON.parse(data);
          } catch (error) {
            console.error("Error parsing data:", error);
          }
        }
      //  console.log('data', data);
  
        this.products = data?.success?.aliexpress_ds_text_search_response?.data.products?.selection_search_product;
        this.chunkedProducts = this.chunkArray(this.products, 4);
     //   console.log('products', this.products);
        this.slides.slideTo(1); // Moves to index 1
        this.slides.startAutoplay();
      });
    }
    getStars(rating: number): { icon: string, filled: boolean }[] {
      const stars: { icon: string, filled: boolean }[] = [];
      const fullStars = Math.floor(rating);
      const decimal = rating % 1;
    
      // Add filled stars
      for (let i = 0; i < fullStars; i++) {
        stars.push({ icon: 'star', filled: true });
      }
    
      // Add half star if decimal >= 0.5 and not at 5 stars
      if (decimal >= 0.5 && stars.length < 5) {
        stars.push({ icon: 'star-half', filled: true });
      }
    
      // Fill remaining with empty stars
      while (stars.length < 5) {
        stars.push({ icon: 'star-outline', filled: false });
      }
    
      return stars;
    }
 // Function to format numbers with suffixes

 formatNumber(value: string | number | undefined | null): string {
  // Handle null/undefined
  if (!value) return '0';

  // Convert to string and check if it has a plus sign
  const input = String(value);
  const hasPlus = input.includes('+');
  
  // Clean the number part (remove everything except numbers and commas)
  const cleanNumber = (input || '').replace(/[^\d,]/g, '').replace(/,/g, '');
  const num = Number(cleanNumber) || 0;
  
  
  if (isNaN(num)) return '0';

  let result: string;
  if (num >= 1000000000000) {
    result = (num / 1000000000000).toFixed(1) + 'T';
  } else if (num >= 1000000000) {
    result = (num / 1000000000).toFixed(1) + 'B';
  } else if (num >= 1000000) {
    result = (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    result = (num / 1000).toFixed(1) + 'K';
  } else {
    result = num.toString();
  }
  return result;
  // Add plus sign back if it was in the original
  //return hasPlus ? result + '+' : result;
}
chunkedProducts: any[][] = [];



  chunkArray(array: any[], chunkSize: number): any[][] {
    return Array.from({ length: Math.ceil(array?.length / chunkSize) }, (_, i) =>
      array.slice(i * chunkSize, i * chunkSize + chunkSize)
    );
  }

  search_icon(){
   this.search_bar=true;
    const element = document.getElementById('myClickableDiv');
    if (element) {
      element.click(); // Simulates a user click
    } else {
      console.error("Element with ID 'myClickableDiv' not found!");
    }
  }
  open_pub_template(index: number, productGroup: number) {
    this.publication_template=true;
    console.log('Product Index in Current Slide:', index);
    console.log('Product Index in Current Slide:',(productGroup ));
    console.log('Product Index in Current Slide:', (index ) * (productGroup ));
    this.slides.slideTo(index + ((productGroup) * 4) );
  }
  segment_section;
  call_emitChange(event){
this.segment_section=event.detail.value;
console.log(this.segment_section)

	
	}
  chunk_products(){
    this.publication_template=false;
  }
  navigateTo(){
    if(!this.formservice.profile){
      Swal2.fire({
        title: 'Debes iniciar sesión',
        text: `Ve a https://supercumulo.laniakea.tv/login`,
        icon: 'error',
        timer: 6900,
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        background: '#010f4e',
      });
      return;
    }
  }
  add_to_favorites(){
    if(!this.formservice.profile){
      Swal2.fire({
        title: 'Debes iniciar sesión',
        text: `Ve a https://supercumulo.laniakea.tv/login`,
        icon: 'error',
        timer: 6900,
        timerProgressBar: true,
        toast: true,
        position: 'top-start',
        showConfirmButton: false,
        background: '#010f4e',
      });
      return;
    }
  }
  restrictSpaces(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault(); // Prevent space input
    }
  }
  
}
