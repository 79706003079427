import { CuentaComponent } from '../../general-design/cuenta/cuenta.component';
import { Calendar } from '@ionic-native/calendar/ngx';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { Router } from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  Type,
  ViewChild,
} from '@angular/core';
import { Style } from '@capacitor/status-bar';
import * as moment from 'moment';
import { ModalController } from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { withSSRContext } from 'aws-amplify';
import { element } from 'protractor';

import { format } from 'path';

import { Console, log } from 'console';
import {PagosPorPublicacionComponent} from 'src/app/components/publication/create/Pagos/Pagos-por-publicacion/Pagos-por-publicacion.component';

interface Fecha {
  cupo: string;
  fecha: string;
  hora: any;
}
@Component({
  selector: 'app-calendary',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendaryComponent implements OnInit {
  
  isSelectingMonth: boolean = false;
  currentUTCTime: string;
  @ViewChild('asToday') today: ElementRef;
  week: any = [
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
    'Domingo',
  ];
  Horas: any = [];
// List of time zones
timeZones: string[] = [
  '(GMT-11:00) Niue Time - Alofi',
  '(GMT-11:00) Samoa Standard Time - Midway',
  '(GMT-11:00) Samoa Standard Time - Pago Pago',
  '(GMT-10:00) Cook Islands Standard Time - Avarua',
  '(GMT-09:00) Hawaii-Aleutian Daylight Time - Adak',
  '(GMT-10:00) Hawaii-Aleutian Standard Time - Honolulu',
  '(GMT-10:00) Tahiti Time - Faaa',
  '(GMT-09:30) Marquesas Time - Marquesas',
  '(GMT-08:00) Alaska Daylight Time - Anchorage',
  '(GMT-09:00) Gambier Time - Gambier',
  '(GMT-07:00) Pacific Daylight Time - Los Angeles',
  '(GMT-07:00) Pacific Daylight Time - Tijuana',
  '(GMT-07:00) Pacific Daylight Time - Vancouver',
  '(GMT-08:00) Pitcairn Time - Adamstown',
  '(GMT-07:00) Mexican Pacific Standard Time - Hermosillo',
  '(GMT-06:00) Mountain Daylight Time - Calgary',
  '(GMT-05:00) Central Daylight Time - Ciudad Juarez',
  '(GMT-06:00) Mountain Daylight Time - Denver',
  '(GMT-07:00) Mountain Standard Time - Fort St. John',
  '(GMT-07:00) Mountain Standard Time - Phoenix',
  '(GMT-07:00) Yukon Time - Whitehorse',
  '(GMT-06:00) Central Standard Time - Belize City',
  '(GMT-05:00) Central Daylight Time - Chicago',
  '(GMT-06:00) Central Standard Time - Guatemala City',
  '(GMT-06:00) Central Standard Time - Managua',
  '(GMT-06:00) Central Standard Time - Mexico City',
  '(GMT-06:00) Central Standard Time - San Jose',
  '(GMT-06:00) Central Standard Time - San Salvador',
  '(GMT-06:00) Central Standard Time - Saskatoon',
  '(GMT-06:00) Central Standard Time - Tegucigalpa',
  '(GMT-05:00) Central Daylight Time - Winnipeg',
  '(GMT-05:00) Easter Island Summer Time - Easter',
  '(GMT-06:00) Galapagos Time - Galapagos',
  '(GMT-05:00) Acre Standard Time - Rio Branco',
  '(GMT-05:00) Colombia Standard Time - Bogota',
  '(GMT-04:00) Cuba Daylight Time - Havana',
  '(GMT-05:00) Eastern Standard Time - Atikokan',
  '(GMT-05:00) Eastern Standard Time - Cancun',
  '(GMT-04:00) Eastern Daylight Time - Cockburn Town',
  '(GMT-05:00) Eastern Standard Time - George Town',
  '(GMT-05:00) Eastern Standard Time - Kingston',
  '(GMT-04:00) Eastern Daylight Time - Nassau',
  '(GMT-04:00) Eastern Daylight Time - New York City',
  '(GMT-05:00) Eastern Standard Time - Panama',
  '(GMT-04:00) Eastern Daylight Time - Port-au-Prince',
  '(GMT-04:00) Eastern Daylight Time - Toronto',
  '(GMT-05:00) Ecuador Time - Guayaquil',
  '(GMT-05:00) Peru Standard Time - Lima',
  '(GMT-04:00) Amazon Standard Time - Manaus',
  '(GMT-04:00) Atlantic Standard Time - Basseterre',
  '(GMT-04:00) Atlantic Standard Time - Brades',
  '(GMT-04:00) Atlantic Standard Time - Bridgetown',
  '(GMT-04:00) Atlantic Standard Time - Castries',
  '(GMT-04:00) Atlantic Standard Time - Chaguanas',
  '(GMT-04:00) Atlantic Standard Time - Fort-de-France',
  '(GMT-04:00) Atlantic Standard Time - Gustavia',
  '(GMT-03:00) Atlantic Daylight Time - Halifax',
  '(GMT-03:00) Atlantic Daylight Time - Hamilton',
  '(GMT-04:00) Atlantic Standard Time - Kingstown',
  '(GMT-04:00) Atlantic Standard Time - Kralendijk',
  '(GMT-04:00) Atlantic Standard Time - Les Abymes',
  '(GMT-04:00) Atlantic Standard Time - Levis',
  '(GMT-04:00) Atlantic Standard Time - Marigot',
  '(GMT-04:00) Atlantic Standard Time - Oranjestad',
  '(GMT-04:00) Atlantic Standard Time - Philipsburg',
  '(GMT-04:00) Atlantic Standard Time - Road Town',
  '(GMT-04:00) Atlantic Standard Time - Roseau',
  '(GMT-04:00) Atlantic Standard Time - Saint Croix',
  '(GMT-04:00) Atlantic Standard Time - Saint George\'s',
  '(GMT-04:00) Atlantic Standard Time - Saint John’s',
  '(GMT-04:00) Atlantic Standard Time - San Juan',
  '(GMT-04:00) Atlantic Standard Time - Santo Domingo',
  '(GMT-04:00) Atlantic Standard Time - The Valley',
  '(GMT-03:00) Atlantic Daylight Time - Thule',
  '(GMT-04:00) Atlantic Standard Time - Willemstad',
  '(GMT-04:00) Bolivia Time - Santa Cruz de la Sierra',
  '(GMT-03:00) Chile Summer Time - Santiago',
  '(GMT-04:00) Guyana Time - Georgetown',
  '(GMT-03:00) Paraguay Summer Time - Asuncion',
  '(GMT-04:00) Venezuela Time - Caracas',
  '(GMT-02:30) Newfoundland Daylight Time - St. John\'s',
  '(GMT-03:00) Argentina Standard Time - Buenos Aires',
  '(GMT-03:00) Brasilia Standard Time - Sao Paulo',
  '(GMT-03:00) GMT-03:00 - Palmer',
  '(GMT-03:00) GMT-03:00 - Punta Arenas',
  '(GMT-03:00) Falkland Islands Standard Time - Stanley',
  '(GMT-03:00) French Guiana Time - Cayenne',
  '(GMT-02:00) St. Pierre & Miquelon Daylight Time - Saint-Pierre',
  '(GMT-03:00) Suriname Time - Paramaribo',
  '(GMT-03:00) Uruguay Standard Time - Montevideo',
  '(GMT-02:00) GMT-02:00 - Nuuk',
  '(GMT-02:00) Fernando de Noronha Standard Time - Noronha',
  '(GMT-02:00) South Georgia Time - Grytviken',
  '(GMT-01:00) Azores Standard Time - Ponta Delgada',
  '(GMT-01:00) Cape Verde Standard Time - Praia',
  '(GMT-02:00) GMT-02:00 - Scoresbysund',
  '(GMT+00:00) Greenwich Mean Time - Abidjan',
  '(GMT+00:00) Greenwich Mean Time - Accra',
  '(GMT+00:00) Greenwich Mean Time - Bamako',
  '(GMT+00:00) Greenwich Mean Time - Bissau',
  '(GMT+00:00) Greenwich Mean Time - Camayenne',
  '(GMT+00:00) Greenwich Mean Time - Dakar',
  '(GMT+00:00) Greenwich Mean Time - Danmarkshavn',
  '(GMT+00:00) Greenwich Mean Time - Douglas',
  '(GMT+00:00) Greenwich Mean Time - Freetown',
  '(GMT+00:00) Greenwich Mean Time - Jamestown',
  '(GMT+00:00) Greenwich Mean Time - Lome',
  '(GMT+00:00) Greenwich Mean Time - London',
  '(GMT+00:00) Greenwich Mean Time - Monrovia',
  '(GMT+00:00) Greenwich Mean Time - Nouakchott',
  '(GMT+00:00) Greenwich Mean Time - Ouagadougou',
  '(GMT+00:00) Greenwich Mean Time - Reykjavik',
  '(GMT+00:00) Greenwich Mean Time - Saint Helier',
  '(GMT+00:00) Greenwich Mean Time - Saint Peter Port',
  '(GMT+00:00) Greenwich Mean Time - Serekunda',
  '(GMT+00:00) Greenwich Mean Time - Sao Tome',
  '(GMT+00:00) Greenwich Mean Time - Troll',
  '(GMT+00:00) Western European Standard Time - Las Palmas de Gran Canaria',
  '(GMT+00:00) Western European Standard Time - Lisbon',
  '(GMT+00:00) Western European Standard Time - Torshavn',
  '(GMT+01:00) Central European Standard Time - Algiers',
  '(GMT+01:00) Central European Standard Time - Amsterdam',
  '(GMT+01:00) Central European Standard Time - Andorra la Vella',
  '(GMT+01:00) Central European Standard Time - Belgrade',
  '(GMT+01:00) Central European Standard Time - Berlin',
  '(GMT+01:00) Central European Standard Time - Birkirkara',
  '(GMT+01:00) Central European Standard Time - Bratislava',
  '(GMT+01:00) Central European Standard Time - Brussels',
  '(GMT+01:00) Central European Standard Time - Budapest',
  '(GMT+01:00) Central European Standard Time - Ljubljana',
  '(GMT+01:00) Central European Standard Time - Luxembourg City',
  '(GMT+01:00) Central European Standard Time - Madrid',
  '(GMT+01:00) Central European Standard Time - Monaco',
  '(GMT+01:00) Central European Standard Time - Nicosia',
  '(GMT+01:00) Central European Standard Time - Oslo',
  '(GMT+01:00) Central European Standard Time - Paris',
  '(GMT+01:00) Central European Standard Time - Podgorica',
  '(GMT+01:00) Central European Standard Time - Prague',
  '(GMT+01:00) Central European Standard Time - Rome',
  '(GMT+01:00) Central European Standard Time - San Marino',
  '(GMT+01:00) Central European Standard Time - Sarajevo',
  '(GMT+01:00) Central European Standard Time - Skopje',
  '(GMT+01:00) Central European Standard Time - Stockholm',
  '(GMT+01:00) Central European Standard Time - Tirana',
  '(GMT+01:00) Central European Standard Time - Vaduz',
  '(GMT+01:00) Central European Standard Time - Valletta',
  '(GMT+01:00) Central European Standard Time - Vatican City',
  '(GMT+01:00) Central European Standard Time - Vienna',
  '(GMT+01:00) Central European Standard Time - Warsaw',
  '(GMT+01:00) Central European Standard Time - Zagreb',
  '(GMT+01:00) West Africa Standard Time - Lagos',
  '(GMT+01:00) West Africa Standard Time - Lagos'
];
selectedTimeZone: string = '';
dropdownOpen: boolean = false;
placeholder: string = 'Select a Time Zone';
// Filtered time zones based on search
filteredTimeZones: string[] = []; // Initially, this can be the same as timeZones
searchText: string = '';
  optionSelect: Array<string>;
  fechas: Fecha;
  Fguard: Array<any> = [];

  Agenda;

  ReservaF: Array<any> = [];

  dia: string = '';
  monthSelect: any[];
  dateSelect: any;
  cupos: string;

  constructor(
    private renderer2: Renderer2,
    private modal: ModalController,
    public formservice: FormServiceService,
    private pagarPublicacion: PagosPorPublicacionComponent
  ) {
     // Initialize filteredTimeZones with all time zones initially
 // Ensure it's an array to begin with
  }

  
  ngOnInit() {
    this.filteredTimeZones = this.timeZones; 
    moment.locale('es');
    const hoy = moment.utc();
    const mes = hoy.format('M');
    const año = hoy.format('YYYY');

    this.getDaysFromDate(mes, año);

    for (let i = 0; i < 24; i++) {
      if (i <= 11) {
        this.Horas.push({ label: `${i}:00 AM `, key: i });
      } else {
        this.Horas.push({ label: `${i}:00 PM `, key: i });
      }
    }

    this.updateCurrentUTCTime(); // Initialize the time immediately
    setInterval(() => {
      this.updateCurrentUTCTime(); // Update the time every second
    }, 1000);
  }

  retorno() {
    this.modal.dismiss();
  }

  getDaysFromDate(month, year) {
    const starDate = moment.utc(`${year}/${month}/01`);
    const endDate = starDate.clone().endOf('month');

    this.dateSelect = endDate;

    const diffDays = endDate.diff(starDate, 'days', true);
    // 30.999999
    const numberDays = Math.round(diffDays); //31

    const arrayDays = Object.keys([...Array(numberDays)]).map((a: any) => {
      a = parseInt(a) + 1;

      const dayObject = moment.utc(`${year}-${month}-${a}`);
      return {
        name: dayObject.format('dddd'),
        value: a,
        indexWeek: dayObject.isoWeekday(),
      };
    });

    this.monthSelect = arrayDays;
  }

  changeMonth(flag) {
    this.dia = '';
    this.isSelectingMonth = false;
    // Clear other properties if needed
    this.fechas = null;
    if (flag < 0) {
      const prevDate = this.dateSelect.clone().subtract(1, 'month');
      this.getDaysFromDate(prevDate.format('MM'), prevDate.format('YYYY'));
    } else {
      const nextDate = this.dateSelect.clone().add(1, 'month');
      this.getDaysFromDate(nextDate.format('MM'), nextDate.format('YYYY'));
    }
  }

  
  RecoverInput(event) {
    this.cupos = event.detail.value;
    if (this.fechas){
      const Horario: Fecha = {
        cupo: this.cupos,
        fecha: this.dia,
        hora: this.optionSelect,
      };

      this.fechas = Horario;
    }
  }

  recoverHora(event: CustomEvent) {
    let Horarios: Array<Fecha> = [];

    this.optionSelect = event.detail.value;

    this.optionSelect.forEach((element) => {
      const Horario: Fecha = {
        cupo: this.cupos,
        fecha: this.dia,
        hora: this.optionSelect,
      };

      this.fechas = Horario;
    });
    if (this.formservice.payCalendar ===true && this.formservice.EarthCalendar ===false){
if (this.fechas.fecha){
  this.pagarPublicacion.solicitarCupoLand(this.dia, this.optionSelect);
}
   

    }
  }

  ConfirmarReserva() {
    if (this.formservice.EarthCalendar ===false){
    if (this.formservice.dias === 0){
    if (this.isSelectingMonth ===true) {
      if (this.fechas.cupo) {
        if (this.fechas.hora){
        
          const [startDate, endDate] = this.dia.split(' - ');

          // Convert start and end dates to moment objects
          const startMoment = moment.utc(startDate);
          const endMoment = moment.utc(endDate);
    
          // Create an empty array to store the date range
          const dateRange = [];
    
          // Loop through each day in the date range
          let currentDate = startMoment.clone();
          while (currentDate.isSameOrBefore(endMoment, 'day')) {
            dateRange.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
          }
    
          // Loop through each date in the date range
          dateRange.forEach(date => {
            const Horario: Fecha = {
              cupo: this.cupos,
              fecha: date,
              hora: this.optionSelect,
            };
    
            // Check if the date is already in Fguard
            if (this.Fguard[date]) {
              if (this.formservice.payCalendar ===false){
              // If it's present, add the selected hours to the existing hours array
              this.Fguard[date].hora.push(...this.optionSelect);
            }else{
              alert('No puedes alterar los días agregados');
            }
            } else {
              // If it's not present, create a new entry with selected hours
              this.Fguard[date] = {
                hora: [...this.optionSelect],
                cupo: this.cupos,
              };
              if (this.formservice.payCalendar ===true){ 
              this.formservice.dias =  dateRange.length; // Update dias quantity
              }
            }
          });
      
    }else{
      alert('Ingresa las horas de disponibilidad');
    }
    }else{
      alert('Ingresa la cantidad de cupos');
    }
    } else {
    moment.locale('es');
    const hoy = moment.utc();
    //hoy.format('dddd Do MMMM YYYY');
    console.table({
      hoy: hoy.format('dddd Do MMMM YYYY'),
      dia: hoy.format('D'),
      diaSemana: hoy.format('dddd'),
      año: hoy.format('YYYY'),
      mes: hoy.format('M'),
    });

    if (this.fechas.cupo) {
      if (this.fechas.fecha) {
      if (this.fechas.hora){
     
     
        if (this.Fguard.includes(this.fechas.fecha)) {
          if (this.formservice.payCalendar ===false){
          this.Fguard[this.fechas.fecha].hora.push(this.fechas.hora);
        }else{
          alert('No puedes alterar los días agregados');
        }
        } else {
        
          this.Fguard[this.fechas.fecha] = {
            hora: this.fechas.hora,
            cupo: this.fechas.cupo,
          };
          if (this.formservice.payCalendar ===true){
          this.formservice.dias = this.formservice.dias + 1;
          }
        }
      
      }else{
        alert('Ingresa las horas de disponibilidad');
      }
      } else {
        alert(
          'No se te olvide seleccionar un día y volver a seleccionar las horas'
        );
      }
   
    } else {
      alert('Ingrese cantidad de cupos');
    }
  }
  if (this.formservice.payCalendar ===true){
    this.formservice.cupos = Number(this.cupos); // Update cupo quantity
 
    this.formservice.horas = this.fechas.hora.length,
    this.pagarPublicacion.getLandCalendarPrice();
  }

 
this.formservice.landpublication.IsReservaCalendario = true;
    this.formservice.landpublication.ReservaCalendario = this.Fguard;

    console.log(
      'ReservaServicio:',
      this.formservice.landpublication.ReservaCalendario
    );
  }else{
    alert('Solo puedes comprar un día o un mes a la vez');
  }
}else{
  if (this.formservice.dias === 0){
    if (this.isSelectingMonth ===true) {

    
        
          const [startDate, endDate] = this.dia.split(' - ');

          // Convert start and end dates to moment objects
          const startMoment = moment.utc(startDate);
          const endMoment = moment.utc(endDate);
    
          // Create an empty array to store the date range
          const dateRange = [];
    
          // Loop through each day in the date range
          let currentDate = startMoment.clone();
          while (currentDate.isSameOrBefore(endMoment, 'day')) {
            dateRange.push(currentDate.format('YYYY-MM-DD'));
            currentDate.add(1, 'day');
          }
    
          // Loop through each date in the date range
          dateRange.forEach(date => {
            const Horario: Fecha = {
              cupo: '1',//this.cupos,
              fecha: date,
              hora: '0:00 AM'//this.optionSelect,
            };
    
            // Check if the date is already in Fguard
            if (this.Fguard[date]) {
      
              alert('No puedes alterar los días agregados');
            
            } else {
              console.log('Fguardcal');
              // If it's not present, create a new entry with selected hours
              this.Fguard[date] = {
                hora: '0:00 AM',//[...this.optionSelect],
                cupo:'1'// this.cupos,
              };

            
              if (this.formservice.payCalendar ===true){
              //  this.pagarPublicacion.getLandCalendarPrice();
             
              this.formservice.dias =  dateRange.length; // Update dias quantity
              }
              this.pagarPublicacion.getEarthCalendarPrice();
            }
          });
      


    } else {
    moment.locale('es');
    const hoy = moment.utc();
    //hoy.format('dddd Do MMMM YYYY');
    console.table({
      hoy: hoy.format('dddd Do MMMM YYYY'),
      dia: hoy.format('D'),
      diaSemana: hoy.format('dddd'),
      año: hoy.format('YYYY'),
      mes: hoy.format('M'),
    });

  console.log('1');
      if (this.fechas.fecha) {

        console.log('2');
     
        if (this.Fguard.includes(this.fechas.fecha)) {
  
          alert('Dia(s) ya agregados');
        
        } else {
          console.log('Fguardcal');
          this.Fguard[this.fechas.fecha] = {
            hora: '0:00 AM',//this.fechas.hora,
            cupo:'1', //this.fechas.cupo,
          };
          if (this.formservice.payCalendar ===true){
          this.formservice.dias = this.formservice.dias + 1;
         // this.pagarPublicacion.getLandCalendarPrice();
          this.pagarPublicacion.getEarthCalendarPrice( );
        
          }
       
        }
      
        console.log('3');
      } else {
        alert(
          'No se te olvide seleccionar un día y volver a seleccionar las horas'
        );
      }
   
      console.log('4');
  }
  console.log('5');
  this.formservice.earthpublication.IsReservaCalendario = true;
  this.formservice.earthpublication.ReservaCalendario = this.Fguard;

  console.log(
    'ReservaServicio:',
    this.formservice.earthpublication.ReservaCalendario
  );

  

  }else{
    alert('Solo puedes comprar un día o un mes a la vez');
  }
}
  }
  getDayBackgroundColor(day: any): string {
    const monthYear = this.dateSelect.format('YYYY-MM');
    // Extract the day value from the day object
    const dayValue = day.value;
  
    // Check if the day value matches the dia property
    if (this.dia === `${monthYear}-${dayValue}`) {
      // If it matches, return the desired background color
      return '#010f4e'; // Set the desired color here
    } else {
      // If it doesn't match, return an empty string for no background color
      return '';
    }
  }
  
  clickDay(day) {
    const monthYear = this.dateSelect.format('YYYY-MM');
    this.dia = `${monthYear}-${day.value}`;
    const objectDate = moment.utc(this.dia);
if (this.formservice.EarthCalendar ===false){
    if (this.fechas){
      const Horario: Fecha = {
        cupo: this.cupos,
        fecha: this.dia,
        hora: this.optionSelect,
      };

      this.fechas = Horario;
    }
    if (this.formservice.payCalendar ===true && this.formservice.EarthCalendar ===false){
      this.pagarPublicacion.solicitarCupoLand(this.dia, this.optionSelect);
    }
  }else{
  
      console.log('idk::');
      const Horario: Fecha = {
        cupo: '0',
        fecha: this.dia,
        hora: '0:00 AM',
      };
      this.fechas = Horario;
      console.log('fechass::',this.fechas);
      this.fechas.fecha =this.dia;
      console.log('dia::',this.fechas.fecha);
       if(this.formservice.payCalendar !==false  && this.formservice.EarthCalendar ===true){
        this.pagarPublicacion.solicitarCupoEarth(this.dia, this.optionSelect);
      }
    
  }

  }

  selectMonth() {
    if (!this.isSelectingMonth) {
      const firstDayOfMonth = this.dateSelect.clone().startOf('month');
      const lastDayOfMonth = this.dateSelect.clone().endOf('month');
      const monthYear = this.dateSelect.format('YYYY-MM');
      const firstDay = firstDayOfMonth.format('YYYY-MM-DD');
      const lastDay = lastDayOfMonth.format('YYYY-MM-DD');

      this.dia = `${firstDay} - ${lastDay}`;
      this.isSelectingMonth = true;

    
        const [startDate, endDate] = this.dia.split(' - ');
           // Create an empty array to store the date range
           const dateRange = [];
        // Convert start and end dates to moment objects
        const startMoment = moment.utc(startDate);
        const endMoment = moment.utc(endDate);
           // Loop through each day in the date range
           let currentDate = startMoment.clone();
           while (currentDate.isSameOrBefore(endMoment, 'day')) {
             dateRange.push(currentDate.format('YYYY-MM-DD'));
             currentDate.add(1, 'day');
           }
           
  

           if (this.formservice.payCalendar ===true && this.formservice.EarthCalendar ===false){
    this.pagarPublicacion.solicitarCupodeMesLand(dateRange, this.optionSelect);
      }else if(this.formservice.payCalendar ===true && this.formservice.EarthCalendar ===true){
        this.pagarPublicacion.solicitarCupoEarth(dateRange, this.optionSelect);
      }
    } else {
      this.dia = '';
      this.isSelectingMonth = false;
      // Clear other properties if needed
      this.fechas = null;
      // Clear any other properties that need to be reset
    }
    
  }
  
  
  updateCurrentUTCTime() {
    const now = new Date();
    const currentTime = now.toISOString().split('T')[1].substring(0, 8);
    this.currentUTCTime = currentTime; // Get the current UTC time (HH:mm:ss)
  }
  
  filterTimeZones(event: any) {
    const searchLower = event.detail.value.toLowerCase(); // Get the input value from the event
  
    // Filter based on the name property if timeZones is an array of objects
    this.filteredTimeZones = searchLower 
      ? this.timeZones.filter(zone => zone.toLowerCase().includes(searchLower))
      : [...this.timeZones]; // Reset to original list if search is empty
  
    // Open dropdown if there are any filtered results
    this.dropdownOpen = this.filteredTimeZones.length > 0;
  }
  
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectTimeZone(zone: string) {
    this.formservice.landpublication.time_zone=zone;
    this.formservice.earthpublication.time_zone=zone;
    this.formservice.time_zone=zone;
    this.selectedTimeZone = zone;
    this.dropdownOpen = false; // Close dropdown on selection
    this.searchText = ''; // Clear search text on selection if desired
    this.filteredTimeZones = [...this.timeZones]; // Reset filtered list on selection if needed
  }
}
