import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ShootingStarComponent} from 'src/app/components/publication/create/shooting-star/shooting-star.component';
import  Swal from 'sweetalert2';
declare var google: any; // Declare the google namespace
import { Router } from '@angular/router';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { RequestService } from 'src/app/services/request/request.service';
@Component({
  selector: 'app-shooting-star2',
  templateUrl: './shooting-star2.component.html',
  styleUrls: ['./shooting-star2.component.scss'],
})
export class ShootingStar2Component implements OnInit {
// Set the values of startPoint_lat and startPoint_lng
startPoint_lat;
startPoint_lng ;

// Set the values of destination_lat and destination_lng
destination_lat ;
destination_lng;
  public addedLocationMarkers: boolean=false;
  public startPoint:any;
  public destination:any;
public Servicio: any;
  public showOrdenarButton:boolean=false;
  private directionsService: google.maps.DirectionsService;
  private directionsRenderer: google.maps.DirectionsRenderer;
  apikey= environment.ApiKeyGoogleMaps;
public Autocomplete:boolean=false;
public items = [
  { label: 'Transporte personal', icon: 'boat-sharp' },
  { label: 'Transporte de paquetería', icon: 'cube' },
  //{ label: 'Flete', icon: 'cube-sharp' },
  //{ label: 'Comida rápida', icon: 'fast-food-sharp' },
  //{ label: 'Recoger en tienda', icon: 'bag-handle-sharp' },
 // { label: 'Otro', icon: 'add-sharp' }
];

envio;
delivery_payload
delivery_window_start
delivery_window_end
total_charge_value
total_charge_unit
pick_up_window_start
pick_up_window_end
  constructor(private modalcontroller:ModalController,
    private httpClient: HttpClient,
    public router: Router,
    private formService: FormServiceService,
    private request: RequestService
    ) { }

    ngOnInit() {
     
      /*
      this.request.createRequestPost('requestAnUberRide', { }).then((response) => {
console.log('response', response);
      }).catch((error) => {
        console.error('Failed to send request:', error);
      });*/
      try {
      //  if (this.formService.googleMapOpened===false){
          this.formService.googleMapOpened=true;
          this.initMap();
      /*  }else{
          console.error('Error initializing Google Map:');
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            // Navigate back to the current URL
            this.router.navigate(['/loader']);
            });
          setTimeout(() => {
          // Reload the current page to reinitialize the app
          window.location.reload();
          },300);
        }*/

} catch (error) {
  console.error('Error initializing Google Map:', error);
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    // Navigate back to the current URL
    this.router.navigate(['/loader']);
    });
  setTimeout(() => {
  // Reload the current page to reinitialize the app
  window.location.reload();
  },300);
  // Handle the error gracefully, e.g., display an error message to the user.
}
    }

    Ordenar(){
      if(this.startPoint && this.destination){
        this.modalcontroller.create({
          component:ShootingStarComponent,
          cssClass: 'detailsInModal',
          swipeToClose: true,
          componentProps: {
            startPoint:this.startPoint,
            destination:this.destination,
            Servicio: this.Servicio,
            startPoint_lat: this.startPoint_lat,
            startPoint_lng: this.startPoint_lng,
            
            // Set the values of destination_lat and destination_lng
            destination_lat : this.destination_lat,
            destination_lng : this.destination_lng
              },
        })
        .then((modal => {modal.present();} ))
      }else{
        Swal.fire({
          title: 'Información de punto de partida y destino faltante',
          icon: 'error',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
          customClass: {
            confirmButton: 'swal-button'
          }
        });
      }
    
    }
    dismiss(){
      this.router.navigate(['home/ecosystem-page', ]);
    }
  setAutocomplete(icon){
    console.log(icon);
    this.Servicio=icon;
    this.Autocomplete=true;
  }
    initMap(): void {
      try {
      const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: { lat: 23.634501, lng: -102.552784 },
        zoom: 13,
        mapTypeControl: false,
      });
      const card = document.getElementById('pac-card') as HTMLElement;
      const input = document.getElementById('pac-input') as HTMLInputElement;
      const input2 = document.getElementById('pac-input-destination') as HTMLInputElement;
      const biasInputElement = document.getElementById('use-location-bias') as HTMLInputElement;
      const strictBoundsInputElement = document.getElementById('use-strict-bounds') as HTMLInputElement;
      const options = {
        fields: ['formatted_address', 'geometry', 'name'],
        strictBounds: false,
      };
  
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);
  
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      const autocomplete2 = new google.maps.places.Autocomplete(input2, options);
  
      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo('bounds', map);
      autocomplete2.bindTo('bounds', map);

      const infowindow = new google.maps.InfoWindow();
      const infowindow2 = new google.maps.InfoWindow();
      const infowindowContent = document.getElementById(
        'infowindow-content'
      ) as HTMLElement;
      const infowindowContent2 = document.getElementById(
        'infowindow-content2'
      ) as HTMLElement;
  
      infowindow.setContent(infowindowContent);
      infowindow2.setContent(infowindowContent2);
  
      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29),
      });
      const marker2 = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -39),
      });
  
      autocomplete.addListener('place_changed', () => {
        this.handlePlaceChanged(autocomplete, infowindow, marker, input.value);
      });
  
      autocomplete2.addListener('place_changed', () => {
        this.handlePlaceChanged(autocomplete2, infowindow2, marker2, input2.value);
      });
  
      // Initialize DirectionsService and DirectionsRenderer
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(map);
  // Add the following code to your initMap function

// Initialize a variable to hold the user's location marker
let userLocationMarker = null;

// Check if the Geolocation API is available in the browser
if ('geolocation' in navigator) {
  // Watch the user's current position and update the marker
  const watchId = navigator.geolocation.watchPosition((position) => {
    const userLatLng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    if(this.addedLocationMarkers===false){
      let plane = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/Space-Planes_Web+Background+Removed.png';
      let helicopter='https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/helicopter+Background+Removed.png';
      this.addCustomMarker(map, position.coords.latitude, position.coords.longitude, plane);
      //this.addCustomMarker(map, position.coords.latitude +0.3, position.coords.longitude -0.0042, 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Embraer-Jet-Taking-Off-Plane-png-hd.png');
      this.addCustomMarker(map, position.coords.latitude -0.6, position.coords.longitude +0.0039, helicopter);
      //this.addCustomMarker(map, position.coords.latitude +0.9, position.coords.longitude -0.0036, 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Embraer-Jet-Taking-Off-Plane-png-hd.png');
      this.addCustomMarker(map, position.coords.latitude -0.012, position.coords.longitude +0.0033,plane);
     // this.addCustomMarker(map, position.coords.latitude +0.015, position.coords.longitude -0.0030, 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Embraer-Jet-Taking-Off-Plane-png-hd.png');
      this.addCustomMarker(map, position.coords.latitude -0.018, position.coords.longitude +0.0027, helicopter);
     // this.addCustomMarker(map, position.coords.latitude +0.021, position.coords.longitude -0.0024, 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Embraer-Jet-Taking-Off-Plane-png-hd.png');
      this.addCustomMarker(map, position.coords.latitude -0.024, position.coords.longitude +0.0021, plane);
     // this.addCustomMarker(map, position.coords.latitude +0.027, position.coords.longitude -0.0018, 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Embraer-Jet-Taking-Off-Plane-png-hd.png');
      this.addCustomMarker(map, position.coords.latitude -0.030, position.coords.longitude +0.0015, helicopter);
     // this.addCustomMarker(map, position.coords.latitude +0.033, position.coords.longitude -0.0012, 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Embraer-Jet-Taking-Off-Plane-png-hd.png');
      this.addCustomMarker(map, position.coords.latitude -0.036, position.coords.longitude +0.009, plane);
     // this.addCustomMarker(map, position.coords.latitude +0.039, position.coords.longitude -0.006, 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/Embraer-Jet-Taking-Off-Plane-png-hd.png');
      this.addCustomMarker(map, position.coords.latitude -0.042, position.coords.longitude +0.003, helicopter);
      this.addedLocationMarkers=true;
    }
    // If the user location marker is already created, update its position
    if (userLocationMarker) {
      userLocationMarker.setPosition(userLatLng);
    } else {
      // Create a new marker if it doesn't exist
      userLocationMarker = new google.maps.Marker({
        position: userLatLng,
        map: map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE, // Use a circle as the icon
          fillColor: 'blue', // Blue color for the dot
          fillOpacity: 1,
          scale: 6, // Adjust the size as needed
          strokeWeight: 0,
        },
        zIndex: google.maps.Marker.MAX_ZINDEX + 1, // Ensure the user's location marker is on top
      });
    }

    // Optionally, you can also center the map on the user's location
   // map.setCenter(userLatLng);

    // Optionally, you can also zoom in to a specific level
    //map.setZoom(15); // Adjust the zoom level as needed
  });

  // To stop watching the user's location, you can use the following code:
  // navigator.geolocation.clearWatch(watchId);
} else {
  // Geolocation is not available in this browser
  console.error('Geolocation is not available in this browser.');
}
let plane = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/Space-Planes_Web+Background+Removed.png';
let helicopter = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/helicopter+Background+Removed.png';

this.addCustomMarker(map, -34.6037, -58.3816, helicopter); // Buenos Aires, Argentina
this.addCustomMarker(map, -22.9068, -43.1729, plane);      // Rio de Janeiro, Brazil
this.addCustomMarker(map, -15.7801, -47.9292, helicopter); // Brasília, Brazil
this.addCustomMarker(map, -12.0464, -77.0428, plane);      // Lima, Peru
this.addCustomMarker(map, -33.4489, -70.6693, helicopter); // Santiago, Chile
this.addCustomMarker(map, -0.1807, -78.4678, plane);       // Quito, Ecuador
this.addCustomMarker(map, 10.4806, -66.9036, helicopter);  // Caracas, Venezuela
this.addCustomMarker(map, -17.7833, -63.1821, plane);      // Santa Cruz, Bolivia
this.addCustomMarker(map, -31.4201, -64.1888, helicopter); // Córdoba, Argentina
this.addCustomMarker(map, -25.2637, -57.5759, plane);      // Asunción, Paraguay
this.addCustomMarker(map, -34.9011, -56.1911, helicopter); // Montevideo, Uruguay
this.addCustomMarker(map, -2.1700, -79.9224, plane);       // Guayaquil, Ecuador
this.addCustomMarker(map, 4.7110, -74.0721, helicopter);   // Bogotá, Colombia
this.addCustomMarker(map, -8.0476, -34.8770, plane);       // Recife, Brazil
this.addCustomMarker(map, -25.3005, -57.6362, helicopter); // Luque, Paraguay
this.addCustomMarker(map, -23.5505, -46.6333, plane);      // São Paulo, Brazil
this.addCustomMarker(map, -16.4897, -68.1193, helicopter); // La Paz, Bolivia
this.addCustomMarker(map, 3.4216, -76.5205, plane);        // Cali, Colombia
this.addCustomMarker(map, -29.9027, -71.2519, helicopter); // La Serena, Chile
this.addCustomMarker(map, -3.7492, -73.2538, plane);       // Iquitos, Peru
this.addCustomMarker(map, -3.0704, -60.0212, helicopter);  // Manaus, Brazil
this.addCustomMarker(map, 10.9685, -74.7813, plane);       // Barranquilla, Colombia
this.addCustomMarker(map, -53.1638, -70.9171, helicopter); // Punta Arenas, Chile
this.addCustomMarker(map, -27.4698, -58.8306, plane);      // Corrientes, Argentina
this.addCustomMarker(map, 8.9833, -79.5167, helicopter);   // Panama City, Panama
this.addCustomMarker(map, -2.9001, -79.0059, plane);       // Cuenca, Ecuador
this.addCustomMarker(map, -32.8895, -68.8458, helicopter); // Mendoza, Argentina
this.addCustomMarker(map, -36.8201, -73.0351, plane);      // Concepción, Chile
this.addCustomMarker(map, -38.4161, -63.6167, helicopter); // Patagonia, Argentina
this.addCustomMarker(map, 11.0041, -74.8060, plane);       // Santa Marta, Colombia
this.addCustomMarker(map, -0.2310, -78.5122, helicopter);  // Ambato, Ecuador
this.addCustomMarker(map, 10.3910, -75.4794, plane);       // Cartagena, Colombia
this.addCustomMarker(map, -12.0621, -77.0365, helicopter); // Miraflores, Peru
this.addCustomMarker(map, -3.0039, -60.0208, plane);       // Amazon Rainforest, Brazil
this.addCustomMarker(map, -13.1639, -74.2239, helicopter); // Ayacucho, Peru
this.addCustomMarker(map, 6.2442, -75.5812, plane);        // Medellín, Colombia
this.addCustomMarker(map, -20.3485, -70.1386, helicopter); // Iquique, Chile
this.addCustomMarker(map, -3.1190, -60.0217, plane);       // Amazon River, Brazil
this.addCustomMarker(map, -3.7437, -73.2516, helicopter);  // Amazon Basin, Peru
this.addCustomMarker(map, -9.1900, -75.0152, plane);       // Huánuco, Peru
this.addCustomMarker(map, 9.3047, -70.2021, helicopter);   // Mérida, Venezuela
this.addCustomMarker(map, -5.0892, -80.1625, plane);       // Piura, Peru
this.addCustomMarker(map, -0.9677, -80.7089, helicopter);  // Manta, Ecuador
this.addCustomMarker(map, -25.5095, -54.6753, plane);      // Iguazu Falls, Argentina-Brazil
this.addCustomMarker(map, 9.9333, -84.0833, helicopter);   // San José, Costa Rica
this.addCustomMarker(map, 12.1150, -86.2362, plane);       // Managua, Nicaragua
this.addCustomMarker(map, 15.2000, -86.2420, helicopter);  // Tegucigalpa, Honduras
this.addCustomMarker(map, 4.8604, -58.9302, plane);        // Georgetown, Guyana
this.addCustomMarker(map, 5.8520, -55.2038, helicopter);   // Paramaribo, Suriname
this.addCustomMarker(map, 4.9346, -52.3303, plane);        // Cayenne, French Guiana
this.addCustomMarker(map, 19.4326, -99.1332, helicopter);  // Mexico City, Mexico





setTimeout(() => {
  let png='https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/cars-transparent-10+Background+Removed.png';
// Coordinates for the start and end points
let startPoint = { lat: 23.6345, lng: -102.5528 };
let endPoint = { lat: 20.9676, lng: -89.6237 };
// Call the generatePolyline function to display the polyline
this.generatePolyline(map, startPoint, endPoint,png);
// Call the generatePolyline function to display the polyline
//this.generatePolyline(map, endPoint, startPoint,png);

setTimeout(() => {
let startPoint0 = { lat: 20.6345, lng: -103.5528 };
let endPoint0 = { lat: 20.2976, lng: -103.1837 };
// Call the generatePolyline function to display the polyline
//this.generatePolyline(map, endPoint0, startPoint0,png);
this.generatePolyline(map, startPoint0, endPoint0,png);

},900);

setTimeout(() => {
  let startPoint1 = { lat: 21.1619, lng: -86.8516 };
  let endPoint1 = { lat: 20.9676, lng: -89.6237 };
  // Call the generatePolyline function to display the polyline
  //this.generatePolyline(map, endPoint1, startPoint1,png);
  this.generatePolyline(map, startPoint1, endPoint1,png);
}, 900);

setTimeout(() => {
  let startPoint2 = { lat: 25.6765, lng: -100.3185 };
  let endPoint2 = { lat: 19.7027, lng: -101.1949 };
  // Call the generatePolyline function to display the polyline
  //this.generatePolyline(map, endPoint2, startPoint2,png);
  this.generatePolyline(map, startPoint2, endPoint2,png);
}, 900);
setTimeout(() => {
  let startPoint3 = { lat: 19.4326, lng: -99.1332 };
  let endPoint3 = { lat: 20.5881, lng: -100.3899 };
  // Call the generatePolyline function to display the polyline
  //this.generatePolyline(map, endPoint3, startPoint3,png);
  this.generatePolyline(map, startPoint3, endPoint3,png);
}, 900);

setTimeout(() => {
  let startPoint4 = { lat: 16.8634, lng: -99.8825 };
  let endPoint4 = { lat: 20.9676, lng: -89.6237 };
  // Call the generatePolyline function to display the polyline
  this.generatePolyline(map, endPoint4, startPoint4,png);
 // this.generatePolyline(map, startPoint4, endPoint4,png);
}, 900);

setTimeout(() => {
  let startPoint5 = { lat: 19.1738, lng: -96.1342 };
  let endPoint5 = { lat: 20.9676, lng: -89.6237 };
  // Call the generatePolyline function to display the polyline
  this.generatePolyline(map, endPoint5, startPoint5,png);
  //this.generatePolyline(map, startPoint5, endPoint5,png);
}, 900);

setTimeout(() => {
  let startPoint6 = { lat: 27.9721, lng: -110.8975 };
  let endPoint6 = { lat: 20.9676, lng: -89.6237 };
  // Call the generatePolyline function to display the polyline
  this.generatePolyline(map, endPoint6, startPoint6,png);
  //this.generatePolyline(map, startPoint6, endPoint6,png);
}, 900);

setTimeout(() => {
  let startPoint7 = { lat: 21.1526, lng: -101.7117 };
  let endPoint7 = { lat: 20.9676, lng: -89.6237 };
  // Call the generatePolyline function to display the polyline
  this.generatePolyline(map, endPoint7, startPoint7,png);
  //this.generatePolyline(map, startPoint7, endPoint7,png);
}, 900);
setTimeout(() => {
  let startPoint8 = { lat: 25.6714, lng: -100.3090 };
  let endPoint8 = { lat: 25.4275, lng: -100.9737 };
  // Call the generatePolyline function to display the polyline
  this.generatePolyline(map, endPoint8, startPoint8,png);
 // this.generatePolyline(map, startPoint8, endPoint8,png);
}, 900);

setTimeout(() => {
  let startPoint9 = { lat: 25.6490, lng: -100.4437 };
  let endPoint9 = { lat: 25.4167, lng: -100.9737 };
  // Call the generatePolyline function to display the polyline
  this.generatePolyline(map, endPoint9, startPoint9,png);
  //this.generatePolyline(map, startPoint9, endPoint9,png);
}, 900);

setTimeout(() => {
  let startPoint10 = { lat: 25.3961, lng: -100.0411 };
  let endPoint10 = { lat: 25.6732, lng: -100.3095 };
  // Call the generatePolyline function to display the polyline
  this.generatePolyline(map, endPoint10, startPoint10,png);
 // this.generatePolyline(map, startPoint10, endPoint10,png);
}, 900);


setTimeout(() => {

  setTimeout(() => {
    let png = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/camion_unitario+Background+Removed.png';
  
    let startPoint11 = { lat: 32.4989, lng: -117.0208 }; // Tijuana
    let endPoint11 = { lat: 31.7386, lng: -116.7287 };   // Ensenada
    this.generatePolyline(map, startPoint11, endPoint11, png);
   // this.generatePolyline(map, endPoint11, startPoint11, png);
  
    setTimeout(() => {
      let startPoint12 = { lat: 22.8905, lng: -109.9124 }; // Cabo San Lucas
      let endPoint12 = { lat: 24.1426, lng: -110.3128 };   // La Paz
      this.generatePolyline(map, startPoint12, endPoint12, png);
      this.generatePolyline(map, endPoint12, startPoint12, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint13 = { lat: 20.6296, lng: -105.2202 }; // Puerto Vallarta
      let endPoint13 = { lat: 21.1619, lng: -104.4877 };   // Tepic
      this.generatePolyline(map, startPoint13, endPoint13, png);
     // this.generatePolyline(map, endPoint13, startPoint13, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint14 = { lat: 28.6321, lng: -106.0691 }; // Chihuahua
      let endPoint14 = { lat: 31.6899, lng: -106.4897 };   // Ciudad Juárez
      this.generatePolyline(map, startPoint14, endPoint14, png);
     // this.generatePolyline(map, endPoint14, startPoint14, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint15 = { lat: 24.8055, lng: -107.3940 }; // Culiacán
      let endPoint15 = { lat: 25.4383, lng: -108.9773 };   // Los Mochis
      this.generatePolyline(map, startPoint15, endPoint15, png);
     // this.generatePolyline(map, endPoint15, startPoint15, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint16 = { lat: 17.6440, lng: -101.5523 }; // Zihuatanejo
      let endPoint16 = { lat: 16.8596, lng: -99.8776 };    // Acapulco
      this.generatePolyline(map, startPoint16, endPoint16, png);
     // this.generatePolyline(map, endPoint16, startPoint16, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint17 = { lat: 16.7347, lng: -93.7153 };  // Tuxtla Gutiérrez
      let endPoint17 = { lat: 17.0542, lng: -96.7132 };    // Oaxaca
      this.generatePolyline(map, startPoint17, endPoint17, png);
     // this.generatePolyline(map, endPoint17, startPoint17, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint18 = { lat: 19.4285, lng: -99.1277 };  // Mexico City
      let endPoint18 = { lat: 18.9242, lng: -99.2216 };    // Cuernavaca
      this.generatePolyline(map, startPoint18, endPoint18, png);
      //this.generatePolyline(map, endPoint18, startPoint18, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint19 = { lat: 19.0414, lng: -98.2062 };  // Puebla
      let endPoint19 = { lat: 18.1428, lng: -94.1431 };    // Coatzacoalcos
      this.generatePolyline(map, startPoint19, endPoint19, png);
      //this.generatePolyline(map, endPoint19, startPoint19, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint20 = { lat: 19.1809, lng: -96.1429 };  // Veracruz
      let endPoint20 = { lat: 20.0609, lng: -97.0584 };    // Poza Rica
      this.generatePolyline(map, startPoint20, endPoint20, png);
     // this.generatePolyline(map, endPoint20, startPoint20, png);
    }, 900);
  
  }, 900);
  

  setTimeout(() => {
    let png = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/moto+Background+Removed.png';
  
    let startPoint21 = { lat: 22.3961, lng: -97.9310 }; // Tampico
    let endPoint21 = { lat: 21.3156, lng: -98.8083 };   // Tuxpan
    this.generatePolyline(map, startPoint21, endPoint21, png);
    //this.generatePolyline(map, endPoint21, startPoint21, png);
  
    setTimeout(() => {
      let startPoint22 = { lat: 17.0654, lng: -96.7237 }; // Oaxaca
      let endPoint22 = { lat: 16.3224, lng: -95.2469 };   // Salina Cruz
      this.generatePolyline(map, startPoint22, endPoint22, png);
     // this.generatePolyline(map, endPoint22, startPoint22, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint23 = { lat: 20.6159, lng: -105.2302 }; // Puerto Vallarta
      let endPoint23 = { lat: 20.6770, lng: -103.3475 };   // Guadalajara
      this.generatePolyline(map, startPoint23, endPoint23, png);
    //  this.generatePolyline(map, endPoint23, startPoint23, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint24 = { lat: 24.0277, lng: -104.6532 }; // Durango
      let endPoint24 = { lat: 22.7690, lng: -102.5772 };   // Aguascalientes
      this.generatePolyline(map, startPoint24, endPoint24, png);
    //  this.generatePolyline(map, endPoint24, startPoint24, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint25 = { lat: 29.0972, lng: -110.9533 }; // Hermosillo
      let endPoint25 = { lat: 27.4934, lng: -109.9386 };   // Ciudad Obregón
      this.generatePolyline(map, startPoint25, endPoint25, png);
     // this.generatePolyline(map, endPoint25, startPoint25, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint26 = { lat: 25.8677, lng: -97.5062 };  // Matamoros
      let endPoint26 = { lat: 24.8333, lng: -98.1599 };    // Ciudad Victoria
      this.generatePolyline(map, startPoint26, endPoint26, png);
    //  this.generatePolyline(map, endPoint26, startPoint26, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint27 = { lat: 18.9338, lng: -103.8844 };  // Colima
      let endPoint27 = { lat: 19.2490, lng: -103.7271 };    // Manzanillo
      this.generatePolyline(map, startPoint27, endPoint27, png);
   //   this.generatePolyline(map, endPoint27, startPoint27, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint28 = { lat: 28.6353, lng: -106.0889 };  // Chihuahua
      let endPoint28 = { lat: 27.4770, lng: -107.8642 };    // Creel
      this.generatePolyline(map, startPoint28, endPoint28, png);
    //  this.generatePolyline(map, endPoint28, startPoint28, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint29 = { lat: 18.8766, lng: -99.2205 };  // Cuernavaca
      let endPoint29 = { lat: 18.6823, lng: -99.6567 };    // Taxco
      this.generatePolyline(map, startPoint29, endPoint29, png);
     // this.generatePolyline(map, endPoint29, startPoint29, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint30 = { lat: 20.5871, lng: -100.3881 };  // Querétaro
      let endPoint30 = { lat: 20.4131, lng: -99.6831 };    // San Juan del Río
      this.generatePolyline(map, startPoint30, endPoint30, png);
    //  this.generatePolyline(map, endPoint30, startPoint30, png);
    }, 900);
  
  }, 900);

  let png = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/sl6+Background+Removed.png';

  let startPoint31 = { lat: 19.0413, lng: -98.2062 }; // Puebla
  let endPoint31 = { lat: 18.9242, lng: -99.2216 };   // Cuernavaca
  this.generatePolyline(map, startPoint31, endPoint31, png);
 // this.generatePolyline(map, endPoint31, startPoint31, png);

  setTimeout(() => {
    let startPoint32 = { lat: 20.5888, lng: -100.3899 }; // Querétaro
    let endPoint32 = { lat: 21.1619, lng: -86.8515 };   // Cancún
    this.generatePolyline(map, startPoint32, endPoint32, png);
   // this.generatePolyline(map, endPoint32, startPoint32, png);
  }, 900);

  setTimeout(() => {
    let startPoint33 = { lat: 21.8853, lng: -102.2916 }; // Aguascalientes
    let endPoint33 = { lat: 20.6769, lng: -103.3475 };   // Guadalajara
    this.generatePolyline(map, startPoint33, endPoint33, png);
   // this.generatePolyline(map, endPoint33, startPoint33, png);
  }, 900);

  setTimeout(() => {
    let startPoint34 = { lat: 17.5506, lng: -99.5059 }; // Chilpancingo
    let endPoint34 = { lat: 16.8634, lng: -99.8825 };   // Acapulco
    this.generatePolyline(map, startPoint34, endPoint34, png);
   // this.generatePolyline(map, endPoint34, startPoint34, png);
  }, 900);

  setTimeout(() => {
    let startPoint35 = { lat: 24.8053, lng: -107.3940 }; // Culiacán
    let endPoint35 = { lat: 23.2494, lng: -106.4111 };   // Mazatlán
    this.generatePolyline(map, startPoint35, endPoint35, png);
    //this.generatePolyline(map, endPoint35, startPoint35, png);
  }, 900);

  setTimeout(() => {
    let startPoint36 = { lat: 25.4383, lng: -100.9737 };  // Saltillo
    let endPoint36 = { lat: 25.6866, lng: -100.3161 };    // Monterrey
    this.generatePolyline(map, startPoint36, endPoint36, png);
  //  this.generatePolyline(map, endPoint36, startPoint36, png);
  }, 900);

  setTimeout(() => {
    let startPoint37 = { lat: 17.0654, lng: -96.7236 };  // Oaxaca
    let endPoint37 = { lat: 16.7569, lng: -93.1292 };    // Tuxtla Gutiérrez
    this.generatePolyline(map, startPoint37, endPoint37, png);
   // this.generatePolyline(map, endPoint37, startPoint37, png);
  }, 900);

  setTimeout(() => {
    let startPoint38 = { lat: 30.6940, lng: -112.1643 };  // Caborca
    let endPoint38 = { lat: 31.3113, lng: -110.9422 };    // Nogales
    this.generatePolyline(map, startPoint38, endPoint38, png);
   // this.generatePolyline(map, endPoint38, startPoint38, png);
  }, 900);

  setTimeout(() => {
    let startPoint39 = { lat: 32.5027, lng: -117.0037 };  // Tijuana
    let endPoint39 = { lat: 31.8627, lng: -116.6067 };    // Ensenada
    this.generatePolyline(map, startPoint39, endPoint39, png);
   // this.generatePolyline(map, endPoint39, startPoint39, png);
  }, 900);

  setTimeout(() => {
    let startPoint40 = { lat: 22.3961, lng: -97.9310 };  // Tampico
    let endPoint40 = { lat: 23.6345, lng: -102.5528 };    // Zacatecas
    this.generatePolyline(map, startPoint40, endPoint40, png);
  //  this.generatePolyline(map, endPoint40, startPoint40, png);
  }, 900);

  setTimeout(() => {
    let png = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/SUV+Background+Removed.png';
  
    let startPoint41 = { lat: 24.0277, lng: -104.6532 }; // Durango
    let endPoint41 = { lat: 27.4770, lng: -99.5496 };   // Nuevo Laredo
    this.generatePolyline(map, startPoint41, endPoint41, png);
  //  this.generatePolyline(map, endPoint41, startPoint41, png);
  
    setTimeout(() => {
      let startPoint42 = { lat: 26.0806, lng: -98.2899 }; // Reynosa
      let endPoint42 = { lat: 25.4695, lng: -97.5253 };   // Matamoros
      this.generatePolyline(map, startPoint42, endPoint42, png);
    //  this.generatePolyline(map, endPoint42, startPoint42, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint43 = { lat: 22.8905, lng: -109.9167 }; // Cabo San Lucas
      let endPoint43 = { lat: 23.6345, lng: -102.5528 };   // Zacatecas
      this.generatePolyline(map, startPoint43, endPoint43, png);
     // this.generatePolyline(map, endPoint43, startPoint43, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint44 = { lat: 29.0959, lng: -110.9613 }; // Hermosillo
      let endPoint44 = { lat: 28.6320, lng: -106.0691 };   // Chihuahua
      this.generatePolyline(map, startPoint44, endPoint44, png);
    //  this.generatePolyline(map, endPoint44, startPoint44, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint45 = { lat: 20.9984, lng: -89.6176 }; // Mérida
      let endPoint45 = { lat: 21.1743, lng: -86.8466 };   // Cancún
      this.generatePolyline(map, startPoint45, endPoint45, png);
  //    this.generatePolyline(map, endPoint45, startPoint45, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint46 = { lat: 17.0654, lng: -96.7236 };  // Oaxaca
      let endPoint46 = { lat: 18.1345, lng: -94.4588 };    // Coatzacoalcos
      this.generatePolyline(map, startPoint46, endPoint46, png);
    //  this.generatePolyline(map, endPoint46, startPoint46, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint47 = { lat: 19.1817, lng: -88.4791 };  // Chetumal
      let endPoint47 = { lat: 17.2534, lng: -92.2269 };    // Tuxtla Gutiérrez
      this.generatePolyline(map, startPoint47, endPoint47, png);
 //     this.generatePolyline(map, endPoint47, startPoint47, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint48 = { lat: 21.0186, lng: -89.5773 };  // Progreso
      let endPoint48 = { lat: 20.1011, lng: -98.7592 };    // Pachuca
      this.generatePolyline(map, startPoint48, endPoint48, png);
   //   this.generatePolyline(map, endPoint48, startPoint48, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint49 = { lat: 18.9242, lng: -99.2216 };  // Cuernavaca
      let endPoint49 = { lat: 18.9248, lng: -99.2305 };    // Cuautla
      this.generatePolyline(map, startPoint49, endPoint49, png);
     // this.generatePolyline(map, endPoint49, startPoint49, png);
    }, 900);
  
    setTimeout(() => {
      let startPoint50 = { lat: 20.5888, lng: -100.3899 };  // Querétaro
      let endPoint50 = { lat: 20.6869, lng: -103.3500 };    // Guadalajara
      this.generatePolyline(map, startPoint50, endPoint50, png);
    //  this.generatePolyline(map, endPoint50, startPoint50, png);
    }, 900);

    setTimeout(() => {
      let png = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/truck_remolque+Background+Removed.png';
    
      let startPoint51 = { lat: 19.4326, lng: -99.1332 };  // Mexico City
      let endPoint51 = { lat: 20.6766, lng: -103.3475 };   // Guadalajara
      this.generatePolyline(map, startPoint51, endPoint51, png);
     // this.generatePolyline(map, endPoint51, startPoint51, png);
    
      setTimeout(() => {
        let startPoint52 = { lat: 25.8572, lng: -97.5069 }; // Matamoros
        let endPoint52 = { lat: 25.4383, lng: -100.9737 };  // Saltillo
        this.generatePolyline(map, startPoint52, endPoint52, png);
    //    this.generatePolyline(map, endPoint52, startPoint52, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint53 = { lat: 29.1026, lng: -110.9773 }; // Hermosillo
        let endPoint53 = { lat: 31.7383, lng: -106.4875 };   // Ciudad Juárez
        this.generatePolyline(map, startPoint53, endPoint53, png);
       // this.generatePolyline(map, endPoint53, startPoint53, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint54 = { lat: 24.8053, lng: -107.3935 }; // Culiacán
        let endPoint54 = { lat: 28.6395, lng: -106.0785 };   // Chihuahua
        this.generatePolyline(map, startPoint54, endPoint54, png);
      //  this.generatePolyline(map, endPoint54, startPoint54, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint55 = { lat: 22.1566, lng: -100.9855 }; // San Luis Potosí
        let endPoint55 = { lat: 21.8853, lng: -102.2916 };   // Aguascalientes
        this.generatePolyline(map, startPoint55, endPoint55, png);
      //  this.generatePolyline(map, endPoint55, startPoint55, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint56 = { lat: 20.9700, lng: -89.6209 };  // Mérida
        let endPoint56 = { lat: 18.6510, lng: -91.8075 };    // Campeche
        this.generatePolyline(map, startPoint56, endPoint56, png);
      //  this.generatePolyline(map, endPoint56, startPoint56, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint57 = { lat: 18.9261, lng: -96.9256 };  // Córdoba
        let endPoint57 = { lat: 19.1809, lng: -96.1429 };    // Veracruz
        this.generatePolyline(map, startPoint57, endPoint57, png);
       // this.generatePolyline(map, endPoint57, startPoint57, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint58 = { lat: 25.5695, lng: -103.4287 }; // Torreón
        let endPoint58 = { lat: 25.4383, lng: -100.9737 };   // Saltillo
        this.generatePolyline(map, startPoint58, endPoint58, png);
     //   this.generatePolyline(map, endPoint58, startPoint58, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint59 = { lat: 26.9098, lng: -101.4227 }; // Monclova
        let endPoint59 = { lat: 27.5036, lng: -99.5075 };    // Laredo
        this.generatePolyline(map, startPoint59, endPoint59, png);
     //   this.generatePolyline(map, endPoint59, startPoint59, png);
      }, 900);
    
      setTimeout(() => {
        let startPoint60 = { lat: 16.7569, lng: -93.1292 };  // Tuxtla Gutiérrez
        let endPoint60 = { lat: 17.0654, lng: -96.7236 };    // Oaxaca
        this.generatePolyline(map, startPoint60, endPoint60, png);
     //   this.generatePolyline(map, endPoint60, startPoint60, png);
      }, 900);
    
    }, 900);    
  
  }, 900);

}, 900);
setTimeout(() => {// another displayed in San Raymundo baja california it should be displayed in the pacific ocean,
  const seaCoordinates = [
    { lat: 20.4, lng: -86.5 },     // Caribbean Sea, off Cancún
    { lat: 18.5, lng: -92 },       // Gulf of Mexico, further off Campeche
    { lat: 18.9, lng: -93 },     // Gulf of Mexico, further near the border with Guatemala
    { lat: 20.8, lng: -107.5 },    // Pacific Ocean, off Puerto Vallarta
    { lat: 15.5, lng: -97.8 },     // Pacific Ocean, off Puerto Escondido
    { lat: 18.8, lng: -106.5 },    // Pacific Ocean, off Manzanillo
    { lat: 22.7, lng: -110 },      // Pacific Ocean, off Cabo San Lucas
    { lat: 30.6, lng: -117.5 },    // Pacific Ocean, off Ensenada
    { lat: 24.6, lng: -90 },       // Gulf of Mexico, further off Tampico
    { lat: 24.8, lng: -81 },       // Gulf of Mexico, further off Ciudad del Carmen
    { lat: 14.2, lng: -96.5 },     // Pacific Ocean, off Oaxaca
    { lat: 20.5, lng: -108 },      // Pacific Ocean, off Punta Mita
    { lat: 22.2, lng: -110.7 },      // Gulf of California further near La Paz
    { lat: 31.2, lng: -118.2 },      // Pacific Ocean, further off Baja California
    { lat: 19.5, lng: -93 },     // Gulf of Mexico further near Coatzacoalcos
    { lat: 25.7, lng: -114.5 },    // Pacific Ocean, further near Loreto
    { lat: 18.5, lng: -92 },       // Gulf of Mexico further near Sabancuy
    { lat: 20.0, lng: -86.5 },     // Caribbean Sea, further off Tulum
    { lat: 25.2, lng: -111 },      // Gulf of California, off Los Mochis
    { lat: 16.4, lng: -101 },      // Pacific Ocean, off Acapulco
    { lat: 19.0, lng: -95.5 },     // Gulf of Mexico, off Veracruz
    { lat: 23.9, lng: -110 },      // Gulf of California, further near La Ventana
    { lat: 27.6, lng: -116.5 },    // Pacific Ocean, off Guerrero Negro
    { lat: 25.0, lng: -97 },       // Gulf of Mexico, near Matamoros
    { lat: 29.0, lng: -115.5 },    // Gulf of California, near Bahía de los Ángeles
    { lat: 32.2, lng: -120 },      // Pacific Ocean, off Tijuana
    { lat: 21.2, lng: -109 },      // Pacific Ocean, off San Blas
    { lat: 26.6, lng: -113.5 },    // Gulf of California, near Bahía Concepción
    { lat: 24.2, lng: -81.5 },     // Off Key West, Florida (Close to Mexican waters but this one is adjusted to be closer to Florida)
    { lat: 18.3, lng: -88 }        // Caribbean Sea, further off Chetumal
];




let delay = 0;
seaCoordinates.forEach(coord => {
    setTimeout(() => {
        let png = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/lancha+Background+Removed.png';
        this.generateBoatMarker(map, coord, png);
    }, delay);
    delay += 900;
});
const globalSeaCoordinates = [
  { lat: 37.3, lng: -126 },      // Pacific Ocean, off San Francisco
  { lat: -34, lng: 154 },        // Pacific Ocean, off Sydney
  { lat: 35, lng: 142 },         // Pacific Ocean, off Tokyo Bay
  { lat: 0.5, lng: 104 },        // South of Singapore
  { lat: -24, lng: -40 },        // Atlantic Ocean, off São Paulo
  { lat: 58.8, lng: 18 },        // Baltic Sea, near Stockholm
  { lat: 47.2, lng: -4.5 },      // Atlantic Ocean, off France
  { lat: 31, lng: 28 },          // Mediterranean, off Cairo
  { lat: -33, lng: 16 },         // Atlantic Ocean, off South Africa
  { lat: 50.5, lng: -5 },        // Atlantic Ocean, off UK
  { lat: 12.5, lng: 100 },       // Gulf of Thailand, off Bangkok
  { lat: 53.5, lng: 3.5 },       // North Sea, off Netherlands
  { lat: -38, lng: -57 },        // Atlantic Ocean, off Buenos Aires
  { lat: 39.7, lng: -73 },       // Atlantic Ocean, off New York
  { lat: 38, lng: 120 },         // Yellow Sea, off Beijing
  { lat: -7.5, lng: 105.5 },     // Indian Ocean, off Jakarta
  { lat: 68.5, lng: 40 },        // Barents Sea, off Murmansk (moved from Moscow)
  { lat: 19, lng: -106 },        // Pacific Ocean, off Mexico
  { lat: 40, lng: 9 },           // Tyrrhenian Sea, off Rome
  { lat: 43.7, lng: -78 },       // Lake Ontario, near Toronto
  { lat: -35, lng: 16 },         // Atlantic Ocean, off Cape Town (corrected)
  { lat: 33, lng: -123 },        // Pacific Ocean, off Los Angeles
  { lat: 30.5, lng: 124 },       // East China Sea, off Shanghai
  { lat: 24, lng: 65 },          // Arabian Sea, off Pakistan
  { lat: 55, lng: 8 },           // North Sea, off the coast of Denmark (moved from Munich)
  { lat: -14, lng: 132 },        // Timor Sea, off Darwin
  { lat: 20, lng: -162 },        // Pacific Ocean, off Honolulu
  { lat: 57.8, lng: 20 },        // Baltic Sea, off Riga
  { lat: 37, lng: -15 },         // Atlantic Ocean, off Lisbon
  { lat: -43.5, lng: 172 }       // Pacific Ocean, south of New Zealand
];




globalSeaCoordinates.forEach(coord => {
  setTimeout(() => {
      let png = 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/automobiles/yacht+Background+Removed.png';
      this.generateBoatMarker(map, coord, png);
  }, delay);
  delay += 900;
});

}, 900);
},900);
      // ... (rest of your code for Autocomplete setup)
    } catch (error) {
      console.error('Error initializing Google Map:', error);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // Navigate back to the current URL
        this.router.navigate(['/loader']);
        });
      setTimeout(() => {
      // Reload the current page to reinitialize the app
      window.location.reload();
      },300);
      // Handle the error gracefully, e.g., display an error message to the user.
    }
    }
// Define the function to add a custom marker to the map with a random rotation
public addCustomMarker(map, latitude, longitude, imageUrl) {
 // Verify that latitude and longitude are numbers
 if (typeof latitude !== 'number' || typeof longitude !== 'number') {
  console.error('Invalid coordinates provided for marker.');
  return;
}

// Create a marker at the specified coordinates
const marker = new google.maps.Marker({
  position: { lat: latitude, lng: longitude },
  map: map, // Specify the map where you want to add the marker
});

// Customize the marker icon with an image
if (imageUrl) {
  marker.setIcon({
    url: imageUrl, // URL of the image you want to use as the marker icon
    scaledSize: new google.maps.Size(48, 48), // Adjust the size as needed
  });
} else {
  console.warn('No image URL provided for the marker.');
}

 // Function to update marker's position smoothly along the latitude axis
 const updateMarkerPosition = () => {
  // Calculate new latitude coordinate
  const newLatitude = marker.getPosition().lat() + (marker.getPosition().lat() *0.00009) ; // Adjust the step size as needed
 // Calculate new latitude coordinate
 const newLongitud = marker.getPosition().lng() + (marker.getPosition().lng()* 0.00009); // Adjust the step size as needed
  // Set the new position
  marker.setPosition({ lat: newLatitude, lng: newLongitud });

  // Repeat the update after a delay
  setTimeout(updateMarkerPosition, 100); // Update every 100 milliseconds (adjust the interval)
};

// Start the smooth movement animation
updateMarkerPosition();
}



    private handlePlaceChanged(
      autocomplete: google.maps.places.Autocomplete,
      infowindow: google.maps.InfoWindow,
      marker: google.maps.Marker,
      inputValue: string
    ) {
      infowindow.close();
      marker.setVisible(false);
    
      const place = autocomplete.getPlace();
    
      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
    
      // Explicitly cast map to google.maps.Map to access fitBounds
      const map = marker.getMap() as google.maps.Map;
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
    
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
    
      infowindow.setContent(`<div><strong>${inputValue}</strong></div><div>${place.formatted_address}</div>`);
      infowindow.open(map, marker);
    
      // Calculate and display the route
      const startPointInput = document.getElementById('pac-input') as HTMLInputElement;
      const destinationInput = document.getElementById('pac-input-destination') as HTMLInputElement;
      this.calculateAndDisplayRoute(startPointInput.value, destinationInput.value);
    }
    
  
    private calculateAndDisplayRoute(startPoint: string, destination: string) {
      this.startPoint = startPoint;
      this.destination = destination;



      console.log(startPoint, destination);
    
      this.directionsService.route(
        {
          origin: startPoint,
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            this.showOrdenarButton = true;
            this.directionsRenderer.setDirections(response);
          // Define the size of the custom markers
          const markerSize = new google.maps.Size(48, 48); // Adjust the size as needed
            // Add a marker at the start point
            const startMarker = new google.maps.Marker({
              map: this.directionsRenderer.getMap(),
              position: response.routes[0].legs[0].start_location,
              icon: {
                url: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/inicio_punto_de_partida.png', // URL to your marker image
                scaledSize: markerSize,
              },    
            });
            const endMarker = new google.maps.Marker({
              map: this.directionsRenderer.getMap(),
              position: response.routes[0].legs[0].end_location,
              icon: {
                  url: 'https://laniakea-multimedia.s3.amazonaws.com/imagenes/meta_destion_icono.png', // URL to your marker image
                  scaledSize: markerSize,
              },    
          });
              // Create geocoder instance
    const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: response.routes[0].legs[0].end_address }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results[0].geometry) {

              const destination = results[0].geometry.location;
              console.log('destination',destination);
              this.destination_lat = destination.lat();
        this.destination_lng = destination.lng();
        console.log('destination',this.destination_lat);
        console.log('destination',this.destination_lng);
 // Set the values of destination_lat and destination_lng

            } else {
              console.error('Geocoding for destination failed:', status);
            }
            });
          
            geocoder.geocode({ address: response.routes[0].legs[0].start_address }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK && results[0].geometry) {
  
                const destination = results[0].geometry.location;
                console.log('destination',destination);
                this.startPoint_lat = destination.lat();
          this.startPoint_lng = destination.lng();
          console.log('startPoint_lat',this.startPoint_lat);
          console.log('startPoint_lng',this.startPoint_lng);
   // Set the values of destination_lat and destination_lng
  
              } else {
                console.error('Geocoding for destination failed:', status);
              }
              });
            // You can also add an InfoWindow to the marker if needed
            const startInfowindow = new google.maps.InfoWindow({
              content: 'Start Point',
            });
    
            startMarker.addListener('click', () => {
              startInfowindow.open(this.directionsRenderer.getMap(), startMarker);
            });
          }
        }
      );
    }
    
  
  
  getdatamaps(){
    this.modalcontroller.create({
      component:GooglemapsComponent,
      cssClass: 'detailsInModal',
			swipeToClose: true,
    })
    .then((modal => {modal.present();} ))

    
  }
  generatePolyline(map, startPoint, endPoint, png) {
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({ map: map });
  
    const startLatLng = new google.maps.LatLng(startPoint.lat, startPoint.lng);
    const endLatLng = new google.maps.LatLng(endPoint.lat, endPoint.lng);
  
    const request = {
      origin: startLatLng,
      destination: endLatLng,
      travelMode: google.maps.TravelMode.DRIVING,
    };
  
    directionsService.route(request, (response, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
      // Define the size of the custom markers
      const markerSize = new google.maps.Size(48, 48); // Adjust the size as needed
        // Add a marker at the start point
        const startMarker = new google.maps.Marker({
          position: startLatLng,
          map: map,
          icon: {
            url: png, // URL to your marker image
            scaledSize: markerSize,
          },
        
        });
  
        // Animate the marker along the polyline
        const path = response.routes[0].overview_path;
        let step = 0;
        const numSteps = path.length;
  
        const interval = setInterval(() => {
          if (step >= numSteps) {
            step = 0;
          } else {
            const nextPosition = path[step];
            startMarker.setPosition(nextPosition);
            step++;
          }
        }, 69000); // Adjust the interval for desired marker speed
      } else {
        console.error('Directions request failed:', status);
      }
    });
  }

   generateBoatMarker(map, position, png) {
    const markerSize = new google.maps.Size(48, 48); // Adjust the size as needed
    
    const boatMarker = new google.maps.Marker({
        position: new google.maps.LatLng(position.lat, position.lng),
        map: map,
        icon: {
            url: png, // URL to your boat image
            scaledSize: markerSize,
        },
    });
}

  
  
  fetch_freight_rates(){
    this.envio='Uber Eats';
    let getRates;
    let getUberRates;
    if(this.envio === 'Amazon Shipping'){
      getRates=true;
      getUberRates=false;
     }else if(this.envio == 'Uber Eats'){
      getRates=false;
      getUberRates=true;
     }
    this.request.createRequestPost('handleShipping', {getRates:getRates,getUberRates:getUberRates, parametric:true })
    .then((data) => {
      console.log('data',data);
      if(this.envio === 'Amazon Shipping'){
      const rate = data.payload.rates[0]; // Access the first rate object (index 0)
       this.delivery_payload=rate;
      this.delivery_window_start = rate.promise.deliveryWindow.start;  
      this.delivery_window_end = rate.promise.deliveryWindow.end;
      this.total_charge_value = rate.totalCharge.value;  
this.total_charge_unit = rate.totalCharge.unit;

this.pick_up_window_start = this.delivery_payload?.promise?.pickupWindow?.start ;
this.pick_up_window_end =this.delivery_payload?.promise?.pickupWindow?.end;
      }else if(this.envio === 'Uber Eats'){
        
       const rate = data; // Access the first rate object (index 0)
       this.delivery_payload=rate;
      this.delivery_window_start = rate.dropoff_eta;  
      this.delivery_window_end = rate.dropoff_deadline;
      this.pick_up_window_start = rate.pickup_duration;
      this.pick_up_window_end =rate.duration;
      this.total_charge_value = rate.fee/100;  
this.total_charge_unit = rate.currency_type;
      }
   
    });
  }


}


// This example requires the Places library. Include the libraries=places
// parameter when you first load the API. For example:
// <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">

