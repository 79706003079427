import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import plantillas from "src/assets/plantillas/data-earth.json";
import { RequestService } from 'src/app/services/request/request.service';
import {LoadingController} from "@ionic/angular";
import Swal from 'sweetalert2';
@Component({
	selector: 'app-earht',
	templateUrl: './earht.component.html',
	styleUrls: ['./earht.component.scss'],
})
export class EarhtComponent implements OnInit {
	displayContainer:boolean=true;
	public subCategoria:boolean= false;
	public loading: any;
	categoriasHistory: Array<any> = [];
	selectedCategoria: string;
	public lugar: any = plantillas;
	public arraylugars: any = [];
	public arraytipos: any = [];
	public limpiar: number = 0;
	public objetoverlo: any = [];

	public arraylimpiar: any = [];

	DataPublications = [
		{
		  Name: 'Normal',
		  selected: true
		},
		{
		  Name: 'Educación Epoch',
		  Categorias: [
	
			{
			  categoria: 'Departamento',
			  selected: false,
			  id: 1,
			  creator_id: 0,
			},
			{
			  categoria: 'Casa',
			  selected: false,
			  id: 6,
			  creator_id: 0
	
			},
			{
			  categoria: 'Hotel',
			  selected: false,
			  id: 26,
			  creator_id: 0
			},
			/*
			{
			  categoria: 'Videoblogs',
			  selected: false,
			  id: 3125,
			  creator_id: 0,
			},
			{
			  categoria: 'Documentales',
			  selected: false,
			  id: 2448,
			  creator_id: 0,
			},*/
		  ],
		  selected: true
		},
		{
		  Name: '---------',
		  selected: false
		},
		{
		  Name: '---------',
		  selected: false
		},
		{
		  Name: '---------',
		  selected: false
		},
		{
		  Name: '---------',
		  selected: false
		},
	  ];
	  selectCheck(check) {
		console.log(check);
	  
	  
	  }
	constructor(public formservice: FormServiceService,
		public navCtrl: NavController,
		public alertController: AlertController, private requestService: RequestService,
		private loadingController:LoadingController,
	) { }

	ngOnInit() {
		for (var cdlugar in this.lugar) {
			let objetolugar = { id: cdlugar, nombre: this.lugar[cdlugar].lugar };
			this.arraylugars.push(objetolugar);
		}
	}

	selectver(event) {
		this.limpiar = 0;
		this.arraytipos = [];
		for (var cdtipo in this.lugar[event.target.value].tipos) {
			let objetotipo = { id: cdtipo, nombre: this.lugar[event.target.value].tipos[cdtipo].tipo };
			this.arraytipos.push(objetotipo);
			
			this.formservice.earthpublication.lugar = this.lugar[event.target.value].lugar
			console.log(this.formservice.earthpublication.lugar)
		}
	}
	selectipo(event) {
		this.formservice.earthpublication.tipo = event.target.value
	}

	Nprueba(){

		prompt("Esto es una prueba")
	}
	updateSelectedCategoria() {
		if(this.subCategoria === false){
			this.subCategoria = true;
		const categorias = this.DataPublications[1].Categorias;
		const selectedCategoria = categorias.find(categoria => categoria.selected);
		this.selectedCategoria = selectedCategoria ? selectedCategoria.categoria : '';
		this.formservice.earthpublication.lugar = this.selectedCategoria;
		}else{
			const categorias = this.DataPublications[1].Categorias;
			const selectedCategoria = categorias.find(categoria => categoria.selected);
			this.selectedCategoria = selectedCategoria ? selectedCategoria.categoria : '';
			this.formservice.earthpublication.tipo = this.selectedCategoria;
			Swal.fire({
				title: 'Categorización exitosa',
		
				icon: 'success',
				confirmButtonText: 'OK',
				color: '#ffffff',
				background: '#010f4e',
			  });
		}
	  }
	
	  async showLoading() {
		this.loading = await this.loadingController.create({
		  message: '⚡️⚡️⚡️',
		  spinner: 'circles'
	
		});
		this.loading.present();
	  }
	  async onClick(checklist) {
		  // Update the selected attribute of the clicked checklist
	  checklist.selected = !checklist.selected;
	  // Log the updated checklist
	  console.log(checklist);
		this.updateSelectedCategoria();
		console.log('onClick() function called'); // Debugging message
	  
		// Check if there are no categories selected
		const hasNoSelectedCategories = this.DataPublications.every(publication => {
		  if (publication.Categorias) {
			return publication.Categorias.every(category => !category.selected);
		  }
		  return true;
		});
	  
		if (hasNoSelectedCategories) {
		  Swal.fire('Error', 'Porfavor selecciona al menos una categoría.', 'error');
		  return;
		}
	  
		await this.showLoading();
	  
		const getCategorias = async () => {
		  console.log('getCategorias() function called'); // Debugging message
	  
		  // Step 1: Get the current state of the 'Categorias' array
		  const categorias = this.DataPublications[1].Categorias;
	  
		  // Step 2: Filter the 'Categorias' array to retrieve only the selected objects
		  const selectedCategorias = categorias.filter(categoria => categoria.selected);
	  
		  console.log('selectedCategorias:', selectedCategorias); // Debugging message
	  
		  // Step 3: Extract the 'id' values from the filtered objects
		  const selectedIds = selectedCategorias.map(cat => cat.id);
	
		  const endpoint = 'categoriesEarth';
		  const queryParams = { id: selectedIds.join(',') }; // Send the 'id's as a comma-separated string
		  try {
			const data = await this.requestService.createRequestGet(endpoint, queryParams);
			console.log('data:', data); // Debugging message
	  
			// Add JSON parsing and logging
			console.log("Raw JSON data:", data);
			let parsedData;
			try {
			  parsedData = JSON.parse(data);
			  console.log("Parsed JSON data:", parsedData);
			} catch (error) {
			  console.error("Error parsing JSON data:", error);
			}  
	//step 4: map th data
			const newPublication = {
			  Categorias: parsedData.map(categoria => ({
				...categoria,
				selected: false,
			  })),
			};
	  
			// Step 5: Extract the 'categoria', 'id', and 'creator_id' values from the returned objects
			if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'object' && 'categoria' in item && 'id' in item && 'creator_id' in item)) {
			  const newCategorias = parsedData.map(categoria => ({
				categoria: categoria.categoria,
				id: categoria.id,
				creator_id: categoria.creator_id,
				selected: false,
			  }));
			
			  console.log('newCategorias:', newCategorias); // Debugging message
			  if(newCategorias.length ===0){
				this.displayContainer=false;
			  }
			  // Replace the 'Categorias' array with the new one
			  const categoriasCopy = JSON.parse(JSON.stringify(this.DataPublications[1].Categorias));
	  categoriasCopy.forEach(categoria => categoria.selected = false);
	  this.categoriasHistory.push(categoriasCopy);
			  this.DataPublications[1].Categorias = newCategorias;
			   // Select or unselect checkboxes based on "categorias" data
			 
			} else {
			  console.error('Unexpected data format:', data);
			}
	  
			// Hide the loading spinner when the operation is complete
			this.loading.dismiss();
		  } catch (error) {
			console.error('Error fetching categories:', {
			  message: error.message,
			  name: error.name,
			  stack: error.stack,
			  errorObject: error,
			});
		  }
		};
	  
		await getCategorias(); // Invoke the function
	  }
	  onClickBack() {
		if (this.categoriasHistory.length > 0) {
		  this.DataPublications[1].Categorias = this.categoriasHistory.pop();
		} else {
		  Swal.fire({
			icon: 'warning',
			title: 'No hay historial',
			text: 'No se puede retroceder más.',
			background: '#010f4e',
			position: 'top-end',
			timerProgressBar: true,
			toast: true,
		  });
		}
	  }
}
