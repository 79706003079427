import { R3TargetBinder } from '@angular/compiler';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
} from '@ionic/angular';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import Swal2 from 'sweetalert2';

@Component({
  selector: 'app-principal-epoch',
  templateUrl: './principal-epoch.component.html',
  styleUrls: ['./principal-epoch.component.scss'],
})
export class PrincipalEpochComponent implements OnInit {
  public step: number = 1;
  public buttonSee: string = 'ver';

  constructor(
    public formservice: FormServiceService,
    public modalController: ModalController,
    public navCtrl: NavController,
    public alertController: AlertController
  ) {
    this.formservice.epochpublication.title = this.formservice.title;
    this.formservice.epochpublication.description =
      this.formservice.description;
    this.formservice.epochpublication.publication_type =
      this.formservice.publication_type;
    this.formservice.epochpublication.quienpuedever =
      this.formservice.quienpuedever;
    this.formservice.epochpublication.is_feed = this.formservice.is_feed;
    this.formservice.epochpublication.multimedia = this.formservice.multimedia;
  }

  ngOnInit() {}

  //funcion para el boton siguiente
  next() {
   
    console.log(this.formservice.epochpublication);

    switch (this.step < 4) {
      case this.step === 1: {
        if (this.formservice.epochpublication.category) {
          this.step++;
        } else {
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Te falta seleccionar una categoria',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
          });
        }
        //statements;
        break;
      }
      case this.step === 2: {
        if (this.formservice.premiumMedia ===true
         // this.formservice.epochpublication.titleLeccion &&
         // this.formservice.epochpublication.descriptionLeccion
        ) {
          this.step++;
        } else {
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Asegurate de llenar todos los campos y cargar tu multimedia',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
          });
        }
        //statements;
        break;
      }
      case this.step === 3: {
        if (this.formservice.epochpublication.price) {
          this.step++;
        } else {
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Asegurate de poner un precio',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });
        }
        //statements;
        break;
      }
      default: {
        this.buttonSee = 'oculto';
        break;
      }
       
    }
    console.log("nuevo actual: " + this.step)

    // if (this.step < 4) {
    //   this.step++;
    // }
    // if(this.step===4){
    //   this.buttonSee="oculto"
    // }
  
  }

  //cerrar el modal
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  //abrir un mensaje de salir de la publicacion
  async back() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Salir de la publicación',
      message: 'Si desea salir se eliminará todo su avance',
      buttons: [
        {
          text: 'Salir',
          handler: () => {
            this.formservice.title = '';
            this.formservice.description = '';
            this.formservice.is_feed = false;
            this.formservice.quienpuedever = 'Para todos';
            this.formservice.epochpublication.category = undefined;
            this.formservice.epochpublication.titleLeccion = undefined;
            this.formservice.epochpublication.descriptionLeccion = undefined;
            this.formservice.epochpublication.price = undefined;
            this.dismiss();
          },
        },
        {
          text: 'Continuar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
      ],
    });

    await alert.present();
  }
}
