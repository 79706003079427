
import { Platform , ModalController, NavController} from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
//import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';

import { ForegroundService } from '@ionic-native/foreground-service/ngx';
import { Component, NgZone, ApplicationRef, HostListener,ViewChild } from '@angular/core';
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';
import { LkfilmViewBySlidesComponent } from 'src/app/components/lkfilm-view-by-slides/lkfilm-view-by-slides.component';
//import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';


/*
  import { ToastController } from '@ionic/angular';
import { NotificationService } from './services/notifications/notification.service';
import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	Token,
  } from '@capacitor/push-notifications';
   const { LocalNotifications } = Plugins;
     import { Plugins } from '@capacitor/core';
	   import { FirebaseApp } from '@angular/fire';
  import * as firebase from 'firebase';
    import { WelcomeMessageComponent } from 'src/app/components/general-design/welcome-message/welcome-message.component';
  import * as models from 'src/app/Models/Classes/graphql/models/models-graphql';
  import { CreateUserInput } from 'src/app/Models/Classes/graphql/models/models-graphql';
  import { Console } from 'console';
    import { Utils } from 'src/app/Models/Classes/utils';
	  import { Router } from '@angular/router';
	  import { GraphQLAPIService } from 'src/app/services/request/GraphQl-Api.service';
	    import { Title } from '@angular/platform-browser';
  import { Meta } from '@angular/platform-browser';*/



 
  import { InAppPurchase2,IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
  import  Swal from 'sweetalert2';
  import { SplashScreen } from '@capacitor/splash-screen';
  import { FormServiceService } from 'src/app/services/publications/form-service.service';

  import { Capacitor } from '@capacitor/core';

  import { ProfileService } from 'src/app/services/profile/profile.service';


  import { Location } from '@angular/common';

  import { Howl } from 'howler';

  import { Device } from '@capacitor/device';
  import { Network } from '@capacitor/network';
  import { HttpClient } from '@angular/common/http';
  import { RequestService } from 'src/app/services/request/request.service';
  import { Browser } from '@capacitor/browser';
  import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
  import {PrivacyScreen}from '@capacitor-community/privacy-screen';
import{ContentPreferencesComponent}from'src/app/content-preferences/content-preferences.component';
import {  NavigationError, Event } from '@angular/router';
import { RouterOutlet, Router, ActivationStart } from '@angular/router'
var MediaRecorder;
import { NgxSmartBannerService, NgxSmartBannerPlatform } from '@netcreaties/ngx-smart-banner';
import { ViewContainerRef } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	@ViewChild(RouterOutlet) outlet: RouterOutlet;
	screenshotCombinationPressed:boolean=false;
	laniakea_tv_loaded:boolean=false;
	allowScrolling:boolean=false;
	pr:any
	public MEETING_ID_KEY: any;
	public publications: any[] = [];
	productId2: string = '1618';
	public SubscriptionExpired: boolean =true;
	constructor( 
		private platform: Platform,
		private statusBar: StatusBar,
		//private backgroundMode: BackgroundMode,
		private nativeAudio: NativeAudio,
		//private notificationsService: NotificationService,
		public foregroundService: ForegroundService,
		private actualizacionservice:ActualizacionServiceService,
		private modalctrl:ModalController,
		private zone: NgZone,
		//private toastController: ToastController,
		private store: InAppPurchase2,
		private formService:FormServiceService,
		//private router: Router,
		//private utils: Utils,
		private profileService: ProfileService,
		//private Api: GraphQLAPIService,
		private location: Location,
		private navCtrl: NavController,
		private http: HttpClient,
		//private metaService: Meta,
		//private titleService: Title,
		private request: RequestService,
		private sanitizer: DomSanitizer,
		private ngZone: NgZone,
		private router: Router,
		private readonly ngxSmartBannerService: NgxSmartBannerService,
		private readonly viewContainerRef: ViewContainerRef,
	) {
		this.initializeApp();


		document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationError) {
			  console.error('NavigationError Event:', event.error, event, event.url);
			  this.navCtrl.navigateRoot('/loader').then(() => {
				// Then reload the page
				window.location.reload();
			  });
			  this.router.events.subscribe(e => {
				if (e instanceof ActivationStart && e.snapshot.outlet === "administration")
				  this.outlet.deactivate();
			  });
			}
		  });

		  this.ngxSmartBannerService.initialize({
		
			priceSuffix:{
				ios:' App Store',

			},
			icon:{
			ios:'https://upload.wikimedia.org/wikipedia/commons/0/06/Www.Laniakea.tv_logo.png'
			},
			buttonUrl:{
				ios:'https://apps.apple.com/mx/app/laniakea/id1660407742?l=en-GB'
			},
			title: 'Laniakea',
			author: 'Laniakea Technologies',
			enabledPlatforms: [NgxSmartBannerPlatform.IOS],
			rating: {
			  ios: 5,
			  android: 5
			},
			viewContainerRef: this.viewContainerRef, // ✅ Pass the instance from the component
			daysHidden: 6,
			daysReminder: 21,
		  });
		
	}
	
	handleVisibilityChange() {
		if(this.formService.profile?.id!==12){
		if (document.hidden) {
			if(this.formService.profile?.id!==12){
			this.screenshotCombinationPressed = true;
			console.log(`Page is in background: `);
		}
			this.formService.changeMediaPeriodically=false;
			
		} else {
			console.log(`Page is in foreground: `);
		  // Optionally, handle the case when the app comes back to the foreground
		  this.screenshotCombinationPressed = false;
		}
	}
	  }
	@HostListener('document:keydown', ['$event'])
	handleKeyDownEvent(event: KeyboardEvent) {
		if(this.formService.profile?.id!==12){
	  // Check if the keys pressed are Command (Mac) or Ctrl (Windows/Linux) and Shift
	  if ((event.metaKey || event.ctrlKey) && event.shiftKey) {
	
		if(this.formService.profile?.id!==12){
		
		// If the combination of keys matches, prevent default behavior
		event.preventDefault();
		// Set the flag to indicate that the screenshot combination is pressed
		this.screenshotCombinationPressed = true;
		// Do something to handle the screenshot attempt
		console.log('Screenshot attempt detected!');
		Swal.fire({
			title: '¡Capturas de pantalla prohibida!',
			text: `Debido a que dentro de Laniakea hay contenido exclusivo, o de paga, no se permiten capturas o grabaciones de pantalla.`,
			icon: 'warning',
			//timer: 6900, // Show the alert for 3.9 seconds
			//timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showCancelButton: false,
			confirmButtonText: 'ok',
			cancelButtonText: 'No',
			color: '#ffffff',
			background: '#010f4e',
		  });
		}
	
	  }else if (event.key === 'VolumeUp'||event.key === 'VolumeDown') {
		if(this.formService.profile?.id!==12){
		// Handle volume up button press
		console.log('Volume up button pressed on iOS');
		this.screenshotCombinationPressed = true;
		// Do something to handle the screenshot attempt
		console.log('Screenshot attempt detected!');
		Swal.fire({
			title: '¡Capturas de pantalla prohibida!',
			text: `Debido a que dentro de Laniakea hay contenido exclusivo, o de paga, no se permiten capturas o grabaciones de pantalla.`,
			icon: 'warning',
			//timer: 6900, // Show the alert for 3.9 seconds
			//timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showCancelButton: false,
			confirmButtonText: 'ok',
			cancelButtonText: 'No',
			color: '#ffffff',
			background: '#010f4e',
		  });
		}
	  }
	}
	}
	initVolumeKeyDetection() {
		if(this.formService.profile?.id!==12){
		// Detect volume button presses
		window.addEventListener('keydown', this.detectVolumeKeys.bind(this));
		}
	  }
	
	  private detectVolumeKeys(event: KeyboardEvent) {
		if(this.formService.profile?.id!==12){
		this.ngZone.run(() => {
		  if (event.key === 'VolumeDown') {
			if(this.formService.profile?.id!==12){
			this.screenshotCombinationPressed = true;
			// Do something to handle the screenshot attempt
			console.log('Screenshot attempt detected!');
			Swal.fire({
				title: '¡Capturas de pantalla prohibida!',
				text: `Debido a que dentro de Laniakea hay contenido exclusivo, o de paga, no se permiten capturas o grabaciones de pantalla.`,
				icon: 'warning',
				//timer: 6900, // Show the alert for 3.9 seconds
				//timerProgressBar: true,
				toast: true,
				position: 'top-end',
				showCancelButton: false,
				confirmButtonText: 'ok',
				cancelButtonText: 'No',
				color: '#ffffff',
				background: '#010f4e',
			  });
			console.log('Volume Down button pressed');
			}
			// Add your logic here
		  } else if (event.key === 'VolumeUp') {
			if(this.formService.profile?.id!==12){
			this.screenshotCombinationPressed = true;
			// Do something to handle the screenshot attempt
			console.log('Screenshot attempt detected!');
			Swal.fire({
				title: '¡Capturas de pantalla prohibida!',
				text: `Debido a que dentro de Laniakea hay contenido exclusivo, o de paga, no se permiten capturas o grabaciones de pantalla.`,
				icon: 'warning',
				//timer: 6900, // Show the alert for 3.9 seconds
				//timerProgressBar: true,
				toast: true,
				position: 'top-end',
				showCancelButton: false,
				confirmButtonText: 'ok',
				cancelButtonText: 'No',
				color: '#ffffff',
				background: '#010f4e',
			  });
			console.log('Volume Down button pressed');
			// Add your logic here
		  }
		}
		});
	}
	  }
	@HostListener('document:keyup', ['$event'])
	handleKeyUpEvent(event: KeyboardEvent) {
		if(this.formService.profile?.id!==12){
	  // Check if the keys released are Command (Mac) or Ctrl (Windows/Linux) and Shift
	  if ((event.metaKey || event.ctrlKey) || event.shiftKey) {
		if(this.formService.profile?.id!==12){
		
	
		// Reset the flag to indicate that the screenshot combination is released
		this.screenshotCombinationPressed = false;
		}
		this.formService.changeMediaPeriodically=false;
		console.log('Screenshot disattempt detected!');
	  } if (event.key === 'VolumeUp'||event.key === 'VolumeDown') {
		if(this.formService.profile?.id!==12){
		// Handle volume down button press
		console.log('Volume  button pressed on mobile');
		this.screenshotCombinationPressed = false;
		}
	  }
	}
	}
	@HostListener('window:keydown', ['$event'])
	handleMobileKeyDownEvent(event: KeyboardEvent) {
		if(this.formService.profile?.id!==12){
	  // Check if the key pressed is the volume up button (iOS)
	  if (event.key === 'VolumeUp'||event.key === 'VolumeDown') {
		// Handle volume up button press
		console.log('Volume up/down button pressed');
		if(this.formService.profile?.id!==12){
		this.screenshotCombinationPressed = true;
		}
		// Do something to handle the screenshot attempt
		console.log('Screenshot attempt detected!');
		Swal.fire({
			title: '¡Capturas de pantalla prohibida!',
			text: `Debido a que dentro de Laniakea hay contenido exclusivo, o de paga, no se permiten capturas o grabaciones de pantalla.`,
			icon: 'warning',
			//timer: 6900, // Show the alert for 3.9 seconds
			//timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showCancelButton: false,
			confirmButtonText: 'ok',
			cancelButtonText: 'No',
			color: '#ffffff',
			background: '#010f4e',
		  });
	  }
	}
	}
	
	@HostListener('window:keyup', ['$event'])
	handleMobileKeyUpEvent(event: KeyboardEvent) {
		if(this.formService.profile?.id!==12){
	  // Check if the key pressed is the volume down button (Android)
	  if (event.key === 'VolumeUp'||event.key === 'VolumeDown') {
		// Handle volume down button press
		if(this.formService.profile?.id!==12){
		this.screenshotCombinationPressed = false;
		}
		console.log('Volume up/down button released on mobile');
	  }
	}
	}
	

	
	async getNetwork() {
		const getId = await Device.getId();
		  
			console.log('device',getId.identifier);
		this.formService.deviceid=getId.identifier;
	
			const getInfo = await Device.getInfo();
		  
			console.log('device',getInfo);
			this.formService.device=getInfo;
	
			const getLanguageCode = await Device.getLanguageCode();
		  
			console.log('language', getLanguageCode.value);
	this.formService.language=getLanguageCode.value;
		const info = await Network.getStatus();
		let net = info;
		console.log('network',net);
		this.formService.network=net;
		let networkListener = Network.addListener('networkStatusChange', (status) => {
			console.log('Network status changed:', status);
			this.formService.network=status;
			// Handle network status change here
		  });
		this.getClientIpAddress();
		this.send_internet_pro_data();
	  }
	  send_internet_pro_data(){
		 
		this.request.createRequestPost('GetAuthUser', {get_user:true })
  .then((final_data) => {
    console.log('final_data',final_data);
	if (final_data && typeof final_data === 'string') {
        try {
          final_data = JSON.parse(final_data);
          console.log('getRecordByToken ios:',final_data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
    this.request.createRequestPost('ProtoInternetData', {user_id:final_data?.user?.id, IPAddress: this.formService.IPAddress, deviceid:this.formService.deviceid, language: this.formService.language, device: this.formService.device, network:this.formService.network })
      .then((final_data) => {
		
        /*
         Swal2.fire({
          title: 'INTERNET verificado exitosamente.',
          text: El token ha sido validado exitosamente: ${this.celular}. ,
          icon: 'success',
          confirmButtonText: 'Aceptar',
          background: '#010f4e',
        });*/
      })
  })
  .catch((error) => {
    console.error('Error in GetAuthUser:', error);
    // Make ProtoInternetData request here even if there is an error in GetAuthUser
    this.request.createRequestPost('ProtoInternetData', {
      user_id: null, // Set user_id to null or a default value
      IPAddress: this.formService.IPAddress,
      deviceid:this.formService.deviceid,
      language: this.formService.language,
      device: this.formService.device,
      network:this.formService.network
    })
      .then((final_data) => {
      
      })
  });
	  }
	  async getClientIpAddress(): Promise<string> {
		try {
		  const ipAddress = await this.http.get('https://api.ipify.org/?format=json').toPromise();
		  //console.log('ipAddress',ipAddress['ip']);
		  this.formService.IPAddress=ipAddress['ip'];
		  return ipAddress['ip'];
		} catch (error) {
		  console.error('Error fetching IP address:', error);
		  return null;
		}
	  }
	  async open_content_preferences_modal(){
		const modal = await this.modalctrl.create({
			component: ContentPreferencesComponent,
			swipeToClose: true,
			componentProps: {
		
			  //valueSelect: 'MENU',
			  // Pass the publication ID or other necessary data to the component
			}
		  });
		  await modal.present();
	  }
	 
	initializeApp() {
		this.playAudio();

if (Capacitor.getPlatform() !== 'ios') {
	// Function to handle the screen recording started event
	const handleScreenRecordingStarted = () => {
		console.log('Screen recording started');
		this.screenshotCombinationPressed=true;
		// Add your logic here
	  };
	// Add the event listener when your component initializes or as needed
	const screenRecordingStartedListener = PrivacyScreen.addListener('screenRecordingStarted', handleScreenRecordingStarted);
	}
		// Show the splash for two seconds and then automatically hide it:
	
this.SplashScreen();


		//	this.titleService.setTitle('Comunicación');
			//const interval = setInterval(() => {
				this.initVolumeKeyDetection();
	//		}, 3000);
this.getNetwork();
		console.log('Initializing app t-9...');
		console.log('Initializing app t-8...');
		console.log('Initializing app t-7...');
		console.log('Initializing app t-6...');
		console.log('Initializing app t-5...');
		console.log('Initializing app t-4...');
		console.log('Initializing app t-3...');
		console.log('Initializing app t-2...');
		console.log('Initializing app t-1...');
		console.log('Initializing app t-0...');
		console.log('Initializing app t1...');

		

		this.profileService.getProfileInfo()
    .then((result) => {
        this.pr = JSON.parse(result);
        console.log("profile", this.pr);
        this.formService.profile = this.pr;

        if (Capacitor.getPlatform() !== 'web') {
            if (this.formService.profile?.id !== 12) {
                PrivacyScreen.enable()
                    .then(() => console.log('PrivacyScreen.enable'))
                    .catch((error) => console.error('Error enabling PrivacyScreen:', error));
            } else {
                PrivacyScreen.disable()
                    .then(() => console.log('PrivacyScreen.disable'))
                    .catch((error) => console.error('Error disabling PrivacyScreen:', error));
            }
        }

        if (!this.pr.content_preferences) { 
            setTimeout(() => {
                this.open_content_preferences_modal();
            }, 160000);
        }
    })
    .catch((error) => {
        console.error('Error fetching profile info:', error);
        
        // Execute PrivacyScreen logic in case of error
        if (Capacitor.getPlatform() !== 'web') {
            if (this.formService.profile?.id !== 12) {
                PrivacyScreen.enable()
                    .then(() => console.log('PrivacyScreen.enable'))
                    .catch((error) => console.error('Error enabling PrivacyScreen:', error));
            } else {
                PrivacyScreen.disable()
                    .then(() => console.log('PrivacyScreen.disable'))
                    .catch((error) => console.error('Error disabling PrivacyScreen:', error));
            }
        }
    });

		
		  if (Capacitor.getPlatform() !== 'web') { // this update is infered 
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				// Example url: https://laniakea.laniakea.tv/12
				// slug = /tabs/tab2
				const slug = event.url.split(".laniakea").pop();
				if (slug) {
					this.handleUniversalLink(slug);
					 // Prevent Angular routing for this URL
					 this.location.replaceState('/');
				}
				// If no match, do nothing - let regular routing
				// logic take over
			});
		});
	}

		this.foregroundService.start('Laniakea esta activo', 'Servicio Laniakea Activo en tu dispositivo', 'drawable/fsicon');
		let promises: Array<Promise<any>> = [];
		if (Capacitor.getPlatform() !== 'web') {
		this.subscribedToProduct();
		}
		this.platform.ready().then(() => {
		  console.log('Platform ready');
		  this.initializeBackGroundMode();
		  //promises.push(this.notificationsService.initialize());
		  if (Capacitor.getPlatform() !== 'web') {
		  promises.push(this.initializeSampleAudio());
		  }
		  Promise.all(promises).then(() => {
			console.log('Promises resolved');
	
		
		  }).catch((error) => {
			console.error('Promise error:', error);
		  });
	
		}).catch((error) => {
		  console.error('Platform error:', error);
		});
		

	  }
	  async SplashScreen(){
		  // Show the splash for two seconds and then automatically hide it:
await SplashScreen.show({
	showDuration: 900,
	autoHide: true,
  });



  
  

	  }
	 
	
	  handleUniversalLink(slug: string) {
		console.log('handleUniversalLink called');
		if (slug.includes('/loader?meeting')) {
			const meetingID = this.extractMeetingIDFromSlug(slug);
			console.log('Meeting ID:', meetingID);
			if (meetingID !== null) {
				// Handle the meeting URL, e.g., navigate to a meeting page
				// or perform any other action specific to meetings
				this.navigateToMeetingPage(meetingID);
			}
		} else if (slug.includes('/home/ecosystem-page')){
		const publicationId = this.extractPublicationIdFromSlug(slug);
		console.log('Publication ID:', publicationId);
		if (publicationId !== null) {
			this.getUniversalLinkPublications(publicationId);
		}
		}else if(slug.includes('/home/profile')){
			const profileId = this.extractProfileIdFromSlug(slug);
			console.log('profile ID:', profileId);
			if (profileId !== null) {
				this.navCtrl.navigateForward('home/profile/' + profileId);
			}

		}
	
		}
		extractMeetingIDFromSlug(slug: string): string | null {

			const queryParams = new URLSearchParams(slug.split('?')[1]);
			const meetingIDStr = queryParams.get('meeting');

		
			return meetingIDStr;
		  }
		  
		  // ...
		  
		  navigateToMeetingPage(meetingID: string) {
			// Build the URL
			const url = `https://supercumulo.laniakea.tv/loader?meeting=${meetingID}`;
		  
		
		  
			// Optionally, you can also open the URL in a browser
			this.openBrowser(url);
		  }
		  
		  async openBrowser(url: string) {
			//await Browser.open({ url });
			window.open(url, '_blank'); // Open the URL in a new tab or window
		  }
		  extractPublicationIdFromSlug(slug: string): number | null {
			// Extract the 'p' query parameter from the URL
			const queryParams = new URLSearchParams(slug.split('?')[1]);
			const publicationIdStr = queryParams.get('p');
		  
			if (!publicationIdStr) {
			  console.error('Publication ID not found in URL:', slug);
			  return null;
			}
		  
			const publicationId = Number(publicationIdStr);
			if (isNaN(publicationId)) {
			  console.error('Invalid publication ID:', publicationIdStr);
			  return null;
			}
		  
			return publicationId;
		  }
		  extractProfileIdFromSlug(slug: string): number | null {
			// Extract the 'p' query parameter from the URL
			const slugParts = slug.split('/');
			const publicationIdStr = slugParts[slugParts.length -1];
		  
			if (!publicationIdStr) {
			  console.error('Publication ID not found in URL:', slug);
			  return null;
			}
		  
			const publicationId = Number(publicationIdStr);
			if (isNaN(publicationId)) {
			  console.error('Invalid publication ID:', publicationIdStr);
			  return null;
			}
		  
			return publicationId;
		  } 
	
	  
	
	  async getUniversalLinkPublications(publicationId: number) {
		  console.log('pub_id',publicationId);
		try {
		  const result = await this.actualizacionservice.getUniversalLinkPublications(
			null,
			false,
			publicationId,
			null,
			null
		  );
		  const fetchedPublications = result || [];
		  this.publications = fetchedPublications;
		  this.openComponentVideo(fetchedPublications);
		} catch (error) {
		  console.error(error);
		}
	  }
	  async openComponentVideoTry(fetchedPublications) {
		const modal = await this.modalctrl.create({
		  component: LkfilmViewBySlidesComponent,
		  swipeToClose: true,
		  componentProps: {
			publications: fetchedPublications,
			//valueSelect: 'MENU',
			// Pass the publication ID or other necessary data to the component
		  }
		});
		await modal.present();
	  }
	  openComponentVideo(fetchedPublications){
		
		this.formService.mostrar = false;
	 this.formService.publications=fetchedPublications;
	  this.formService.valueSelect= 'MENU';
	  this.formService.mediaTemplate=true;
	
	  }
	

	initializeSampleAudio() {
		return new Promise((resolve, reject) => {
			let promises: Array<Promise<any>> = new Array<Promise<any>>();

			promises.push(this.nativeAudio.preloadSimple('send_sound', 'assets/sounds/send_sound.wav'));
			promises.push(this.nativeAudio.preloadSimple('receive_sound', 'assets/sounds/receive_sound.wav'));
			promises.push(this.nativeAudio.preloadSimple('new_notification', 'assets/sounds/new_notification.wav'));
			
			
			Promise.all(promises).then((promises) => {
				resolve(promises)
			}).catch((error) => {
				reject(error);
			});
		});
	}

	initializeBackGroundMode() {
		const options = {
			id: 1,
			title: 'Laniakea',
			text: 'Active Service',
			hidden: false,
			silent: true,
			sticky: true,
			resume: true,
			foreground: true,
		};
		//this.backgroundMode.setDefaults(options);
		//this.backgroundMode.excludeFromTaskList();
		//this.backgroundMode.overrideBackButton();
		//this.backgroundMode.enable();
		//this.backgroundMode.on('activate').subscribe(() => {
			//this.backgroundMode.disableWebViewOptimizations();
			//this.backgroundMode.disableBatteryOptimizations();
	//	});
		this.statusBar.overlaysWebView(false);
		this.statusBar.backgroundColorByHexString("#222428");
		this.statusBar.styleLightContent();
	}
	
	
	async subscribedToProduct(): Promise<void> {
		/*
		this.store.register({
		  id: this.productId2,
		  alias: this.productId2,
		  type: this.store.PAID_SUBSCRIPTION, // Change the type to PAID_SUBSCRIPTION
		});
		
		// Fetch the product information
		const product = await this.store.get(this.productId2);
		
		// Updated
		this.store.when(this.productId2).updated((product: IAPProduct) => {
		  console.log('Updated: ' + JSON.stringify(product));
		  if (product.owned) {
			this.SubscriptionExpired=false; 
		  } 
		else{
			this.SubscriptionExpired=true; 
		}
		});
		
		// User closed the native purchase dialog
		this.store.when(this.productId2).cancelled((product) => {
		  console.error('Purchase was Cancelled');
		});
		
  
		// Handle error events
		this.store.when(this.productId2).error((err) => {
		console.log('Error occurred during purchase:', err);
		
		});
		this.store.when(this.productId2).approved((product) => {
		  product.finish();
		  });
	  
		// Refresh the product information
		this.store.refresh();
		*/
		}
	
		playAudio() {
		
			const audioFile = 'assets/sounds/Intro.mp3';
			const sound = new Howl({
			  src: [audioFile],
			  autoplay: true,
			  volume: 1.2, // Initial volume level (0.0 to 1.0)
			});
		  
			// Start lowering the volume after 2 seconds
			setTimeout(() => {
			  // Gradually lower the volume over a duration of 2 seconds
			  sound.fade(1.0, 0.0, 3000);
			}, 2100);
			
			sound.play();
		}
		  
		  openCapacitor(){
			Swal.fire({
				title: 'Compatibilidad con otros sitios en internet',
				text: `Algunos sitios rechazarán conectarse a Laniakea. ¿Deseas abrir el navegador?`,
				icon: 'info',
				//timer: 6900, // Show the alert for 3.9 seconds
				//timerProgressBar: true,
				toast: true,
				position: 'top-end',
				showCancelButton: true,
				confirmButtonText: 'Si',
				cancelButtonText: 'No',
				color: '#ffffff',
				background: '#010f4e',
			  }).then((result) => {
				if (result.isConfirmed) {
				  // Call your desired function here
				  Browser.open({url:this.formService.valueInput['changingThisBreaksApplicationSecurity']});
				}else{
					this.allowScrolling = true;
				}
			  });
			  this.allowScrolling = true;
		  }
		  displayLaniakea(){
			  this.laniakea_tv_loaded=true;
			  console.log('displayLaniakea called');
			this.formService.valueInput=this.sanitizer.bypassSecurityTrustResourceUrl('https://laniakea.tv/blog');
			this.allowScrolling = true;
		  }
		  updateMetaDescription(content: string): void {
			let metaDescription = document.querySelector('meta[name="description"]');
			if (metaDescription) {
				metaDescription.setAttribute('content', content);
			} else {
				metaDescription = document.createElement('meta');
				metaDescription.setAttribute('name', 'description');
				metaDescription.setAttribute('content', content);
				document.head.appendChild(metaDescription);
			}
		  }  
}


