import { Component, OnInit} from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { FormServiceService } from "src/app/services/publications/form-service.service";
import Swal2 from "sweetalert2"
@Component({
  selector: 'app-principal-earth',
  templateUrl: './principal-earth.component.html',
  styleUrls: ['./principal-earth.component.scss'],
})
export class PrincipalEarthComponent implements OnInit {
  public step: number = 1;
  public buttonSee:string="ver";

  constructor(public formservice:FormServiceService,
    public modalController:ModalController,
    public navCtrl: NavController,
    public alertController: AlertController) {
    this.formservice.earthpublication.title=this.formservice.title
    this.formservice.earthpublication.description=this.formservice.description
    this.formservice.earthpublication.publication_type=this.formservice.publication_type
    this.formservice.earthpublication.quienpuedever=this.formservice.quienpuedever
    this.formservice.earthpublication.price=this.formservice.price
    this.formservice.earthpublication.is_feed=this.formservice.is_feed
    this.formservice.earthpublication.multimedia=this.formservice.multimedia
   }

  ngOnInit() {}

  //funcion para el boton siguiente
  next() {
    console.log(this.formservice.earthpublication)
    switch (this.step < 7) {
      case this.step===1:
        if(this.formservice.earthpublication.tipo && this.formservice.earthpublication.lugar){
          this.step++
        }
        else{
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Aun no has llenado todos los campos ',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });
        }

        break;

      case this.step===2:
        if(this.formservice.earthpublication.alojamiento && this.formservice.earthpublication.alojamiento && this.formservice.earthpublication.capacidad){
          this.step++
        }else{
          Swal2.fire({
            title: 'Advertencia!',
            text: 'Aun no has Completado todos los campos ',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });
        }
        break;
      case this.step===3:
        if(this.formservice.earthpublication.bedrooms && this.formservice.earthpublication.bathroom && this.formservice.earth3===true){
          this.step++
        }else{
          Swal2.fire({
            title: 'Advertencia!',
            text: 'No se te olvide elegir cantidad de cuartos y baños ',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
          });

        }
        break;

      case this.step===4:
        console.log(this.formservice.earthpublication.latitud)
        console.log(this.formservice.earthpublication.longitud)
       // if(this.formservice.earthpublication.direction && this.formservice.earthpublication.col&&this.formservice.earthpublication.city&&this.formservice.earthpublication.state && this.formservice.earthpublication.postal_code&&(this.formservice.earthpublication.interior_number || this.formservice.earthpublication.exterior_number)){
         if(this.formservice.earthpublication.latitud && this.formservice.earthpublication.longitud){
          this.step++
        }else{

          Swal2.fire({
            title: 'Advertencia!',
            text: 'Porfavor llena todos los campos, dale click a "Mi ubicación actual"',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
          });

        }
        break;
      case this.step===5:
        if(this.formservice.earth51 ===true && this.formservice.earth5 ===true){
          this.step++

        }else{
          alert('Selcciona al menos un servicio y detalles adicionales que deban conocer los huéspedes');
        }
        break;
      case this.step===6:
       // if(this.formservice.earthpublication.fecha_inicial && this.formservice.earthpublication.fecha_final){
        if ( this.formservice.earthpublication.IsReservaCalendario === true && this.formservice.earthpublication.price) {
          this.step++
          this.buttonSee="oculto"
        }else{

          Swal2.fire({
            title: 'Advertencia!',
            text: 'Porfavor elije una fecha de inicio y final de hospedaje ',
            icon: 'warning',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
          });

        }
        break;



      default:
        break;
    }
  
  
    // if (this.step < 7) {
    //   this.step++;
    // }
    // if(this.step===7){
    //   this.buttonSee="oculto"
    // }
    
  }

   //cerrar el modal
   dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  //abrir un mensaje de salir de la publicacion
  async back() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Salir de la publicación',
      message: 'Si desea salir se eliminará todo su avance',
      buttons: [
        {
          text: 'Salir',
          handler: () => {
            this.formservice.title="";
            this.formservice.description="";
            this.formservice.is_feed=false;
            this.formservice.quienpuedever="Para todos";
            this.formservice.earthpublication.price=undefined;
            this.formservice.earthpublication.lugar=undefined;
            this.formservice.earthpublication.tipo=undefined;
            this.formservice.earthpublication.alojamiento=undefined;
            this.formservice.earthpublication.alojamientoExclusivo=undefined;
            this.formservice.earthpublication.capacidad=undefined;
            this.formservice.earthpublication.bedrooms=0;
            this.formservice.earthpublication.bathroom=0;
            this.formservice.earthpublication.king_bed=0;
            this.formservice.earthpublication.queen_bed=0;
            this.formservice.earthpublication.matrimonial_bed=0;
            this.formservice.earthpublication.individual_bed=0;
            this.formservice.earthpublication.sofa_bed=0;
            this.formservice.earthpublication.sofa=0;
            this.formservice.earthpublication.inflatable_mattress=0;
            this.formservice.earthpublication.litera=0;
            this.formservice.earthpublication.mattress_on_the_floor=0;
            this.formservice.earthpublication.bed_for_children=0;
            this.formservice.earthpublication.crib=0;
            this.formservice.earthpublication.water_bed=0;
            this.formservice.earthpublication.hamaca=0;
            this.formservice.earthpublication.direction=undefined;
            this.formservice.earthpublication.interior_number=undefined;
            this.formservice.earthpublication.exterior_number=undefined;
            this.formservice.earthpublication.postal_code=undefined;
            this.formservice.earthpublication.col=undefined;
            this.formservice.earthpublication.city=undefined;
            this.formservice.earthpublication.state=undefined;
            this.formservice.earthpublication.wifi="No";
            this.formservice.earthpublication.kitchen_room="No";
            this.formservice.earthpublication.free_parking="No";
            this.formservice.earthpublication.tv="No";
            this.formservice.earthpublication.air_conditioning="No";
            this.formservice.earthpublication.washing_machine="No";
            this.formservice.earthpublication.drying_machine="No";
            this.formservice.earthpublication.iron="No";
            this.formservice.earthpublication.swimming_pool="No";
            this.formservice.earthpublication.pets="No";
            this.formservice.earthpublication.smoking_is_allowed="No";
            this.formservice.earthpublication.events_are_allowed="No";
            this.formservice.earthpublication.personal_clean="No";
            this.formservice.earthpublication.additional_details=undefined;
            this.formservice.earthpublication.fecha_inicial=undefined;
            this.formservice.earthpublication.fecha_final=undefined;
            this.dismiss();
          }
        },
        {
          text: 'Continuar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });
    await alert.present();
  }
}
