import {AfterViewInit, Component, Input, OnInit} from '@angular/core';

import { RequestService } from 'src/app/services/request/request.service';
import Swal2 from 'sweetalert2';
import { ModalController,PopoverController } from '@ionic/angular';
import { Route, Router } from '@angular/router';
import {SliderInformationComponent} from "../slider-information/slider-information.component";
import { PagoTarjetaComponent } from './Pago-Tarjeta/Pago-Tarjeta.component';
import {ReconoceridComponent} from "../slider-information/reconocerid/reconocerid.component";
import { Browser } from '@capacitor/browser';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ActivatedRoute } from '@angular/router';
import {Confirmacion} from 'src/app/components/email-confirm/ConfirmaciondeCorreo1/Confirmacion';
import { InAppPurchase2,IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Platform } from '@ionic/angular';
//import { LocalNotifications } from '@capacitor/local-notifications';
import  Swal from 'sweetalert2';
import { Plugins } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';

const { LocalNotifications } = Plugins;
@Component({
  selector: 'app-pago-verificacion',
  templateUrl: './pago-verificacion.component.html',
  styleUrls: ['./pago-verificacion.component.scss']
})
export class PagoVerificacionComponent implements OnInit, AfterViewInit {
  public typeTextCalled:boolean=false;
  public textAlign:any;
  @Input() pr: any;
  profileinfo: any;
  urldirection: any;
  productId: string = '123';
  productId2: string = '1618';
  web:boolean=false;
  constructor(
    private router: Router,
    private request: RequestService,
    private sliderInfo: SliderInformationComponent,
    private modalcontroller: ModalController,
    private profile_service: ProfileService,
    public route: ActivatedRoute,
    private Confirmacion: Confirmacion,
    private store: InAppPurchase2, 
     private platform: Platform,
     private popOverController: PopoverController,
    // private localNotification: LocalNotifications,
 
    ) {
    
     }

  ngOnInit() {
    console.log('profile antes de pagar', this.pr);
    this.getprofile();
    
    if (Capacitor.getPlatform() === 'web') {
this.web=true;
    }

  
}


  dismiss(){
    this.modalcontroller.dismiss();
  }

  async openBrowser(url: string) {
    await Browser.open({ url: url });
}

async getOnboardingLinkAndCheckStatus() {
  // Send a POST request to your server API
  // Get the onboarding link URL
  this.request.createRequestPost('Onboard', { id: this.pr.id, email: this.profileinfo.user.email })
      .then(response => {
          if (response) {

              console.log('After API call:', response);  // Log the received response
              this.handleResponse(response);  // Handle the response
              this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Continua con el siguiente paso para verificarte.');
          } else {
              console.error('No response from API call.');
          }
      })
      .catch(error => {
          console.error('Error from API call:', error);  // Log error from API call
      });
}

// This function can be used to handle the response from both API calls
handleResponse(response: any) {
  try {
    if(Capacitor.getPlatform() !=='web'){
      this.urldirection = JSON.parse(response);  // Try to parse the response
    }else{
      this.urldirection = response;  // Try to parse the response
    }

      console.log('Parsed response:', this.urldirection);  // Log the parsed response
      if (this.urldirection && this.urldirection.url) {  // Check if the parsed response has a 'url' property
          // Open the browser with the received URL
          this.openBrowser(this.urldirection.url);
      } else if (this.urldirection && this.urldirection.status === 'complete') {
        this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Continua con el siguiente paso para verificarte.');
        Swal2.fire({
          title: 'Formulario financiero exitoso',
  
          icon: 'success',
          confirmButtonText: 'OK',
          color: '#ffffff',
          background: '#010f4e',
        });
      } else {
          console.error('Parsed response does not contain a URL.');
      }
  } catch (error) {
      console.error('Failed to parse response:', error);  // Log the error if parsing failed
  }
}


  
  async metodoTarjeta(inscription_or_subscription?){
    let openpay_subscription_payment;
    if(inscription_or_subscription === 'inscription'){
      openpay_subscription_payment=false;
      this.popOverController.create({
        component: PagoTarjetaComponent,
     
       
        componentProps: {
          openpay_subscription_payment:openpay_subscription_payment,
          profile: this.pr,
          pago_tarjeta_stripe:false,
        }
        }).then((modal) => {
        modal.onDidDismiss().then((data) => {
      
        
        });
        modal.present();
         // Listen to the output when the modal is dismissed
      
        }).catch((error) => {
        console.error(error);
        });
    }else if(inscription_or_subscription==='subscription'){
      openpay_subscription_payment=true;
      this.open_tarjeta(openpay_subscription_payment);
    }
   
  }

  async open_tarjeta(openpay_subscription_payment){
    const modal = await this.modalcontroller.create({
      component: PagoTarjetaComponent,
      cssClass: 'detailsInModal',
      swipeToClose: true,
      componentProps: {
        openpay_subscription_payment:openpay_subscription_payment,
        profile: this.pr,
        pago_tarjeta_stripe:true
      },
    });
    modal.onDidDismiss().then((data) => {

      console.log('profile after pay', this.pr);
    });
    return await modal.present();
  }

  async reconoserid(){
    const modal = await this.modalcontroller.create({
      cssClass: 'detailsInModal',
      swipeToClose: true,
      component: ReconoceridComponent,
      componentProps: {
        profile: this.pr,
        correo: this.profileinfo.user.email,
      },
    });
    modal.onDidDismiss().then((data)=>{
      this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Continua con el siguiente paso para verificarte.');
      this.pr = data.data;
    });
    return await modal.present();
  }

  ngAfterViewInit(): void {
    console.log('after init pago verificacion');
    //this.sendNotification('¿Inconveniente con la verificación?', 'Toca aquí para saber más de la verificación');
    this.web=true;
  }
  getprofile() {
    this.request.createRequestGet("profile-all", null, { id: this.pr.id })
        .then((data => {
            this.profileinfo = JSON.parse(data);
            console.log("Successfully fetched profile info.");
            console.log("Profile info:", this.profileinfo);
            console.log("Email:", this.profileinfo.user.email);
        }))
        .catch((error) => {
            console.error("Error fetching profile info:", error);
        });
}

async ConfirmaciondeCorreo() {

	let data = {
	  email: this.profileinfo.user.email,
	};
  
	// Step 1: Request email confirmation
	try {
    Swal.fire({
      title: 'Procesando...',
      text: 'El correo se está procesando.',
      icon: 'success',
      timer: 3600, // Show the alert for 3 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
    });
	  const response = await this.request.createRequestPost('send-email', data);
    const responseData = JSON.parse(response);
	  // Check if email confirmation was successful
	  if (responseData.code === 200) {
		const modal = await this.modalcontroller.create({
		  component: Confirmacion,
      cssClass: 'detailsInModal',
      swipeToClose: true,
		  componentProps: {
			email: this.profileinfo.user.email,
      id:this.pr.id
		  },
		});
		modal.onDidDismiss().then((data) => {
		  
		});
		await modal.present();
  
		// Email confirmation sent successfully
		if (this.profileinfo && this.profileinfo.user) {
     // this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Continua con el siguiente paso para verificarte.');
		  console.log("Profile info and user exist. Displaying alert...");
		  Swal2.fire({
			title: 'Confirmacion de Correo Electronico',
			text: `Confirmacion de correo Enviada a ${this.profileinfo.user.email}`,
			icon: 'success',
			confirmButtonText: 'Entendido',
			color: '#ffffff',
			background: '#010f4e',
		  }).then(() => {
			console.log("Alert displayed successfully.");
		  }).catch((error) => {
			console.error("Error displaying alert:", error);
		  });
		} else {
		  console.warn("Profile info or user does not exist. Alert not displayed.");
		}
	  } else {
		Swal2.fire({
		  title: 'Confirmación de correo no procesada',
		  text: `Confirmación no enviada a la cuenta de tu correo ${this.profileinfo.user.email}`,
		  icon: 'error',
		  confirmButtonText: 'Entendido',
		  color: '#ffffff',
		  background: '#010f4e',
		}).then(() => {
		  console.log("Alert displayed successfully.");
		}).catch((error) => {
		  console.error("Error displaying alert:", error);
		});
		// Email confirmation failed
		// Handle failure here (e.g., show an error message to the user)
	  }
	} catch (error) {
	  console.error("Error:", error);
	  // Handle network errors or other unexpected errors here
	}
  }
  Pay_verification(inscription_or_subscription){
    if (!this.web){
      Swal2.fire({
        title: 'Pago de inscripción',
        text: `Paga tu inscripción por 4.99 USD. Después tendrás que confirmar tu suscripción mensual por 0.99 USD`,
        icon: 'info',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      }).then(() => {
        this.InscriptionToProduct();
      }).catch((error) => {
        console.error("Error displaying alert:", error);
      });

    }else{
      Swal.fire({
        title: 'Procesando...',
        text: 'El producto se está procesando.',
        icon: 'success',
        timer: 6900, // Show the alert for 3.9 seconds
        timerProgressBar: true,
        toast: true,
        position: 'top-end',
        showConfirmButton: false
      });
      this.metodoTarjeta(inscription_or_subscription);
    }
  }

 
  async InscriptionToProduct(): Promise<void> {
    Swal.fire({
      title: 'Procesando...',
      text: 'El producto se está procesando.',
      icon: 'success',
      timer: 6900, // Show the alert for 3.9 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
    });
    return new Promise<void>(async (resolve, reject) => {
      this.store.verbosity = this.store.DEBUG;
  
      // Register the product with the given ID
      this.store.register({
        id: this.productId,
        alias: this.productId,
        type: this.store.NON_CONSUMABLE, // Change the type to NON_CONSUMABLE
      });
  
      // Fetch the product information
      const product = await this.store.get(this.productId);
  
      // Updated
      this.store.when(this.productId).updated((product: IAPProduct) => {
        console.log('Updated' + JSON.stringify(product));
      });
  
      // User closed the native purchase dialog
      this.store.when(this.productId).cancelled((product) => {
        console.error('Purchase was Cancelled');
      });
  
      // Run some code only when the store is ready to be used
      this.store.ready(() => {
        console.log('Store is ready');
        console.log('Products: ' + JSON.stringify(this.store.products));
        console.log(JSON.stringify(this.store.get(this.productId)));
      });
  
      if (product) {
        // Check if the product is already owned
        if (product.state === this.store.OWNED) {
          console.log('Product already owned');
          resolve();
          return;
        }
  
        // Check if the product is invalid
       // if (product.state === this.store.INVALID) {
         // reject(new Error('Product is invalid'));
         // return;
       // }
  
        // To make a purchase
        this.store.order(this.productId);
  
        // Proceed with the purchase flow
        this.store.when(this.productId).approved((product) => {
          product.finish();
  
          console.log('Subscription ordered!');
  
          this.store.when(this.productId).owned(async (product) => {
            console.log('Product is owned');
            try {
              let data = {
                profile_id: this.pr.id,
              };
  
              await this.request.createRequestPost('IN-APP-PURCHASE-1', data);
              console.log('Status modified!');
              Swal2.fire({
                title: '¡Tienes un mes gratis!',
                text: `Suscríbete ahora, aprovecha el mes gratis. Disfruta de los beneficios de un usuario verificado.`,
                icon: 'success',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
              }).then(() => {
                this.subscribeToProduct();
              }).catch((error) => {
                console.error("Error displaying alert:", error);
              });
          
              resolve();
              this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Continua con el siguiente paso para verificarte.');
            } catch (error) {
              console.log('Error modifying status:', error);
              reject(error);
            }
          });
        });
  
        // Handle error events
        this.store.when(this.productId).error((err) => {
          console.log('Error occurred during purchase:', err);
          reject(err);
        });
  
        // Refresh the product information
        this.store.refresh();
      } else {
        reject(new Error('Failed to fetch product information'));
      }
    });
  }
  async subscribeToProduct(): Promise<void> {
    Swal.fire({
      title: 'Procesando...',
      text: 'El producto se está procesando.',
      icon: 'success',
      timer: 6900, // Show the alert for 3.9 seconds
      timerProgressBar: true,
      toast: true,
      position: 'top-end',
      showConfirmButton: false
    });
    return new Promise<void>(async (resolve, reject) => {
      this.store.verbosity = this.store.DEBUG;
  
      // Register the product with the given ID
      this.store.register({
        id: this.productId2,
        alias: this.productId2,
        type: this.store.PAID_SUBSCRIPTION, // Change the type to PAID_SUBSCRIPTION
      });
  
      // Fetch the product information
      const product = await this.store.get(this.productId2);
  
      // Updated
      this.store.when(this.productId2).updated((product: IAPProduct) => {
        console.log('Updated' + JSON.stringify(product));
      });
  
      // User closed the native purchase dialog
      this.store.when(this.productId2).cancelled((product) => {
        console.error('Purchase was Cancelled');
      });
  
      // Run some code only when the store is ready to be used
      this.store.ready(() => {
        console.log('Store is ready');
        console.log('Products: ' + JSON.stringify(this.store.products));
        console.log(JSON.stringify(this.store.get(this.productId2)));
      });
  
      if (product) {
        // Check if the product is already owned
        if (product.state === this.store.OWNED) {
          console.log('Product already owned');
          resolve();
          return;
        }
  
        // Check if the product is invalid
        if (product.state === this.store.INVALID) {
          reject(new Error('Product is invalid'));
          return;
        }
  
        // To make a purchase
        this.store.order(this.productId2);
  
        // Proceed with the purchase flow
        this.store.when(this.productId2).approved((product) => {
          product.finish();
  
          console.log('Subscription ordered!');
  
          this.store.when(this.productId2).owned(async (product) => {
            console.log('Product is owned');
  
            try {
              let data = {
                profile_id: this.pr.id,
              };
              Swal2.fire({
                title: '¡Tienes un mes gratis!',
                text: `Te haz suscrito exitosamente a Laniakea. Disfruta de los beneficios de un usuario verificado.`,
                icon: 'success',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
              }).then(() => {

              }).catch((error) => {
                console.error("Error displaying alert:", error);
              });
              await this.request.createRequestPost('IN-APP-PURCHASE-2', data);
              console.log('Status modified!');
              this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Continua con el siguiente paso para verificarte.');
              resolve();
              console.log('Product is owned');
            
            } catch (error) {
              console.log('Error modifying status:', error);
              reject(error);
            }
          });
        });
  
        // Handle error events
        this.store.when(this.productId2).error((err) => {
          console.log('Error occurred during purchase:', err);
          reject(err);
        });
  
        // Refresh the product information
        this.store.refresh();
      } else {
        reject(new Error('Failed to fetch product information'));
      }
    });
  }
  
  sendNotification(title, body) {
   
    return new Promise((resolve, reject) => {
      const notification = {
        title: title,
        body: body,
        profile_id:this.pr.id,

      };
  
      this.request.createRequestPost('GreatAtractorNotifications', notification)
        .then((data) => {
          Swal2.fire({
            title: 'Vínculo generado',
            text: 'Nuevo status asequida exitosamente',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
          });
          resolve(data);
        }).catch((error) => {
          console.error('Failed to send notification:', error);
          reject(error);
        });
    });
  }
  typeText(text: string, delay: number) {
    	 // Check if typeText has already been called for this currentStateIndex
	 if (this.typeTextCalled) {
		return;
	  }
    this.typeTextCalled = true; // Set the flag to indicate 
    let index = 0;
    this.textAlign='';
    const interval = setInterval(() => {
      if (index < text.length) {
        this.textAlign += text.charAt(index);
        index++;
      } else {
        clearInterval(interval);
      }
    }, delay);
  }
}