import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import {HeaderBarComponent} from "../header-bar/header-bar.component";
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { Capacitor } from '@capacitor/core';
import Swal2 from 'sweetalert2';
import { Browser } from '@capacitor/browser';
import { IonSlides } from '@ionic/angular';
import { RequestService } from 'src/app/services/request/request.service';
import { Howl } from 'howler';
import { PopoverController, ModalController } from '@ionic/angular';
import {MyObservationsComponent} from 'src/app/my-observations/my-observations.component';
declare var MediaRecorder;
import { NewPublicationButtonComponent } from 'src/app/components/common-elements/buttons/new-publication-button/new-publication-button.component';
import { SolicitudDeReservaComponent } from '../../Solicitud-reserva/solicitud-de-reserva/solicitud-de-reserva.component';
import { CreatePublicationPage } from 'src/app/pages/create-publication/create-publication.page';
import { Console } from 'console';
import {InlineBowsingComponent} from 'src/app/inline-bowsing/inline-bowsing.component';
@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss'],
})

export class FooterBarComponent implements OnInit {
  display_create_post_button:boolean=false;
  stream;
  isRecording = false;
  index=1;
  slideOptions = {
    loop: true,
    autoplay: {
			delay: 21000,
			disableOnInteraction: true, // doesnt keeps autoplay enabled after user interaction
		  }
  }
  @ViewChild('footerSlides', { static: true }) slides: IonSlides;

  constructor(
    private formService:FormServiceService,
    private request: RequestService,
    private popoverCtrl:PopoverController,
    private NewPublication: NewPublicationButtonComponent,
    private modalController: ModalController,
  ) {}

  public url:string = "assets/iconoslk/";
  handleSlideChanged(){
    
    if(!this.slides){
      return;
    }
    setTimeout(() => {
    const createPublicationButton = document.getElementById('new-publication-action-sheet');


      createPublicationButton.addEventListener('click', () =>     this.NewPublication.presentActionSheet());
    
  }, 699);
    this.slides.getActiveIndex().then(index => {
      console.log('Current slide index is', index);

      if (index==0||index==3){
        Swal2.fire({
          title: '<ion-icon name="camera-outline" style="font-size: 2rem; color: #ffcc00;"></ion-icon> ¿Iniciar cámara?',
          text: '¿Quieres activar la cámara para capturar imágenes o videos?',
          icon: 'info',
          position: 'top-end', // Moves it to the top-right corner
          showCancelButton: true,
          confirmButtonText: '<ion-icon name="checkmark-circle-outline"></ion-icon> Sí',
          cancelButtonText: '<ion-icon name="close-circle-outline"></ion-icon> No',
          color: '#ffffff',
          background: '#010f4e',
          timerProgressBar: true,
			toast: true,
          customClass: {
            //popup: 'swal2-rounded',
            confirmButton: 'swal2-confirm-btn',
            cancelButton: 'swal2-cancel-btn'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.slides.lockSwipes(true);
            this.formService.POSTING=true;
            document.body.classList.remove('dark');
            this.formService.mostrar = false;
            setTimeout(() => {
            this.camera();
            setTimeout(() => {
            this.slides.lockSwipes(false);
          },699);
          }, 699);
          }else{
             this.slides.slideTo(1); // Moves to index 1
  // ✅ Stop autoplay using Swiper API
  this.slides.stopAutoplay();
          }
        });

      }else if(index==1 ||index==4){
        this.formService.POSTING=false;
        if(this.index===1){
          return;
        }
        if(this.index===2){
          return;
        }
        document.body.classList.add('dark');
        this.formService.POSTING=false;
        this.formService.mostrar = true;
        this.stopMedia();

       }else if(index==2 && this.index==0){
        this.formService.POSTING=false;
        this.formService.POSTING=false;
        this.formService.mostrar = true;
        document.body.classList.add('dark');
        this.stopMedia();
       } else{
  
        this.formService.POSTING=false;
       // this.formService.mostrar = true;
        this.stopMedia();

      }
      this.index=index;
      // You can perform any other actions with the index here
    });
  }
  camera(){
    const video = document.getElementById('video') as HTMLVideoElement;
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const recordButton = document.getElementById('record');
    const takePhotoButton = document.getElementById('take-photo');
    const ctx = canvas.getContext('2d');
    
    let mediaRecorder;
    let chunks = [];
    const createPublicationButton = document.getElementById('create-publication-button');
    if (createPublicationButton) {
      createPublicationButton.addEventListener('click', () => this.open_create_publication('bb'));
    }
    
    // Access the camera
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then(stream => {
          this.stream = stream;
            video.srcObject = stream;
    
            mediaRecorder = new MediaRecorder(stream);
    
            mediaRecorder.ondataavailable = event => {
                chunks.push(event.data);
            };
    
            mediaRecorder.onstop = () => {
              this.display_create_post_button=true;
                const blob = new Blob(chunks, { 'type': 'video/mp4;' });
                chunks = [];
                const videoURL = URL.createObjectURL(blob);
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                const a = document.createElement('a');
                a.href = videoURL;
                a.download = 'video.mp4';
                document.body.appendChild(a);
                a.click();
                const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
                if(this.formService.normalpublication.blobsMultimedia.length < 1){
                  this.formService.normalpublication.blobsMultimedia[0]={blob:blob,isPremium:false, filename: currentTime + '.jpeg'};
                }else {
                  this.formService.normalpublication.blobsMultimedia.push({blob:blob,isPremium:false, filename: currentTime + '.jpeg'});
                } 
                this.formService.Capacitor_convertFileSrc_video=videoURL;
            };
        })
        .catch(error => {
            console.error('Error accessing the camera', error);
        });
    
    // Take photo
    takePhotoButton.addEventListener('click', () => {
      this.display_create_post_button=true;
       // canvas.width = video.videoWidth * 0.10;
       // canvas.height = video.videoHeight* 0.10;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = 'photo.png';
        document.body.appendChild(a);
        a.click();
        canvas.toBlob(blob => {
          const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
          if(this.formService.normalpublication.blobsMultimedia.length < 1){
            this.formService.normalpublication.blobsMultimedia[0]={blob:blob,isPremium:false, filename: currentTime + '.jpeg'};
          }else {
            this.formService.normalpublication.blobsMultimedia.push({blob:blob,isPremium:false, filename: currentTime + '.jpeg'});
          } 
        }, 'image/png');
        this.formService.Capacitor_convertFileSrc=dataUrl;
    });
    
    // Record video
    recordButton.addEventListener('click', () => {
        if (this.isRecording) {
          this.formService.recording_icon='radio-button-on';
            mediaRecorder.stop();
            //recordButton.textContent = 'Record';
        } else {
          this.formService.recording_icon='square';
            mediaRecorder.start();
            //recordButton.textContent = 'Stop Recording';
        }
        this.isRecording = !this.isRecording;
    });
    

  }
  stopMedia(){
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      const video = document.getElementById('video') as HTMLVideoElement;
      if(video){
        video.srcObject = null;
      }
    
    }

  }
  menu:string="no";
  formatCounter(value: number): string {
    if (isNaN(value)) {
      return '0';
    }
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(1) + 'T';
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(1) + 'B';
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    } else {
      
      return value.toFixed(0);
    }
  }
  ngOnInit() {

      this.request.createRequestPost('Historic_calls', { getUnreadCalls:true})
      .then((final_data) => {
   
      this.formService.unseenCalls= this.formatCounter(final_data);;
      })
    

  }

  async open_create_publication(event){
    this.slides.slideNext();
    Swal2.fire({
			title: 'Procesando...',
			text: 'El comando de voz se está procesando.',
			icon: 'success',
			timer: 2100, // Show the alert for 45 seconds
			timerProgressBar: true,
			toast: true,
			position: 'top-end',
			showConfirmButton: false
		  });

    console.log('open_create_publication CALLED');
    this.formService.publication_type='normal';
    setTimeout(() => {
    this.modalController.create({
      component:CreatePublicationPage,
      cssClass: 'detailsInModal',
	  swipeToClose: true,
			componentProps: {
			
			},
    }).then((modal=>{
      modal.present()
    }))
  }, 1800);
  }
  ngAfterViewInit(){
  
    setTimeout(() => {
      const createPublicationButton = document.getElementById('new-publication-action-sheet');
      if (createPublicationButton) {
        createPublicationButton.addEventListener('click', () =>     this.NewPublication.presentActionSheet());
      }
      /*
    if (!this.formService.profile){
      this.slides.slideNext();
      this.OpenLegalDocs('terms');
      
    }
    this.changeColor('go-ecosystem-tab');*/
  }, 3999);
      }
      changeColor(tabId: string){
        const tabButton1 : HTMLElement = document.getElementById('my-feed');
        const tabButton2 : HTMLElement = document.getElementById('crear');
        const tabButton3 : HTMLElement = document.getElementById('go-ecosystem-tab');
        const tabButton4 : HTMLElement = document.getElementById('messages-page');
        const tabButton5 : HTMLElement = document.getElementById('profile');
        tabButton1.style.backgroundColor = '#000';
        tabButton2.style.backgroundColor = '#000';
        tabButton3.style.backgroundColor = '#000';
        tabButton4.style.backgroundColor = '#000';
        tabButton5.style.backgroundColor = '#000';

        console.log('changeColor called', tabId);
        const tabButton : HTMLElement = document.getElementById(tabId);
        if (tabButton) {
          console.log('element found');
          // Apply custom styles to the tab button
          tabButton.style.backgroundColor = '#010f41';
          // Add any other styling as needed
        }
      }
  change_title(title:string){

    this.formService.itemsArray=[];
    const audioUrl = 'https://laniakea-multimedia.s3.amazonaws.com/public/bongo.mp3';
				
    const sound = new Howl({
     html5: true,
             src: [audioUrl],
             format: ['mp3'], // Specify the audio format as MP3
             autoplay: true,
             volume: 1.0,
           });
                     sound.play();
    this.formService.mostrar = true; 
    console.log("has presionado el boton de ecosistema");

    if(title==='Laniakea'){
      //this.formService.mostrar = false; 
      if(this.formService.mediaTemplate===true){
        this.formService.mediaTemplate=false;
        this.formService.Entrelazamiento=false;
      }

    }

    if (title=='OFERTA' || 'Laniakea' || 'Whisper'){
      this.formService.isKYCValid = false;
    }
    this.formService.titleHeader = title;
   
    console.log("title section",this.formService.titleHeader);

  }
  async OpenLegalDocs(type){
    let url;
    if(type==='terms'){
url='https://www.laniakea.tv/zh/post/服务条款和条件';
    }else if(type==='privacyPolicy'){
url='https://www.laniakea.tv/zh/post/_隐私政策';
    }
    if (Capacitor.getPlatform() === 'web') {
      const modal = await this.modalController.create({
        cssClass: 'detailsInModal',
        swipeToClose: true,
        component: InlineBowsingComponent,
        componentProps: {
          url: url,
        
        },
        });
        modal.onDidDismiss().then((data)=>{
        
        });
        return await modal.present();
       

    }else{
      //this.openIFrame(url);
      this.openBrowser(url);
    }
  }

  async openBrowser(url: string) {
		await Browser.open({ url });
	  }
    onTouchEnd($event) {
	


      this.displayObservationsPopUp($event);


}
async openContact(){
  const modal = await this.modalController.create({
    cssClass: 'detailsInModal',
    swipeToClose: true,
    component: InlineBowsingComponent,
    componentProps: {
      url: 'https://www.laniakea.tv/contacto-de-soporte',
    
    },
    });
    modal.onDidDismiss().then((data)=>{
    
    });
    return await modal.present();
}
    async displayObservationsPopUp(ev:any) {
      const popover = await this.popoverCtrl.create({
        component: MyObservationsComponent, // Replace with your component for observations
        translucent: true,
        event: ev,
        backdropDismiss: true, // Allow dismissal by tapping outside the popover
      });
    
      await popover.present();
    }

}
