import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'; //AfterViewInit
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { IonSlides, ModalController } from '@ionic/angular';
import { LkfilmViewBySlidesComponent } from 'src/app/components/lkfilm-view-by-slides/lkfilm-view-by-slides.component';
import { ActualizacionServiceService } from 'src/app/services/actualizacion/Actualizacion-service.service';
import Swal2 from "sweetalert2";
import { ToastController } from '@ionic/angular';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
//import { PagoVerificacionComponent } from 'src/app/components/pago-verificacion/pago-verificacion.component';
//import { SliderInformationComponent } from 'src/app/components/slider-information/slider-information.component';
import {RequestService} from "../../../services/request/request.service";
import { WalletServiceService } from 'src/app/services/wallet/wallet-service.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { Capacitor } from '@capacitor/core';
import { r3JitTypeSourceSpan } from '@angular/compiler';


//import  Swal from 'sweetalert2';
//import { InAppPurchase2,IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
// Declare shaka as a global variable

@Component({
  selector: 'app-main-publications-swipecontainer',
  templateUrl: './main-publications-swipecontainer.component.html',
  styleUrls: ['./main-publications-swipecontainer.component.scss']
})
export class MainPublicationsSwipecontainerComponent implements OnInit { //OnChanges, AfterViewInit
  containerWidth: string = '99vw'; // Example: Set this dynamically as needed
  @Input() window_width: any;
  SlideInteraction:boolean=true;
  @Input() general_index: any=0;
  @Input() media_index: any=0;
  checkIntervalId: any;

  timeoutId;
  public web: boolean =false;
  slideOpts = {
    direction: 'vertical',
    initialSlide: 0,
    speed: 420,
  };
  public showSwipeIntro: boolean = false;
  numero=10
  mostrar:boolean=false;
  lanzamiento
  @Input() pr: any;
  public msg:string="Leyendo.."
  @ViewChild('slides') slides: IonSlides;
  NextSlide: any;
  public ChunkedPublications: any[] = [];
  public OldPublications: any[] = [];
  public valueSelect:string;
  //public publicationsLoaded = false;
  public publications: any[] = [];
  @Input() initial_index = 0;
  productId2: string = '1618';
  media_width: string='46.6vw';
  public HubbleExpired: boolean = false;
  constructor(private actualizacionservice:ActualizacionServiceService,private formService:FormServiceService,private Mctrl:ModalController, private toastController: ToastController,
    private faio:FingerprintAIO, private request: RequestService, private walletService: WalletServiceService, private profile:ProfileService, ) { }


/*
  ngOnChanges(changes: SimpleChanges): void {
    console.log('Publication main swipe Changes detected at ', changes);
    if (changes.pr) {
      // Handle changes to the 'pr' input property
      console.log("pr changed in child component", changes.pr.currentValue);
      this.pr = changes.pr.currentValue;
      console.log( 'main pr:', this.pr);
    console.log( 'main pr:', this.pr.id);
    console.log( 'main pr:', this.pr.kyc);
    }
    //if (changes !== undefined) {

      //if('publications' in changes) {
     //   this.ChunkedPublications = changes.publications.currentValue;
   //   }
   // }
  }
  */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['window_width'] && changes['window_width'].currentValue !== undefined) {
      const newWidth = changes['window_width'].currentValue;
      this.containerWidth = newWidth >= 510 ? '81vw' : '99vw';
      this.media_width= newWidth >= 510 ? '36.3vw' : '46.6vw';
    }

    if (changes['general_index'] && changes['general_index'].currentValue !== undefined && !this.formService.mediaTemplate) {
      if (changes['general_index'].currentValue > changes['general_index'].previousValue) {
        this.slides.slideNext();
      } else {
        this.slides.slidePrev();
      }
    }
    

  }

  get_class(index) {
    // console.log('se lllama get_class');


    if((index % 4 === 0)) {
      return 'reminder0 ';
    }
    else if(index % 4 === 1){
      return 'reminder1 ';
    }
    else if(index % 4 === 2){
      return 'reminder2 ';
    }else{
      return 'reminder3 ';
    }

    // if((index % 4 === 0)) {
    //   if (this.formService.lkpublication.index_close === index){
    //     return 'reminder0 shadow ';
    //   }else{
    //     return 'reminder0 ';
    //   }
    // }else if(index % 4 === 1) {
    //   if (this.formService.lkpublication.index_close === index){
    //     return 'reminder1 shadow ';
    //   }else {
    //     return 'reminder1';
    //   }
    // }else if(index % 4 === 2) {
    //   if (this.formService.lkpublication.index_close === index){
    //     return 'reminder2 shadow';
    //   }else {
    //     return 'reminder2';
    //   }
    // }
    // else {
    //   if (this.formService.lkpublication.index_close === index){
    //     return 'reminder3 shadow ';
    //   }else {
    //     return 'reminder3';
    //   }
    // }

  }
  get_class2(index) {
    // console.log('se lllama get_class2');

    if(this.formService.lkpublication.index_close===index){
      return 'shadow'
    }




  }
    /*
  pauseAllVideos() {
    let allVideos = document.getElementsByTagName('video');
    for (let i = 0; i < allVideos.length; i++) {
      allVideos[i].pause();
    }
  }
  async playVideosInActiveSlide() {
    let currentIndex = await this.slides.getActiveIndex();
    let activeSlideElement = document.getElementById('slide-' + currentIndex);
    if (activeSlideElement) {
      let videos = activeSlideElement.getElementsByTagName('video');
      for (let i = 0; i < videos.length; i++) {
        videos[i].play();
      }
    }
  }

  dismiss() {
    this.Mctrl.dismiss({
      'dismissed': true
    });
  }

  openComponentVideo0(){//index
    this.Mctrl.create({
        component: LkfilmViewBySlidesComponent,
        swipeToClose: true,
        componentProps: {
          publications:this.publications,
          //index: index
        }
    }).then((modal)=>{
        modal.present();
        modal.onDidDismiss().then((data)=>{
          let index_close = this.formService.lkpublication.index_close;
          console.log('index after close', index_close);
          this.publications[index_close]['shadow'] = true;
          console.log(this.publications[index_close]);
        });
    });
}


  async fetchPublications2(){
    const result = await this.actualizacionservice.getPublications(null, false, null, false, null);
    console.log("result en publication media template",result);
    return result;
  }

  public async getPublications2(event: any) {
    const result: any[] = await this.actualizacionservice.getPublications(event, false, null, null, null);
    let old_publications = result; // Use the result instead of this.publications
    this.publications = [];
    // let i = 0;
    old_publications.forEach((publication) => {
      if ('quienpuedever' in publication) {
        if (publication['quienpuedever'].includes('Para todos')) {
          // if (i <= 3) {
          this.publications.push(publication);
          // i++;
          // }
        }
      }
    });
  }
  

  async new_page2() {
    this.actualizacionservice.current_page++;
    console.log('pagina en actualizacion service', this.actualizacionservice.current_page);
    let publications_from_server;
    publications_from_server= await this.fetchPublications2();
    console.log('publications from server', publications_from_server);
    if (publications_from_server.length == null || publications_from_server.length === 0 ) {
      Swal2.fire({
        title: 'Tranquilo, no te desplazes tan rápido',
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
    let i = 0;
    for (const pub of publications_from_server) {
      //if (pub['publication_type'] == 'lkfilm'){
        i++;
        this.publications.push(pub);
      //}
    }
    //no hubo publicaciones de seguidores en la pagina X avanzar a la siguiente
    console.log('number of publications with lkfilm', i);
    if (i==0 && this.actualizacionservice.current_page < 4){
      this.actualizacionservice.current_page++;
      await this.new_page2();
    }else if(i==0 && this.actualizacionservice.current_page >= 4) {
      Swal2.fire({
        title: 'La velocidad del movimiento de tu dedo excede el límite',
        icon: 'warning',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
      });
      return;
    }
  }

  async onIonInfinite() {
    await this.new_page2().then(()=>{
      console.log("cargando publicaciones");

    });
  }


  validate_is_last_page(){
    let me = this;
    me.slides.getActiveIndex().then((slidesIndex) => {
      console.log('index chnaged slides', slidesIndex);
      this.formService.lkpublication.index_close = slidesIndex;
    });
    me.slides.isEnd().then(async (istrue) => {
      if (istrue) {
        me.NextSlide = 'Finish';

        this.actualizacionservice.current_page++;
        await this.new_page2();
      } else {

        me.NextSlide = 'Next';

      }
    });
  }
  */
  
  async ngOnInit() {
    if (Capacitor.getPlatform() === 'web'){
      this.web =true;
    }
    this.fetchPublications(true);
    this.changeChunk();
   
   
  }
  
  
    
  async fetchPublications(isrefresh: boolean){
    const result = await this.actualizacionservice.getAIPublications(null, isrefresh, this.pr.id, null, null) ;
    const fetchedPublications = result || [];

    
    this.filterPublications(fetchedPublications);
    
  }
  nextPub() {
    this.slides.slideNext(); // Method to move to the next slide
  }
  backPub(){
    this.slides.slidePrev(); // Method to move to the next slide
  }
  async filterPublications(publications) {
    //const filteredPublications = publications.filter((publication) => {
    //  if ('quienpuedever' in publication && publication['quienpuedever'].includes('Para todos')) {
    //    return true;
    //  }
    //  return false;
    //});
  
    if (publications.length > 0) {
      this.publications = publications;
      //this.publications = publications;
      this.ChunkedPublications = publications;
      this.chunkPublications();
   
    }
  
    console.log('siguiendo length of pub', this.ChunkedPublications.length);
  
    if (this.ChunkedPublications.length < 1 && this.actualizacionservice.current_page <3) {
      console.log('pub < x');
      this.actualizacionservice.current_page++;
      await this.fetchPublications(false);
    }
  }
  private chunkPublications() {
    const chunkSize = 4;
    const chunkedPublications = [];
    let index = 0;
  
    while (index < this.ChunkedPublications.length) {
      const chunk = this.ChunkedPublications.slice(index, index + chunkSize);
      if (chunk.length > 0) {
        chunkedPublications.push(chunk);
      }
      index += chunkSize; // Update the index by the chunkSize, not the chunk length
    }
  
    this.ChunkedPublications = chunkedPublications;

  }
  
  

  
  
  
  
  
  
  
  async handleSlideChange() {
    this.formService.changeMediaPeriodically=true;
    this.formService.mostrar = false;
    const currentIndex = await this.slides.getActiveIndex();
  
    // Check if the current slide is the last slide
    if (currentIndex === (await this.slides.length() - 1)) {
      // Fetch more publications when reaching the last slide
     
  
       await this.new_page();
    }
  }
  
  ngOnDestroy() {
    clearInterval(this.timeoutId);
    clearTimeout(this.timeoutId);
    clearTimeout(this.checkIntervalId);
    this.formService.changeMediaPeriodically=false;
  }
  
  
  changeChunk(){
    this.checkIntervalId = setInterval(() => {
      if(this.ChunkedPublications.length < 1){
return;
      }
      if(this.SlideInteraction){
        this.SlideInteraction=false;
        this.timeoutId = setTimeout(() => {
          this.SlideInteraction=true;
        }, 3000);
     // console.log('status,', this.formService.changeMediaPeriodically);
      //console.log('status2,', this.duration);
      if(this.formService.user_diasabled_autoswipe ){
        return;
      }
if(!this.formService.changeMediaPeriodically && this.formService.user_diasabled_autoswipe){
return;
}else{
        Swal2.fire({
          title: 'Siguiente',
          text: ``,
          icon: 'info',
          timer: 6900, // Show the alert for 6.9 seconds
          timerProgressBar: true,
          toast: true,
          position: 'bottom-end',
          showCancelButton: true,
        showConfirmButton:true,
          cancelButtonText: '<ion-icon name="reload"></ion-icon>No',
          confirmButtonText: '<ion-icon name="play-skip-forward-sharp"></ion-icon>Si',
          color: 'black',
          background: 'white',
          }).then((result) => {
            this.formService.changeMediaPeriodically=false;
            clearTimeout(this.timeoutId);
            clearInterval(this.timeoutId);
          if (result.isConfirmed) {
  this.nextPub();
  this.formService.changeMediaPeriodically=true;
          }else{
           
            clearTimeout(this.timeoutId);
            clearInterval(this.timeoutId);
            this.formService.changeMediaPeriodically=false;
  this.timeoutId=null;
          }
          });
  
          // Set a timeout to call getRandomPosts if there is no interaction from the user
   this.timeoutId = setTimeout(() => {

    if(!this.formService.changeMediaPeriodically){
      return;
    }
    this.nextPub();
  }, 6300);
      
}
}
    }, 6900);

  
  }

  ngAfterViewInit() {

    if (localStorage.getItem('swipe_introduction_showed') != 'true') {
     this.showSwipeIntro=true;
     setTimeout(() => {
      this.showSwipeIntro=false;
      localStorage.setItem("swipe_introduction_showed","true");
      }, 9000);
     
      }
    this.slides.slideTo(this.initial_index);
    this.slides.ionSlideDidChange.subscribe(async () => {
      await this.handleSlideChange();
    });
     let me = this;
     me.slides.slideTo(this.initial_index);
  }
  
  
  
  
  
  async new_page() {
    try {
      this.showToast();
      this.actualizacionservice.current_page++;
      const fetchedPublications = await this.actualizacionservice.getAIPublications(null, false, null, false, null);
    
      if (!fetchedPublications || fetchedPublications.length === 0) {
        Swal2.fire({
          title: 'La velocidad del movimiento de tu dedo excede el límite',
          icon: 'warning',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
        });
        return;
      }
    
      const chunkedPublications = this.chunkNewPublications(fetchedPublications);
      this.ChunkedPublications.push(...chunkedPublications);
    } catch (error) {
      console.error('Error fetching publications:', error);
    }
    
  }
  async showToast() {
    /*
    const toast = await this.toastController.create({
      message: `
        <div class="toast-content" style="background-color:black; border-radius:9px; border: solid #010f4e;">
        <img src="https://laniakea-multimedia.s3.amazonaws.com/loading_chunk.gif" alt="Image" style="width: 48px; height: 48px; border-radius:9px; border: solid white">
        </div>
      `,
      duration: 6399,
      position: 'bottom',
      cssClass: 'custom-toast detailsInModal',
      /*
      buttons: [
        {
          text: '...',
          role: 'cancel'
        }
      ]
  
    });
  
    toast.present();    */
    Swal2.fire({
      title: 'Fetching posts',
      text: ``,
      icon: 'info',
      timer: 6900, // Show the alert for 6.9 seconds
      timerProgressBar: true,
      toast: true,
      position: 'bottom-start',
    //  showCancelButton: true,
    //showConfirmButton:true,
     // cancelButtonText: '<ion-icon name="reload"></ion-icon>No',
      //confirmButtonText: '<ion-icon name="play-skip-forward-sharp"></ion-icon>Si',
      color: 'black',
      background: 'white',
      }).then((result) => {
      });
  }
  
  
  
  
  chunkNewPublications(publications) {
    const chunkSize = 4; // Define your desired chunk size here
    const chunkedArray = [];
    
    for (let i = 0; i < publications.length; i += chunkSize) {
      const chunk = publications.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    
    return chunkedArray;
  }
  
  openComponentVideoTry(index, chunk){//
  console.log('chunkk',chunk);
  console.log('indexxx',index);
  const indexxx = chunk * 4 + index;
    this.Mctrl.create({
        component: LkfilmViewBySlidesComponent,
        cssClass: 'detailsInModal',
        swipeToClose: true,
        componentProps: {
          publications: this.publications,
          index: indexxx,
          valueSelect: 'MENU'
        }
    }).then((modal)=>{
        modal.present();
    });
}

openComponentVideo(index: number, chunk: number) {
  try {
   
    this.formService.mostrar = false;
    //console.log('pubs', this.publications);
    this.formService.publications = this.publications;
    const indexxx = chunk * 4 + index;
    //this.initial_index = indexxx;
    this.formService.index = indexxx;
    this.formService.valueSelect = 'MENU';
    this.formService.mediaTemplate = true;

    // Log some information to check the values before opening the component
    console.log('Index:', index);
    console.log('Chunk:', chunk);
    console.log('Indexxx:', indexxx);
    
  } catch (error) {
    console.error('Error in openComponentVideo:', error);
  }
}



Mostrar(){
  if(this.mostrar== false){
    this.mostrar=true
  this.formService.mostrar = true;
    console.log('swiped',this.formService.mostrar );
  }else{
    this.mostrar=false
  this.formService.mostrar = false;
    console.log('swiped',this.formService.mostrar );
  }
}
formatCounter(value: number): string {
  if (value >= 1000000000000) {
    return (value / 1000000000000).toFixed(1) + 'T';
  } else if (value >= 1000000000) {
    return (value / 1000000000).toFixed(1) + 'B';
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + 'K';
  } else {
    return value.toString();
  }
}


}



///PROXIMAMENTE...
/*
async presentToast(pub) {

  this.ionViewillEnter(pub);

}
ionViewillEnter(pub) {
  this.faio.show({
    title: "Leyendo huella Digital",
    subtitle: "Use su huella para permitir el acceso a retiros",
  })
    .then((res) => {
      this.lanzamiento = setInterval(() => {
        this.mostrar = true
        console.log(`lanzamiento en ${this.numero}`);
        this.numero--
    
        if (this.numero === 0) {
          clearInterval(this.lanzamiento);
          console.log("Se terminó el tiempo con Exito!!");
    
          setTimeout(() => {
            this.mostrar = false;
            this.numero = 10;
          }, 4000);
          // Call envioRegalo() here
          this.openKYCApplicationModal(pub);
        }
      }, 1200);
      console.log(res);
      this.msg = 'Se valido el acceso con tu huella';
  
    })
    .catch((err) => {
      console.log(err);
      this.msg = 'Acceso incorrecto';
      alert(err);
    });
}
private openKYCApplicationModal(pub) {
		console.log('openKYCApplicationModal called');
		this.walletService.KYC_validation().then((result_kyc) => {
			console.log('KYC_validation result:', result_kyc);
		  if (result_kyc['permit']) {
			this.subscribedToProductGifts(pub);
		  } else if (this.pr.kyc === "Sin Validar") {
			this.showSliderInformationModal();
		  } else {
			this.showPagoVerificacionModal();
		  }
		}).catch((error) => {
			console.error('KYC_validation error:', error);
		  console.error(error);
		});
	  }
    private showSliderInformationModal() {
      console.log('showSliderInformationModal called');
      this.Mctrl.create({
        component: SliderInformationComponent,
        componentProps: {
        pr: this.pr,
        },
      }).then((modal) => {
        modal.present();
      }).catch((error) => {
        console.error(error);
      });
      }
      private showPagoVerificacionModal() {
        console.log('showPagoVerificacionModal called');
        console.log("entro al else if ");
        
        this.Mctrl.create({
          component: PagoVerificacionComponent,
          swipeToClose: true,
          componentProps: {
          pr: this.pr
          }
        }).then((modal) => {
          modal.present();
        });
        }
        async envioRegalo(pub) {
          if (!this.HubbleExpired){
          const data = {
            monto: '1',
            divisa: 'MXN',
            receiver_id: pub.user_id,
            publication_id: pub.id,
            user_id: this.pr.id,
          };
        
          this.request.createRequestPost('store_regalo', data).then(async (result: any) => {
            if (result.title === 'Error al enviar el regalo') {
              // If the server returned an error, show an error Swal2.fire
              Swal2.fire({
                title: result.title,
                text: result.message,
                icon: 'error',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
              });
            } else {
              // If the server returned a success, show a success Swal2.fire
              Swal2.fire({
                title: 'Regalo enviado a publicación con éxito',
                icon: 'success',
                confirmButtonText: 'Entendido',
                color: '#ffffff',
                background: '#010f4e',
              });
             // Fetch the updated gift count
             
            }
          }).catch((error) => {
            // Handle the error here
            console.error('Error sending gift:', error);
          });
        }
        }
        async RenewSuscription(pub): Promise<void> {
          Swal.fire({
            title: 'Sucripción de usuario...',
            text: 'Tu suscripción de usuario verificado ha expirado. Espera...',
            icon: 'success',
            timer: 4500, // Show the alert for 4.5 seconds
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false
            });
          return new Promise<void>(async (resolve, reject) => {
            this.store.verbosity = this.store.DEBUG;
          
            // Register the product with the given ID
            this.store.register({
            id: this.productId2,
            alias: this.productId2,
            type: this.store.PAID_SUBSCRIPTION, // Change the type to PAID_SUBSCRIPTION
            });
          
            // Fetch the product information
            const product = await this.store.get(this.productId2);
          
            // Updated
            this.store.when(this.productId2).updated((product: IAPProduct) => {
           
              console.log('Updated' + JSON.stringify(product));
              });
          
            // User closed the native purchase dialog
            this.store.when(this.productId2).cancelled((product) => {
            console.error('Purchase was Cancelled');
            });
          
            // Run some code only when the store is ready to be used
            this.store.ready(() => {
            console.log('Store is ready');
            console.log('Products: ' + JSON.stringify(this.store.products));
            console.log(JSON.stringify(this.store.get(this.productId2)));
            });
          
            if (product) {
            // Check if the product is already owned
            if (product.state === this.store.OWNED) {
              //this.envioRegalo(pub);
              console.log('Product already owned');
              resolve();
              return;
            }
          
            // Check if the product is invalid
            if (product.state === this.store.INVALID) {
              reject(new Error('Product is invalid'));
              return;
            }
          
            // To make a purchase
            this.store.order(this.productId2);
          
            // Proceed with the purchase flow
            this.store.when(this.productId2).approved((product) => {
              product.finish();
          
              console.log('Subscription ordered!');
          
              this.store.when(this.productId2).owned(async (product) => {
                this.envioRegalo(pub);
              console.log('Product is owned');
              this.sendNotification('Estatus de tu perfil actualizado', '¡Felicitaciones! Ya tienes acceso a la cartera Hubble.');
              
              });
            });
          
            // Handle error events
            this.store.when(this.productId2).error((err) => {
              console.log('Error occurred during purchase:', err);
              reject(err);
            });
          
            // Refresh the product information
            this.store.refresh();
            } else {
            reject(new Error('Failed to fetch product information'));
            }
          
          });
          }
          async subscribedToProductGifts(pub): Promise<void> {
            Swal.fire({
              title: 'Procesando...',
              text: 'Hubble está procesando.',
              icon: 'success',
              timer: 3000, // Show the alert for 3.9 seconds
              timerProgressBar: true,
              toast: true,
              position: 'top-end',
              showConfirmButton: false
            });
            
            this.store.register({
              id: this.productId2,
              alias: this.productId2,
              type: this.store.PAID_SUBSCRIPTION, // Change the type to PAID_SUBSCRIPTION
            });
            
            // Fetch the product information
            const product = await this.store.get(this.productId2);
            
            // Updated
            this.store.when(this.productId2).updated((product: IAPProduct) => {
              console.log('Updated: ' + JSON.stringify(product));
              if (product.owned)  this.envioRegalo(pub);
            else this.RenewSuscription(pub);
            });
            
            // User closed the native purchase dialog
            this.store.when(this.productId2).cancelled((product) => {
              console.error('Purchase was Cancelled');
            });
            
            // Run some code only when the store is ready to be used
            this.store.ready(() => {
              console.log('Store is ready');
              console.log('Products: ' + JSON.stringify(this.store.products));
              console.log(JSON.stringify(this.store.get(this.productId2)));
            });
            // Handle error events
            this.store.when(this.productId2).error((err) => {
            console.log('Error occurred during purchase:', err);
            
            });
          
            // Refresh the product information
            this.store.refresh();
            
            }
          
          sendNotification(title, body) {
           
          return new Promise((resolve, reject) => {
            const notification = {
            title: title,
            body: body,
            user_id:this.pr.id,
        
            };
          
            this.request.createRequestPost('GreatAtractorNotifications', notification)
            .then((data) => {
             
              resolve(data);
            }).catch((error) => {
              console.error('Failed to send notification:', error);
              reject(error);
            });
          });
          }
         
          
}
*/



