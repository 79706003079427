import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FooterBarComponent } from '../footer-bar/footer-bar.component';
import { HeaderBarComponent } from '../header-bar/header-bar.component';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { SaldoGoiComponent } from '../saldo-goi/saldo-goi.component';
import { ButtonsModule } from '../../common-elements/buttons/buttons-module/buttons.module';
import { WelcomeMessageComponent } from '../welcome-message/welcome-message.component';
import { PopOptionsListComponent } from '../pop-options-list/pop-options-list.component';
import { BuildMessageComponent } from '../build-message/build-message.component';
import { SinResultadosComponent } from '../sin-resultados/sin-resultados.component';
import { ConfiguracionComponent } from '../configuracion/configuracion.component';
import { CuentaComponent } from '../cuenta/cuenta.component';
import { InformacionDelUsuarioComponent } from '../informacion-del-usuario/informacion-del-usuario.component';
import { PrivacidadComponent } from '../privacidad/privacidad.component';
import { SeguridadComponent } from '../seguridad/seguridad.component';
import { VerificacionDeCuentaComponent } from '../verificacion-de-cuenta/verificacion-de-cuenta.component';
import { PasswordComponent } from '../password/password.component';
import { SliderComponent } from '../../publication/single-elements/slider/slider.component';
import { RegaloModalComponent } from '../Regalo-Modal/Regalo-Modal.component';
import { FilterBarComponent } from '../Filter-Bar/Filter-Bar.component';
import { PublicacionesColeccionComponent } from '../../publication/create/publicaciones-coleccion/publicaciones-coleccion/publicaciones-coleccion.component';
import { FormsModule } from '@angular/forms';
import { MessagesPagePage } from 'src/app/pages/messages-page/messages-page.page';
//import { WalletAppComponent } from 'src/app/components/go-ecosystem/wallet-app/wallet-app.component';
import {AIPopupComponentComponent} from 'src/app/ai-popup-component/ai-popup-component.component';
import {TpPostComponent} from 'src/app/tp-post/tp-post.component';
import {MyObservationsComponent} from 'src/app/my-observations/my-observations.component';
import{ContentPreferencesComponent}from'src/app/content-preferences/content-preferences.component';
import {StatsComponent} from 'src/app/stats/stats.component';
import {PlaylistsComponent} from 'src/app/playlists/playlists.component';

@NgModule({
	declarations: [

		PlaylistsComponent,
		StatsComponent,
		ContentPreferencesComponent,
		AIPopupComponentComponent,
		MyObservationsComponent,
		TpPostComponent,
		//WalletAppComponent,
		BuildMessageComponent,
		//ReturnBarComponent,
		FooterBarComponent,
		HeaderBarComponent,
		MainMenuComponent,
		SaldoGoiComponent,
		WelcomeMessageComponent,
		PopOptionsListComponent,
		SinResultadosComponent,
		ConfiguracionComponent,
		CuentaComponent,
		InformacionDelUsuarioComponent,
		PrivacidadComponent,
		SeguridadComponent,
		VerificacionDeCuentaComponent,
		PasswordComponent,
    RegaloModalComponent,
    FilterBarComponent,
	MessagesPagePage







		//LoadingComponentComponent
	],
	exports: [

		PlaylistsComponent,
		StatsComponent,
		ContentPreferencesComponent,
		AIPopupComponentComponent,
		MyObservationsComponent,
		TpPostComponent,
		//WalletAppComponent,
		BuildMessageComponent,
		FooterBarComponent,
		HeaderBarComponent,
		MainMenuComponent,
		SaldoGoiComponent,
		WelcomeMessageComponent,
		PopOptionsListComponent,
		SinResultadosComponent,
		ConfiguracionComponent,
		CuentaComponent,
		InformacionDelUsuarioComponent,
		PrivacidadComponent,
		SeguridadComponent,
		VerificacionDeCuentaComponent,
		PasswordComponent,
    RegaloModalComponent,
	MessagesPagePage


		//LoadingComponentComponent
	],
	entryComponents: [
		PlaylistsComponent,
		StatsComponent,
		ContentPreferencesComponent,
		AIPopupComponentComponent,
		MyObservationsComponent,
		TpPostComponent,
		//WalletAppComponent,
		BuildMessageComponent,
		FooterBarComponent,
		HeaderBarComponent,
		MainMenuComponent,
		SaldoGoiComponent,
		WelcomeMessageComponent,
		PopOptionsListComponent,
		SinResultadosComponent,
		ConfiguracionComponent,
		CuentaComponent,
		InformacionDelUsuarioComponent,
		PrivacidadComponent,
		SeguridadComponent,
		VerificacionDeCuentaComponent,
		PasswordComponent,
    RegaloModalComponent,
	MessagesPagePage






		//LoadingComponentComponent
	],
	imports: [


	CommonModule,
		IonicModule,
		ButtonsModule,
    FormsModule





	],
})
export class GeneralModuleModule { }
