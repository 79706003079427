import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BienesComponent } from '../../bienes/bienes/bienes.component';
import { Bienes2Component } from '../../bienes/bienes2/bienes2.component';
import { Bienes3Component } from '../../bienes/bienes3/bienes3.component';
import { Bienes4Component } from '../../bienes/bienes4/bienes4.component';
import { Bienes5Component } from '../../bienes/bienes5/bienes5.component';
import { Bienes6Component } from '../../bienes/bienes6/bienes6.component';
import { PrincipalBienesFeexComponent } from '../principal-bienes-feex/principal-bienes-feex.component';
import { IonicModule } from '@ionic/angular';
import { DescripcionPublicationComponent } from '../../descripcion-publication/descripcion-publication.component';
import { DescriptionModuleModule } from '../../description-module/description-module.module';
import { GooglemapsComponent } from 'src/app/components/googlemaps/googlemaps.component';


@NgModule({
  declarations: [
    GooglemapsComponent,
    /* componente principal bienes-feex */
    PrincipalBienesFeexComponent,
    BienesComponent,
    Bienes2Component,
    Bienes3Component,
    Bienes4Component,
    Bienes5Component,
    Bienes6Component,
  ],
  exports:[
    GooglemapsComponent,
    PrincipalBienesFeexComponent,
    /* Sub componente bienes*/
    BienesComponent,
    Bienes2Component,
    Bienes3Component,
    Bienes4Component,
    Bienes5Component,
    Bienes6Component,
  ],
  entryComponents:[
    GooglemapsComponent,
    /* componente principal bienes-feex */
    PrincipalBienesFeexComponent,
    BienesComponent,
    Bienes2Component,
    Bienes3Component,
    Bienes4Component,
    Bienes5Component,
    Bienes6Component,
  ],
  imports: [
    CommonModule,
    IonicModule,
    DescriptionModuleModule,
    FormsModule,   
  ]
})
export class BienesModuleModule { }
