import { Component, Input, OnInit ,OnDestroy} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IonSlides, ModalController } from '@ionic/angular';
import { RequestService } from 'src/app/services/request/request.service';

import { ViewChild } from '@angular/core';


declare const Hls: any;
@Component({
  selector: 'app-superlative-streams',
  templateUrl: './superlative-streams.component.html',
  styleUrls: ['./superlative-streams.component.scss'],
})

export class SuperlativeStreamsComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides | undefined;
  currentChannel
  @Input() url: string = '';
  @Input() iptv:any;

  @Input() channels: { name: string; logo: string; url: string; gifts?: number; likes?: number; views?: number }[] = [];
  safeUrl: SafeResourceUrl = '';
  @Input() activeChannelIndex:number =0;
  show=true;
  videoUrl;
  slideOpts = {
    loop: true,
    direction: 'horizontal',
    initialSlide: 0,
    speed: 420,
    autoplay: {
      delay: 21000,
      disableOnInteraction: true, // doesnt keeps autoplay enabled after user interaction
    }
  };

  constructor(private sanitizer: DomSanitizer,private Mctrl: ModalController,
    private request: RequestService,
    ) {}

  ngOnInit() {

    console.log('iptv', this.activeChannelIndex)
    console.log('iptv length', this.channels?.length)
    console.log('iptv ', this.iptv)
    if(!this.iptv){
    // Convert YouTube URL to embed format and add autoplay and controls parameters
    const embedUrl = this.url
      .replace('watch?v=', 'embed/')
      + '?autoplay=1&controls=0&playsinline=1';
      
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
    setTimeout(() => {
      this.show = true;
    }, 9999);
          
  }else{
    setTimeout(() => {
    console.log('channels activeChannelIndex',this.channels[this.activeChannelIndex])
    console.log('channels activeChannelIndex',this.iptv)
     this.currentChannel = this.channels[this.activeChannelIndex];
     const channel = this.channels.find((channel) => channel.url === this.iptv.url);
        // Find the index of the slide
        this.currentChannel = channel;
      
        // Find the index of the currentChannel
        const slideIndex = this.channels.indexOf(this.currentChannel);
        console.log('slideIndex', slideIndex +1 );
          // Navigate to the matching slide
          this.slides.slideTo(slideIndex +1)  ;
     if (channel) {
      this.currentChannel = channel;
      this.playChannel(channel.url, channel);
     } 
  }, 1800);
  }
  }
  ngAfterViewInit(){
   

  }
  onSlideChange(event: any) {
    this.show=false;

    const slider = event.target;
    slider.getActiveIndex().then((index: number) => {
    
        this.activeChannelIndex = index;
      
       //this.channels.find((channel) => channel.url === this.iptv.url);
           const channel = this.channels[this.activeChannelIndex-1]

           if (channel) {
            this.currentChannel = channel;
            this.playChannel(channel.url, channel);
           } 
        
        
      
    })
  }
  async playChannel(url:any, channel) {

    this.request.createRequestPost('PersistentLiveStreamIPTVController', { record: channel })
    .then((final_data) => {
      if (final_data && typeof final_data === 'string') {
        try {
          final_data = JSON.parse(final_data);
          console.log('getRecordByToken ios:',final_data);
        } catch (error) {
          console.error("Error parsing data:", error);
        }
        }
  console.log('final_data',final_data);
  let record=final_data;
     // Find the channel where the URL matches record.m3u8
     const channel = this.channels.find((channel) => channel.url === record.m3u8);

     if (channel) {
         // Update the channel object with gifts, likes, and views
         channel.gifts = record.gifts;
         channel.likes = record.likes;
         channel.views = record.views;
     } else {
         console.error('Channel not found for URL:', record.m3u8);
     }
    })
    
    const video = document.getElementById(
      `${this.currentChannel.url}`
    ) as HTMLVideoElement;
    if (!video) {
      console.error('Video element not found.');
      return;
    }
  
    // Clean up any previous Hls.js instance
    if ((window as any).hlsInstance) {
      (window as any).hlsInstance.destroy();
      (window as any).hlsInstance = null;
    }
  
    // Directly use the raw URL (no proxy)
    const directUrl = url;
  
    if (Hls.isSupported()) {
      console.log('Using Hls.js with M3U8 URL:', directUrl);
      const hls = new Hls();
      (window as any).hlsInstance = hls;
  
      hls.loadSource(directUrl);
      hls.attachMedia(video);
  
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('HLS manifest parsed, playing...');
        this.videoUrl = directUrl;
        video.play().catch(err => console.error('Play error:', err));
      });
  
      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('Hls.js error:', data);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // try to recover media error
              hls.recoverMediaError();
              break;
            default:
              // cannot recover
              hls.destroy();
              break;
          }
        }
      });
    }
    // If iOS/Safari can play .m3u8 natively
    else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      console.log('Using native HLS in Safari...');
      video.src = directUrl;
      this.videoUrl = directUrl;
      video.play().catch(err => console.error('Play error (native):', err));
    } else {
      console.error('No HLS support in this browser.');
    }
  }
  dismiss(){
    this.Mctrl.dismiss();
  }
  preventIframeClick() {
    const overlay = document.querySelector('.overlay') as HTMLElement;
    if (overlay) {
      overlay.style.pointerEvents = 'none'; // Disable clicks
    }
  }
  
}



