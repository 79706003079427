import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Capacitor, Plugins } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource, Photo, CameraOptions } from '@capacitor/camera';
import { ChangeDetectorRef } from '@angular/core';
import { UploadMultimediaService } from 'src/app/services/upload/upload-multimedia.service';
import { RequestService } from 'src/app/services/request/request.service';
import  Swal2 from 'sweetalert2';
import { FormServiceService } from 'src/app/services/publications/form-service.service';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
defineCustomElements(window);
@Component({
  selector: 'app-shooting-star7',
  templateUrl: './shooting-star7.component.html',
  styleUrls: ['./shooting-star7.component.scss'],
})

export class ShootingStar7Component implements OnInit {
  NumeroDePasajeros;
  TarifaPorHora;
  public title:any;
  public vehicle:any;
  public marca:any;
  public modelo:any;
  public ano:any;
  public matricula:any;
  public tarifa:any;
  public pasajeros:any;
showVehiculos:boolean=false;
showRegistarVehiculo:boolean=false;
showContactos:boolean=false;
mediaType: string;
MisVehiculos: any;

  constructor(private modalctrl:ModalController,
    private ref: ChangeDetectorRef,
    public uploadMedia: UploadMultimediaService,
    private request: RequestService,
    private formservice: FormServiceService) { }

  ngOnInit() {
    this.getVehicles();
  }
  onVehicleSelect(vehiculo: any) {
   
    // Handle the selected vehicle here
    console.log('Selected Vehicle:', vehiculo);
    // You can store the selected vehicle or perform other actions as needed
    this.formservice.selectedVehicle=vehiculo;
    this.dismiss();
    setTimeout(() => {
      this.modalctrl.dismiss();
      }, 399);
      setTimeout(() => {
        this.modalctrl.dismiss();
        Swal2.fire({
          title: 'El vehículo fue activado exitosmente.',
          text: 'El vehículo ha sido activdo, ya puedes comenzar como conductor en Estrellas Fugaces.',
          icon: 'success',
          confirmButtonText: 'Entendido',
          color: '#ffffff',
          background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
        });
        }, 999);
   
  }
  getVehicles(){
    this.request.createRequestPost('myVehiclesData',{ GetVehicles: 'GetMyVehicles' }) .then((data) => {
      console.log('vehicles_: ', data);
      console.log(data.vehicles);
      this.MisVehiculos=data.vehicles;
      if (typeof data === 'string') {
        let response = JSON.parse(data);
        console.log('json result publication service.ts', response);
        this.MisVehiculos=response.vehicles;
        } else {
          this.MisVehiculos=data.vehicles;
        }
    }).catch((error) => {
      console.error('Failed to send request:', error);
    });
  }
  VerFormularioParaRegistrarVehiculo(){
    this.showRegistarVehiculo=true;
  }
  onClickVehiculos(){
this.showVehiculos=true;
this.showRegistarVehiculo=false;
  }
  dismiss(){

    this.modalctrl.dismiss();
  }
  onClickContactos(){
    this.showContactos=true;
  }
  async pickImage(){
    const imagePreviewElement = document.getElementById("VehiculoimagePreview") as HTMLImageElement;
    try {
      const options: CameraOptions = {
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Photos,
        saveToGallery: false,
      };
      console.log('About to call Camera.getPhoto()');
      const media = await Camera.getPhoto(options);
  
      if (media) {

        console.log('Media picked successfully:', media);

      if (Capacitor.getPlatform() === 'web') {
        // For web, create a Blob from the media data and convert it to a data URL
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageUrl = reader.result as string;
          imagePreviewElement.src = imageUrl;
          this.ref.detectChanges(); // Manually trigger change detection
        };
        reader.readAsDataURL(blobfile);
      } else {
        // For iOS and Android, use Capacitor.convertFileSrc to get the public URL
        const imageUrl = Capacitor.convertFileSrc(media.path);
        imagePreviewElement.src = imageUrl;
      }
        this.mediaType = media.format === 'jpeg' || media.format === 'png' ? 'image' : 'video';
        this.ref.detectChanges();
        const mimeType = media.format === 'jpeg' ? 'image/jpeg' : media.format === 'png' ? 'image/png' : 'video/mp4';
  
        // Read the file as a Blob
        const response = await fetch(media.webPath);
        const blobfile = await response.blob();
        
  
        // Log the Blob for debugging purposes
        console.log('fileBlob:', blobfile);
        //const filename = media.path.substring(media.path.lastIndexOf('/') + 1);

  this.uploadMedia.blob = blobfile;
  if (Capacitor.getPlatform() !== 'web') {
        this.uploadMedia.filename = media.path.substring(media.path.lastIndexOf('/') + 1);
  }else{
    const currentTime = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
    this.uploadMedia.filename = currentTime + '.png';
  }

        
      }
    } catch (error) {
      console.error('Error picking media:', error);
    }
  }  
  onVehicleSelectChange(event: CustomEvent) {
    // Handle the selected vehicle here
    const selectedVehicle = event.detail.value;
    console.log('Selected Vehicle:', selectedVehicle);
    this.vehicle=selectedVehicle;
    switch (selectedVehicle) {
      case 'Motocicleta':
        this.TarifaPorHora = 132.3; // 65 per person. Set the TarifaPorHora value for Motocicleta

        this.NumeroDePasajeros= 2;
        break;
      case 'Automovil':
        this.TarifaPorHora = 423; // 105.75 per person. Set the TarifaPorHora value for Automovil

        this.NumeroDePasajeros= 4;
        break;
      case 'Camioneta SUV':
        this.TarifaPorHora = 540; //90 per person. Set the TarifaPorHora value for Camioneta

        this.NumeroDePasajeros= 6;
        break;
      case 'Camioneta Van':
        this.TarifaPorHora = 810; // Set the TarifaPorHora value for Vagoneta

        this.NumeroDePasajeros= 9;
        break;
      case 'Autobús':
        this.TarifaPorHora = 2100; // Set the TarifaPorHora value for Autobús

        this.NumeroDePasajeros= 33;
        break;
      case 'CamiónUnitario':
        this.TarifaPorHora = 1200; // Set the TarifaPorHora value for Camión Unitario
        break;
      case 'Camiónremolque':
        this.TarifaPorHora = 1800; // Set the TarifaPorHora value for Camión con remolque
        break;
      case 'AviónPrivado':
        this.TarifaPorHora = 42000; // Set the TarifaPorHora value for Avión privado

        this.NumeroDePasajeros= 18;
        break;
      case 'Helicóptero':
        this.TarifaPorHora = 18000; // Set the TarifaPorHora value for Helicóptero

        this.NumeroDePasajeros= 9;
        break;
      case 'Lancha':
        this.TarifaPorHora = 2100; // Set the TarifaPorHora value for Lancha

        this.NumeroDePasajeros= 12;
        break;
      case 'Yate':
        this.TarifaPorHora = 6900; // Set the TarifaPorHora value for Yate

        this.NumeroDePasajeros= 18;
        break;
      case 'Bicicleta':
        this.TarifaPorHora = 69; // Set the TarifaPorHora value for Bicicleta
        break;
      case 'Patineta':
        this.TarifaPorHora = 30; // Set the TarifaPorHora value for Patineta
        break;
      case 'Triciclo':
        this.TarifaPorHora = 45; // Set the TarifaPorHora value for Triciclo
        break;
      default:
        this.TarifaPorHora = 0; // Default value if none of the cases match
        break;
  }
}
 
  SaveVehicle(){
    console.log(this.title);
    console.log(this.vehicle);
    console.log(this.marca);
    console.log(this.modelo);
    console.log(this.ano);
    console.log(this.matricula);
    console.log(this.tarifa);
    console.log(this.pasajeros);
    console.log(this.uploadMedia.blob);
    if (!this.title) {
      Swal2.fire({
        title: 'Título faltante',
        text: 'Por favor, ingrese un título para el vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
      });
      return; // Salir de la función para evitar enviar la solicitud
    }
  
    if (!this.vehicle) {
      Swal2.fire({
        title: 'Tipo de vehículo faltante',
        text: 'Por favor, seleccione el tipo de vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return; // Salir de la función para evitar enviar la solicitud
    }
  
    // Agrega alertas para las demás variables aquí
    if (!this.marca) {
      Swal2.fire({
        title: 'Marca faltante',
        text: 'Por favor, ingrese la marca del vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
  
    if (!this.modelo) {
      Swal2.fire({
        title: 'Modelo faltante',
        text: 'Por favor, ingrese el modelo del vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
  
    if (!this.ano) {
      Swal2.fire({
        title: 'Año faltante',
        text: 'Por favor, ingrese el año del vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
  
    if (!this.matricula) {
      Swal2.fire({
        title: 'Matrícula faltante',
        text: 'Por favor, ingrese la matrícula del vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
  
    if (!this.tarifa) {
      Swal2.fire({
        title: 'Tarifa faltante',
        text: 'Por favor, ingrese la tarifa por hora del vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
  
    if (!this.pasajeros) {
      Swal2.fire({
        title: 'Número de pasajeros faltante',
        text: 'Por favor, ingrese el número de pasajeros del vehículo.',
        icon: 'error',
        confirmButtonText: 'Entendido',
        color: '#ffffff',
        background: '#010f4e',
        position: 'top-end',
        timerProgressBar: true,
        toast: true,
      });
      return;
    }
    if (!this.uploadMedia.blob){
      Swal2.fire({
				title: 'Hubo un error inesperado',
				text: 'Porfavor su una imagen del vehículo antes de continuar. Si el error persiste comunícate con el soporte de Laniakea',
				icon: 'error',
				confirmButtonText: 'Entendido',
				color: '#ffffff',
			  background: '#010f4e',
          position: 'top-end',
          timerProgressBar: true,
          toast: true,
			  });
        return; // Salir de la función para evitar enviar la solicitud
    }
    this.uploadMedia.post(this.uploadMedia.blob, this.uploadMedia.filename).then(({ link, mimeType }) => {
 

         const ruta = link;
         console.log('ruta', ruta);
        
         this.request.createRequestPost('myVehiclesData',{ ImageUrl: ruta, Titulo:this.title, vehiculo:this.vehicle, Marca:this.marca, Modelo: this.modelo, Año:this.ano, Matricula:this.matricula, Tarifa: this.tarifa, Pasajeros:this.pasajeros,  }) .then((data) => {
           console.log(data);
           Swal2.fire({
            title: 'Vehiculo guardado.',
            text: 'El vehículo ha sido guardado exitosamente.',
            icon: 'success',
            confirmButtonText: 'Entendido',
            color: '#ffffff',
            background: '#010f4e',
            position: 'top-end',
            timerProgressBar: true,
            toast: true,
            });
            this.dismiss();
         }).catch((error) => {
           console.error('Failed to send request:', error);
         });
    
       }).catch((error) => {
         console.error('Failed to send request:', error);
       });
  }
  calculateRoundedValue(value: number): number {
    return Math.round(value);
  }
  
}
